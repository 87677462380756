import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { capitalizeFirstLetter } from '../../../../../helpers/utils';
import { SupplierPriceData } from './types';
import theme from '../../../../../styles/theme';

export const useSupplierPricesChart = () => {
  const { t } = useTranslation();

  const handleFormatValueFields = (number: string | number): string => {
    try {
      return Number(number).toLocaleString(t('common.localeMoney'), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } catch (error) {
      console.log(error);
      return '-';
    }
  };

  const CustomLegend = ({ payload }: any) => {
    return (
      <S.LegendContainer>
        {payload.length !== 0 ? (
          payload.map((item: any, index: any) => (
            <>
              <S.IndicatorLegend index={index} />
              <S.TextLegend key={item.vendorName}>{item.vendorName}</S.TextLegend>
            </>
          ))
        ) : (
          <div>{t('pages.procurement.supplierPriceCostTrend.noLegend')}</div>
        )}
      </S.LegendContainer>
    );
  };

  const DataLegend = (dataLegend: any) => {
    let BiggerArrayLegend: any = [];
    if (dataLegend.data.length !== 0) {
      dataLegend.data.forEach((legend: any) => {
        if (legend.items.length > BiggerArrayLegend.length) {
          BiggerArrayLegend = legend.items;
        }
      });
    }
    return BiggerArrayLegend;
  };

  const CustomToolTip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <S.TooltipContainer>
          <S.TooltipBodyContainer>
            {payload[0].payload.supplierData.map((data: any, index: number) => (
              <S.ItensContainer key={data.name}>
                <S.LegendTooltip index={index} />
                <S.SupplierContentContainer>
                  <S.SupplierName>{data.name}</S.SupplierName>
                  <S.PartNumber>{data.partNumber}</S.PartNumber>
                  <S.ShareProportionContainer>
                    <S.ShareProportion>
                      {t('pages.procurement.supplierPriceCostTrend.shareProportion')}
                    </S.ShareProportion>
                    <S.ShareProportionValue>{data.shareProportion}</S.ShareProportionValue>
                  </S.ShareProportionContainer>
                </S.SupplierContentContainer>
              </S.ItensContainer>
            ))}
          </S.TooltipBodyContainer>
        </S.TooltipContainer>
      );
    }
    return null;
  };
  const CustomDataXlabel = (tickFormater: string) => {
    return dayjs(capitalizeFirstLetter(tickFormater.split('/')[0]), 'MMMM', 'en')
      .locale(t('common.dayjs'))
      .format('MMMM');
  };

  const dataChange = (dataSupplier: any) => {
    const dataTrate: Array<SupplierPriceData> = [];
    try {
      if (dataSupplier.data.length !== 0) {
        dataSupplier.data.map((dt: any) => {
          dataTrate.push({
            month: dt.month,
            supplier1: dt.items[0] ? dt.items[0].totalInvoiceFbr.toFixed(2) : null,
            supplier2: dt.items[1] ? dt.items[1].totalInvoiceFbr.toFixed(2) : null,
            supplier3: dt.items[2] ? dt.items[2].totalInvoiceFbr.toFixed(2) : null,
            supplierData: dt.items.map((item: any) => ({
              name: item.vendorName,
              partNumber: item.partNumbers[0],
              shareProportion: item.compQty,
            })),
          });
          return dataTrate;
        });
        return dataTrate;
      }
    } catch (error) {
      console.log(error);
      return dataTrate;
    }
  };

  const formatTick = (number: string) => {
    const num = Number(number);
    if (num >= 1e3) {
      return `$${num / 1e3}K`;
    }
    if (num >= 1e6) {
      return `$${num / 1e6}M`;
    }
    return `$${number}`;
  };
  const CustomLabel = ({ x: eixoX, y: eixoY, width, value }: any) => {
    if (value) {
      return (
        <text
          x={eixoX + width / 2}
          y={eixoY - 8}
          fill={theme.colorsDesignSystem.grayAcer}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {handleFormatValueFields(value)}
        </text>
      );
    }
    return null;
  };

  return {
    CustomLegend,
    DataLegend,
    CustomToolTip,
    CustomDataXlabel,
    dataChange,
    formatTick,
    CustomLabel,
  };
};
