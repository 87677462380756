import styled from 'styled-components';

export const Container = styled.main`
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.background};
  min-height: 95vh;
`;

export const BackPageQuotesContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.dark_green};
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  p {
    margin: 0;
  }
`;

export const TitlePage = styled.p`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.text};
  margin-top: 1.5rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 2.5rem 1rem 2.5rem;
  height: 6.5rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
  border-radius: ${({ theme }) => theme.dimensions.border_radius_large};
  margin-bottom: 1rem;
`;

export const QuotePriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #00000026;
  width: 40%;
`;

export const LabelInfoContainer = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorsDesignSystem.headerSubTitleTableText};
`;

export const InfoContainerText = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  font-weight: bold;
`;

export const QuotesInfoPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.875rem;
  gap: 0.25rem;
  margin-top: 0.5rem;

  span {
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-weight: 300;
  }

  p {
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-weight: bold;
    margin: 0;
  }
`;

export const OtherInformationsPriceContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const SingleInformationPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const DataCellTableContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3.375rem;

  &.right {
    justify-content: flex-end;
  }

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  span {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-weight: 400;
  }
`;

export const HeaderCellTable = styled.div`
  display: flex;
  flex-direction: row;
  height: 3.438rem;
  align-items: center;
  border-top-left-radius: 10px;

  padding-left: 1rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.tag_background};

  span {
    border-right: 1px solid #00000026;
    width: 100%;
    padding-right: 1rem;
    font-weight: bold;
  }
`;

export const Demand = styled.span<{ isBlue?: boolean }>`
  font-size: 0.875rem;
  color: ${({ theme, isBlue }) =>
    isBlue ? theme.colorsDesignSystem.blue : theme.colorsDesignSystem.text};
`;
