import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Spin, Space, Checkbox, Radio, InputNumber } from 'antd';
import {
  PlusOutlined,
  LoadingOutlined,
  ExclamationCircleFilled,
  WarningFilled,
  CheckCircleFilled,
} from '@ant-design/icons';
import { oneAlert } from '../../../../helpers/utils';
import { SelectInput } from '../../../../components/LateralFilter/SelectInput';
import * as SC from '../../../../styles/common';
import * as S from './styles';
import { ComponentsTypeSelect } from '../../../../components/forms/ComponentsTypeSelect';
import { useCrateRules } from './useCreateRules';

export function CreateRulesPPB({ onClose, form, save, loading }) {
  const {
    statusCritical,
    statusHigh,
    statusGood,
    isBonus,
    setIsBonus,
    setGoodMin,
    setHighMax,
    setGoodMax,
    setHighMin,
    setCriticalMax,
    setCriticalMin,
    HandleCriticalStatus,
    HandleHighStatus,
    HandleGoodStatus,
  } = useCrateRules();
  const { t } = useTranslation();
  const [maxScore, setMaxScore] = useState();

  useEffect(() => {
    HandleCriticalStatus(form.getFieldValue(['variablesScore']));
    HandleHighStatus(form.getFieldValue(['variablesScore']));
    HandleGoodStatus(form.getFieldValue(['variablesScore']));
    setMaxScore(form.getFieldValue(['maxScore']));
  }, [form.getFieldValue(['variablesScore'])]);

  useEffect(() => {
    setIsBonus(form.getFieldValue(['bonusStage']) === 'Yes');
  }, [form.getFieldValue(['bonusStage'])]);
  const formValues = {
    bonusStage: 'No',
    calcBase: 'Finish Good',
  };

  const validateMessages = {
    required: `\${label} ${t('common.validate.isRequired')}`,
    pattern: {
      mismatch: `\${label} ${t('common.validate.invalid')}`,
    },
    types: {
      number: `\${label} ${t('common.validate.number')}`,
    },
    string: {
      max: `\${label} ${t('common.validate.max')} \${max} ${t('common.characters')}`,
      range: `\${label}' ${t('common.validate.range')} \${min} ${t('common.and')} \${max} ${t(
        'common.characters'
      )}`,
    },
    number: {
      range: `\${label} ${t('common.validate.range')} \${min} ${t('common.and')} \${max}`,
    },
  };

  const handleValidateFormBeforeSave = () => {
    if (
      statusCritical.max === 'error' ||
      statusCritical.min === 'error' ||
      statusHigh.max === 'error' ||
      statusHigh.min === 'error' ||
      statusGood.max === 'error' ||
      statusGood.min === 'error'
    ) {
      oneAlert('error', t('pages.ppb.fields.variablesScore.fieldsError'));
      return false;
    }

    return true;
  };

  const onFinish = async (values) => {
    if (handleValidateFormBeforeSave()) {
      await save();
    }
  };

  return (
    <Row justify="center">
      <Col span={24}>
        <Spin
          tip="Loading..."
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            validateMessages={validateMessages}
            initialValues={formValues}
          >
            <Row gutter={[16, 20]}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label={t('pages.ppb.fields.description')}
                  rules={[{ required: true }]}
                >
                  <Input.TextArea autoSize placeholder={t('pages.ppb.fields.description')} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.List name="partTypes" label={t('pages.ppb.fields.partTypes')}>
                  {(fields, { add, remove }) => (
                    <>
                      <Row gutter={16}>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Col span={6} key={key}>
                            <Space align="baseline">
                              <Form.Item
                                {...restField}
                                name={[name, 'name']}
                                fieldKey={[fieldKey, 'name']}
                                rules={[{ required: true }]}
                                messageVariables={{ label: t('pages.ppb.fields.partTypes') }}
                              >
                                <ComponentsTypeSelect
                                  placeholder={t('pages.ppb.fields.partTypes')}
                                  style={{ width: 240 }}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'countSubComponents']}
                                fieldKey={[fieldKey, 'countSubComponents']}
                                valuePropName="checked"
                                noStyle
                                initialValue={false}
                                hidden
                              >
                                <Checkbox>{t('pages.ppb.fields.countSubComponent')}</Checkbox>
                              </Form.Item>
                              <S.CloseCircleOutlined onClick={() => remove(name)} />
                            </Space>
                          </Col>
                        ))}
                      </Row>
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          {t('common.add')} {t('pages.ppb.fields.partTypes')}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>

              <Col span={10}>
                <Form.Item name="calcBase" label={t('pages.ppb.fields.calcBase')}>
                  <SelectInput
                    placeholder={t('pages.ppb.fields.calcBase')}
                    allowClear
                    options={[
                      { value: 'Finish Good', label: 'Finish Good' },
                      { value: 'Part Type', label: 'Part Type' },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col span={10}>
                <Form.Item
                  name="maxScore"
                  label={t('pages.ppb.fields.maxScore')}
                  rules={[{ required: true, pattern: /^[1-9][0-9.]*$/ }]}
                >
                  <Input placeholder={t('pages.ppb.fields.maxScore')} />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item
                  name="bonusStage"
                  label={t('pages.ppb.fields.bonusStage')}
                  rules={[{ required: true }]}
                >
                  <Radio.Group onChange={(e) => setIsBonus(e.target.value === 'Yes')}>
                    <Radio value="No">{t('common.no')}</Radio>
                    <Radio value="Yes">{t('common.yes')}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Form disabled={isBonus} form={form} onFinish={onFinish}>
            <Col span={24}>
              <S.VariablesTitle>{t('pages.ppb.fields.variablesScore.title')}</S.VariablesTitle>

              <S.VariablesContainer>
                <Col>
                  <S.LabelContainer>
                    <S.ExclamationIcon />
                    {t('pages.ppb.fields.variablesScore.critical')}
                  </S.LabelContainer>
                  <S.ItensContainer>
                    <S.InputContainer>
                      <Form.Item
                        name={['variablesScore', 'criticalMin']}
                        label={t('pages.ppb.fields.variablesScore.min')}
                        style={{ marginBottom: '0' }}
                        disabled
                      >
                        <InputNumber
                          onChange={setCriticalMin}
                          status={statusCritical.min}
                          step={0.01}
                          max={maxScore}
                        />
                      </Form.Item>
                      {statusCritical.min && (
                        <S.ErrorLabel>{t('pages.ppb.fields.variablesScore.minError')}</S.ErrorLabel>
                      )}
                    </S.InputContainer>
                    <S.InputContainer>
                      <Form.Item
                        name={['variablesScore', 'criticalMax']}
                        label={t('pages.ppb.fields.variablesScore.max')}
                        style={{ marginBottom: '0' }}
                      >
                        <InputNumber
                          onChange={setCriticalMax}
                          status={statusCritical.max}
                          step={0.01}
                          max={maxScore}
                        />
                      </Form.Item>
                      {statusCritical.max && (
                        <S.ErrorLabel>
                          {t('pages.ppb.fields.variablesScore.criticalMaxError')}
                        </S.ErrorLabel>
                      )}
                    </S.InputContainer>
                  </S.ItensContainer>
                </Col>
                <Col>
                  <S.LabelContainer>
                    <S.WarningIcon />
                    {t('pages.ppb.fields.variablesScore.highRisk')}
                  </S.LabelContainer>
                  <S.ItensContainer>
                    <S.InputContainer>
                      <Form.Item
                        name={['variablesScore', 'highMin']}
                        label={t('pages.ppb.fields.variablesScore.min')}
                        style={{ marginBottom: '0' }}
                      >
                        <InputNumber
                          onChange={setHighMin}
                          status={statusHigh.min}
                          step={0.01}
                          max={maxScore}
                        />
                      </Form.Item>
                      {statusHigh.min && (
                        <S.ErrorLabel>{t('pages.ppb.fields.variablesScore.minError')}</S.ErrorLabel>
                      )}
                    </S.InputContainer>
                    <S.InputContainer>
                      <Form.Item
                        name={['variablesScore', 'highMax']}
                        label={t('pages.ppb.fields.variablesScore.max')}
                        style={{ marginBottom: '0' }}
                      >
                        <InputNumber
                          onChange={setHighMax}
                          status={statusHigh.max}
                          step={0.01}
                          max={maxScore}
                        />
                      </Form.Item>
                      {statusHigh.max && (
                        <S.ErrorLabel>
                          {t('pages.ppb.fields.variablesScore.highRiscMaxError')}
                        </S.ErrorLabel>
                      )}
                    </S.InputContainer>
                  </S.ItensContainer>
                </Col>
                <Col>
                  <S.LabelContainer>
                    <S.CheckIcon />
                    {t('pages.ppb.fields.variablesScore.good')}
                  </S.LabelContainer>
                  <S.ItensContainer>
                    <S.InputContainer>
                      <Form.Item
                        name={['variablesScore', 'goodMin']}
                        label={t('pages.ppb.fields.variablesScore.min')}
                        style={{ marginBottom: '0' }}
                      >
                        <InputNumber
                          onChange={setGoodMin}
                          status={statusGood.min}
                          step={0.01}
                          max={maxScore}
                        />
                      </Form.Item>
                      {statusGood.min && (
                        <S.ErrorLabel>{t('pages.ppb.fields.variablesScore.minError')}</S.ErrorLabel>
                      )}
                    </S.InputContainer>
                    <S.InputContainer>
                      <Form.Item
                        name={['variablesScore', 'goodMax']}
                        label={t('pages.ppb.fields.variablesScore.max')}
                        style={{ marginBottom: '0' }}
                      >
                        <InputNumber
                          onChange={setGoodMax}
                          status={statusGood.max}
                          step={0.01}
                          max={maxScore}
                        />
                      </Form.Item>
                    </S.InputContainer>
                  </S.ItensContainer>
                </Col>
              </S.VariablesContainer>
            </Col>
          </Form>
          <Form form={form} onFinish={onFinish}>
            <Form.Item>
              <S.FooterContainer>
                <Button danger onClick={onClose}>
                  {t('common.cancel')}
                </Button>
                <Button type="primary" htmlType="submit" data-cy="save">
                  {t('common.save')}
                </Button>
              </S.FooterContainer>
            </Form.Item>
          </Form>
        </Spin>
      </Col>
    </Row>
  );
}
