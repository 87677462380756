import styled from 'styled-components';
import { Row as AtdRow } from 'antd';

export const Row = styled(AtdRow)`
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
  justify-content: flex-end;
  gap: 0.25rem;
  b {
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
`;
