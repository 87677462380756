import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../../services/api';
import { getURI } from '../../../helpers/utils';
import { SelectInput } from '../../LateralFilter/SelectInput';

async function fetchSuppliers(valueAsId = false) {
  const params = { order: 'shortName', orderBy: 'ASC' };
  return api.get(getURI('suppliers', params)).then((resp) =>
    resp.data.content.map((supplier) => ({
      label: supplier.shortName,
      value: valueAsId ? supplier.id : supplier.shortName,
      key: supplier.id,
    }))
  );
}

export default function SuppliersSelect({ valueAsId, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setFetching(true);
    fetchSuppliers(valueAsId).then((newOptions) => {
      setOptions(newOptions);
      setFetching(false);
    });
  }, []);

  return (
    <SelectInput
      showSearch
      filterOption={(input, option) => {
        return (
          option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
      loading={fetching}
      options={options}
      {...props}
      placeholder={props.placeholder || t('filter.supplier')}
    />
  );
}
