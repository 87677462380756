const monthDefaultValues = {
  price: {
    value: '0',
    edited: false,
  },
  factor: {
    value: '1',
    edited: false,
  },
  locked: false,
};

export const initialPrice = {
  spec: '', //nao precisa mandar
  partNumber: '', //id do componente
  supplier: '', //id do supplyer
  january: { ...monthDefaultValues },
  february: { ...monthDefaultValues },
  march: { ...monthDefaultValues },
  april: { ...monthDefaultValues },
  may: { ...monthDefaultValues },
  june: { ...monthDefaultValues },
  july: { ...monthDefaultValues },
  august: { ...monthDefaultValues },
  september: { ...monthDefaultValues },
  october: { ...monthDefaultValues },
  november: { ...monthDefaultValues },
  december: { ...monthDefaultValues },
};
