import i18n from 'i18next';

export const formatValueToCash = (value: number) => {
  if (value) {
    return value.toLocaleString(i18n.t('common.localeMoney'), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
};
