import { Tag } from 'antd';
import styled from 'styled-components';
import { ThemeIndicationTag } from './types';

export const StyledTagRfq = styled(Tag)<ThemeIndicationTag>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 0.25rem !important;
  border-color: ${({ borderColor }) => borderColor};
  color: ${({ fontColor }) => fontColor};
  span {
    font-size: 0.75rem;
    color: ${({ borderColor }) => borderColor};
    font-weight: 700;
  }
`;
