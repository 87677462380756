import { PageHeader } from '@ant-design/pro-layout';
import styled from 'styled-components';

export const StyledPageHeader = styled(PageHeader)`
  padding-top: 1rem;
  padding-inline: 1.5rem;
  .ant-page-header-heading {
    border-bottom: 0px;
    margin-bottom: 0px;
  }
`;
