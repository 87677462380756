import { Col } from 'antd';
import styled from 'styled-components';

export const Container = styled(Col)`
  padding: 1.5rem 2.5rem;
  background: ${({ theme }) => theme.colors.newBackground};
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ScoreColor = styled.span`
  color: ${({ score }) => {
    if (score < 42) {
      return '#FF0000';
    }

    if (score > 42) {
      return '#FF9900';
    }

    return '#0B5394';
  }};
`;
