import styled from 'styled-components';
import theme from '../../../../../../styles/theme';

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border: 0.063rem solid ${theme.colorsDesignSystem.grayBorder};
  border-radius: 0.75rem;
  width: 33%;
  height: 21.25rem;
  box-sizing: content-box;
  &:first-child {
    margin-left: 0.5rem;
  }
  &:last-child {
    margin-right: 0.5rem;
  }
  @media (max-width: 1870px) {
    width: 48%;
  }
  @media (max-width: 1310px) {
    width: 70%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MonthContainer = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 2.5rem;
  margin-top: 1.25rem;
`;
