import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $trimTextContentFromAnchor } from '@lexical/selection';
import { $restoreEditorState } from '@lexical/utils';
import { $getSelection, $isRangeSelection, EditorState, RootNode } from 'lexical';
import { useEffect } from 'react';
import { ICharacterLimit } from './types';

export function CharacterLimit({ maxCharacters, countCharacters }: ICharacterLimit) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    let lastRestoredEditorState: EditorState | null = null;

    return editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return;
      }

      const prevEditorState = editor.getEditorState();
      const prevTextContentSize = prevEditorState.read(() => rootNode.getTextContentSize());
      const textContentSize = rootNode.getTextContentSize();
      countCharacters(textContentSize);

      if (prevTextContentSize !== textContentSize) {
        const delCount = textContentSize - maxCharacters;
        const { anchor } = selection;

        if (delCount > 0) {
          if (
            prevTextContentSize === maxCharacters &&
            lastRestoredEditorState !== prevEditorState
          ) {
            lastRestoredEditorState = prevEditorState;
            $restoreEditorState(editor, prevEditorState);
          } else {
            $trimTextContentFromAnchor(editor, anchor, delCount);
          }
        }
      }
    });
  }, [editor, maxCharacters]);

  return null;
}
