import { Row } from 'antd';
import styled from 'styled-components';

export const NumberValueText = styled.div`
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  font-size: 1.5rem;
  font-weight: 600;
`;

export const UsdText = styled.div`
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  font-weight: 300;
  font-size: 0.75rem;
  padding-bottom: 0.2rem;
`;

export const SkuText = styled.div`
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  font-size: 0.625rem;
  font-weight: 400;
`;

export const ColorBar = styled.div`
  margin: 0.25rem;
  padding: 0.1rem;
  border-radius: 12px;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const UsdContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  align-items: end;
`;

export const ContainerRow = styled(Row)`
  gap: 0.5rem;
`;
