import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  line-height: 1rem;

  span {
    font-size: 1rem;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: unset;
  justify-content: center;
  min-width: 20.668rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  .blue {
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
`;

export const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const AltGroup = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const Classification = styled.span`
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 3.5rem;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  padding: 0.1rem 0.5rem;
`;

export const GrossPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #dedede;
  gap: 4px;
  padding: 0.7rem 0.5rem;
`;

export const GrossPriceLabel = styled.span`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.timeStop};
`;

export const GrossPriceValue = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-inline: 0.8rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
`;
