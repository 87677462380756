import { InfoCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 0.8rem 0rem;
`;

export const BlueTextSubTitle = styled.div`
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 120% */
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
  background: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.header};
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
`;

export const StyledInfoIcon = styled(InfoCircleFilled)`
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
`;

export const BlueBox = styled.div`
  display: flex;
  gap: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.blue};
  background-color: aliceblue;
  padding: 0.5rem;
  border-radius: 0.25rem;
`;
