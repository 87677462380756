import { CloseOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledFilterTag from '../../../components/Common/StyledFilterTag';
import StyledClearTag from '../../../components/Common/StyledFilterTag/StyledClearTag';
import { StyledSelectOptions } from '../../../components/Common/StyledSelect/types';
import { oneAlert } from '../../../helpers/nUtils';
import { getSortOrder, getURI } from '../../../helpers/utils';
import { api } from '../../../services/api';
import theme from '../../../styles/theme';
import { CellMoneyHeaderSpan, CellMoneySpan } from './styles';
import {
  AllData,
  BodyFiltersOptionsData,
  ClearFieldsBodyFormProps,
  PageFilters,
  UpdateFieldsBodyFormProps,
  allFiltersSku,
  columnsTypes,
  dataType,
  headerFilters,
} from './types';
import { useCmsContext } from '../../../context/CmsContext';

export const useFinanceDashboard = () => {
  const { handleGetCms, handleSelectDefaultCm } = useCmsContext();
  const CMs = handleGetCms();

  const initilParams = {
    product: '',
    cm: handleSelectDefaultCm(''),
    level: 'L10',
    skus: [],
    projectNames: [],
    month: dayjs().format('MMMM'),
    year: dayjs().format('YYYY'),
    withForecast: false,
    order: '',
    orderBy: '',
    date: dayjs(),
  } as PageFilters;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFirstRendering, setIsFirstRendering] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState<boolean>(true);
  const [params, setParams] = useState(initilParams);
  const [lastData, setLastData] = useState<dayjs.Dayjs>(dayjs().subtract(1, 'day'));
  const [headerFilterData, setHeaderFilterData] = useState<headerFilters>({
    level: [],
    productCategory: [],
  });
  const [allSkuData, setAllSkuData] = useState<allFiltersSku>({
    SKU_01: [],
    SKU_02: [],
    SKU_03: [],
  });
  const [allData, setAllData] = useState<AllData>();
  const [bodyData, setBodyData] = useState<Array<dataType>>([]);
  const [selectedData, setSelectedData] = useState<Array<dataType>>([]); //data selecionada na table
  const [skusList, setSkusList] = useState<Array<StyledSelectOptions>>([]);
  const [allProjectsWithSku, setAllProjectsWithSku] = useState<Array<BodyFiltersOptionsData>>([]);
  const [projectsList, setProjectsList] = useState<Array<StyledSelectOptions>>([]);
  const [currentDate, setCurrentDate] = useState(dayjs(new Date()));
  const [threeFirstSelectedSkus, setThreeFirstSelectedSkus] = useState<Array<dataType>>([]);

  const updateThreeFirstSelectedSkus = (data: Array<dataType>) => {
    setThreeFirstSelectedSkus(
      data.sort((a: dataType, b: dataType) => Number(b.total) - Number(a.total)).slice(0, 3)
    );
  };

  const clearSelectedSkus = () => {
    setThreeFirstSelectedSkus([]);
  };

  const getThreeFirstSkusSelected = () => {
    const idList = selectedData.map((item) => item.id);
    const sortedSelectedData = bodyData.filter((item) => {
      return idList.includes(item.id);
    });
    return sortedSelectedData.slice(0, 3);
  };

  /**
   * Filter Skus by project name selected on the form
   * @param projectName The project name to be used to filter the data
   * @returns Array<StyleSelectOptions> with the skus filtered by the project name
   */
  const handleFilterSkuByProjectName = (
    projectName: string,
    data?: Array<BodyFiltersOptionsData>
  ): Array<StyledSelectOptions> => {
    const allProjects = data || allProjectsWithSku;
    const newAllProjectsWithSku = allProjects
      .filter((item: BodyFiltersOptionsData) => item.project === projectName || item.project === '')
      .map((item: any) => {
        return {
          value: item.sku,
          label: item.sku,
        };
      });

    return newAllProjectsWithSku;
  };

  /**
   *
   * @param qtColors how many colors will be generated
   * @returns list of colors
   */
  function colorsGenerator(qtColors: number) {
    const red = 123;
    const green = 45;
    const blue = 20;

    const color = `rgb(${((red * qtColors) % 180) + 35}, ${((green * qtColors) % 180) + 30}, ${
      ((blue * qtColors) % 180) + 35
    })`;

    return color;
  }

  /**
   *
   * @param field Update the data of the sku, based on the project name selected
   * @param value The value will be used to filter the data
   */
  const handleUpdateSkuData = (
    field: string,
    value: string,
    data?: Array<BodyFiltersOptionsData>
  ) => {
    setAllSkuData((prevState) => {
      return {
        ...prevState,
        [field]: handleFilterSkuByProjectName(value, data),
      };
    });
  };

  const [form] = Form.useForm();
  const [formBody] = Form.useForm();

  const { t } = useTranslation();

  const formatDateForCard = (month: string, year: string) => {
    return `${month.slice(0, 3)} ${year}`;
  };

  const formatProjectNameParamsForCard = (projects: any) => {
    const projectsView = projects.filter((item: any) => item).join(' x ');
    if (!projectsView) {
      return 'All Projects';
    }
    return `${projectsView}`;
  };

  /**
   * Used to convert data to the format of StyledSelectOptions, ot be used on StyledSelect component
   * @param data data to be converted, like ['L6', 'L10']
   * @returns Array of StyledSelectOptions
   */
  const handleConvertDataToStyledSelectOptionsAndViewAll = (
    data: any
  ): Array<StyledSelectOptions> => {
    const optionsData = data.map((item: string) => {
      return {
        value: item,
        label: item,
      };
    });

    optionsData.unshift({ value: '', label: t('common.viewAll') });
    return optionsData;
  };

  /**
   * Used to convert data to the format of StyledSelectOptions, ot be used on StyledSelect component
   * @param data data to be converted, like ['L6', 'L10']
   * @returns Array of StyledSelectOptions
   */
  const handleConvertDataToStyledSelectOptions = (data: any): Array<StyledSelectOptions> => {
    const optionsData = data.map((item: string) => {
      return {
        value: item,
        label: item,
      };
    });

    return optionsData;
  };

  const updateDate = (date: any) => {
    setCurrentDate(date);
  };

  const fetchRecentData = async () => {
    setIsFirstRendering(true);
    try {
      const { data, status } = await api.get(
        getURI('/finance/quote/dashboard/recent-data', {
          date: dayjs().format('DD-MM-YYYY'),
          cm: params.cm,
        })
      );
      if (status === 200) {
        const formattedDate = dayjs(data, 'MM/YYYY');
        setLastData(dayjs(formattedDate));

        setParams({
          ...params,
          date: formattedDate,
          month: formattedDate.format('MMMM'),
          year: formattedDate.format('YYYY'),
        });

        updateDate(formattedDate);
        return;
      }
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message || t('toast.errorOnList') });
    } finally {
      setIsLoading(false);
      setIsFirstRendering(false);
    }
  };

  /**
   * Fetch data for the options from the filters etAllOnlyUniqueon Header
   * @returns error if something goes wrong
   */
  const fetchHeaderFiltersContent = async () => {
    try {
      const { data } = await api.get('/finance/quote/dashboard/header');
      setHeaderFilterData({
        level: handleConvertDataToStyledSelectOptions(data.level),
        productCategory: handleConvertDataToStyledSelectOptionsAndViewAll(data.productCategory),
      });
    } catch (error) {
      return error;
    }
  };

  const getAllSkuByForm = () => {
    return [
      formBody.getFieldValue('sku1'),
      formBody.getFieldValue('sku2'),
      formBody.getFieldValue('sku3'),
    ];
  };

  const getAllProjectNamesByForm = () => {
    return [
      formBody.getFieldValue('projectName1'),
      formBody.getFieldValue('projectName2'),
      formBody.getFieldValue('projectName3'),
    ];
  };

  /**
   *
   * @returns params without empty values
   */
  const ignoreEmptyParams = () => {
    const updatedParams: any = {
      ...params,
      skus: getAllSkuByForm().join(','),
      projectNames: getAllProjectNamesByForm().join(','),
    };
    const keys = Object.keys(updatedParams);
    const paramsWithoutEmpty: any = {};
    keys.forEach((key) => {
      if (updatedParams[key] !== '') {
        paramsWithoutEmpty[key] = updatedParams[key];
      }
    });
    return paramsWithoutEmpty;
  };

  /**
   * fetch data for the table body
   * @returns error if something goes wrong
   */
  const fetchBodyContent = async (updateSelectedData = false) => {
    setTableIsLoading(true);
    const paramsWithoutEmpty = ignoreEmptyParams();
    try {
      const { data } = await api.get('/finance/quote/dashboard/filter', {
        params: {
          ...paramsWithoutEmpty,
        },
      });
      const skuCostListWithColors: Array<dataType> = data.skuCostList.map(
        (item: any, index: number) => {
          return { ...item, color: colorsGenerator(index + 1) };
        }
      );

      data.skuCostList = skuCostListWithColors;
      updateThreeFirstSelectedSkus(data.skuCostList);
      updateSelectedData && setSelectedData(data.skuCostList);
      setAllData(data);
      setBodyData(data.skuCostList);
    } catch (error) {
      return error;
    } finally {
      setTableIsLoading(false);
    }
  };

  /**
   *
   * @param order the way you want to order, like ASC or DESC
   * @param orderBy the field you want to order
   * @returns error if something goes wrong
   */
  const orderBodyContent = async (order: string, orderBy: string) => {
    setTableIsLoading(true);
    const paramsWithoutEmpty = ignoreEmptyParams();
    try {
      const { data } = await api.get('/finance/quote/dashboard/filter', {
        params: {
          ...paramsWithoutEmpty,
          orderBy,
          order,
        },
      });
      setAllData(data);
      setBodyData(data.skuCostList);
    } catch (error) {
      return error;
    } finally {
      setTableIsLoading(false);
    }
  };

  const getAllOnlyUnique = (array: any, field: string) => {
    const AllItems = array.map((item: any) => item[field]);
    const uniqueItems: Array<string> = [];
    AllItems.forEach((item: any) => {
      if (!uniqueItems.includes(item)) {
        uniqueItems.push(item);
      }
    });
    return handleConvertDataToStyledSelectOptions(uniqueItems);
  };

  /**
   * Fetch all body filter content, like sku and project name
   * @returns error if something goes wrong
   */
  const fetchBodyFilterContent = async () => {
    const date = `${params.month}/${params.year}`;
    try {
      const { data } = await api.get('/finance/quote/dashboard/body', {
        params: {
          ...(params.cm && { cm: params.cm }),
          ...(date && { date }),
          ...(params.level && { level: params.level }),
          withForecast: params.withForecast,
        },
      });
      setAllProjectsWithSku(data);
      const projects = data.map((item: any) => {
        return {
          value: item.project,
          label: item.project,
        };
      });
      setProjectsList(getAllOnlyUnique(projects, 'value'));
      const skus = data.map((item: any) => {
        return {
          value: item.sku,
          label: item.sku,
        };
      });
      setSkusList(getAllOnlyUnique(skus, 'value'));
      handleUpdateSkuData('SKU_01', formBody.getFieldValue('projectName1'), data);
      handleUpdateSkuData('SKU_02', formBody.getFieldValue('projectName2'), data);
      handleUpdateSkuData('SKU_03', formBody.getFieldValue('projectName3'), data);
    } catch (error) {
      return error;
    }
  };

  /**
   * fetch all data from the page
   */
  const fetchAll = async () => {
    setIsLoading(true);
    Promise.all([fetchBodyFilterContent(), fetchHeaderFiltersContent(), fetchBodyContent(true)])
      .then(() => {
        setIsLoading(false);
        setTableIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   *  Handle the change of the table, like pagination, filters and sorter
   * @param pagination number of page if has one
   * @param filters filter if has one on the table header
   * @param sorter object with sorter details, like ASC or column key
   */
  const handleChangeTable = async (pagination: number, filters: any, sorter: any) => {
    setParams({
      ...params,
      order: getSortOrder(sorter.order),
      orderBy: sorter.columnKey,
    });
    orderBodyContent(getSortOrder(sorter.order), sorter.columnKey);
  };

  const columns: ColumnsType<columnsTypes> = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      sorter: true,
      width: '8.25rem',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: t('pages.finance.dashboard.headerTitles.projectName'),
      dataIndex: 'projectName',
      width: '10.25rem',
      key: 'projectName',
      sorter: true,
    },
    {
      title: 'CM',
      dataIndex: 'cm',
      key: 'cm',
      width: '6.375rem',
      sorter: true,
    },
    {
      title: (
        <CellMoneyHeaderSpan>
          {t('pages.finance.dashboard.headerTitles.monthDemand')}
        </CellMoneyHeaderSpan>
      ),
      dataIndex: 'monthForecast',
      key: 'monthForecast',
      align: 'right',
      width: '6.25rem',
      sorter: true,
      render: (text: string, record: any) => {
        return (
          <CellMoneySpan>
            {Number(record.monthForecast).toLocaleString(t('common.localeMoney'))}
          </CellMoneySpan>
        );
      },
    },
    {
      title: (
        <CellMoneyHeaderSpan>{t('pages.finance.dashboard.headerTitles.unit')}</CellMoneyHeaderSpan>
      ),
      dataIndex: 'unit',
      key: 'unit',
      align: 'right',
      width: '7.25rem',
      sorter: true,
      render: (text: string, record: any) => {
        return (
          <CellMoneySpan>
            USD{' '}
            {Number(record.unit).toLocaleString(t('common.localeMoney'), {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </CellMoneySpan>
        );
      },
    },
    {
      title: <CellMoneyHeaderSpan>Total</CellMoneyHeaderSpan>,
      dataIndex: 'total',
      align: 'right',
      key: 'total',
      width: '8.25rem',
      sorter: true,
      render: (text: string, record: any) => {
        return (
          <CellMoneySpan>
            USD{' '}
            {Number(record.total).toLocaleString(t('common.localeMoney'), {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </CellMoneySpan>
        );
      },
    },
    {
      title: <CellMoneyHeaderSpan style={{ marginRight: '1rem' }}>%</CellMoneyHeaderSpan>,
      dataIndex: 'porcentage',
      align: 'right',
      key: 'porcentage',
      width: '5.25rem',
      render: (text: string, record: any) => {
        return (
          <CellMoneySpan>
            {`${Number(record.porcentage).toLocaleString(t('common.localeMoney'), {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}%`}
          </CellMoneySpan>
        );
      },
    },
  ];

  /**
   * Clear form Field
   * @param param0 Form field to be cleared
   */
  const handleClearOldSkuAfterSelectNewProject = ({ field }: ClearFieldsBodyFormProps) => {
    formBody.setFieldValue(field, '');
  };

  /**
   * Update form field
   * @param param0 Form field to be updated
   */
  const handleSaveSelectedSkuOnForm = ({ field, value }: UpdateFieldsBodyFormProps) => {
    formBody.setFieldValue(field, value);
  };

  /**
   * Receive the field and the value to be updated.
   * But if the field is 'skus' or 'projectNames', it will update by the form value and not by the value passed by parameter.
   * @param field Which field will be updated
   * @param value The new value of the field
   */
  const updateParams = (field: string, value: any) => {
    setParams((prevState) => {
      if (field === 'skus') {
        return {
          ...prevState,
          [field]: [
            formBody.getFieldValue('sku1'),
            formBody.getFieldValue('sku2'),
            formBody.getFieldValue('sku3'),
          ],
        };
      }
      if (field === 'projectNames') {
        return {
          ...prevState,
          [field]: [
            formBody.getFieldValue('projectName1'),
            formBody.getFieldValue('projectName2'),
            formBody.getFieldValue('projectName3'),
          ],
        };
      }
      return {
        ...prevState,
        [field]: value,
      };
    });
    if (field === 'projectNames' || field === 'skus') {
      setTableIsLoading(true);
      fetchBodyContent(true);
      setTableIsLoading(false);
    }
  };

  const handleClearTag = () => {
    setParams({
      ...initilParams,
      date: lastData,
      month: lastData.format('MMMM'),
      year: lastData.format('YYYY'),
    });
    setTableIsLoading(true);
    fetchBodyContent();
    setTableIsLoading(false);
  };
  const handleRenderClearAllFilters = () => {
    if (params.cm) {
      return <StyledClearTag onClick={handleClearTag} />;
    }
  };

  const handleRenderFilterTags = () => {
    return (
      <>
        {params.cm && (
          <StyledFilterTag
            label="cm"
            title={params.cm}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            closeble
            onClose={() => {
              updateParams('cm', '');
            }}
          />
        )}
        {/* {params.level && (
          <StyledFilterTag
            label="level"
            title={params.level}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            closeble
            onClose={() => updateParams('level', '')}
          />
        )} */}
      </>
    );
  };
  const totalCheckedUSDReduce = (data: Array<dataType>) => {
    const total = data.reduce((acc, item) => {
      acc += Number(item.total);
      return acc;
    }, 0);
    return total;
  };

  const totalCheckedUSD = () => {
    const total = totalCheckedUSDReduce(selectedData);
    return total.toLocaleString(t('common.localeMoney'), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const selectedTotalIsEmptyOrZero = () => {
    return selectedData.length && totalCheckedUSDReduce(selectedData);
  };

  const calculatePercentage = (data: dataType[]) => {
    const total = totalCheckedUSDReduce(data);
    const percentage = data.map((item) => {
      return {
        ...item,
        porcentage: ((Number(item.total) / total) * 100).toFixed(2),
      };
    });
    return percentage;
  };

  useEffect(() => {
    fetchRecentData();
    clearSelectedSkus();
  }, [params.cm]);

  return {
    columns,
    headerFilterData,
    allSkuData,
    form,
    formBody,
    projectsList,
    skusList,
    bodyData,
    selectedData,
    isLoading,
    tableIsLoading,
    params,
    allData,
    currentDate,
    threeFirstSelectedSkus,
    CMs,
    updateThreeFirstSelectedSkus,
    getThreeFirstSkusSelected,
    calculatePercentage,
    updateDate,
    selectedTotalIsEmptyOrZero,
    totalCheckedUSDReduce,
    totalCheckedUSD,
    setParams,
    fetchBodyFilterContent,
    setIsLoading,
    handleClearTag,
    formatProjectNameParamsForCard,
    setSelectedData,
    handleChangeTable,
    updateParams,
    fetchBodyContent,
    handleUpdateSkuData,
    handleClearOldSkuAfterSelectNewProject,
    handleSaveSelectedSkuOnForm,
    fetchAll,
    formatDateForCard,
    colorsGenerator,
    handleRenderFilterTags,
    handleRenderClearAllFilters,
    fetchHeaderFiltersContent,
    isFirstRendering,
    clearSelectedSkus,
  };
};
