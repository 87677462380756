import { useTranslation } from 'react-i18next';
import { dataPartNumber } from '../types';
import * as S from './styles';
import theme from '../../../../styles/theme';

export const useUnitPriceTotalInvoice = () => {
  const { t } = useTranslation();
  const handleFormatValueFields = (number: string | number): string => {
    try {
      return Number(number).toLocaleString(t('common.localeMoney'), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } catch (error) {
      console.log(error);
      return '-';
    }
  };

  const CustomTooltipComponent = (dados: any) => {
    if (dados) {
      return (
        <S.TooltipContainer>
          <S.TooltipBodyContainer>
            {dados.dataTooltip.map((data: any) => (
              <>
                {data.partNumbers.map((partNumbersData: dataPartNumber) =>
                  partNumbersData.negotiatedValue !== 0.0 ||
                  partNumbersData.executedValue !== 0.0 ? (
                    <>
                      {data.projectName ? (
                        <S.ProjectTooltip key={data.projectName}>
                          {data.projectName ? data.projectName : 'N/A'}
                        </S.ProjectTooltip>
                      ) : (
                        <S.ProjectTooltip>N/A</S.ProjectTooltip>
                      )}
                      <S.SpecTooltip>{data.spec}</S.SpecTooltip>
                      <S.PartNumberTooltipContainer>
                        <S.PartNumberTooltip>{partNumbersData.partNumber}</S.PartNumberTooltip>
                        <S.PartNumberValue>
                          {dados.label === 'Negotiated'
                            ? handleFormatValueFields(partNumbersData?.negotiatedValue)
                            : dados.label === 'Gap'
                            ? handleFormatValueFields(
                                partNumbersData.negotiatedValue - partNumbersData.executedValue
                              )
                            : handleFormatValueFields(partNumbersData?.executedValue)}
                        </S.PartNumberValue>
                      </S.PartNumberTooltipContainer>
                      <S.SupllierTooltip>{partNumbersData.supplier}</S.SupllierTooltip>
                    </>
                  ) : null
                )}
              </>
            ))}
          </S.TooltipBodyContainer>
        </S.TooltipContainer>
      );
    }
    return null;
  };

  const CustomLabel = ({ x: eixoX, y: eixoY, width, value }: any) => {
    return (
      <text
        x={eixoX + width / 2}
        y={eixoY - 8}
        fill={theme.colorsDesignSystem.grayAcer}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {handleFormatValueFields(value)}
      </text>
    );
  };
  const handleColorBars = (name: string) => {
    if (name === 'Executed' || name === 'Executado') {
      return theme.colorsDesignSystem.chartColors.blue;
    }
    if (name === 'Negociado' || name === 'Negotiated') {
      return theme.colorsDesignSystem.chartColors.green;
    }
    if (name === 'Gap') {
      return theme.colorsDesignSystem.chartColors.gray;
    }
    return theme.colorsDesignSystem.chartColors.blue;
  };
  const formatTick = (number: string) => {
    const num = Number(number);
    if (num >= 1e3) {
      return `$${num / 1e3}K`;
    }
    if (num >= 1e6) {
      return `$${num / 1e6}M`;
    }
    return `$${number}`;
  };

  return { CustomTooltipComponent, CustomLabel, handleColorBars, formatTick };
};
