import { Col, ConfigProvider, Row, Form, Checkbox, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { BiDownload, BiPlus } from 'react-icons/bi';
import { CsvExport } from '../../components/Common/CsvExport';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import { HeaderTitle } from '../../components/PageHeader/HeaderTitle';
import { CustomPagination } from '../../components/CustomPagination';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { Loading } from '../../components/Loading';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useNotifications } from '../../context/notification';
import { Button } from '../../components/Button';
import SimpleTable from '../../components/Tables/SimpleTable';

export function NotificationsPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.notifications.title'));

  const {
    isLoading,
    notifications,
    pageControl,
    params,
    changePageValue,
    columnsWithAction,
    handleSelection,
    selectedNotifications,
    toggleFullSelection,
    deleteSomeNotifications,
  } = useNotifications();

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <StyledPageHeader
                  title={
                    <HeaderTitle
                      title={t('notifications.title')}
                      subtitle={`${notifications.length} ${t('notifications.found')}`}
                    />
                  }
                  className="pl-0 pr-0"
                />
              </Col>
              <Col
                span={23}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginBottom: 12,
                  gap: 12,
                }}
              >
                <Checkbox
                  checked={selectedNotifications.length === notifications.length}
                  indeterminate={
                    selectedNotifications.length > 0 &&
                    selectedNotifications.length < notifications.length
                  }
                  onChange={(e) => {
                    toggleFullSelection();
                  }}
                >
                  {t('common.selectAll')}
                </Checkbox>
                <Popconfirm
                  onClick={(e) => e.stopPropagation()}
                  title={t('common.deleteMessage')}
                  onConfirm={() => deleteSomeNotifications(selectedNotifications as string[])}
                  placement="left"
                  cancelText={t('common.cancel')}
                >
                  <Button
                    icon={null}
                    iconEnd={null}
                    buttonText={null}
                    onClick={null}
                    type="primary"
                    disabled={selectedNotifications.length === 0}
                  >
                    {t('common.delete')}
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <ConfigProvider>
                  <SimpleTable
                    rowKey="id"
                    data-cy="table"
                    bordered
                    striped
                    dataSource={notifications}
                    columns={columnsWithAction}
                    rowSelection={{
                      fixed: true,
                      onChange: handleSelection,
                      selectedRowKeys: selectedNotifications,
                    }}
                    pagination={false}
                    locale={{
                      emptyText: (
                        <ResultNoScenarios
                          title={t('common.results.noDataTitle')}
                          subTitle={t('common.results.noDataSubtitle')}
                          icon={null}
                        />
                      ),
                    }}
                  />
                </ConfigProvider>

                {pageControl.totalPages > 1 && (
                  <CustomPagination
                    limit={params.limit}
                    page={params.page + 1}
                    pageLabel={pageControl.pageLabel}
                    total={pageControl.totalElements}
                    totalPages={pageControl.totalPages}
                    changePageValue={changePageValue}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
