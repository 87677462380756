import styled from 'styled-components';
import { Row as AntdRow, Upload } from 'antd';

export const Row = styled(AntdRow)`
  height: calc(100vh - 50px);

  @media screen and (max-width: 1200px) {
    width: 980px;
    margin: 0 auto;
    justify-content: initial;
  }
`;

export const UploadDrag = styled(Upload)`
  padding: 10px;
`;

export const StepScenario = styled.div`
  header {
    border-bottom: 2px dotted ${({ theme }) => theme.colors.border};
    display: flex;
    margin-bottom: 16px;
    padding-bottom: 16px;

    .block {
      margin-right: 8%;

      h3 {
        margin-bottom: 0;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.title};
        font-weight: 500;
      }

      div {
        font-weight: 500;
      }
    }
  }

  h4 {
    margin: 16px 0;

    span {
      color: ${({ theme }) => theme.colors.orange};
    }
  }

  .or {
    margin: 5px 28px 0 16px;
  }

  .btn-wrapper {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .btn-upload {
    display: inline-flex;
    margin-right: 8px;
  }

  .title-prices {
    strong {
      font-weight: bold;
    }
  }
`;
