import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  ComposedChart,
  Line,
} from 'recharts';
import { useBarLineGraphic } from '../../../../../components/Common/BarLineGraphic/useBarLineGraphic';
import { useCostTrendChart } from './useCostTrend';
import { DataChart } from './types';

export default function CostTrendChart(data: DataChart) {
  const { handleRenderLegend } = useBarLineGraphic();
  const { CustomTooltip, CustomDataXlabel, formatTick } = useCostTrendChart();
  return (
    <ResponsiveContainer width="100%" height="100%" maxHeight={480}>
      <ComposedChart
        data={data.chartData}
        margin={{
          top: 25,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={data.config.labelColumn} tickFormatter={(tick) => CustomDataXlabel(tick)} />
        <YAxis tickFormatter={formatTick} />
        <Tooltip
          data-testid="tooltip-graph"
          content={
            <CustomTooltip
              data={data}
              colorBar={data.themeGraphic.colorCurrentMonth}
              colorLine={data.themeGraphic.colorLineChart}
            />
          }
        />
        <Legend align="right" content={handleRenderLegend(data.labels)} />
        <Bar barSize={27} dataKey={data.config.dataKeyBar}>
          {data.chartData.map((entry, index) => (
            <Cell key={entry.barValue} fill={data.themeGraphic.colorCurrentMonth} />
          ))}
        </Bar>
        <Line
          type="monotone"
          dataKey={data.config.dataKeyLine}
          stroke={data.themeGraphic.colorLineChart}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
