import { Typography, Table, Col } from 'antd';
import styled from 'styled-components';
import SimpleTable from '../../components/Tables/SimpleTable';
// import { Table } from '../../styles/common';

export const Container = styled(Col)`
  padding: 1.5rem 2.5rem;
`;

export const EyeContainer = styled.div`
  align-self: baseline;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button svg {
    width: 24px;
    height: 24px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .eye-input {
    display: flex;
    align-items: center;

    input {
      width: 120px;
    }

    button {
      font-weight: bold;
      padding: 8px;
    }
  }
`;

export const Text = styled(Typography)`
  color: ${({ theme, edited }) => (edited ? theme.colors.text_blue : theme.colors.text_dark)};
  font-weight: 500;
  font-size: 13px;
`;
export const PricePreviewTable = styled(SimpleTable)`
  overflow: auto;
`;

export const MainContainer = styled.main`
  margin-right: 0.5rem;
`;
