import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import { formatDataToSelect } from '../../helpers/nUtils';
import { oneAlert } from '../../helpers/utils';
import { api } from '../../services/api';
import { getIdToken, getPermissions } from '../../services/auth';
import { services } from '../../services/services';
import { CmsContextType } from './types';
import i18n from '../../translations/i18nTests';

const AppContext = createContext<CmsContextType | undefined>({} as CmsContextType);

export function CmsProvider({ children }: { children: ReactNode }) {
  const handleSaveCms = (cms: Array<string>) => {
    const formattedCms = formatDataToSelect(cms, false);
    sessionStorage.setItem('CMs', JSON.stringify(formattedCms));
  };

  const handleGetCms = (): Array<StyledSelectOptions> => {
    try {
      const storedValue = sessionStorage.getItem('CMs');
      const allCms: Array<StyledSelectOptions> = storedValue ? JSON.parse(storedValue) : [];
      return allCms.map((cm) =>
        cm.value === 'SHOW ALL' ? { label: i18n.t('common.showAll'), value: '' } : cm
      );
    } catch (error) {
      oneAlert('error', error);
      return [];
    }
  };

  const handleGetCmsByUser = async () => {
    try {
      const cms = handleGetCms();
      if (cms.length === 0) {
        const { data, status } = await api.get(
          `${import.meta.env.VITE_API_LOGIN_UNICO}${services.singleLogin}/user-cm`,
          {
            headers: {
              Authorization: `Bearer ${getIdToken()}`,
              Permissions: getPermissions(),
            },
          }
        );
        status === 200 && handleSaveCms(data);
      }
    } catch (error: any) {
      oneAlert('error', error);
    }
  };

  useEffect(() => {
    handleGetCmsByUser();
  }, []);

  const verifyUserIsAdmin = (): boolean => {
    const permissions = localStorage.getItem('permissions');
    return permissions ? permissions.toUpperCase() === 'ADMIN' : false;
  };

  const handleSelectDefaultCm = (cmDefault: string) => {
    const CMs = handleGetCms();
    if (verifyUserIsAdmin() && CMs.some((cm) => cm.value === cmDefault)) {
      return cmDefault;
    }
    return CMs[0]?.label || '';
  };

  const data = useMemo(
    () => ({
      handleGetCms,
      handleGetCmsByUser,
      handleSelectDefaultCm,
    }),
    []
  );

  return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
}

export function useCmsContext() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }

  return context;
}
