import { UserOutlined } from '@ant-design/icons';
import { Col, Flex, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CancelMessage } from './Components/CancelMessage';
import { CardStatus } from './Components/CardStatus';
import { TermsAndConditions } from './Components/TermsAndConditions';
import { TermsConditionsContentTitle } from './Components/TermsAndConditions/styles';
import * as S from './styles';
import { useBidSupplierView } from './useBidSupplierView';

import ExclamationCircle from '../../assets/exclamation-circle.svg';
import CardCountDown from '../../components/Common/CardCountdown';
import StyledBidCardList from '../../components/Common/StyledBidCardList';
import StyledButton from '../../components/Common/StyledButton';
import { StyledLoading } from '../../components/Common/StyledLoading';
import StyledModal from '../../components/Common/StyledModal';
import StyledSelect from '../../components/Common/StyledSelect';
import { handleFormatValueFields } from '../../helpers/nUtils';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export function BidSupplierView() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.rfq.title'));
  const {
    steps,
    bidData,
    bidOptions,
    idBid,
    loading,
    termsConditon,
    termsConditionData,
    openModal,
    firstAccess,
    termsConditionDetail,
    acceptedTerms,
    acceptTerms,
    denyTerms,
    getSupplierUserName,
    fetchStatus,
    getBidId,
    fetchData,
    setIdBid,
    setOpenModal,
    Review,
  } = useBidSupplierView();
  const history = useHistory();

  useEffect(() => {
    getBidId();
  }, []);

  useEffect(() => {
    fetchData();
    fetchStatus();
  }, [idBid]);
  return (
    <>
      <StyledModal
        key="modal-newcomponent"
        title="Terms Condition"
        open={openModal}
        onClose={() => setOpenModal(false)}
        footer={
          <StyledButton variant="secondary" onClick={() => setOpenModal(false)}>
            {t('common.close')}
          </StyledButton>
        }
        body={
          <>
            <Flex align="center" justify="space-between">
              <TermsConditionsContentTitle>{bidData.rfq}</TermsConditionsContentTitle>
              <Flex align="center" gap={16}>
                {termsConditionDetail?.acceptDate && (
                  <CardStatus
                    status="accepted"
                    time={dayjs(termsConditionDetail.acceptDate).format('MM.DD.YYYY - hh:mma')}
                    user={`${termsConditionDetail?.userAccept} - ${termsConditionDetail?.supplier}`}
                  />
                )}
                {termsConditionDetail?.rejectDate && (
                  <CardStatus
                    status="denied"
                    time={dayjs(termsConditionDetail.rejectDate).format('MM.DD.YYYY - hh:mma')}
                    user={`${termsConditionDetail.userReject} - ${termsConditionDetail.supplier}`}
                  />
                )}
              </Flex>
            </Flex>
            <S.ModalDivider />
            <div
              dangerouslySetInnerHTML={{
                __html: termsConditionData,
              }}
            />
          </>
        }
        width="64rem"
      />
      <Flex justify="space-between" align="center">
        <Col>
          <Row align="middle" style={{ gap: 4 }}>
            <S.SpanTile>{t('pages.bidSupplierRFQ.Title')}</S.SpanTile>
            <S.BidComponents>| {bidData.rfq}</S.BidComponents>
          </Row>
        </Col>
        <Col>
          <Row align="middle" style={{ gap: 4 }}>
            <S.SupplierSpan>{getSupplierUserName()?.supplier || '-'}</S.SupplierSpan>
            <S.UserContainer>
              <UserOutlined />
              <span>{getSupplierUserName()?.user || '-'}</span>
            </S.UserContainer>
          </Row>
        </Col>
      </Flex>
      <Flex justify="flex-end" align="center">
        <StyledSelect
          label="BID"
          options={bidOptions}
          value={idBid || ''}
          onChange={(value: string) => setIdBid(value)}
          styles={{ width: '17.5rem' }}
        />
      </Flex>
      {loading ? (
        <StyledLoading height={10} />
      ) : (
        <S.StepsContainer noBorder>
          <S.StepsComponent items={steps} />
        </S.StepsContainer>
      )}

      {loading ? (
        <StyledLoading height={10} />
      ) : steps.some((step) => step.key === 'CANCEL') ? (
        <CancelMessage bidName={bidData.rfq} />
      ) : !termsConditon ? (
        <TermsAndConditions
          title={bidData.rfq}
          body={termsConditionData}
          accept={acceptedTerms}
          firstAccess={firstAccess}
          status={steps.find((item) => item.title === 'Round Open')?.status === 'finish'}
          details={termsConditionDetail}
          review={Review}
          accepted={acceptTerms}
          deny={denyTerms}
        />
      ) : (
        <S.ContentContainer>
          <S.ContentLeftContainer>
            <S.HeaderContentLeft>
              <S.TitleCard>{t('pages.bidSupplierRFQ.itemsTitle')}</S.TitleCard>
              <StyledButton
                variant="primary"
                disabled={steps.find((item) => item.title === 'Round Open')?.status !== 'finish'}
                onClick={() => {
                  history.push(`/bid-response?bidId=${idBid}`);
                }}
              >
                {t('pages.bidSupplierRFQ.editBid')}
              </StyledButton>
            </S.HeaderContentLeft>
            <S.CardsBidContainer>{StyledBidCardList(bidData.itemsQuotation)}</S.CardsBidContainer>
          </S.ContentLeftContainer>
          <S.ContentRightContainer>
            <S.HeaderContentRight>
              <S.TitleCard>{t('pages.bidSupplierRFQ.roundDetails')}</S.TitleCard>
            </S.HeaderContentRight>
            <S.RoundContenteContainer>
              <Flex vertical>
                <S.PotentialLabel>{t('pages.bidSupplierRFQ.potentialRevenue')}</S.PotentialLabel>
                <S.PotentialValue>
                  $
                  {bidData.potentialRevenue
                    ? ` ${handleFormatValueFields({
                        number: bidData.potentialRevenue,
                      })}`
                    : ' -'}
                </S.PotentialValue>
              </Flex>
            </S.RoundContenteContainer>
            <S.RoundContenteContainer>
              {bidData.respondDate && (
                <CardCountDown
                  showTime
                  endDate={bidData.respondDate}
                  stop={steps.find((item) => item.title === 'Round Open')?.status !== 'finish'}
                />
              )}
            </S.RoundContenteContainer>
            <S.TermsConditionContainer>
              <Flex>
                <S.AuditIcon />
                <S.TermsConditionsButton variant="slim" onClick={() => setOpenModal(true)}>
                  {t('pages.bidSupplierRFQ.termsAndConditions.title')}
                </S.TermsConditionsButton>
              </Flex>
              <img src={ExclamationCircle} alt="icon" />
            </S.TermsConditionContainer>
          </S.ContentRightContainer>
        </S.ContentContainer>
      )}
    </>
  );
}
