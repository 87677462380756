import { ExclamationCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

export const Card = styled.div`
  min-width: 10.188rem;
  min-height: 4rem;
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  border-radius: ${({ theme }) => theme.dimensions.border_radius_large};
  margin: 1.531rem 0;
  padding: 0.781rem 1rem;
`;

export const CardTitle = styled.div`
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  display: flex;
  gap: 0.313rem;
`;

export const DeniedIcon = styled(ExclamationCircleFilled)`
  color: ${({ theme }) => theme.colorsDesignSystem.red_secondary};
`;

export const AcceptedIcon = styled.img`
  color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlue};
`;

export const Time = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colorsDesignSystem.grayText};
  margin: 0.313rem 0 0 0;
  line-height: 0.875rem;
`;

export const User = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colorsDesignSystem.grayText};
  margin: 0.313rem 0 0 0;
`;
