import jwtDecode from 'jwt-decode';
import { getIdToken } from '../services/auth';

interface userCompany {
  currentUser: string;
  currentUserNickName: string;
  companyUser: string;
}

interface DecodedToken {
  name: string;
  nickname: string;
  'custom:empresa': string;
}

export function userUtils() {
  const getUserCompanyCurrent = (): userCompany => {
    const idToken = getIdToken();
    const user: DecodedToken = jwtDecode(idToken);
    return {
      currentUser: user.name,
      currentUserNickName: user.nickname,
      companyUser: user['custom:empresa'],
    };
  };

  return {
    getUserCompanyCurrent,
  };
}
