import { Result, Row, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { IResultNoScenarios } from './types';

export function ResultNoScenarios({ title, subTitle, icon }: IResultNoScenarios) {
  const { t } = useTranslation();

  return (
    <S.Col
      span={23}
      data-cy="no-results"
      className="col-no-results"
      data-testid="result-no-scenarios"
    >
      <Row justify="center">
        <Result
          icon={icon || Empty.PRESENTED_IMAGE_SIMPLE}
          title={title || t('common.results.noScenarioTitle')}
          subTitle={subTitle || t('common.results.noScenarioSubtitle')}
        />
      </Row>
    </S.Col>
  );
}
