/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { Divider } from 'antd';
import { useCallback } from 'react';
import { BarChart, Bar, XAxis, Tooltip, Cell, LabelList, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import theme from '../../../styles/theme';

type Quarter =
  | {
      occurred: number;
      forecast: number;
      total: number;
      occurredPercentage: number;
      forecastPercentage: number;
      label: string;
      future: boolean;
    }
  | {
      fob: number;
      br: number;
      total: number;
      fobPercentage: number;
      brPercentage: number;
      label: string;
      future: boolean;
    };

type GraphDataType = {
  name: string;
  q1: Quarter;
  q2: Quarter;
  q3: Quarter;
  q4: Quarter;
};

type GeneratedQuarter = Quarter & {
  occurredFill?: string;
  forecastFill?: string;
  fobFill?: string;
  brFill?: string;
};

type TooltipPayload = {
  name: string;
  value: number;
  dataKey: string;
  fill: string;
  payload: GraphDataType;
};

type CustomTooltipProps = {
  active?: boolean;
  payload?: TooltipPayload[];
  label?: string;
};

type ShareProportionGraphProps = {
  data: GraphDataType[];
  type: 'specifications' | 'SKU';
};

const ShareProportionsGraph = ({ data, type }: ShareProportionGraphProps) => {
  const { t } = useTranslation();
  const FIRST_BAR_COLOR =
    type === 'specifications'
      ? theme.colorsDesignSystem.shareProportionGraph.blue
      : theme.colorsDesignSystem.shareProportionGraph.orange; // FOB / occurred
  const SECOND_BAR_COLOR =
    type === 'specifications'
      ? theme.colorsDesignSystem.shareProportionGraph.yellow
      : theme.colorsDesignSystem.shareProportionGraph.gray; // BR / Forecast
  const LABEL_COLOR = '#000';

  function generateBarLabel(barPercentage: number) {
    const percentage = Math.floor(barPercentage);

    if (percentage > 100) {
      return '100%';
    }

    return percentage > 0 ? `${percentage}%` : '';
  }

  function formatPercentages(percentage: number) {
    const formattedPercentage = Math.floor(percentage);

    if (formattedPercentage > 100) {
      return 100;
    }

    if (formattedPercentage < 0) {
      return 0;
    }

    return formattedPercentage;
  }

  function formatQuarters(quarter: Quarter) {
    const label = quarter.total > 0 ? quarter.label : '';
    let firstColor = FIRST_BAR_COLOR;
    let secondColor = SECOND_BAR_COLOR;

    if (quarter.future) {
      firstColor = `${FIRST_BAR_COLOR}4d`;
      secondColor = `${SECOND_BAR_COLOR}4d`;
    }

    if ('fob' in quarter) {
      return {
        ...quarter,
        label,
        fobFill: firstColor,
        brFill: secondColor,
        fobPercentage: formatPercentages(quarter.fobPercentage), // limitar entre 0% e 100%
        brPercentage: formatPercentages(quarter.brPercentage), // limitar entre 0% e 100%
        fobPercentageString: generateBarLabel(quarter.fobPercentage),
        brPercentageString: generateBarLabel(quarter.brPercentage),
      };
    }

    return {
      ...quarter,
      label,
      occurredFill: firstColor,
      forecastFill: secondColor,
      occurredPercentage: quarter.occurredPercentage > 100 ? 100 : quarter.occurredPercentage, // limitar entre 0% e 100%
      forecastPercentage: quarter.forecastPercentage < 0 ? 0 : quarter.forecastPercentage, // limitar entre 0% e 100%
      occurredPercentageString: generateBarLabel(quarter.occurredPercentage),
      forecastPercentageString: generateBarLabel(quarter.forecastPercentage),
    };
  }

  function generateChartData(graphData: GraphDataType[]) {
    const chartData = graphData.map((item) => {
      const { q1, q2, q3, q4 } = item;

      return {
        ...item,
        q1: formatQuarters(q1),
        q2: formatQuarters(q2),
        q3: formatQuarters(q3),
        q4: formatQuarters(q4),
      };
    });

    return chartData;
  }

  function getFillColor(quarter: GeneratedQuarter, key: 'fob' | 'br' | 'occurred' | 'forecast') {
    if (('fob' || 'br') in quarter) {
      return key === 'fob' ? quarter.fobFill : quarter.brFill;
    }

    if (('occurred' || 'forecast') in quarter) {
      return key === 'occurred' ? quarter.occurredFill : quarter.forecastFill;
    }
  }

  const CustomTooltip = useCallback(({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      // format payload to have the total at the end of every quarter
      const formattedPayload = payload.flatMap((item, index) => {
        if (index % 2 === 1) {
          return [item, 'total']; // Append the item at even indexes
        }
        return item; // Keep the original item
      });

      let quarterTotal: number;

      return (
        <S.TooltipContainer>
          <p>{label}</p>
          <Divider style={{ margin: 0 }} />
          <S.TooltipList>
            {formattedPayload.map((entry, index) => {
              if (typeof entry === 'string') {
                if (type === 'specifications' && quarterTotal === 0) return null;

                const tooltipLabel = type === 'specifications' ? 'Total' : 'Gap';

                return (
                  <S.TooltipTotal key={crypto.randomUUID()}>
                    {tooltipLabel}: {quarterTotal.toLocaleString(t('common.localeMoney'))}
                  </S.TooltipTotal>
                );
              }

              const [quarter, dataType] = entry.name.toLowerCase().split(' '); // quarter (q1, q2, q3, q4) & dataType (occurred, forecast, fob, br)

              const total = entry.payload[quarter as keyof GraphDataType][
                dataType as keyof Omit<Quarter, 'label' | 'total' | 'future'>
              ] as number;

              quarterTotal = (entry.payload[quarter as keyof GraphDataType] as Quarter).total;

              const isEven = index % 2 === 0;

              if (total === 0) return null;

              const name = dataType === 'forecast' ? 'Demand' : entry.name;

              return (
                <S.TooltipItem key={crypto.randomUUID()} color={entry.fill}>
                  {name}: {total?.toLocaleString(t('common.localeMoney'))}
                </S.TooltipItem>
              );
            })}
          </S.TooltipList>
        </S.TooltipContainer>
      );
    }
    return null;
  }, []);

  const chartData = generateChartData(data);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={chartData} barSize={20} margin={{ top: 50, left: 6 }}>
        <XAxis dataKey="name" />
        <Tooltip content={<CustomTooltip />} position={{ y: -100 }} />

        {/* QUARTER 1 */}
        <Bar
          dataKey={type === 'specifications' ? 'q1.fobPercentage' : 'q1.occurredPercentage'}
          stackId="quarter1"
          name={`Q1 ${type === 'specifications' ? 'FOB' : 'occurred'}`}
          fill={FIRST_BAR_COLOR}
        >
          {chartData.map((entry, index) => (
            <Cell
              key={`occurred-${index}`}
              fill={getFillColor(entry.q1, type === 'specifications' ? 'fob' : 'occurred')}
            />
          ))}
          <LabelList
            dataKey={
              type === 'specifications' ? 'q1.fobPercentageString' : 'q1.occurredPercentageString'
            }
            position="inside"
            angle={-45}
            fill={LABEL_COLOR}
            style={{
              fontSize: 10,
              fontWeight: 700,
            }}
          />
        </Bar>
        <Bar
          dataKey={type === 'specifications' ? 'q1.brPercentage' : 'q1.forecastPercentage'}
          stackId="quarter1"
          name={`Q1 ${type === 'specifications' ? 'BR' : 'Forecast'}`}
          fill={SECOND_BAR_COLOR}
        >
          {chartData.map((entry, index) => (
            <Cell
              key={`forecast-${index}`}
              fill={getFillColor(entry.q1, type === 'specifications' ? 'br' : 'forecast')}
            />
          ))}
          <LabelList
            dataKey={
              type === 'specifications' ? 'q1.brPercentageString' : 'q1.forecastPercentageString'
            }
            position="inside"
            angle={-45}
            fill={LABEL_COLOR}
            style={{
              fontSize: 10,
              fontWeight: 700,
            }}
          />
          <LabelList dataKey="q1.label" position="top" fill={LABEL_COLOR} />
        </Bar>

        {/* QUARTER 2 */}
        <Bar
          dataKey={type === 'specifications' ? 'q2.fobPercentage' : 'q2.occurredPercentage'}
          stackId="quarter2"
          name={`Q2 ${type === 'specifications' ? 'FOB' : 'occurred'}`}
          fill={FIRST_BAR_COLOR}
        >
          {chartData.map((entry, index) => (
            <Cell
              key={`occurred-${index}`}
              fill={getFillColor(entry.q2, type === 'specifications' ? 'fob' : 'occurred')}
            />
          ))}
          <LabelList
            dataKey={
              type === 'specifications' ? 'q2.fobPercentageString' : 'q2.occurredPercentageString'
            }
            position="inside"
            angle={-45}
            style={{
              fontSize: 10,
              fontWeight: 700,
            }}
            fill={LABEL_COLOR}
          />
        </Bar>
        <Bar
          dataKey={type === 'specifications' ? 'q2.brPercentage' : 'q2.forecastPercentage'}
          stackId="quarter2"
          name={`Q2 ${type === 'specifications' ? 'BR' : 'Forecast'}`}
          fill={SECOND_BAR_COLOR}
        >
          {chartData.map((entry, index) => (
            <Cell
              key={`forecast-${index}`}
              fill={getFillColor(entry.q2, type === 'specifications' ? 'br' : 'forecast')}
            />
          ))}
          <LabelList
            dataKey={
              type === 'specifications' ? 'q2.brPercentageString' : 'q2.forecastPercentageString'
            }
            position="inside"
            angle={-45}
            style={{
              fontSize: 10,
              fontWeight: 700,
            }}
            fill={LABEL_COLOR}
          />
          <LabelList dataKey="q2.label" position="top" fill={LABEL_COLOR} />
        </Bar>

        {/* QUARTER 3 */}
        <Bar
          dataKey={type === 'specifications' ? 'q3.fobPercentage' : 'q3.occurredPercentage'}
          stackId="quarter3"
          name={`Q3 ${type === 'specifications' ? 'FOB' : 'occurred'}`}
          fill={FIRST_BAR_COLOR}
        >
          {chartData.map((entry, index) => (
            <Cell
              key={`occurred-${index}`}
              fill={getFillColor(entry.q3, type === 'specifications' ? 'fob' : 'occurred')}
            />
          ))}
          <LabelList
            dataKey={
              type === 'specifications' ? 'q3.fobPercentageString' : 'q3.occurredPercentageString'
            }
            position="inside"
            angle={-45}
            style={{
              fontSize: 10,
              fontWeight: 700,
            }}
            fill={LABEL_COLOR}
          />
        </Bar>
        <Bar
          dataKey={type === 'specifications' ? 'q3.brPercentage' : 'q3.forecastPercentage'}
          stackId="quarter3"
          name={`Q3 ${type === 'specifications' ? 'BR' : 'Forecast'}`}
          fill={SECOND_BAR_COLOR}
        >
          {chartData.map((entry, index) => (
            <Cell
              key={`forecast-${index}`}
              fill={getFillColor(entry.q3, type === 'specifications' ? 'br' : 'forecast')}
            />
          ))}
          <LabelList
            dataKey={
              type === 'specifications' ? 'q3.brPercentageString' : 'q3.forecastPercentageString'
            }
            position="inside"
            angle={-45}
            style={{
              fontSize: 10,
              fontWeight: 700,
            }}
            fill={LABEL_COLOR}
          />
          <LabelList dataKey="q3.label" position="top" fill={LABEL_COLOR} />
        </Bar>

        {/* QUARTER 4 */}
        <Bar
          dataKey={type === 'specifications' ? 'q4.fobPercentage' : 'q4.occurredPercentage'}
          stackId="quarter4"
          name={`Q4 ${type === 'specifications' ? 'FOB' : 'occurred'}`}
          fill={FIRST_BAR_COLOR}
        >
          {chartData.map((entry, index) => (
            <Cell
              key={`occurred-${index}`}
              fill={getFillColor(entry.q4, type === 'specifications' ? 'fob' : 'occurred')}
            />
          ))}
          <LabelList
            dataKey={
              type === 'specifications' ? 'q4.fobPercentageString' : 'q4.occurredPercentageString'
            }
            position="inside"
            angle={-45}
            style={{
              fontSize: 10,
              fontWeight: 700,
            }}
            fill={LABEL_COLOR}
          />
        </Bar>
        <Bar
          dataKey={type === 'specifications' ? 'q4.brPercentage' : 'q4.forecastPercentage'}
          stackId="quarter4"
          name={`Q4 ${type === 'specifications' ? 'BR' : 'Forecast'}`}
          fill={SECOND_BAR_COLOR}
        >
          {chartData.map((entry, index) => (
            <Cell
              key={`forecast-${index}`}
              fill={getFillColor(entry.q4, type === 'specifications' ? 'br' : 'forecast')}
            />
          ))}
          <LabelList
            dataKey={
              type === 'specifications' ? 'q4.brPercentageString' : 'q4.forecastPercentageString'
            }
            position="inside"
            angle={-45}
            style={{
              fontSize: 10,
              fontWeight: 700,
            }}
            fill={LABEL_COLOR}
          />
          <LabelList dataKey="q4.label" position="top" fill={LABEL_COLOR} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export { ShareProportionsGraph };
