import * as S from './styles';
import StyleFilterTagProps from './types';

export default function StyledFilterTag({
  closeble,
  closeicon,
  title,
  label,
  onClose,
}: StyleFilterTagProps) {
  return (
    <S.StyledTag
      closeIcon={closeicon}
      closable={closeble}
      onClose={onClose}
      data-testid="filter-tag"
    >
      <S.TextContainer data-testid="text-container">
        <span className="label" data-testid="label">
          {label}
        </span>{' '}
        <span className="pipe">|</span>{' '}
        <span className="title" data-testid="title">
          {title}
        </span>
      </S.TextContainer>
    </S.StyledTag>
  );
}
