import { Empty } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';
import { useAddQuantities } from './useAddQuantities';
import { Step3Data, Step3Props } from './types';

import StyledButton from '../../../../components/Common/StyledButton';
import { DeleteCategoryModal } from '../../DeleteCategoryModal';
import { StyledLoading } from '../../../../components/Common/StyledLoading';

export function AddQuantities({
  currentStep,
  bidId,
  nextStep,
  previousStep,
  onCancel,
}: Step3Props) {
  const {
    step3data,
    visibleDeleteModal,
    loading,
    handleDeleteGroupQuantitiesItem,
    setIdDeletedItem,
    setVisibleDeleteModal,
    RenderContent,
    handleFetchStep3Data,
  } = useAddQuantities(bidId);

  useEffect(() => {
    handleFetchStep3Data();
  }, [currentStep]);

  const { t } = useTranslation();
  return (
    <>
      <S.ContentForm>
        <DeleteCategoryModal
          isOpen={visibleDeleteModal}
          onDelete={() => {
            handleDeleteGroupQuantitiesItem();
          }}
          onCloseModal={() => {
            setIdDeletedItem(null);
            setVisibleDeleteModal(false);
          }}
        />

        {loading ? (
          <StyledLoading height={50} />
        ) : step3data.length > 0 ? (
          RenderContent(step3data)
        ) : (
          <Empty />
        )}
      </S.ContentForm>
      <S.StepFooter>
        <StyledButton variant="slim" onClick={onCancel}>
          {t('common.cancel')}
        </StyledButton>
        <S.StepButtonContainer>
          <StyledButton variant="secondary" onClick={previousStep}>
            {t('common.previous')}
          </StyledButton>
          <StyledButton
            variant="primary"
            disabled={step3data.some((item: Step3Data) => item.status !== 'OK')}
            onClick={nextStep}
          >
            {t('common.next')}
          </StyledButton>
        </S.StepButtonContainer>
      </S.StepFooter>
    </>
  );
}
