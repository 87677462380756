/* eslint-disable no-undefined */
import { useState } from 'react';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { api } from '../../../../services/api';
import { SKU } from './types';

dayjs.extend(quarterOfYear);

export const useSkuAnalysis = () => {
  const [open, setOpen] = useState(true);
  const [skusData, setSkusData] = useState<SKU>();
  const [params, setParams] = useState({
    date: [
      dayjs(new Date()).subtract(1, 'month').format('MMMM/YYYY').toUpperCase(),
      dayjs(new Date()).format('MMMM/YYYY').toUpperCase(),
      dayjs(new Date()).add(1, 'month').format('MMMM/YYYY').toUpperCase(),
    ].join(','),
    //level: 'L10',
    period: 'month',
  });
  const [currentDate, setCurrentDate] = useState(dayjs(new Date()));
  const [isLoadingDataGraphic, setIsLoadingDataGraphic] = useState<boolean>(false);

  const updateDateByExternalPicker = (date: dayjs.Dayjs) => {
    return {
      date: [
        dayjs(date).subtract(1, 'month').format('MMMM/YYYY').toUpperCase(),
        dayjs(date).format('MMMM/YYYY').toUpperCase(),
        dayjs(date).add(1, 'month').format('MMMM/YYYY').toUpperCase(),
      ].join(','),
    };
  };

  const fetchSkuAnalysis = async (skus: any, date?: any) => {
    const skusParams = skus.map((sku: any) => sku?.sku);
    const newDate = date ? updateDateByExternalPicker(date) : { date: params.date };
    try {
      setIsLoadingDataGraphic(true);
      const { data, status } = await api.get('/dashboard/finance/skuanalysis', {
        params: { ...params, skus: skusParams.join(','), ...newDate },
      });
      if (status === 204) {
        setSkusData(undefined);
      }
      setSkusData(data);
    } catch {
      setSkusData(undefined);
    } finally {
      setIsLoadingDataGraphic(false);
    }
  };

  const getDataForGraph = (periodType?: string) => {
    const lastMonthDtos = skusData?.lastMonthDtos;
    const currentMonthDtos = skusData?.currentMonthDtos;
    const nextMonthDtos = skusData?.nextMonthDtos;

    return [
      {
        sku1Value: lastMonthDtos?.[0]?.usd,
        sku2Value: lastMonthDtos?.[1]?.usd,
        sku3Value: lastMonthDtos?.[2]?.usd,
        month: lastMonthDtos?.[0]?.month,
      },
      {
        sku1Value: currentMonthDtos?.[0]?.usd,
        sku2Value: currentMonthDtos?.[1]?.usd,
        sku3Value: currentMonthDtos?.[2]?.usd,
        month: currentMonthDtos?.[0]?.month,
      },
      {
        sku1Value: nextMonthDtos?.[0]?.usd,
        sku2Value: nextMonthDtos?.[1]?.usd,
        sku3Value: nextMonthDtos?.[2]?.usd,
        month: nextMonthDtos?.[0]?.month,
      },
    ];
  };

  const toogleView = () => {
    setOpen(!open);
  };

  const nextMonth = () => {
    setCurrentDate(currentDate.add(1, 'month'));
    setParams({
      ...params,
      date: [
        currentDate.format('MMMM/YYYY').toUpperCase(),
        currentDate.add(1, 'month').format('MMMM/YYYY').toUpperCase(),
        currentDate.add(2, 'month').format('MMMM/YYYY').toUpperCase(),
      ].join(','),
    });
  };

  const previousMonth = () => {
    setCurrentDate(currentDate.subtract(1, 'month'));
    setParams({
      ...params,
      date: [
        currentDate.subtract(2, 'month').format('MMMM/YYYY').toUpperCase(),
        currentDate.subtract(1, 'month').format('MMMM/YYYY').toUpperCase(),
        currentDate.format('MMMM/YYYY').toUpperCase(),
      ].join(','),
    });
  };

  const updatePeriod = (value: string, date: dayjs.Dayjs) => {
    if (value === 'month') {
      return setParams({
        ...params,
        date: [
          date.subtract(1, 'month').format('MMMM/YYYY').toUpperCase(),
          date.format('MMMM/YYYY').toUpperCase(),
          date.add(1, 'month').format('MMMM/YYYY').toUpperCase(),
        ].join(','),
        period: 'month',
      });
    }
    if (value === 'q1') {
      setCurrentDate(dayjs(`01/02/${date.year()}`, 'DD/MM/YYYY'));
      return setParams({
        ...params,
        date: '',
        period: `q4/${date.subtract(1, 'year').format('YYYY').toUpperCase()},q1/${date
          .format('YYYY')
          .toUpperCase()},q2/${date.format('YYYY').toUpperCase()}`,
      });
    }
    if (value === 'q2') {
      setCurrentDate(dayjs(`01/05/${date.year()}`, 'DD/MM/YYYY'));

      return setParams({
        ...params,
        period: `q1/${date.format('YYYY').toUpperCase()},q2/${date
          .format('YYYY')
          .toUpperCase()},q3/${date.format('YYYY').toUpperCase()}`,
      });
    }
    if (value === 'q3') {
      setCurrentDate(dayjs(`01/08/${date.year()}`, 'DD/MM/YYYY'));

      return setParams({
        ...params,
        period: `q2/${date.format('YYYY').toUpperCase()},q3/${date
          .format('YYYY')
          .toUpperCase()},q4/${date.format('YYYY').toUpperCase()}`,
      });
    }
    if (value === 'q4') {
      setCurrentDate(dayjs(`01/11/${date.year()}`, 'DD/MM/YYYY'));
      return setParams({
        ...params,
        period: `q3/${date.format('YYYY').toUpperCase()},q4/${date
          .format('YYYY')
          .toUpperCase()},q1/${date.add(1, 'year').format('YYYY').toUpperCase()}`,
      });
    }
  };

  const getQuarterByParams = (position: string) => {
    if (position === 'previous') return params.period.split(',')[0].split('/')[0];
    return params.period.split(',')[2].split('/')[0];
  };

  const previousQuarter = () => {
    const date = currentDate.subtract(3, 'month');
    setCurrentDate(date);
    const quarter = getQuarterByParams('previous');
    updatePeriod(quarter, date);
  };

  const nextQuarter = () => {
    const date = currentDate.add(3, 'month');
    setCurrentDate(date);
    const quarter = getQuarterByParams('next');
    updatePeriod(quarter, date);
  };
  return {
    open,
    skusData,
    params,
    currentDate,
    setCurrentDate,
    nextQuarter,
    previousQuarter,
    updatePeriod,
    updateDateByExternalPicker,
    previousMonth,
    nextMonth,
    getDataForGraph,
    fetchSkuAnalysis,
    toogleView,
    isLoadingDataGraphic,
  };
};
