import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import ExpandableTable from '../../../../ComponentsPrices/ExpandableTable';
import { InOutCardProps } from './types';
import { useDashboardInOut } from './useDashboardInOut';
import { Loading } from '../../../../../components/Loading';

export function InOutCard({ partNumber, quarter }: InOutCardProps) {
  const { t } = useTranslation();
  const {
    expandableViewStatus,
    isLoading,
    handleUpdateExpandableViews,
    handleFetchData,
    handleChart,
  } = useDashboardInOut({
    partNumber,
    quarter,
  });

  useEffect(() => {
    handleFetchData();
  }, [partNumber, quarter]);
  return (
    <S.TablesContainer>
      <S.HeaderExpandableItem>
        <ExpandableTable
          isOpen={expandableViewStatus}
          onClick={() => {
            handleUpdateExpandableViews();
          }}
        />
        <S.HeaderExpandableContainer>
          <span>{t('pages.inventory.titlesTab.inOut')}</span>
        </S.HeaderExpandableContainer>
      </S.HeaderExpandableItem>
      {isLoading ? (
        <S.ChartContainer>
          <Loading />
        </S.ChartContainer>
      ) : (
        expandableViewStatus && <S.ChartContainer>{handleChart()}</S.ChartContainer>
      )}
    </S.TablesContainer>
  );
}
