import React from 'react';
import { Table } from 'antd';
import * as S from './styles';
import { useSimpleTable } from './useSimpleTable';
import { SimpleTableProps } from './types';

const SimpleTable: React.FC<SimpleTableProps> = ({
  dataSource,
  columns,
  rowKey,
  locale,
  striped,
  expandRowByClick,
  defaultExpandedRowKeys,
  components,
  onChange,
  onRow,
  rowClassName,
  rowSelection,
  pagination,
  showHeader,
  scroll,
  isLoading,
  headerNoPadding = false,
  noBorder = false,
  scrollable = false,
  size = 'middle',
  ...restProps
}) => {
  const { rowClassNameStripe, customExpandIcon } = useSimpleTable();
  return (
    <S.TableContainer headerNoPadding={headerNoPadding} noBorder={noBorder} scrollable={scrollable}>
      <Table
        {...restProps}
        dataSource={dataSource}
        columns={columns}
        rowKey={rowKey}
        showHeader={showHeader}
        onChange={onChange}
        scroll={scroll}
        rowClassName={
          striped
            ? (_, index) => {
                return rowClassNameStripe(rowClassName, index);
              }
            : rowClassName
        }
        rowSelection={rowSelection}
        components={components}
        locale={locale}
        expandable={
          expandRowByClick
            ? {
                expandRowByClick,
                expandIcon: customExpandIcon,
                defaultExpandedRowKeys,
              }
            : {}
        }
        size={size}
        pagination={pagination}
        onRow={onRow}
        loading={isLoading}
      />
    </S.TableContainer>
  );
};

export default SimpleTable;
