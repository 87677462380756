import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

export const Button = styled(AntdButton)`
  border-color: ${({ theme, type }) => type === 'primary' && theme.colors.link};
  background: ${({ theme, type }) => type === 'primary' && theme.colors.link};
  font-weight: 500;
  box-shadow: none;
  color: ${({ theme, type }) => (type === 'primary' ? theme.colors.background : theme.colors.link)};
  transition: border-color 0.2s;
  padding: ${({ shape }) => shape !== 'circle' && '4px 16px'};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:hover &:focus {
    background-color: ${({ theme, type }) => type === 'primary' && theme.colors.prim};
    color: ${({ theme, type }) =>
      type === 'primary' ? theme.colors.background : theme.colors.red} !important;
  }

  svg {
    align-self: center;
    margin-bottom: 1px;
  }
`;

export const TableActionButton = styled(AntdButton)`
  border: none;
  font-weight: 500;
  box-shadow: none;
  background: transparent !important;
  color: ${({ theme, danger, disabled }) =>
    disabled ? 'rgba(0, 0, 0, 0.25)' : danger ? theme.colors.danger : theme.colors.prim}!important;
  &:hover,
  &:focus {
    opacity: 0.9;
  }
`;
