/* eslint-disable react/destructuring-assignment */
import { Select } from 'antd';
import React from 'react';
import * as S from './styles';

const { Option } = Select;

export function SelectInput({ options, ...props }) {
  return (
    <S.SelectFilter {...props}>
      {options?.map(({ value, label, ...Optprops }) => (
        <Option key={value} value={value} {...Optprops}>
          {label}
        </Option>
      ))}
    </S.SelectFilter>
  );
}
