import * as S from './styles';
import StyledStatusIconProps from './types';

export default function StyledStatusIcon({
  Fstatus,
  module,
  title,
  content,
  arrow,
  placement,
}: StyledStatusIconProps) {
  if (module === 'finance') {
    if (Fstatus === 'success') {
      return (
        <S.PopOver
          title={
            <S.Title data-testid="popover-title-container">
              <S.CheckIcon data-testid="popover-icon" />
              <S.StyledSpanSuccess data-testid="popover-title">{title}</S.StyledSpanSuccess>
            </S.Title>
          }
          content={<S.Content data-testid="popover-content">{content}</S.Content>}
          arrow={arrow}
          placement={placement}
        >
          <S.CheckIcon data-testid="status-icon" />
        </S.PopOver>
      );
    }
    if (Fstatus === 'processing') {
      return (
        <S.PopOver
          title={
            <S.Title data-testid="popover-title-container">
              <S.ProcessingIcon data-testid="popover-icon" />
              <S.StyledSpanProcessing data-testid="popover-title">{title}</S.StyledSpanProcessing>
            </S.Title>
          }
          content={<S.Content data-testid="popover-content">{content}</S.Content>}
          arrow={arrow}
          placement={placement}
        >
          <S.ProcessingIcon data-testid="status-icon" />
        </S.PopOver>
      );
    }
    return (
      <S.PopOver
        title={
          <S.Title data-testid="popover-title-container">
            <S.WarningIcon data-testid="popover-icon" />
            <S.StyledSpanError data-testid="popover-title">{title}</S.StyledSpanError>
          </S.Title>
        }
        content={<S.Content data-testid="popover-content">{content}</S.Content>}
        arrow={arrow}
        placement={placement}
      >
        <S.WarningIcon data-testid="status-icon" />
      </S.PopOver>
    );
  }
  return <div> teste</div>;
}
