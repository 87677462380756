/* eslint-disable react/no-unstable-nested-components */
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ScorePerformedGraph } from '../../../components/Dashboard';
import * as S from './styles';
import SimpleTable from '../../../components/Tables/SimpleTable';
import StyledModal from '../../../components/Common/StyledModal';
import { Forecast } from '../types';

export function SKUTable({
  data,
  isLoading,
  columns,
  selectedProject,
  isModalOpen,
  toggleModal,
}: any) {
  const { t } = useTranslation();

  const ModalTitle = () => {
    return (
      <S.ModalTitleContainer>
        <span>
          {t('pages.dashboard.fields.projectName')}: <strong>{selectedProject?.projectName}</strong>
        </span>
        <span>
          {t('pages.dashboard.fields.modelName')}: <strong>{selectedProject?.modelName}</strong>
        </span>
        <span>
          SKU: <strong>{selectedProject?.sku}</strong>
        </span>
      </S.ModalTitleContainer>
    );
  };

  const ModalContent = () => {
    return (
      <S.ModalContent>
        <S.ModalTable>
          <thead>
            <tr>
              {selectedProject?.forecast.map((item: Forecast) => (
                <S.TableHead key={item.month}>{item.month}</S.TableHead>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {selectedProject?.forecast.map((item: Forecast) => (
                <S.TableData key={item.month}>{item.value}</S.TableData>
              ))}
            </tr>
          </tbody>
        </S.ModalTable>

        <S.Card>
          <S.ModalGraphTitle>
            {t('pages.dashboard.fields.scorePerformed')} X{' '}
            {t('pages.dashboard.fields.accumulatedScore')}
          </S.ModalGraphTitle>

          <ScorePerformedGraph data={selectedProject?.forecast as Forecast[]} />
        </S.Card>

        <S.ModalGraphLegend>
          <S.LegendContainer padding="0">
            <S.Legend color="#D9d9d9" type="square" />
            <span>PPB {t('common.score')}</span>
          </S.LegendContainer>
          <S.LegendContainer padding="0">
            <S.Legend color="#3D85C6" type="line" />
            <span>{t('common.target')}</span>
          </S.LegendContainer>
          {/*<S.LegendContainer padding="0">*/}
          {/*  <S.Legend color="#FF8B27" type="line" />*/}
          {/*  <span>{t('common.trend')}</span>*/}
          {/*</S.LegendContainer>*/}
        </S.ModalGraphLegend>
      </S.ModalContent>
    );
  };

  return (
    <>
      {isModalOpen && (
        <StyledModal
          open={isModalOpen}
          title={<ModalTitle />}
          body={<ModalContent />}
          footer={null}
          onClose={toggleModal}
          width="78rem"
        />
      )}

      <Row justify="center">
        <Col span={23}>
          <SimpleTable
            dataSource={data.tableScore}
            columns={columns}
            rowKey="id"
            striped
            size="middle"
            pagination={false}
            scroll={{ x: 'max-content', y: 'auto' }}
          />
        </Col>
      </Row>
    </>
  );
}
