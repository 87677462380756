import { Col, Dropdown, Form, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus, BiDownload } from 'react-icons/bi';
import { UpdateDate } from '../../components/Common/UpdateDate';
import { CsvExport } from '../../components/Common/CsvExport';
import StyledButton from '../../components/Common/StyledButton';
import { ActionButton } from '../../components/ActionButton';
import { EditableCell } from '../../components/EditableCell';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import { useBOM } from './useBOM';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import BomFilter from './BomFilter';
import * as SC from '../../styles/common';
import { Loading } from '../../components/Loading';
import ProjectsSelect from '../../components/forms/ProjectsSelect';
import { getURI } from '../../helpers/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import StyledSelect from '../../components/Common/StyledSelect';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import { StyledPagination } from '../../components/Common/StyledPagination';
import { Container } from './styles';

export function BOMPage({ view = null, onClose = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.bom.title'));

  const validateMessages = ValidateMessages();

  const {
    data,
    params,
    pageControl,
    fetchScenarios,
    scenariosOptions,
    selectedScenario,
    fetchBOM,
    changePage,
    handleChangeTable,
    isLoading,
    form,
    handleAddItem,
    onValuesChange,
    editingKey,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    isBaseScenario,
    expandedRowKeys,
    canUpdate,
    rowClassName,
    mergedColumns,
    transformData,
    headerCsv,
    setExpandedRowKeys,
    updateProject,
    updateProduct,
    getQueryParams,
  } = useBOM(dataModal);

  const scenarioName = selectedScenario
    ? `${params?.targetYear} - ${selectedScenario?.label}`
    : t('common.results.noScenarioTitle');

  const modifiedDate = selectedScenario
    ? (dataModal && dataModal.modified) || selectedScenario?.updated?.split('T')[0]
    : '';

  const showTagsFilter =
    params.header_sku?.length ||
    params.header_partType?.length ||
    params.header_spec?.length ||
    params.header_partNumber ||
    params.header_amount ||
    params.header_local ||
    params.header_description;

  useEffect(() => {
    if (params.targetYear) {
      fetchScenarios();
    }
  }, [params.targetYear]);

  useEffect(() => {
    if (params.targetYear && params.scenario && canUpdate) {
      fetchBOM();
    }
  }, [params]);

  const expandRowIds = (expanded, record) => {
    const keys = [];
    if (record.sku) {
      if (expanded) {
        keys.push(record.id);
      }
    } else if (record.className === 'partType') {
      const sku = record?.id.split('$')[0];
      if (expanded) {
        keys.push(record.id, sku);
      } else {
        keys.push(sku);
      }
    } else if (record?.partTypeId) {
      const sku = record?.id.split('$')[0];
      if (expanded) {
        keys.push(record.partTypeId, sku);
      } else {
        keys.push(sku);
      }
    }

    setExpandedRowKeys(keys);
  };

  const actions = [
    {
      key: '1',
      label: (
        <ActionButton
          key={1}
          icon={<BiPlus />}
          onClick={(e) => handleAddItem(e)}
          disabled={editingKey !== '' || isBaseScenario || isLoading}
          buttonText={`${t('common.add')} ${t('common.item')}`}
        />
      ),
    },
    {
      key: '2',
      label: (
        <ActionButton
          key={2}
          className="btn-csv"
          disabled={editingKey !== ''}
          icon={<BiDownload />}
        >
          <CsvExport
            url={getURI(`scenarios/${params.scenario}/bom`, getQueryParams(params))}
            filename={['bom', selectedScenario?.label]}
            limit={pageControl.totalElements}
            headers={headerCsv}
            transformData={transformData}
          />
        </ActionButton>
      ),
    },
  ];

  return (
    <main>
      <Row>
        {view ? null : (
          <BomFilter
            title={t('pages.bom.title')}
            clearFilter={clearFilter}
            searchFilter={searchFilter}
            scenariosOptions={scenariosOptions}
            values={params}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <Container span={23}>
            <Row justify="center">
              <Col span={23}>
                <StyledHeader
                  title={t('pages.bom.title')}
                  subtitle={scenarioName}
                  items={pageControl.totalElements}
                  actions={
                    <Dropdown key={0} menu={{ items: actions }} placement="bottomRight">
                      <StyledButton variant="primary">
                        {t('common.action')}
                        <DownOutlined />
                      </StyledButton>
                    </Dropdown>
                  }
                />
              </Col>
            </Row>

            <Row justify="center">
              <Col span={23}>
                <Row style={{ gap: '1rem' }}>
                  <StyledSelect
                    style={{ width: '7rem' }}
                    label={t('filter.product')}
                    placeholder={t('filter.product')}
                    value={params?.product}
                    options={[
                      { label: 'Show All', value: '' },
                      { label: 'Desktop', value: 'Desktop' },
                      { label: 'Notebook', value: 'Notebook' },
                    ]}
                    onClick={(e) => e.stopPropagation()}
                    onSelect={(value) => updateProduct(value)}
                  />
                  <ProjectsSelect
                    value={params?.project}
                    // valueAsId
                    intable="intable"
                    onClick={(e) => e.stopPropagation()}
                    onSelect={(value) => updateProject(value)}
                  />
                </Row>
                <Col span={18} className="col-filters">
                  <SC.ContainerFilteredTags>
                    {params.product && (
                      <SC.TagFilter closable onClose={() => handleCloseFilteredTag('product')}>
                        <div className="tag-title">{t('filter.product')}</div>
                        <div className="tag-value">{params.product}</div>
                      </SC.TagFilter>
                    )}
                    {params.project && (
                      <SC.TagFilter closable onClose={() => handleCloseFilteredTag('project')}>
                        <div className="tag-title">{t('filter.project')}</div>
                        <div className="tag-value">{params.project}</div>
                      </SC.TagFilter>
                    )}
                    {params.header_sku && (
                      <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_sku')}>
                        <div className="tag-title">{t('pages.bom.fields.sku')}</div>
                        <div className="tag-value">{params.header_sku}</div>
                      </SC.TagFilter>
                    )}
                    {params.header_partType && (
                      <SC.TagFilter
                        closable
                        onClose={() => handleCloseFilteredTag('header_partType')}
                      >
                        <div className="tag-title">{t('pages.bom.fields.partType')}</div>
                        <div className="tag-value">{params.header_partType}</div>
                      </SC.TagFilter>
                    )}
                    {params.header_spec && (
                      <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_spec')}>
                        <div className="tag-title">{t('pages.bom.fields.spec')}</div>
                        <div className="tag-value">{params.header_spec}</div>
                      </SC.TagFilter>
                    )}
                    {params.header_partNumber && (
                      <SC.TagFilter
                        closable
                        onClose={() => handleCloseFilteredTag('header_partNumber')}
                      >
                        <div className="tag-title">{t('pages.bom.fields.partNumber')}</div>
                        <div className="tag-value">{params.header_partNumber}</div>
                      </SC.TagFilter>
                    )}
                    {params.header_amount && (
                      <SC.TagFilter
                        closable
                        onClose={() => handleCloseFilteredTag('header_amount')}
                      >
                        <div className="tag-title">{t('pages.bom.fields.amount')}</div>
                        <div className="tag-value">{params.header_amount}</div>
                      </SC.TagFilter>
                    )}
                    {params.header_local && (
                      <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_local')}>
                        <div className="tag-title">{t('pages.bom.fields.local')}</div>
                        <div className="tag-value">{params.header_local}</div>
                      </SC.TagFilter>
                    )}
                    {params.header_description && (
                      <SC.TagFilter
                        closable
                        onClose={() => handleCloseFilteredTag('header_description')}
                      >
                        <div className="tag-title">{t('pages.bom.fields.description')}</div>
                        <div className="tag-value">{params.header_description}</div>
                      </SC.TagFilter>
                    )}
                  </SC.ContainerFilteredTags>
                </Col>
              </Col>
            </Row>

            <Row justify="center">
              <Col span={23}>
                <UpdateDate date={modifiedDate} />
                <Form
                  form={form}
                  component={false}
                  onValuesChange={onValuesChange}
                  validateMessages={validateMessages}
                >
                  <SimpleTable
                    rowKey="id"
                    data-cy="table"
                    striped
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    columns={mergedColumns}
                    dataSource={data}
                    onChange={handleChangeTable}
                    pagination={false}
                    expandRowByClick
                    scroll={{ x: 'max-content', y: 'calc(100vh - 24rem)' }}
                    defaultExpandedRowKeys={showTagsFilter ? [data[0]?.id] : null}
                    expandable={{ expandedRowKeys, indentSize: 0 }}
                    onExpand={expandRowIds}
                    locale={{
                      emptyText: (
                        <ResultNoScenarios
                          title={t('common.results.noDataTitle')}
                          subTitle={t('common.results.noDataSubtitle')}
                        />
                      ),
                    }}
                  />
                </Form>

                {pageControl.totalPages > 1 && (
                  <StyledPagination
                    onChange={changePage}
                    pageNumber={params.page + 1}
                    size={params.limit}
                    totalElements={pageControl.totalElements}
                  />
                )}
              </Col>
            </Row>
          </Container>
        )}
      </Row>
    </main>
  );
}
