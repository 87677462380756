import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../../services/api';
import { getURI } from '../../../helpers/utils';
import { SelectInput } from '../../LateralFilter/SelectInput';
import StyledSelect from '../../Common/StyledSelect';

async function fetchProjects(valueAsId = false) {
  const params = {};
  return api.get(getURI('projects', params)).then((resp) => {
    return resp.data.map((data) => ({
      label: data.project,
      value: valueAsId ? data.id : data.project,
      key: data.id,
    }));
  });
}

export default function ProjectsSelect({ valueAsId, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();

  const labelNone =
    props?.id === 'projectSimulation'
      ? `${t('common.showAll')} - ${t('filter.project')}`
      : t('common.showAll');

  useEffect(() => {
    setFetching(true);
    fetchProjects(valueAsId).then((newOptions) => {
      setOptions([{ value: '', label: labelNone }, ...newOptions]);
      setFetching(false);
    });
  }, []);

  return (
    <StyledSelect
      label={t('filter.project')}
      showSearch
      styles={{ width: '9rem' }}
      filterOption={(input, option) => {
        return (
          option?.value.toLowerCase()?.indexOf(input.toLowerCase()) >= 0 ||
          option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
        );
      }}
      loading={fetching}
      options={options}
      title={t('filter.project')}
      placeholder={t('filter.project')}
      {...props}
    />
  );
}
