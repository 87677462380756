import { Row } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid #f5f5f5;
  width: 100%;
  min-height: 100%;
  border-radius: 0.313rem;
  padding: 1rem;

  .title {
    background-color: #f5f5f5;
    border-radius: 0.313rem;
    padding: 1rem 0.625rem;
    margin-bottom: 1rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-size: 14px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.blue};
      margin-bottom: 0;
    }

    .activateAlert {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;

      span {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors.input_labels};
      }
    }

    .switch {
      margin-top: 1rem;
    }
  }

  .ant-form-item {
    margin-bottom: 1rem;
  }

  .ant-form-item-label {
    padding: 0;
    label {
      color: ${({ theme }) => theme.colors.gray_light};
      font-size: 0.875rem;
      font-weight: 400;
      margin: 0;
      &:not([for='active']) {
        height: auto;
      }
    }
  }

  .ant-form-item-control-input {
    input {
      font-size: 0.875rem;
      font-weight: 400;
      padding: 0.5rem 0.313rem;
      color: ${({ theme }) => theme.colors.title};
      line-height: 1rem;
      border-radius: 0.188rem;
    }
  }

  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.75rem;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-bottom: 0.5rem;
    flex: 1 0 33.33%;
    max-width: 33.33%;

    span {
      &.ant-checkbox {
        top: auto;
        height: auto;
      }

      span {
        display: flex;
        gap: 0.5rem;
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.gray_light};
      }
    }
  }

  .ant-input-number-group-addon {
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.gray_light};
    font-size: 0.875rem;
  }

  #frequency {
    border-radius: 0.188rem;
    text-align: center;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
