import * as S from './styles';
import { BidCardProps } from './types';
import { useBidCard } from './useBidCard';

export default function BidCard({ bidCard, bidCardOnSelect }: BidCardProps) {
  const { handleRenderItensAlGroup } = useBidCard();

  return (
    <S.Container>
      <span>{bidCard.category}</span>
      {handleRenderItensAlGroup(bidCard.groupedByCategories, bidCardOnSelect)}
    </S.Container>
  );
}
