import React from 'react';
import * as S from './styles';

export function Button({ buttonText, icon, iconEnd, onClick, children, type, ...props }) {
  return (
    <>
      {type !== 'table_action' && (
        <S.Button onClick={onClick} type={type} {...props}>
          {icon || null}
          {buttonText || null}
          {children || null}
          {iconEnd || null}
        </S.Button>
      )}
      {type === 'table_action' && (
        <S.TableActionButton onClick={onClick} {...props}>
          {icon || null}
          {buttonText || null}
          {children || null}
          {iconEnd || null}
        </S.TableActionButton>
      )}
    </>
  );
}
