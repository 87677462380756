import { Button } from 'antd';
import styled from 'styled-components';

export const ToolbarContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colorsDesignSystem.secondaryGrayBackground};
  padding: 1rem;
  border-radius: 0.375rem;
`;

export const ToolbarButton = styled(Button)<{ active?: boolean }>`
  background-color: ${({ active, theme }) =>
    active && theme.colorsDesignSystem.multipleSelectOptionActive};

  svg {
    width: 1rem;
    height: 1rem;
  }

  img {
    width: 1.5rem;
    height: 1.5rem;
  }

  .small-image {
    width: 1rem;
    height: 1rem;
  }
`;
