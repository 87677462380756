import { Button, Radio } from 'antd';
import styled from 'styled-components';
import theme from '../../../../styles/theme';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 2.5rem 1rem 2.5rem;
  height: 6.5rem;
  background-color: ${theme.colorsDesignSystem.backgroundSecondaryColor};
  border-radius: ${theme.dimensions.border_radius_large};
  margin-bottom: 1rem;
`;

export const PartNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 0, 063rem solid ${theme.colorsDesignSystem.boxshadowTooltipChart};
  width: 20%;
`;

export const LabelInfoContainer = styled.span`
  font-size: 0.875rem;
  color: ${theme.colorsDesignSystem.headerSubTitleTableText};
`;

export const PartNumberInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.875rem;
  gap: 0.25rem;
  margin-top: 0.5rem;
`;

export const OtherInformationsContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;
export const SingleInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InfoContainerText = styled.span`
  font-size: 0.875rem;
  color: ${theme.colorsDesignSystem.text};
  font-weight: 600;
`;
export const FilterQuarterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledButton = styled(Button)`
  color: ${theme.colorsDesignSystem.activeButton};
  height: 100% !important;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  &:hover {
    color: ${theme.colorsDesignSystem.activeButton} !important;
    background-color: transparent !important;
  }
`;
export const YearContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 0.063rem solid ${theme.colorsDesignSystem.boxshadowTooltipChart};
  border-top-left-radius: 0.313rem;
  border-bottom-left-radius: 0.313rem;
  height: 2rem;
  width: 8%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RadioGroupContainer = styled(Radio.Group)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 92%;
  padding: 0;
  margin: 0 !important;
  &:last-child {
  }
  border-top-right-radius: 0.313rem !important;
  border-bottom-right-radius: 0.313rem !important;
`;

export const StyledRadio = styled(Radio.Button)`
  background-color: transparent;
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  height: 100%;
  text-align: center;
  border-left: none !important;

  &.ant-radio-button-wrapper-checked {
    background-color: ${theme.colorsDesignSystem.activeButton};
    border-radius: 0 !important;
    border-left: none !important;
    color: white;
  }
`;
