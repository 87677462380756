import { useEffect } from 'react';
import { t } from 'i18next';
import SimpleTable from '../../../components/Tables/SimpleTable';
import * as S from './styles';
import { ModalContentProps } from './types';
import { useContentModalShareProportion } from './useContentModalShareProportion';
import { Loading } from '../../../components/Loading';
import StyledButton from '../../../components/Common/StyledButton';
import { formatValuesToThousands } from '../../../helpers/FormatValues';

export function ContentModalShareProportion({
  month,
  specification,
  supplier,
  projectName,
  cm,
  onCancel,
}: ModalContentProps) {
  const { item, isLoading, columns, handleFetchShareProportionDetails, handleRenderValuesWithNa } =
    useContentModalShareProportion();

  useEffect(() => {
    handleFetchShareProportionDetails(month, specification, supplier, projectName, cm);
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div style={{ display: 'flex' }}>
            <S.TitleHeader>{projectName}</S.TitleHeader>
          </div>
          <S.ContainerHeader>
            <S.ContainerTags type="HORIZONTAL">
              <S.ContainerLabels type="VERTICAL">
                <S.Labels type="TITLE">Category</S.Labels>
                <S.Labels type="CONTENT">{item?.category}</S.Labels>
              </S.ContainerLabels>
              <S.ContainerLabels type="VERTICAL">
                <S.Labels type="TITLE">Specification</S.Labels>
                <S.Labels type="CONTENT">{item?.specification}</S.Labels>
              </S.ContainerLabels>
              <S.ContainerLabels type="VERTICAL">
                <S.Labels type="TITLE">CM</S.Labels>
                <S.Labels type="CONTENT">{item?.cm}</S.Labels>
              </S.ContainerLabels>
            </S.ContainerTags>
            <S.ContainerTags type="HORIZONTAL">
              <S.ContainerLabels type="VERTICAL">
                <S.Labels type="TITLE">Component Demand</S.Labels>
                <S.Labels style={{ fontSize: '1.875rem' }} type="CONTENT">
                  {handleRenderValuesWithNa(item?.componentDemand || 'N/A')}
                </S.Labels>
              </S.ContainerLabels>
            </S.ContainerTags>
            <S.ContainerTags type="VERTICAL">
              <S.ContainerLabels type="HORIZONTAL">
                <S.Labels type="TITLE">Month Demand </S.Labels>
                <S.Labels type="CONTENT">
                  {handleRenderValuesWithNa(item?.monthDemand || 'N/A')}
                </S.Labels>
              </S.ContainerLabels>
              <S.ContainerLabels type="HORIZONTAL">
                <S.Labels type="TITLE">Component QTY</S.Labels>
                <S.Labels type="CONTENT">
                  {handleRenderValuesWithNa(item?.componentQTY || 'N/A')}
                </S.Labels>
              </S.ContainerLabels>
            </S.ContainerTags>
          </S.ContainerHeader>
          <SimpleTable
            striped
            bordered={false}
            pagination={false}
            dataSource={item?.shareProportionDetailsList}
            rowKey="partNumber"
            scroll={{ x: 'max-content', y: 'auto' }}
            columns={columns}
            isLoading={isLoading}
          />
          <S.FooterContainer>
            <StyledButton
              small
              onClick={onCancel}
              variant="secondary"
              style={{ height: '2rem' }}
              text={t('common.cancel')}
            />
          </S.FooterContainer>
        </>
      )}
    </div>
  );
}
