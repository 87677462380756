import styled from 'styled-components';

type TooltipItemProps = {
  color: string;
  divider?: boolean;
};

export const TooltipContainer = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
`;

export const TooltipList = styled.ul`
  list-style: none;
`;

export const TooltipItem = styled.li<TooltipItemProps>`
  padding: 4px 0;
  color: ${({ color }) => color};
  border-top: 1px solid transparent;
  border-color: ${({ divider, theme }) => (divider ? theme.colors.gray_border : 'transparent')};
  text-transform: capitalize;
`;

export const TooltipTotal = styled.li`
  padding: 4px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_border};
  color: #000000d9;
`;
