import { Button, Col, ConfigProvider, Row, Space, Popconfirm, Tooltip, Form, Dropdown } from 'antd';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillInteraction } from 'react-icons/ai';
import { DownOutlined, CloseOutlined } from '@ant-design/icons';
import { ComponentsTypeSelect } from '../../components/forms/ComponentsTypeSelect';
import { useFileErros } from './useFileErros';
import * as SC from '../../styles/common';
import { EditableCell } from '../../components/EditableCell';
import { useTable } from '../../hooks/useTable';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { Loading } from '../../components/Loading';
import { renderDateHour } from '../../helpers/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { ActionButton } from '../../components/ActionButton';
import SimpleTable from '../../components/Tables/SimpleTable';
import { StyledPagination } from '../../components/Common/StyledPagination';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledButton from '../../components/Common/StyledButton';
import StyledSelect from '../../components/Common/StyledSelect';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import theme from '../../styles/theme';

export function FileErrorsPage({ view = null, onClose = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.fileerrors.title'));

  const { getSortOrderTable } = useTable();
  const validateMessages = ValidateMessages();
  const {
    data,
    params,
    pageControl,
    canUpdate,
    fetchFileErrors,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    save,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    selectedRowKeys,
    handleDeleteLogs,
    handleSelectAllRows,
    rowSelection,
    handleProcessFiles,
    selectedToProcess,
    statusSelect,
    handleFilter,
  } = useFileErros();

  const modifiedDate = (dataModal && dataModal.modified) || `2022-01-10`;
  const showTagsFilter = params.partType?.length || params.spec;

  const selectButtonText =
    selectedRowKeys.length === data.length
      ? `${t('common.unselect')} ${t('common.all')}`
      : `${t('common.select')} ${t('common.all')}`;

  const columns = [
    {
      key: 'modifiedDate',
      dataIndex: 'modifiedDate',
      title: t('pages.fileerrors.fields.modifiedDate'),
      label: t('pages.fileerrors.fields.modifiedDate'),
      sorter: 'true',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'modifiedDate' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: t('sort.orderBy') },
      width: '11.25rem',
      render: (value) => renderDateHour(value, true),
    },
    {
      key: 'message',
      dataIndex: 'message',
      title: t('pages.fileerrors.fields.message'),
      label: t('pages.fileerrors.fields.message'),
    },
    {
      key: 'file',
      dataIndex: 'file',
      title: t('pages.fileerrors.fields.file'),
      label: t('pages.fileerrors.fields.file'),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('pages.fileerrors.fields.status'),
      label: t('pages.fileerrors.fields.status'),
    },
    {
      key: 'action',
      title: t('common.action'),
      width: '6.25rem',
      render: (_, record) => {
        return record.status === 'ERRO_INTEGRACAO' ? (
          <div style={{ textAlign: 'center' }}>
            <Tooltip placement="left" title={t('pages.fileerrors.tooltipMessage')}>
              <Space direction="horizontal" size={12}>
                <Popconfirm
                  title={t('pages.fileerrors.confirmMessage')}
                  onConfirm={() => save(record)}
                >
                  <Button shape="circle" default icon={<AiFillInteraction />} />
                </Popconfirm>
              </Space>
            </Tooltip>
          </div>
        ) : null;
      },
    },
  ];

  const onCellRecords = (record, col) => ({
    record,
    newInputNode: col.dataIndex === 'partType' && <ComponentsTypeSelect />,
    dataIndex: col.dataIndex,
    title: col.title,
  });

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => onCellRecords(record, col),
    };
  });

  const exportColumns = columns.filter((col) => col.key !== 'action');

  useEffect(() => {
    if (canUpdate) {
      fetchFileErrors();
    }
  }, [params]);

  const actions = [
    {
      key: '1',
      label: (
        <ActionButton
          key={1}
          onClick={handleDeleteLogs}
          buttonText={`${t('common.delete')} ${t('common.selected')}`}
          disabled={selectedRowKeys.length <= 0 || isLoading}
          hidden={selectedRowKeys.length <= 0}
        />
      ),
    },
    {
      key: '2',
      label: (
        <ActionButton
          key={2}
          onClick={handleProcessFiles}
          buttonText={`${t('pages.fileerrors.processMultipleMessage', {
            files: selectedToProcess.length,
          })}`}
          disabled={selectedRowKeys.length <= 0 || isLoading}
          hidden={selectedToProcess.length <= 0}
        />
      ),
    },
  ];

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={24}>
                <StyledHeader
                  title={t('pages.fileerrors.title')}
                  items={pageControl.totalElements}
                  actions={
                    selectedRowKeys.length <= 0 ? (
                      <StyledButton
                        variant="primary"
                        onClick={handleSelectAllRows}
                        disabled={isLoading}
                      >
                        {selectButtonText}
                      </StyledButton>
                    ) : (
                      <Dropdown key={0} menu={{ items: actions }} placement="bottomRight">
                        <StyledButton variant="primary">
                          <DownOutlined />
                          {t('common.action')}
                        </StyledButton>
                      </Dropdown>
                    )
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <StyledSelect
                  label={t('pages.fileerrors.title')}
                  style={{ width: '8rem' }}
                  options={statusSelect}
                  value={params.status}
                  showSearch
                  onChange={(event) => handleFilter('status', event)}
                />
              </Col>
            </Row>

            <Row justify="center">
              <Col span={24}>
                <SC.ContainerFilteredTags>
                  {params.status && (
                    <StyledFilterTag
                      label={t('pages.avlc.filters.category')}
                      closeicon={<CloseOutlined color={theme.colors.prim} />}
                      title={params.status}
                      closeble
                      onClose={() => handleCloseFilteredTag('status')}
                    />
                  )}
                </SC.ContainerFilteredTags>
              </Col>
            </Row>

            <Row justify="center">
              <Col span={24}>
                <Form form={form} component={false} validateMessages={validateMessages}>
                  <ConfigProvider>
                    <SimpleTable
                      rowKey="id"
                      data-cy="table"
                      striped
                      dataSource={data}
                      columns={mergedColumns}
                      onChange={handleChangeTable}
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      scroll={{ x: 'max-content', y: 'calc(100vh - 22rem)' }}
                      rowSelection={{ ...rowSelection }}
                      pagination={false}
                      locale={{
                        emptyText: (
                          <ResultNoScenarios
                            title={t('common.results.noDataTitle')}
                            subTitle={t('common.results.noDataSubtitle')}
                          />
                        ),
                      }}
                    />
                  </ConfigProvider>
                </Form>

                {pageControl.totalPages > 1 && (
                  <StyledPagination
                    onChange={changePageValue}
                    pageNumber={params.page + 1}
                    size={params.limit}
                    totalElements={pageControl.totalElements}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
