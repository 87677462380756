import i18n from 'i18next';

export const formatValueToCash = (value: number) => {
  if (value) {
    return value.toLocaleString(i18n.t('common.localeMoney'), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
};

export const formatValuesToThousands = (value: number) => {
  if (value) {
    return new Intl.NumberFormat(i18n.t('common.localeMoney')).format(value);
  }
};

export const formatNumberToOrdinal = (value: number): string => {
  try {
    if (!value) return '';

    const lastDigit = value % 10;
    const lastTwoDigits = value % 100;

    if (lastDigit === 1 && lastTwoDigits !== 11) {
      return `${value}st`;
    }
    if (lastDigit === 2 && lastTwoDigits !== 12) {
      return `${value}nd`;
    }
    if (lastDigit === 3 && lastTwoDigits !== 13) {
      return `${value}rd`;
    }
    return `${value}th`;
  } catch (error) {
    console.log(error);
    return '';
  }
};
export const formatNumberForTowDecimalPlaces = (value: number | null | undefined) => {
  if (value) {
    return value.toFixed(2);
  }
};
