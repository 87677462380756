import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TitlePage = styled.p`
  margin-top: 2.5rem;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 5rem;
`;
