import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  HourglassOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { QStatus } from '../../pages/AVLC/types';
import theme from '../../styles/theme';

interface StatusIconProps {
  status: QStatus;
}
export const StatusIcon = ({ status }: StatusIconProps) => {
  switch (status) {
    case 'Qd':
      return <CheckCircleOutlined style={{ fontSize: '0.75rem', color: theme.colors.prim }} />;
    case 'Qf':
      return (
        <CloseCircleOutlined
          style={{ fontSize: '0.75rem', color: theme.colorsDesignSystem.red_secondary }}
        />
      );
    case 'Qg':
      return (
        <HourglassOutlined
          style={{ fontSize: '0.75rem', color: theme.colorsDesignSystem.tagColorYellow }}
        />
      );
    case 'Np':
      return <MinusCircleOutlined style={{ fontSize: '0.75rem' }} />;
    default:
      return null;
  }
};
