import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import cancelFolder from '../../../../assets/cancelFolder.svg';

export function CancelMessage({ bidName }: { bidName: string }) {
  const { t } = useTranslation();
  return (
    <S.CancelBidContainer>
      <S.CancelBidHeader>{t('pages.bidSupplierRFQ.canceBid.title')}</S.CancelBidHeader>
      <S.CancelBidContent>
        <S.CancelImage src={cancelFolder} alt="cancel" />
        <Flex gap={4}>
          <S.CancelMessage
            dangerouslySetInnerHTML={{
              __html: t('pages.bidSupplierRFQ.canceBid.message', { bidName }),
            }}
          />
        </Flex>
        <S.CancelSubMessage>{t('pages.bidSupplierRFQ.canceBid.subMessage')}</S.CancelSubMessage>
        <S.CancelSubMessage>{t('pages.bidSupplierRFQ.canceBid.subMessage2')}</S.CancelSubMessage>
      </S.CancelBidContent>
    </S.CancelBidContainer>
  );
}
