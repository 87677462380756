import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledIndicationTag from '../../../../components/IndicationTag';
import { ShareProportionData } from '../../types';
import * as S from './styles';

export function useShareProportionCard(click: React.Dispatch<React.SetStateAction<string>>) {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<string>('0');

  const handleItemClick = (id: string) => {
    setSelectedItem(id);
  };

  const renderCartItems = (dataShareProportion: Array<ShareProportionData>) => {
    return (
      <S.Container arrayLength={dataShareProportion.length}>
        {dataShareProportion.map((item, index) => {
          const itemId = crypto.randomUUID();
          return (
            <S.ItemContainer
              key={itemId}
              arrayLength={dataShareProportion.length}
              onClick={() => {
                handleItemClick(index.toString());
                click(item.partNumber);
              }}
              isSelected={index.toString() === selectedItem}
            >
              <S.PartNumberContainer>
                <S.Label>{t('pages.financePage.shareProportion.partNumber')}</S.Label>
                <S.PartNumberValue>{item.partNumber}</S.PartNumberValue>
              </S.PartNumberContainer>
              <S.SourceContainer>
                <S.Label>{t('pages.financePage.shareProportion.source')}</S.Label>
                <StyledIndicationTag text={item.source} />
              </S.SourceContainer>
              <S.SupplierContainer>
                <S.Label>{t('pages.financePage.shareProportion.supplier')}</S.Label>
                <S.SupllierValue arrayLength={dataShareProportion.length}>
                  {item.supplier}
                </S.SupllierValue>
              </S.SupplierContainer>
              <S.PercentValue
                arrayLength={dataShareProportion.length}
                percentErro={item.porcentage > 100.0}
              >
                {item.porcentage}%
              </S.PercentValue>
            </S.ItemContainer>
          );
        })}
      </S.Container>
    );
  };

  return {
    renderCartItems,
  };
}
