import { DatePicker } from 'antd';
import StyledLabel from '../StyledLabel';
import * as S from './styles';
import StyledRagePickerProps from './types';

export default function StyledRangePiker({
  label,
  value,
  picker,
  changeOnBlur,
  disabled,
  placeholder,
  required = false,
  disabledDate,
  onCalendarChange,
  onChange,
  onOpenChange,
  maxDate,
  minDate,
}: StyledRagePickerProps) {
  const { RangePicker } = DatePicker;
  return (
    <S.Container>
      <StyledLabel title={label || ''} />
      <RangePicker
        minDate={minDate}
        maxDate={maxDate}
        picker={picker}
        value={value}
        changeOnBlur={changeOnBlur}
        disabledDate={disabledDate}
        onCalendarChange={onCalendarChange}
        onChange={onChange}
        onOpenChange={onOpenChange}
        disabled={disabled}
        placeholder={placeholder}
      />
      {required && <S.ContainerRequired>Required</S.ContainerRequired>}
    </S.Container>
  );
}
