import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { t } from 'i18next';
import * as S from './styles';
import backgroundAuth from '../../assets/background_auth.svg';
import titleAuth from '../../assets/title_auth.svg';
import { useAwaitAuth } from './useAwaitAuth';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export function RedirectLogin() {
  const { handleGetDecodedToken } = useAwaitAuth();
  useDocumentTitle(t('pages.awaitAuth.title'));

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  useEffect(() => {
    const token = query.get('refresh-token');
    const user = query.get('user-id');
    token && user && handleGetDecodedToken(token, user);
  }, []);

  return (
    <S.Container data-cy="await-page">
      <S.Content>
        <img src={titleAuth} alt="Acer Simulador PPB" />
        <S.TitlePage>
          {t('pages.awaitAuth.title')}
          <b> Procurement</b>
        </S.TitlePage>
        <img src={backgroundAuth} alt="Background" />
      </S.Content>
    </S.Container>
  );
}
