import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { $patchStyleText } from '@lexical/selection';
import { Input } from 'antd';
import { $getSelection, LexicalEditor } from 'lexical';
import { useCallback, useEffect, useState } from 'react';
import * as S from './styles';

const MIN_ALLOWED_FONT_SIZE = 10;
const MAX_ALLOWED_FONT_SIZE = 32;
const DEFAULT_FONT_SIZE = 12;

export default function FontSize({
  selectionFontSize,
  editor,
}: {
  selectionFontSize: string;
  editor: LexicalEditor;
}) {
  const [inputValue, setInputValue] = useState<string>(selectionFontSize);
  const [inputChangeFlag, setInputChangeFlag] = useState<boolean>(false);

  const updateFontSizeInSelection = useCallback(
    (newFontSize: string | null) => {
      const getNextFontSize = (prevFontSize: string | null): string => {
        if (!prevFontSize) {
          prevFontSize = `${DEFAULT_FONT_SIZE / 16}px`;
        }
        prevFontSize = prevFontSize.slice(0, -2);

        return `${prevFontSize}px`;
      };

      editor.update(() => {
        if (editor.isEditable()) {
          const selection = $getSelection();
          if (selection !== null) {
            $patchStyleText(selection, {
              'font-size': newFontSize || getNextFontSize,
            });
          }
        }
      });
    },
    [editor]
  );

  const updateFontSizeByInputValue = (inputValueNumber: number) => {
    let updatedFontSize = inputValueNumber;
    if (inputValueNumber > MAX_ALLOWED_FONT_SIZE) {
      updatedFontSize = MAX_ALLOWED_FONT_SIZE;
    } else if (inputValueNumber < MIN_ALLOWED_FONT_SIZE) {
      updatedFontSize = MIN_ALLOWED_FONT_SIZE;
    }

    setInputValue(String(updatedFontSize));
    updateFontSizeInSelection(`${String(updatedFontSize / 16)}rem`);
    setInputChangeFlag(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputValueNumber = Number(inputValue);

    if (['e', 'E', '+', '-'].includes(e.key) || Number.isNaN(inputValueNumber)) {
      e.preventDefault();
      setInputValue('');
      return;
    }
    setInputChangeFlag(true);
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();

      updateFontSizeByInputValue(inputValueNumber);
    }
  };

  const handleInputBlur = () => {
    if (inputValue !== '' && inputChangeFlag) {
      const inputValueNumber = Number(inputValue);
      updateFontSizeByInputValue(inputValueNumber);
    }
  };

  const handleButtonClick = (value: number) => {
    updateFontSizeInSelection(`${String(value / 16)}rem`);
    setInputValue(String(value));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value) || value === '' || value === '-') {
      setInputValue(value);
    }
  };

  useEffect(() => {
    setInputValue(selectionFontSize);
  }, [selectionFontSize]);

  return (
    <>
      <S.ToolbarButton
        type="text"
        disabled={selectionFontSize !== '' && Number(inputValue) <= MIN_ALLOWED_FONT_SIZE}
        onClick={() => handleButtonClick(Number(inputValue) - 1)}
      >
        <MinusOutlined />
      </S.ToolbarButton>

      <Input
        value={inputValue}
        min={MIN_ALLOWED_FONT_SIZE}
        max={MAX_ALLOWED_FONT_SIZE}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        onBlur={handleInputBlur}
        style={{ width: '2.5rem' }}
      />

      <S.ToolbarButton
        type="text"
        disabled={selectionFontSize !== '' && Number(inputValue) >= MAX_ALLOWED_FONT_SIZE}
        onClick={() => handleButtonClick(Number(inputValue) + 1)}
      >
        <PlusOutlined />
      </S.ToolbarButton>
    </>
  );
}
