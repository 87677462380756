import { Checkbox } from 'antd';
import styled from 'styled-components';

export const checkbox = styled(Checkbox.Group)`
  flex: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
  min-height: 2rem;
  flex-wrap: nowrap;
  gap: 1rem;

  span {
    font-weight: 400;
  }
`;
