// eslint-disable-next-line no-shadow
export enum ListTypeOptions {
  'Quotes' = 1,
  'Imports Components Price' = 2,
  'Local Components Price' = 3,
  'Packaging Price' = 4,
  'Average' = 5,
  'Forecast' = 8,
  'Share Proportion' = 7,
  'In Out' = 9,
}

// eslint-disable-next-line no-shadow
export enum Months {
  'JANUARY' = 1,
  'FEBRUARY' = 2,
  'MARCH' = 3,
  'APRIL' = 4,
  'MAY' = 5,
  'JUNE' = 6,
  'JULY' = 7,
  'AUGUST' = 8,
  'SEPTEMBER' = 9,
  'OCTOBER' = 10,
  'NOVEMBER' = 11,
  'DECEMBER' = 12,
}
