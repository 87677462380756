import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectInput } from '../../LateralFilter/SelectInput';
import { api } from '../../../services/api';
import { RoutesPath } from '../../../routes/routesPath';

export function GroupsSelect({ ...props }) {
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);

  const getGroups = async () => {
    const { data: groups } = await api.get(
      `${import.meta.env.VITE_API_LOGIN_UNICO}${RoutesPath.groups}`
    );

    if (groups?.length) {
      const formatOptions = groups.map((opt) => ({ label: opt.name, value: opt.name }));
      setOptions([{ value: '', label: t('common.none') }, ...formatOptions]);
    }
  };

  useEffect(() => {
    (async () => {
      await getGroups();
    })();
  }, []);

  return (
    <SelectInput
      // showSearch
      // allowClear
      multiple={false}
      placeholder={t('filter.partType')}
      name="SelectGroups"
      id="SelectGroups"
      options={options}
      {...props}
    />
  );
}
