import { Button } from 'antd';
import styled from 'styled-components';

export interface CellIsTotalizer {
  isTotalizer: boolean;
}

export const Container = styled.main`
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.background};
`;

export const ProjectName = styled.span`
  font-size: ${({ theme }) => theme.dimensions.font_title_large};
  color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
  margin-bottom: 65px;
`;

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-end;
  margin-bottom: 0.75rem;
  margin-top: 2rem;
`;

export const FiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.938rem;
  gap: 0.375rem;
`;

export const TableContainer = styled.div`
  margin-top: 1rem;
`;

export const EditButton = styled(Button)`
  :hover {
    color: ${({ theme }) => theme.colors.link} !important;
  }
`;

export const TableLabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.875rem;
  margin-bottom: -0.55rem;

  span {
    font-size: 0.688rem;
    color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
  }
`;

export const QuoteFilesLabel = styled.p`
  color: ${({ theme }) => theme.colors.dark_green} !important;
  font-size: 0.75rem;
  font-style: bold !important;
  margin: 0;
`;

export const TotalizerField = styled.span`
  font-weight: bold;
`;

export const NormalField = styled.p<CellIsTotalizer>`
  font-weight: ${({ isTotalizer }) => (isTotalizer ? 'bold' : 'normal')};
  margin: 0;
  padding: 0.25rem 0.938rem;
`;
export const Model = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`;
