import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Col, ConfigProvider, Row } from 'antd';
import { Loading } from '../../components/Loading';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import { useQualificationAlert } from './useQualificationAlert';
import * as S from './styles';

export function QualificationAlert() {
  useDocumentTitle(t('pages.qualificationAlert.title'));
  const { id: alertId } = useParams<{ id: string }>();

  const { isLoading, columns, headerInfo, tableData, fetchAlert, handleChangeTable } =
    useQualificationAlert();

  useEffect(() => {
    fetchAlert();
  }, [alertId]);

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <S.HeaderContainer>
                <Row className="rowLeft">
                  <span>
                    {t('pages.qualificationAlert.headerTitle', {
                      data: headerInfo?.type,
                    })}
                  </span>
                  <span>|</span>
                  <b>{headerInfo?.qtFound}</b>
                  <span>{t('pages.qualificationAlert.found')}</span>
                </Row>
                <Row className="rowRight">
                  <span>{headerInfo?.avlc}</span>
                  <span>|</span>

                  <span>{headerInfo?.date}</span>
                  <span>{headerInfo?.time}</span>
                </Row>
              </S.HeaderContainer>
            </Row>

            <Row justify="center">
              <Col span={23} style={{ paddingBottom: 24 }}>
                <ConfigProvider>
                  <SimpleTable
                    rowKey="id"
                    striped
                    onChange={handleChangeTable}
                    dataSource={tableData}
                    columns={columns}
                    pagination={false}
                  />
                </ConfigProvider>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
