import { Button, Col, Form } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import * as S from './styles';

export function LateralFilter({
  title,
  children,
  onSearch,
  onClearFilter,
  values,
  searchFieldsOnChange,
}) {
  const { t } = useTranslation();
  const validateMessages = ValidateMessages();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const newTitle = title ? `${title} - ${t('common.filter')}` : t('common.filter');

  useEffect(() => {
    if (values) {
      form.setFieldsValue({ ...values });
    }
  }, [values]);

  const showDrawer = () => setVisible(true);

  const onClose = () => setVisible(false);

  const search = () => {
    setIsSearching(true);
    return form
      .validateFields()
      .then(async (filters) => {
        await onSearch(filters, 'submit');
        setVisible(false);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsSearching(false));
  };

  const handleSearch = async () => {
    await search();
  };

  const handleClear = async () => {
    const resetedValuesFields = {};

    Object.entries(values).forEach(([k, v]) => {
      resetedValuesFields[k] = Array.isArray(v) ? [] : null;
    });

    form.setFieldsValue({ ...resetedValuesFields });

    onClearFilter();
  };

  const onValuesChange = async (changedValues, allValues) => {
    const filtername = Object.keys(changedValues)[0];
    if (searchFieldsOnChange && searchFieldsOnChange.includes(filtername)) {
      setIsSearching(true);
      await onSearch(allValues, filtername);
      setIsSearching(false);
    }
  };

  return (
    <Col span={1} data-cy="filter-container">
      <S.FilterContainer>
        <S.HeaderFilter onClick={showDrawer} data-cy="filter-open">
          <S.FilterIcon />
        </S.HeaderFilter>
        <S.Drawer
          title={newTitle}
          open={visible}
          onClose={onClose}
          placement="left"
          size="default"
          className="side-filter"
          footer={
            <S.FooterContainer>
              <Button onClick={handleClear} data-cy="filter-clear">
                {t('common.clear')}
              </Button>
              <Button
                type="primary"
                onClick={handleSearch}
                data-cy="filter-search"
                disabled={isSearching}
              >
                {isSearching ? t('common.filtering') : t('common.filter')}
              </Button>
            </S.FooterContainer>
          }
        >
          <Form
            form={form}
            validateMessages={validateMessages}
            layout="vertical"
            onValuesChange={onValuesChange}
          >
            {children}
          </Form>
        </S.Drawer>
      </S.FilterContainer>
    </Col>
  );
}
