import styled from 'styled-components';

export const UploadArea = styled.div`
  border: 1px dashed ${({ theme }) => theme.colors.gray_border};
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  padding: 16px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.link};
  }

  .ant-upload-drag-icon {
    svg {
      font-size: 48px;
      color: ${({ theme }) => theme.colors.prim};
    }
  }
`;
