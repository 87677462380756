/* eslint-disable react/require-default-props */
import React from 'react';
import { ButtonProps } from 'antd';
import * as S from './styles';

export interface ActionButtonProps extends ButtonProps {
  buttonText?: string | null;
  icon?: React.ReactNode | null;
  iconEnd?: React.ReactNode | null;
}

export function ActionButton({
  buttonText,
  icon,
  iconEnd,
  onClick,
  children,
  ...resProps
}: ActionButtonProps) {
  return (
    <S.ActionButton data-testid="action-button" onClick={onClick} {...resProps}>
      {icon || null}
      {buttonText || null}
      {children || null}
      {iconEnd || null}
    </S.ActionButton>
  );
}
