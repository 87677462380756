import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import * as S from './styles';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { StyledTab } from '../../components/Common/StyledTabs/types';
import StyledTabs from '../../components/Common/StyledTabs';
import { InventoryPage } from './InventoryPage';
import { InOutPage } from './InOutPage';

export function InventoryInOutPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.inventory.title'));

  const [tabs, setTabs] = useState<StyledTab[]>([
    { selected: true, title: t('pages.inventory.titlesTab.inventoy') },
    { selected: false, title: t('pages.inventory.titlesTab.inOut') },
  ]);
  return (
    <S.Container>
      <StyledTabs tabs={tabs} setSelectedTab={setTabs} />
      {tabs[0].selected && <InventoryPage />}
      {tabs[1].selected && <InOutPage />}
    </S.Container>
  );
}
