import { Row } from 'antd';
import styled from 'styled-components';

export const ContainerComponents = styled.div`
  background-color: ${({ theme }) => theme.colorsDesignSystem.tag_background};
`;

export const FormContent = styled.div`
  padding: 2rem 5.3rem;
`;

export const FormFooter = styled(Row)`
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  padding: 1rem 1.25rem;
`;
