import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import StyledButton from '../../components/Common/StyledButton';
import { Loading } from '../../components/Loading';
import * as S from './styles';
import { usePricesTaxes } from './usePricesTaxes';
import StyledSelect from '../../components/Common/StyledSelect';
import { getURI, states } from '../../helpers/utils';
import SimpleTable from '../../components/Tables/SimpleTable';
import theme from '../../styles/theme';
import StyledSearchInput from '../../components/Common/StyledSearchInput';
import StyledDatePicker from '../../components/Common/StyledDatePicker';
import { CsvExport } from '../../components/Common/CsvExport';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { antTheme } from '../../styles/antTheme';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import { StyledPagination } from '../../components/Common/StyledPagination';

export function PricesTaxesPage() {
  const { t } = useTranslation();

  const {
    pageControl,
    params,
    isLoading,
    columns,
    taxesPrice,
    suppliers,
    partNumber,
    period,
    headerCsv,
    setParams,
    setPartNumber,
    setPeriod,
    handleFetchPricesTaxe,
    fetchSuppliers,
    changePageValue,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    handleUpdateFilters,
    transformDataToCsv,
  } = usePricesTaxes();

  useDocumentTitle(t('pages.pricesTaxes.title'));

  useEffect(() => {
    handleFetchPricesTaxe();
  }, [params.page, params.limit]);

  useEffect(() => {
    fetchSuppliers();
  }, []);

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <StyledHeader
            title={t('pages.pricesTaxes.title')}
            items={pageControl.totalElements}
            actions={
              <StyledButton small variant="slim" iconLeft={<UploadOutlined />}>
                <CsvExport
                  headers={headerCsv}
                  transformData={transformDataToCsv}
                  filename="Taxes"
                  limit={pageControl.totalElements}
                  url={getURI('taxRecoveryCalculation', params)}
                />
              </StyledButton>
            }
          />
          <S.FiltersContainer>
            <S.Filters>
              <StyledSearchInput
                label={t('pages.pricesTaxes.fields.partNumber')}
                placeholder={t('pages.pricesTaxes.fields.partNumber')}
                value={partNumber}
                onChange={(e) => {
                  setPartNumber(e.target.value.trim());
                }}
                onKeyDown={(e) => {
                  e.key === 'Enter' && partNumber && handleUpdateFilters('partNumber', partNumber);
                }}
                suffix={
                  <SearchOutlined
                    style={{ color: theme.colors.prim, fontSize: 14 }}
                    onClick={() => {
                      partNumber && handleUpdateFilters('partNumber', partNumber);
                    }}
                  />
                }
              />

              <StyledSelect
                label={t('pages.pricesTaxes.fields.supplier')}
                placeholder={t('pages.pricesTaxes.fields.supplierPlaceholder')}
                onChange={(value: string) => {
                  handleUpdateFilters('supplier', value);
                }}
                options={suppliers}
                styles={{ minWidth: '8.5rem' }}
                value={params.supplier || null}
                showSearch
                suffixIcon={
                  <SearchOutlined className="icon" style={{ color: antTheme?.token?.colorIcon }} />
                }
              />
              <StyledSelect
                label={t('pages.pricesTaxes.fields.from')}
                placeholder={t('pages.pricesTaxes.fields.from')}
                onChange={(value: string) => {
                  handleUpdateFilters('from', value);
                }}
                options={states('')}
                value={params.from || null}
              />
              <StyledSelect
                label={t('pages.pricesTaxes.fields.to')}
                placeholder={t('pages.pricesTaxes.fields.to')}
                onChange={(value: string) => {
                  handleUpdateFilters('to', value);
                }}
                options={states('')}
                value={params.to || null}
              />

              <StyledDatePicker
                label={t('pages.pricesTaxes.fields.period')}
                picker="month"
                placeholder={t('pages.pricesTaxes.fields.period')}
                onChange={(date, _) => {
                  handleUpdateFilters('period', date);
                }}
                defaultValue={params.period}
              />
              <StyledButton
                small
                onClick={() => {
                  setParams((prev) => {
                    return { ...prev, page: 0 };
                  });
                  handleFetchPricesTaxe();
                }}
                variant="primary"
                text={t('common.apply')}
              />
            </S.Filters>
            {/* <StyledButton
              iconLeft={<PlusOutlined />}
              variant="primary"
              disabled
              text="Add Tax"
              small
              onClick={() => {
                return '';
              }}
            /> */}
          </S.FiltersContainer>
          <S.FiltersTagContainer>
            {handleRenderFiltersTags()}
            {handleRenderClearAllFilters()}
          </S.FiltersTagContainer>
          <S.TableContainer>
            <SimpleTable
              striped
              pagination={false}
              columns={columns}
              dataSource={taxesPrice}
              rowKey=""
              scroll={{ x: 'max-content', y: 'calc(100vh - 28rem)' }}
            />
          </S.TableContainer>
        </>
      )}
      {pageControl.totalPages > 1 && (
        <StyledPagination
          pageNumber={params.page + 1}
          size={params.limit}
          totalElements={pageControl.totalElements}
          onChange={changePageValue}
        />
      )}
    </S.Container>
  );
}
