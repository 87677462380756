import StyledLabel from '../StyledLabel';
import * as S from './styles';
import { StyledInputSearchProps } from './types';

export default function StyledSearchInput({
  label,
  preffix,
  suffix,
  styles,
  width,
  ...restProps
}: StyledInputSearchProps) {
  return (
    <S.Conteiner style={{ width }}>
      <StyledLabel title={label || ''} />

      <S.containerContent>
        {preffix || null}
        <S.SearchInput style={styles} {...restProps} />
        {suffix || null}
      </S.containerContent>
    </S.Conteiner>
  );
}
