import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colorsDesignSystem.tag_background};
  padding: 2rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  height: auto;
`;
