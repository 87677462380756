import { AxiosResponse } from 'axios';
import { Location } from 'history';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { oneAlert } from '../../helpers/nUtils';
import { api } from '../../services/api';
import { services } from '../../services/services';
import { useAddQuantities } from './ContentSteps/AddQuantities/useAddQuantities';
import { useRegisterBid } from './ContentSteps/RegisterBid/useRegisterBid';
import { useRegisterComponents } from './ContentSteps/RegisterComponents/useRegisterComponents';
import { useSend } from './ContentSteps/Send/useSend';
import { IError, IStep1Form } from './types';

export function useNewRFQ() {
  const { t } = useTranslation();
  const [bidId, setBidId] = useState<string>();
  const [bidStatus, setBidStatus] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [nextLocation, setNextLocation] = useState<string>();

  const prevLocation = useLocation().pathname;
  const history = useHistory();
  const { search } = useLocation();

  const { getInformations, form: formStep1, save } = useRegisterBid(bidId);
  const { form: formStep2, saveComponent: saveStep2 } = useRegisterComponents(bidId || '');
  const { SaveDraftStep3, VerifyStatus } = useAddQuantities();
  const { saveDraftStep5, verifyStatusStep5, isBidFinished } = useSend();

  const allSteps = [formStep1, formStep2];

  const saveStep1 = async (values: IStep1Form): Promise<AxiosResponse<IStep1Form> | undefined> => {
    setIsLoadingSubmit(true);
    return save(values)
      .then((response) => {
        if (response?.data.id) {
          setBidId(response.data.id);
        }
        setBidStatus(response?.data.status);
        setCurrentStep(currentStep + 1);
        return response;
      })
      .finally(() => {
        setIsLoadingSubmit(false);
      });
  };

  const fetchStep1 = async () => {
    setIsLoading(true);
    getInformations()
      .then((response) => {
        if (!response.step1) return setCurrentStep(0);
        if (!response.step2) return setCurrentStep(1);
        if (!response.step3) return setCurrentStep(2);
        if (!response.step4) return setCurrentStep(3);
        if (!response.step5) return setCurrentStep(4);
        if (!response.step6) return setCurrentStep(5);

        setCurrentStep(2);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteBid = async () => {
    try {
      setIsLoading(true);
      await api.delete(`${services.rfq}/bid/${bidId}`, {
        data: {
          id: bidId,
        },
      });
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnDelete'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const allSubmits = [saveStep1, saveStep2];

  const fieldsHasValue = () => {
    const currentForm = allSteps[currentStep];
    return Object.keys(currentForm.getFieldsValue(true)).length > 0;
  };

  const onChangeStep = (step: number) => {
    if (step < currentStep) {
      const currentForm = allSteps[currentStep];
      const currentSubmit = allSubmits[currentStep];

      if (currentStep < 2) {
        currentForm.validateFields().then(() => {
          currentSubmit(currentForm.getFieldsValue(true)).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              setCurrentStep(step);
            }
          });
        });
      } else {
        setCurrentStep(step);
      }
    }
  };

  const saveCurrentStepAsDraf = async () => {
    if (currentStep <= 2) {
      if (currentStep === 2) {
        setIsLoading(true);
        const response = SaveDraftStep3();
        if (await response) {
          setIsLoading(false);
          setOpenDialog(false);
          history.push(nextLocation || '/rfq');
          return;
        }
        setIsLoading(false);
        setOpenDialog(false);
        history.goBack();
        return;
      }

      if (currentStep === 5) {
        setIsLoading(true);
        const response = saveDraftStep5();
        if (await response) {
          setIsLoading(false);
          setOpenDialog(false);
          history.push(nextLocation || '/rfq');
          return;
        }
        setIsLoading(false);
        setOpenDialog(false);
        history.goBack();
        return;
      }

      const currentForm = allSteps[currentStep];
      const currentSubmit = allSubmits[currentStep];

      currentSubmit({ ...currentForm.getFieldsValue(true), sketch: true }).then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          history.push(nextLocation || '/rfq');
        }
      });
    } else history.push(nextLocation || '/rfq');
  };

  const verifyValuesOnNavigate = (location: Location) => {
    if (location.pathname !== prevLocation && isBidFinished) {
      if (VerifyStatus() && currentStep === 2) {
        setNextLocation(location.pathname);
        setOpenDialog(true);
        history.push(prevLocation);
        return;
      }
      if (verifyStatusStep5() && currentStep === 5) {
        setNextLocation(location.pathname);
        setOpenDialog(true);
        history.push(prevLocation);
        return;
      }

      if (!openDialog && fieldsHasValue() && currentStep < 2) {
        setNextLocation(location.pathname);
        setOpenDialog(true);
        history.push(prevLocation);
      }
    }
  };

  useEffect(() => {
    if (currentStep === 0 && bidId) {
      fetchStep1();
    }
  }, [bidId]);

  useEffect(() => {
    if (search) {
      const id = search.split('=')[1];
      setBidId(id);
    }
  }, []);

  return {
    formStep1,
    formStep2,
    saveStep1,
    saveStep2,
    currentStep,
    setCurrentStep,
    openDialog,
    setOpenDialog,
    isLoading,
    isLoadingSubmit,
    allSteps,
    allSubmits,
    nextLocation,
    setNextLocation,
    bidStatus,
    deleteBid,
    verifyValuesOnNavigate,
    saveCurrentStepAsDraf,
    onChangeStep,
    fieldsHasValue,
    history,
    bidId,
  };
}
