import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { Form, Popconfirm, Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ITaxesForm, Taxes } from './types';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import { api } from '../../services/api';
import { getErrorMessage, getURI, invertFormatDate, oneAlert } from '../../helpers/utils';
import { TableActionButton } from '../../components/Button/styles';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import { FooterModalUpload, SpecTag } from './styles';
import StyledButton from '../../components/Common/StyledButton';
import theme from '../../styles/theme';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';

export function useSettingsTaxe() {
  const { t } = useTranslation();

  const initialParams = {
    componentType: '',
    from: '',
    to: '',
    page: 0,
    limit: 10,
    orderby: 'ASC',
  };
  const [form] = Form.useForm<ITaxesForm>();
  const [showModal, setShowModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [componentsTypes, setComponentTypes] = useState<StyledSelectOptions[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [taxesData, setTaxesData] = useState<Array<Taxes>>([]);
  const [taxesToUpload, setTaxesToUpload] = useState<Array<Omit<Taxes, 'taxes'>>>([]);
  const [editTax, setEditTax] = useState<Taxes | null>(null);
  const [params, setParams] = useState(initialParams);
  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });

  const handleUpdateParams = (field: string, value: any) => {
    setParams((currentParams: any) => {
      return { ...currentParams, [field]: value, page: 0 };
    });
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {params.componentType && (
          <StyledFilterTag
            label={t('pages.taxes.fields.componentType')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.componentType}
            closeble
            onClose={() => {
              handleUpdateParams('componentType', '');
            }}
          />
        )}
        {params.from && (
          <StyledFilterTag
            label={t('pages.taxes.placeholders.from')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.from}
            closeble
            onClose={() => {
              handleUpdateParams('from', '');
            }}
          />
        )}
        {params.to && (
          <StyledFilterTag
            label={t('pages.taxes.placeholders.to')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.to}
            closeble
            onClose={() => {
              handleUpdateParams('to', '');
            }}
          />
        )}
      </>
    );
  };

  const clearAllFilters = () => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        componentType: '',
        from: '',
        to: '',
      };
    });
  };

  const handleRenderClearAllFilters = () => {
    if (params.componentType || params.from || params.to) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  const handleShowModalToEditTax = (tax: Taxes) => {
    setEditTax(tax);
    setShowModal(true);
  };

  const handleRoundedTaxes = (taxes: any) => {
    const roundedTaxes = taxes.map((taxe: Taxes) => {
      return {
        ...taxe,
        recoveryTaxDto: {
          recoveryTaxesIcms: taxe.recoveryTaxDto.recoveryTaxesIcms.toFixed(2),
          recoveryTaxesIpi: taxe.recoveryTaxDto.recoveryTaxesIpi.toFixed(2),
          recoveryTaxesPisCofins: taxe.recoveryTaxDto.recoveryTaxesPisCofins.toFixed(2),
          taxesRD: taxe.recoveryTaxDto.taxesRD.toFixed(2),
        },
        taxDto: {
          taxesIcms: taxe.taxDto.taxesIcms.toFixed(2),
          taxesIpi: taxe.taxDto.taxesIpi.toFixed(2),
          taxesPisCofins: taxe.taxDto.taxesPisCofins.toFixed(2),
        },
      };
    });
    setTaxesData(roundedTaxes);
  };

  const handleFetchTaxesData = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(getURI('taxRateSettings', params));
      if (data) {
        handleRoundedTaxes(data.content);
        setPageControl({
          pageLabel: String(params.page + 1),
          totalElements: data.totalElements,
          totalPages: data.totalPages,
        });
      } else {
        setTaxesData([]);
      }
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTaxe = async (taxe: Taxes) => {
    try {
      setIsLoading(true);
      await api.delete(`taxRateSettings/${taxe.id}`);
      oneAlert('success', t('toast.successOnDelete'));
      handleFetchTaxesData();
    } catch (error: any) {
      oneAlert('error', error.errorResponse.message || t('toast.errorOnSave'));
    } finally {
      setIsLoading(false);
    }
  };

  const renderActions = (component: Taxes) => {
    const actions = [
      {
        key: '1',
        label: (
          <TableActionButton
            onClick={(e) => {
              e.preventDefault();
              handleShowModalToEditTax(component);
            }}
            data-cy="edit"
          >
            {t('common.edit')}
          </TableActionButton>
        ),
      },
      {
        key: '2',
        label: (
          <Popconfirm
            placement="topLeft"
            title={t('common.deleteMessage')}
            onConfirm={(e) => {
              e?.stopPropagation();
              handleDeleteTaxe(component);
            }}
            onCancel={(e) => {
              e?.stopPropagation();
            }}
            onClick={(e) => {
              e?.stopPropagation();
            }}
          >
            <TableActionButton danger data-cy="delete">
              {t('common.delete')}
            </TableActionButton>
          </Popconfirm>
        ),
      },
    ];

    return <TableAction placement="bottomLeft" dropdownItems={actions} />;
  };

  const columns: ColumnsType<Taxes> = [
    {
      title: t('pages.taxes.fields.componentType'),
      dataIndex: 'componentType',
      key: 'componentType',
      align: 'left',
      render: (_, record) => {
        if (record.componentType === 'PACKING') {
          const totalTaxes = record.taxes && record.taxes.length - 1;
          return (
            <Row style={{ gap: '1rem' }}>
              {record.componentType}
              {record.taxes && (
                <Row>
                  <SpecTag color="blue">{record.taxes[0]}</SpecTag>
                  {totalTaxes > 0 && <SpecTag color="blue">+{totalTaxes}</SpecTag>}
                </Row>
              )}
            </Row>
          );
        }
        return <span>{record.componentType}</span>;
      },
    },
    {
      title: t('pages.taxes.fields.from'),
      dataIndex: 'from',
      key: 'from',
      align: 'center',
    },
    {
      title: t('pages.taxes.fields.to'),
      dataIndex: 'to',
      key: 'to',
      align: 'center',
    },
    {
      title: 'IPI %',
      align: 'center',
      key: 'taxDto.taxesIpi',
      dataIndex: ['taxDto', 'taxesIpi'],
    },
    {
      title: 'ICMS %',
      key: 'taxDto.taxesIcms',
      align: 'center',
      dataIndex: ['taxDto', 'taxesIcms'],
    },
    {
      title: 'PIS/COFINS %',
      key: 'taxDto.taxesPisCofins',
      align: 'center',
      dataIndex: ['taxDto', 'taxesPisCofins'],
    },
    {
      title: t('pages.taxes.fields.period'),
      key: 'periodFrom',
      align: 'center',
      dataIndex: 'periodFrom',
    },
    {
      title: t('common.action'),
      key: 'action',
      width: 120,
      align: 'center',
      render: (component: Taxes) => renderActions(component),
    },
  ];

  const headerCsv = [
    { label: t('pages.taxes.fields.componentType'), key: 'componentType' },
    { label: t('pages.taxes.fields.from'), key: 'from' },
    { label: t('pages.taxes.fields.to'), key: 'to' },
    { label: t('pages.taxes.fields.periodFrom'), key: 'periodFrom' },
    { label: t('pages.taxes.fields.periodTo'), key: 'periodTo' },
    { label: t('pages.taxes.fields.icms'), key: 'taxesIcms' },
    { label: t('pages.taxes.fields.ipi'), key: 'taxesIpi' },
    { label: t('pages.taxes.fields.pisCofins'), key: 'taxesPisCofins' },
    { label: t('pages.taxes.fields.recoveryIpi'), key: 'recoveryTaxesIpi' },
    { label: t('pages.taxes.fields.recoveryICMS'), key: 'recoveryTaxesIcms' },
    { label: t('pages.taxes.fields.recoveryPisCofins'), key: 'recoveryTaxesPisCofins' },
    { label: t('pages.taxes.fields.recoveryRd'), key: 'taxesRD' },
  ];

  const transformDataToCsv = (data: Array<Taxes>) => {
    const tableRows: any = [];
    data.forEach((taxe: Taxes) => {
      tableRows.push({
        componentType: taxe.componentType,
        from: taxe.from,
        to: taxe.to,
        periodFrom: taxe.periodFrom,
        periodTo: taxe.periodTo,
        taxesIcms: taxe.taxDto.taxesIcms,
        taxesIpi: taxe.taxDto.taxesIpi,
        taxesPisCofins: taxe.taxDto.taxesPisCofins,
        recoveryTaxesIpi: taxe.recoveryTaxDto.recoveryTaxesIpi,
        recoveryTaxesIcms: taxe.recoveryTaxDto.recoveryTaxesIcms,
        recoveryTaxesPisCofins: taxe.recoveryTaxDto.recoveryTaxesPisCofins,
        taxesRD: taxe.recoveryTaxDto.taxesRD,
      });
    });
    return tableRows;
  };

  const saveNewTaxe = async (taxe: ITaxesForm) => {
    try {
      setIsLoading(true);
      const formatedBody = {
        ...taxe,
        taxes: [taxe.taxes],
        periodFrom: dayjs(taxe.period[0]),
        periodTo: dayjs(taxe.period[1]),
      };
      taxe.id
        ? await api.put(`taxRateSettings/${taxe.id}`, formatedBody)
        : await api.post('taxRateSettings', formatedBody);
      oneAlert('success', t('toast.successOnSave'));
      handleFetchTaxesData();
      setShowModal(false);
      form.resetFields();
    } catch (error: any) {
      oneAlert('error', error.errorResponse.message || t('toast.errorOnSave'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearEditTaxe = () => {
    setEditTax(null);
  };

  const handleOpenModalNewTaxe = () => {
    handleClearEditTaxe();
    setShowModal(true);
  };

  const changePage = (page: number, pageSize: number) => {
    setParams({ ...params, page: page - 1, limit: pageSize });
    setPageControl({ ...pageControl, pageLabel: String(page) });
  };

  const handleSaveTaxesCsv = async () => {
    try {
      setIsLoading(true);
      await api.post('taxRateSettings/upload/file', taxesToUpload);
      oneAlert('success', t('toast.successOnUpload'));
      handleFetchTaxesData();
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnUpload'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleRenderFooterModalUpload = () => {
    return (
      <FooterModalUpload>
        <StyledButton
          variant="primary"
          text={t('components.modals.uploadSheets.upload')}
          disabled={!taxesToUpload.length}
          onClick={() => {
            setShowUploadFileModal(false);
            handleSaveTaxesCsv();
          }}
        />
      </FooterModalUpload>
    );
  };

  const handleClearTaxesToUpload = () => {
    setTaxesToUpload([]);
  };

  const handleCloseModalUploadFiles = () => {
    setShowUploadFileModal(false);
    handleClearTaxesToUpload();
  };

  const handleFormatJsonToUpload = (contentXls: any) => {
    const taxesJson: Array<Omit<Taxes, 'taxes'>> = [];
    contentXls.forEach((element: any, index: number) => {
      //pegando a partir do segundo índice pois o primeiro são os headers da planilha
      index > 0 &&
        taxesJson.push({
          componentType: element[0],
          from: element[1],
          to: element[2],
          periodFrom: invertFormatDate(element[3]),
          periodTo: invertFormatDate(element[4]),
          taxDto: {
            taxesIcms: element[5],
            taxesIpi: element[6],
            taxesPisCofins: element[7],
          },
          recoveryTaxDto: {
            recoveryTaxesIcms: element[8],
            recoveryTaxesIpi: element[9],
            recoveryTaxesPisCofins: element[10],
            taxesRD: element[11],
          },
        });
    });
    setTaxesToUpload(taxesJson);
  };

  function handleGenerateIdsToDelete(): string {
    try {
      return taxesData.reduce(
        (allIds, taxe) => (allIds ? `${allIds},${taxe.id}` : String(taxe.id)),
        ''
      );
    } catch (error) {
      return '';
    }
  }

  const handleDeleteAllTaxes = async () => {
    try {
      setIsLoading(true);
      await api.delete(`taxRateSettings/deleteall/${handleGenerateIdsToDelete()}`);
      await handleFetchTaxesData();
      oneAlert('success', t('toast.successOnDelete'));
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnDelete'));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    columns,
    taxesData,
    showModal,
    showUploadFileModal,
    isLoading,
    editTax,
    headerCsv,
    pageControl,
    params,
    handleUpdateParams,
    changePage,
    setShowModal,
    saveNewTaxe,
    handleFetchTaxesData,
    handleOpenModalNewTaxe,
    transformDataToCsv,
    handleRenderFooterModalUpload,
    setShowUploadFileModal,
    handleFormatJsonToUpload,
    handleCloseModalUploadFiles,
    handleClearTaxesToUpload,
    handleDeleteAllTaxes,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    form,
  };
}
