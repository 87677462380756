import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Tooltip } from 'antd';
import { Loading } from '../../components/Loading';
import * as S from './styles';
import { useAverage } from './useAvarage';
import StyledSelect from '../../components/Common/StyledSelect';
import StyledDatePicker from '../../components/Common/StyledDatePicker';
import StyledButton from '../../components/Common/StyledButton';
import ExpandableItem from '../../components/Common/ExpandableItem';
import SimpleTable from '../../components/Tables/SimpleTable';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import StyledIndicationTag from '../../components/IndicationTag';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';

export function Average() {
  const {
    isLoading,
    params,
    CMs,
    expandableViewStatus,
    columns,
    averageData,
    sources,
    categoriesByCm,
    fieldsErros,
    partNumbers,
    setFieldsErros,
    handleUpdateExpandableViews,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
    handleFetchPartNumberByCategory,
    handleUpdateFilters,
    handleFetchAverage,
    handleFetchCategoryByCm,
  } = useAverage();
  const { t } = useTranslation();
  useDocumentTitle(t('pages.average.title'));

  useEffect(() => {
    handleUpdateFilters('partNumber', null);
    handleFetchPartNumberByCategory();
  }, [params.category, params.source]);

  useEffect(() => {
    handleUpdateFilters('category', '');
    handleUpdateFilters('source', '');
    handleUpdateFilters('partNumber', null);
    handleFetchCategoryByCm();
  }, [params.cm]);

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <StyledHeader title={t('pages.average.title')} />

          <S.ContainerFilters>
            <StyledSelect
              label={t('pages.average.filtersLabels.cm')}
              placeholder={t('pages.average.filtersLabels.cm')}
              onChange={(value: any) => {
                handleUpdateFilters('cm', value);
              }}
              options={CMs}
              value={params.cm}
              styles={{ minWidth: '7.25rem' }}
            />

            {/* <StyledSelect
                type="PROJECTS"
                label={t('pages.average.filtersLabels.project')}
                placeholder={t('pages.average.filtersLabels.project')}
                onChange={(value: any) => {
                  handleUpdateFilters('project', value);
                }}
                styles={{ minWidth: '7.25rem' }}
                value={params.project}
              /> */}

            {/* <StyledSearchInput
                label={t('pages.average.filtersLabels.sku')}
                value={selectedSku}
                placeholder={t('pages.average.filtersLabels.sku')}
                onChange={(e: any) => {
                  setSelectedSku(e.target.value.trim());
                }}
                onKeyDown={(e: any) => {
                  e.key === 'Enter' && selectedSku && handleUpdateFilters('sku', selectedSku);
                }}
                styles={{ minWidth: '7.25rem' }}
                suffix={
                  <SearchOutlined
                    style={{ color: theme.colors.prim, fontSize: 14 }}
                    onClick={() => {
                      selectedSku && handleUpdateFilters('sku', selectedSku);
                    }}
                  />
                }
              /> */}

            <StyledSelect
              options={categoriesByCm}
              label={t('pages.average.filtersLabels.category')}
              placeholder={t('pages.average.filtersLabels.category')}
              onChange={(value: any) => {
                handleUpdateFilters('category', value);
              }}
              styles={{ minWidth: '7.25rem' }}
              value={params.category}
              showSearch
            />

            <StyledSelect
              label={t('pages.average.filtersLabels.source')}
              placeholder={t('pages.average.filtersLabels.source')}
              onChange={(value: any) => {
                handleUpdateFilters('source', value);
              }}
              options={sources}
              value={params.source}
              styles={{ minWidth: '7.25rem' }}
            />
            <S.DivErros>
              <StyledSelect
                label={t('pages.average.filtersLabels.partNumber')}
                placeholder={t('pages.average.filtersLabels.partNumber')}
                showSearch
                onChange={(value: any) => {
                  handleUpdateFilters('partNumber', value);
                  setFieldsErros((prevState) => {
                    return {
                      ...prevState,
                      partNumber: false,
                    };
                  });
                }}
                options={partNumbers}
                value={params.partNumber}
                styles={{ minWidth: '8.75rem' }}
              />
              {fieldsErros.partNumber && (
                <S.FieldError>{t('pages.average.requiredFieldMessages.partNumber')}</S.FieldError>
              )}
            </S.DivErros>

            {/* <StyledSelect
              label={t('pages.average.filtersLabels.level')}
              placeholder={t('pages.average.filtersLabels.level')}
              onChange={(value: any) => {
                handleUpdateFilters('level', value);
              }}
              options={levels}
              value={params.level}
              styles={{ minWidth: '7.25rem' }}
            /> */}

            <S.ContainerButton>
              <StyledButton
                variant="primary"
                text={t('pages.quotes.titleButons.apply')}
                disabled={params.partNumber === '' || params.partNumber === null}
                onClick={() => {
                  handleFetchAverage();
                }}
              />
            </S.ContainerButton>
          </S.ContainerFilters>

          <S.FiltersTagContainer>
            {handleRenderFiltersTags()}
            {handleRenderClearAllFilters()}
          </S.FiltersTagContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <S.TablesContainer>
              <S.HeaderExpandableItem>
                <ExpandableItem
                  isOpen={expandableViewStatus.average}
                  onClick={() => {
                    handleUpdateExpandableViews('average', !expandableViewStatus.average);
                  }}
                />
                <span>Average |</span>
                <Tooltip placement="topLeft" title={averageData[0]?.description || 'N/A'}>
                  <p>{averageData[0]?.material} |</p>
                </Tooltip>
                <p>{averageData[0]?.materialDescription} | </p>
                {averageData[0]?.origin && <StyledIndicationTag text={averageData[0]?.origin} />}
              </S.HeaderExpandableItem>
              {expandableViewStatus.average && (
                <div>
                  <SimpleTable
                    striped
                    pagination={false}
                    columns={columns}
                    dataSource={averageData}
                    rowKey=""
                    scroll={{ x: 'max-content', y: 'auto' }}
                  />
                </div>
              )}
            </S.TablesContainer>

            {/* <S.TablesContainer>
              <S.HeaderExpandableItem>
                <ExpandableItem
                  isOpen={expandableViewStatus.suppliersInvoice}
                  onClick={() => {
                    handleUpdateExpandableViews('priceTable', !expandableViewStatus.priceTable);
                  }}
                />
                <span>Price Table | For Month (USD) | </span>
                <p>KN.512RC.001</p>
                <Tooltip placement="right" title="Flash Disk A-DATA SSD NAND 512GB IM2P33F">
                  <InfoCircleFilled style={{ color: theme.colorsDesignSystem.primary }} />
                </Tooltip>
              </S.HeaderExpandableItem>
              {expandableViewStatus.priceTable && (
                <div style={{ width: '100%', height: '300px' }}>
                  <PriceTableChart />
                </div>
              )}
            </S.TablesContainer> */}

            {/* <S.TablesContainer>
              <S.HeaderExpandableItem>
                <ExpandableItem
                  isOpen={expandableViewStatus.suppliersInvoice}
                  onClick={() => {
                    handleUpdateExpandableViews(
                      'suppliersInvoice',
                      !expandableViewStatus.suppliersInvoice
                    );
                  }}
                />
                <span>Supplier Invoice |</span>
                <p>KN.512RC.001</p>
                <Tooltip placement="right" title="Flash Disk A-DATA SSD NAND 512GB IM2P33F">
                  <InfoCircleFilled style={{ color: theme.colorsDesignSystem.primary }} />
                </Tooltip>
              </S.HeaderExpandableItem>
              {expandableViewStatus.suppliersInvoice && (
                <div>
                  <SimpleTable
                    striped
                    pagination={false}
                    columns={columnsInvoice}
                    dataSource={supplierInvoiceData}
                    rowKey=""
                  />
                </div>
              )}
            </S.TablesContainer> */}
          </div>
        </>
      )}
      {/* {pageControl.totalPages > 1 && (
        <CustomPagination
          limit={params.limit}
          page={params.page + 1}
          pageLabel={pageControl.pageLabel}
          total={pageControl.totalElements}
          totalPages={pageControl.totalPages}
          changePageValue={changePageValue}
        />
      )} */}
    </S.Container>
  );
}
