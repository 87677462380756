import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: row;
  display: flex;
  gap: 3.375rem;
`;

export const StyledTabActive = styled.div`
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding-bottom: 0.5rem;
  align-items: center;
  color: ${({ theme }) => theme.colorsDesignSystem.tabBarActiveColor};
  font-size: ${({ theme }) => theme.dimensions.font_title};
  border-bottom: 0.125rem solid;
  border-color: ${({ theme }) => theme.colorsDesignSystem.tabBarActiveColor};
  cursor: pointer;
`;

export const StyledTabInactive = styled.div`
  font-weight: 600;
  display: flex;
  padding-bottom: 0.6rem;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colorsDesignSystem.tabBarInactiveColor};
  font-size: ${({ theme }) => theme.dimensions.font_title};
`;
