import { Space, Drawer as AntdDrawer } from 'antd';
import { BsFilter } from 'react-icons/bs';
import styled from 'styled-components';

export const FilterContainer = styled.div`
  background: ${({ theme }) => theme.colors.shape};
  cursor: pointer;
  height: calc(100vh - 50px);
  padding: 16px 0;
  width: 50px;
  min-height: 100%;
`;

export const HeaderFilter = styled.header`
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-flow: row nowrap;
`;

export const FilterIcon = styled(BsFilter)`
  color: ${({ theme }) => theme.colors.link};
  height: 24px;
  margin: 0 12px 8px;
  width: 24px;
`;

export const FooterContainer = styled(Space)`
  justify-content: space-between;
  width: 100%;
`;

export const Drawer = styled(AntdDrawer)`
  .ant-drawer-body {
    background-color: ${({ theme }) => theme.colors.background};
  }

  .ant-drawer-header {
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text_light};
    border-color: ${({ theme }) => theme.colors.sec_light};
  }

  .ant-drawer-title {
    color: ${(props) => props.theme.colors.link};
  }

  .ant-drawer-footer {
    border-color: ${({ theme }) => theme.colors.sec_light};
  }

  form .ant-form .ant-form-vertical {
    .ant-select .ant-select-single .ant-select-show-arrow {
      margin-bottom: 4px;
    }
  }
`;
