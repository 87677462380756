import { Col, Form, Row } from 'antd';
import { BiDownload } from 'react-icons/bi';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableCell } from '../../../components/EditableCell';
import { ValidateMessages } from '../../../helpers/ValidateMessages';
import { useReportsScenarios } from './useReportsScenarios';
import { ResultNoScenarios } from '../../../components/ResultNoScenarios';
import { CsvExport } from '../../../components/Common/CsvExport';
import * as SC from '../../../styles/common';
import { Loading } from '../../../components/Loading';
import { getURI, yearsScenarios } from '../../../helpers/utils';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import SimpleTable from '../../../components/Tables/SimpleTable';
import { StyledPagination } from '../../../components/Common/StyledPagination';
import { StyledHeader } from '../../../components/Common/StyledHeaderPage';
import StyledButton from '../../../components/Common/StyledButton';
import { UpdateDate } from '../../../components/Common/UpdateDate';
import StyledSelect from '../../../components/Common/StyledSelect';

export function ReportsScenariosPage({ view = null, onClose = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.reports.scenarios.title'));

  const validateMessages = ValidateMessages();

  const {
    data,
    params,
    pageControl,
    fetchScenarios,
    scenariosOptions,
    selectedScenario,
    fetchReportsScenarios,
    handleChangeTable,
    isLoading,
    form,
    onValuesChange,
    searchFilter,
    clearFilter,
    canUpdate,
    handleCloseFilteredTag,
    csvHeaders,
    mergedColumns,
    getReportScenariosParams,
    transformData,
    changePage,
  } = useReportsScenarios(dataModal);

  const scenarioName = selectedScenario
    ? `${params?.targetYear} - ${selectedScenario?.label}`
    : t('common.results.noScenarioTitle');

  const modifiedDate = selectedScenario
    ? (dataModal && dataModal.modified) || selectedScenario?.updated?.split('T')[0]
    : '';
  const showTagsFilter = params.sku;

  useEffect(() => {
    if (params.targetYear) {
      fetchScenarios();
    }
  }, [params.targetYear]);

  useEffect(() => {
    fetchReportsScenarios();
  }, []);

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={24}>
                <StyledHeader
                  title={t('pages.reports.scenarios.title')}
                  subtitle={scenarioName}
                  actions={
                    <StyledButton variant="primary" icon={<BiDownload />}>
                      <CsvExport
                        url={getURI(`scenarios/${params.scenario}/components/report`, {
                          ...getReportScenariosParams(),
                          order: params.order,
                          orderBy: params.orderBy,
                        })}
                        filename={['reports-scenarios', selectedScenario?.label]}
                        limit={pageControl.totalElements}
                        headers={csvHeaders}
                        transformData={transformData}
                      />
                    </StyledButton>
                  }
                />
              </Col>
            </Row>
            <Col span={24}>
              <Row style={{ gap: '0.75rem' }} align="bottom">
                <StyledSelect
                  showSearchstyle={{ width: '6rem' }}
                  options={yearsScenarios}
                  label={t('filter.year')}
                  placeholder={t('filter.year')}
                  onChange={(e) => searchFilter(e, 'targetYear')}
                  value={params.targetYear}
                />
                <StyledSelect
                  showSearch
                  style={{ width: '12rem' }}
                  options={scenariosOptions}
                  label={t('filter.scenario')}
                  placeholder={t('filter.scenario')}
                  onChange={(e) => searchFilter(e, 'scenario')}
                  value={params.scenario}
                />
                <StyledButton onClick={fetchReportsScenarios} variant="primary">
                  {t('common.apply')}
                </StyledButton>
              </Row>
            </Col>

            <Row justify="center">
              <Col span={24}>
                <SC.ContainerFilteredTags>
                  {showTagsFilter && <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>}
                  {params.header_sku && (
                    <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_sku')}>
                      <div className="tag-title">{t('pages.reports.scenarios.fields.sku')}</div>
                      <div className="tag-value">{params.header_sku}</div>
                    </SC.TagFilter>
                  )}
                  {params.header_partType && (
                    <SC.TagFilter
                      closable
                      onClose={() => handleCloseFilteredTag('header_partType')}
                    >
                      <div className="tag-title">
                        {t('pages.reports.scenarios.fields.partType')}
                      </div>
                      <div className="tag-value">{params.header_partType}</div>
                    </SC.TagFilter>
                  )}
                  {params.header_spec && (
                    <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_spec')}>
                      <div className="tag-title">{t('pages.reports.scenarios.fields.spec')}</div>
                      <div className="tag-value">{params.header_spec}</div>
                    </SC.TagFilter>
                  )}
                  {params.header_partNumber && (
                    <SC.TagFilter
                      closable
                      onClose={() => handleCloseFilteredTag('header_partNumber')}
                    >
                      <div className="tag-title">
                        {t('pages.reports.scenarios.fields.partNumber')}
                      </div>
                      <div className="tag-value">{params.header_partNumber}</div>
                    </SC.TagFilter>
                  )}
                  {params.header_componentAmount && (
                    <SC.TagFilter
                      closable
                      onClose={() => handleCloseFilteredTag('header_componentAmount')}
                    >
                      <div className="tag-title">
                        {t('pages.reports.scenarios.fields.componentAmount')}
                      </div>
                      <div className="tag-value">{params.header_componentAmount}</div>
                    </SC.TagFilter>
                  )}
                  {params.header_local && (
                    <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_local')}>
                      <div className="tag-title">{t('pages.reports.scenarios.fields.local')}</div>
                      <div className="tag-value">{params.header_local}</div>
                    </SC.TagFilter>
                  )}
                </SC.ContainerFilteredTags>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <UpdateDate date={modifiedDate} />
                <Form
                  form={form}
                  component={false}
                  onValuesChange={onValuesChange}
                  validateMessages={validateMessages}
                >
                  <SimpleTable
                    rowKey="id"
                    data-cy="table"
                    striped
                    scroll={{ x: 'max-content', y: 'calc(100vh - 24rem)' }}
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    columns={mergedColumns}
                    dataSource={data}
                    pagination={false}
                    onChange={handleChangeTable}
                    locale={{
                      emptyText: (
                        <ResultNoScenarios
                          title={t('common.results.noDataTitle')}
                          subTitle={t('common.results.noDataSubtitle')}
                        />
                      ),
                    }}
                  />
                </Form>

                {pageControl.totalPages > 1 && (
                  <StyledPagination
                    pageNumber={params.page + 1}
                    size={params.limit}
                    totalElements={pageControl.totalElements}
                    onChange={changePage}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
