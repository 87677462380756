import styled from 'styled-components';
import { CardBidItemProps } from './types';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  }
`;

export const ItemContainer = styled.div<CardBidItemProps>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  padding: 1rem 1.5rem;
  gap: 0.25rem;
  min-width: 20.668rem;
  height: 3.13rem;
  border-radius: 0.75rem;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colorsDesignSystem.cardBidSelected : theme.colorsDesignSystem.white};
  border: ${({ theme, selected }) =>
    selected
      ? `0.125rem solid ${theme.colorsDesignSystem.inputLabelColor}`
      : `0.063rem solid ${theme.colorsDesignSystem.primary}`};

  span {
    font-weight: 600;
    color: ${({ theme, selected }) =>
      selected ? theme.colorsDesignSystem.input_labels : theme.colorsDesignSystem.primary};
  }

  .blue {
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
`;

export const ItemContainerAnswered = styled.div<CardBidItemProps>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  padding: 1rem 1.5rem;
  gap: 0.5rem;
  min-width: 20.668rem;
  height: 4.563rem;
  border-radius: 0.75rem;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colorsDesignSystem.cardBidSelected : theme.colorsDesignSystem.white};
  border: ${({ theme, selected }) =>
    selected
      ? `0.125rem solid ${theme.colorsDesignSystem.inputLabelColor}`
      : `0.063rem solid ${theme.colorsDesignSystem.grayBorderTooltip}`};

  span {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
  }
  .blue {
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
    font-weight: 600;
  }
  .position {
    border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
    border-radius: 3.125rem;
    padding-inline: 0.375rem;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  .gross {
    color: ${({ theme }) => theme.colorsDesignSystem.grayBorderTooltip};
  }
`;

export const HeaderContainerCardDubleLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 0.2rem;
`;
