import { AuditOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const TermsConditionsContainer = styled.div`
  width: 100%;
  min-height: 34rem;
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  background-color: ${({ theme }) => theme.colorsDesignSystem.secondaryGrayBackground};
  border-radius: ${({ theme }) => theme.dimensions.border_radius_large};
`;

export const TermsConditionsHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 3.5rem;
  border-bottom: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  gap: 0.75rem;
  font-size: 1.25rem;
  font-weight: 600;
  padding-left: 1.5rem;
  padding: 0 1.5rem;
`;

export const TermsConditionsCardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

export const AuditIcon = styled(AuditOutlined)`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.iconGrey};
`;

export const TermsConditionsContent = styled.main`
  width: 100%;
  height: 25rem;
  padding: 1.5rem 4.375rem;
  overflow-y: auto;
`;

export const TermsConditionsContentTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const TermsConditionsFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 5rem;
  border-top: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  padding-right: 1.5rem;
  gap: 1.5rem;
`;

export const TermsConditionsDenied = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DeniedMessage = styled.p`
  max-width: 39.188rem;
  font-size: 1.5rem;
  text-align: center;
  line-height: 2rem;
  margin: 0;
`;

export const DeniedSubMessage = styled.p`
  max-width: 627px;
  font-size: 1rem;
  text-align: center;
  line-height: 1.375rem;
  margin: 0 0 1.688rem 0;
`;
