/* eslint-disable react/no-unstable-nested-components */
import { Col, Row, Slider } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyledHeader } from '../../../components/Common/StyledHeaderPage';
import { ShareProportionsGraph, VerticalBarChart } from '../../../components/Dashboard';
import { SelectInput } from '../../../components/LateralFilter/SelectInput';
import { Loading } from '../../../components/Loading';
import { ResultNoScenarios } from '../../../components/ResultNoScenarios';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import theme from '../../../styles/theme';
import { SKUTable } from '../SKUTable';
import * as S from './styles';
import { useDashboardSKU } from './useDashboardSKU';

export function SKUDashboardPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.dashboard.titleSku'));

  const {
    data,
    isLoading,
    columns,
    selectedProject,
    isModalOpen,
    params,
    sliderValue,
    projects,
    years,
    toggleModal,
    setSliderValue,
    updateSliderValue,
    handleFilterProject,
    handleFilterDate,
  } = useDashboardSKU();

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            {/* HEADER */}
            <Row justify="center">
              <Col span={24}>
                <StyledHeader title={`SKU - ${t('pages.dashboard.fields.costTrend')}`} />
              </Col>
            </Row>

            {/* FILTERS */}
            <Row justify="center">
              <Col span={24}>
                <S.FilterContainer>
                  <SelectInput
                    placeholder={`${t('common.all')} ${t('pages.dashboard.fields.projects')}`}
                    options={projects}
                    style={{ width: 162 }}
                    onSelect={handleFilterProject}
                    allowClear
                    onClear={() => handleFilterProject('')}
                    // eslint-disable-next-line no-undefined
                    value={params.projectName ? params.projectName : undefined}
                  />
                  <SelectInput
                    onSelect={handleFilterDate}
                    defaultValue={params.targetYear}
                    options={years}
                    style={{ width: 110 }}
                  />
                </S.FilterContainer>
              </Col>
            </Row>

            {/* LEGEND */}
            <Row justify="center">
              <Col span={24}>
                <S.GraphInfoContainer>
                  <span>SKU&apos;s</span>
                  <S.LegendContainer padding="0.25rem 0 1rem">
                    <div>
                      <S.Legend color={theme.colorsDesignSystem.shareProportionGraph.orange} />
                      <span>{t('pages.dashboard.fields.occurred')}</span>
                    </div>

                    <div>
                      <S.Legend color={theme.colorsDesignSystem.placeHolder} />
                      <span>{t('pages.dashboard.fields.forecast')}</span>
                    </div>
                  </S.LegendContainer>
                </S.GraphInfoContainer>
              </Col>
            </Row>

            {/* GRAPH */}
            <Row justify="center">
              <Col span={24}>
                <S.GraphsContainer>
                  {data.verticalBarChartData && data.verticalBarChartData.length > 0 ? (
                    <S.Card>
                      <S.SliderContainer>
                        <span>{t('pages.dashboard.fields.topList')}</span>
                        <Slider
                          value={sliderValue}
                          onChange={setSliderValue}
                          onAfterChange={updateSliderValue}
                          step={5}
                          max={10}
                          min={5}
                          handleStyle={{ borderColor: theme.colorsDesignSystem.primary }}
                          trackStyle={{ backgroundColor: theme.colorsDesignSystem.primary }}
                        />
                      </S.SliderContainer>

                      <VerticalBarChart data={data.verticalBarChartData} type="SKU" />
                    </S.Card>
                  ) : (
                    <S.ErrorCard>
                      {/* @ts-ignore */}
                      <ResultNoScenarios
                        title={t('common.results.noDataTitle')}
                        subTitle={t('common.results.noDataSubtitle')}
                      />
                    </S.ErrorCard>
                  )}

                  {data.shareProportionsGraphData && data.shareProportionsGraphData.length > 0 ? (
                    <S.Card>
                      <ShareProportionsGraph data={data.shareProportionsGraphData} type="SKU" />
                    </S.Card>
                  ) : (
                    <S.ErrorCard>
                      {/* @ts-ignore */}
                      <ResultNoScenarios
                        title={t('common.results.noDataTitle')}
                        subTitle={t('common.results.noDataSubtitle')}
                      />
                    </S.ErrorCard>
                  )}
                </S.GraphsContainer>
              </Col>
            </Row>

            {/* LEGEND */}
            <Row justify="center">
              <Col span={24}>
                <S.LegendContainer align="flex-end" padding="1.5rem 0 1rem">
                  <div>
                    <S.Legend color={theme.colorsDesignSystem.primary} />
                    <span>{t('pages.dashboard.fields.newProjects')}</span>
                  </div>

                  <div>
                    <S.Legend />
                    <span>{t('pages.dashboard.fields.finishedProjects')}</span>
                  </div>
                </S.LegendContainer>
              </Col>
            </Row>

            {/* TABLE */}
            <SKUTable
              data={data}
              columns={columns}
              isLoading={isLoading}
              selectedProject={selectedProject}
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
            />
          </Col>
        )}
      </Row>
    </main>
  );
}
