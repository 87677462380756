import { Input, Form } from 'antd';

function getRule(inputType, required, limit) {
  switch (inputType) {
    case 'numberWithZero':
      return {
        pattern: /^[0-9]*$/,
        required,
      };
    case 'number':
      return {
        type: 'number',
        min: 0,
        max: limit || 100,
        required,
      };
    case 'money':
      return {
        pattern: /^[0-9]{1,8}[.]*[0-9]{0,2}$/,
        required,
      };
    case 'factor':
      return {
        pattern: /^[1-9][0-9.]*$/,
        required,
      };
    case 'stringOnly':
      return {
        type: 'string',
        max: limit || 100,
        required,
      };
    case 'percentage':
      return {
        type: 'string',
        pattern: /[\d%.]+[\s\d%.]?$/,
        max: limit || 100,
        required,
      };
    default:
      return {
        pattern: /[\w]+[\s\w]?$/,
        type: 'string',
        max: limit || 100,
        required,
      };
  }
}

export function MultipleEditableCell({
  editing,
  editable,
  dataIndex,
  title,
  inputType,
  required = true,
  newInputNode,
  record,
  index,
  children,
  limit,
  name = null,
  label,
  ...restProps
}) {
  const inputNode = newInputNode || <Input />;

  return (
    <td {...restProps}>
      {editing && editable ? (
        <Form.Item
          name={name ?? dataIndex}
          label={label}
          messageVariables={{ label: title }}
          style={{
            margin: 0,
          }}
          rules={[getRule(inputType, required, limit)]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}
