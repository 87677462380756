import i18n from 'i18next';
import { StyledSelectOptions } from '../components/Common/StyledSelect/types';

export function formatToSelect<T>(
  items: T[],
  key: keyof T,
  addShowAll: boolean
): StyledSelectOptions[] {
  const formattedItems = items.map((item) => {
    const value = String(item[key]);
    return {
      label: value,
      value,
    };
  });

  if (addShowAll) {
    formattedItems.unshift({ label: i18n.t('common.showAll'), value: '' });
  }

  return formattedItems;
}
