import { TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Spec } from '../types';
import SimpleTable from '../../../components/Tables/SimpleTable';

interface SpecDetailsProps extends TableProps<any> {
  data: Spec | null;
}
export function SpecDetails({ data }: SpecDetailsProps) {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('pages.componentsGroup.fields.partNumber'),
      label: t('pages.componentsGroup.fields.partNumber'),
      width: 300,
      dataIndex: 'partNumber',
      key: 'partNumber',
      editable: false,
    },
    {
      title: t('pages.componentsGroup.fields.supplier'),
      label: t('pages.componentsGroup.fields.supplier'),
      width: 300,
      dataIndex: 'supplier',
      key: 'supplier',
      editable: false,
    },
  ];

  return (
    <SimpleTable striped dataSource={data?.itens} pagination={false} columns={columns} rowKey="" />
  );
}
