import dayjs from 'dayjs';
import StyledLabel from '../StyledLabel';
import * as S from './styles';
import StyledDatePickerProps from './types';
import calendar from '../../../assets/calendar.svg';

export default function StyledDatePicker({
  disable,
  label,
  placeholder,
  picker,
  onChange,
  format,
  suffixIcon,
  styles,
  defaultValue,
  mode,
  allowClear,
  disableDate,
  ...restProps
}: StyledDatePickerProps) {
  return (
    <S.container>
      <StyledLabel title={label || ''} />
      <S.containerOnlyDate>
        <S.datePicker
          mode={mode} // is deprecated but is the only way to block to the user dont change the picker type by ui
          suffixIcon={suffixIcon || <img alt="" src={calendar} />}
          allowClear={allowClear}
          size="middle"
          placeholder={placeholder}
          //@ts-ignore
          picker={picker} // ts ignore because has a lib type error
          format={format}
          value={defaultValue}
          onChange={onChange}
          disabled={disable}
          disabledDate={disableDate}
          style={styles}
          {...restProps}
        />
      </S.containerOnlyDate>
    </S.container>
  );
}
