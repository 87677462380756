import styled from 'styled-components';

export const PartTypeSimulation = styled.div`
  .simulation-ptype {
    width: 250px;
    vertical-align: top;

    .partType {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 160px;
      align-items: center;

      span {
        padding-left: 10px;
        padding-top: 1px;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .simulation {
      display: flex;
      flex-direction: column;

      .block {
        margin-bottom: 12px;
        text-align: center;

        h4 {
          margin: 0;
          color: #999999;
          font-size: 16px;
          font-weight: 400;
        }

        .prices {
          color: #3d85c6;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }

    &.remove-buttons {
      .partType {
        margin-bottom: 8px;
      }

      .simulation {
        .block {
          margin-bottom: 8px;
        }

        .block-price {
          display: none;
        }
      }
    }
  }
`;
