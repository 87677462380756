import { Col, Row, ConfigProvider, Popconfirm, Tooltip, Button } from 'antd';
import { AiFillSetting, AiOutlineFileSearch, AiOutlineWarning } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { SyncOutlined, CloseOutlined } from '@ant-design/icons';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import { HeaderTitle } from '../../components/PageHeader/HeaderTitle';
import { useSimulation } from './useSimulation';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import StyledButton from '../../components/Common/StyledButton';
import * as SC from '../../styles/common';
import * as S from './styles';

import StyledModal from '../../components/Common/StyledModal';
import { useModal } from '../../components/Modal/useModal';
import { SimulationData } from './Form';
import { renderPrice, renderDecimal } from '../../helpers/utils';
import { Loading } from '../../components/Loading';
import { ScenariosSettings } from '../Scenarios/Settings';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { AVLCSimulation } from './AVLC';
import StyledSelect from '../../components/Common/StyledSelect';
import theme from '../../styles/theme';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';

export function SimulationPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.simulation.title'));

  const [visible, openModal, closeModal] = useModal();
  const [visibleSettings, openModalSettings, closeModalSettings] = useModal();
  const {
    scenariosOptions,
    scenarioName,
    data,
    resultData,
    isLoading,
    params,
    hiddenPrices,
    dataHidden,
    resultDataHidden,
    scenarioId,
    scenarioYear,
    isRefreshing,
    visibleQG,
    avlcIds,
    fetchSimulationParams,
    rowClassName,
    EditableCell,
    EditableRow,
    HiddenPricesForm,
    refreshSimulation,
    closeModalQG,
    columnWithQualification,
    products,
    cms,
    projects,
    handleFilter,
    categories,
    fetchSimulation,
    targetYears,
    clearAllFilters,
  } = useSimulation();

  const noScenarios = scenariosOptions.length === 0;
  const resData = hiddenPrices ? resultDataHidden : resultData;

  return (
    <main>
      {visible && (
        <StyledModal
          title={`${scenarioName}`}
          open={visible}
          onClose={closeModal}
          area="SimulationResult"
          body={
            <SimulationData
              onClose={closeModal}
              scenarioName={scenarioName}
              resultData={resData}
              params={params}
            />
          }
          footer={null}
          width="78rem"
        />
      )}

      {visibleSettings && (
        <StyledModal
          title={`${t('pages.scenarios.settings.title')}`}
          open={visibleSettings}
          onClose={closeModalSettings}
          body={
            <ScenariosSettings
              simulationModal={{
                id: scenarioId,
                year: scenarioYear,
                closeModalSettings,
                fetchSimulationParams,
              }}
            />
          }
          width="78rem"
          footer={null}
        />
      )}
      {visibleQG && (
        <StyledModal
          title="Qualification Ongoing Components"
          onClose={closeModalQG}
          body={<AVLCSimulation scenarioName={scenarioName} AVLCIds={avlcIds} />}
          footer={null}
          open
        />
      )}
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={24}>
                <StyledPageHeader
                  title={
                    <HeaderTitle title={t('pages.simulation.title')} subtitle={scenarioName} />
                  }
                  className="pl-0 pr-0"
                  extra={
                    resData?.simulationPartTypes?.length && !noScenarios
                      ? [
                          <S.ActionButtonPontuacao
                            className="btn-modal"
                            buttonText={
                              <S.Pontuacao>
                                <span>{t('common.result')}: </span>
                                <Tooltip
                                  placement="top"
                                  title={t('pages.simulation.tooltipCurrentScore')}
                                >
                                  <SC.ScoreColorSpanNew score="min">
                                    {` ${renderDecimal(resData?.currentScore)} `}
                                  </SC.ScoreColorSpanNew>
                                </Tooltip>
                                <span>| </span>
                                <Tooltip
                                  placement="top"
                                  title={t('pages.simulation.tooltipMaxScore')}
                                >
                                  <SC.ScoreColorSpanNew>
                                    {` ${renderDecimal(resData?.maxScore)} `}
                                  </SC.ScoreColorSpanNew>
                                </Tooltip>
                                <span>| </span>
                                <SC.SpanBlue>{renderPrice(resData?.totalCost)} </SC.SpanBlue>
                                <AiOutlineFileSearch />
                              </S.Pontuacao>
                            }
                            key={2}
                            onClick={openModal}
                          />,
                        ]
                      : null
                  }
                />
              </Col>
            </Row>

            <Row justify="center" align="bottom" style={{ margin: 0, padding: '0 0 8px' }}>
              <Col span={21}>
                <Row justify="left" style={{ gap: '1rem' }} align="bottom">
                  <StyledSelect
                    label={t('pages.simulation.label.cm')}
                    placeholder={t('pages.simulation.placeholder.cm')}
                    styles={{ minWidth: '5.5rem' }}
                    options={cms}
                    value={params.cm}
                    disable
                    onChange={(e) => handleFilter('cm', e)}
                  />
                  <StyledSelect
                    label={t('pages.simulation.label.product')}
                    placeholder={t('pages.simulation.placeholder.product')}
                    styles={{ minWidth: '7.5rem' }}
                    options={products}
                    value={params.product}
                    onChange={(e) => handleFilter('product', e)}
                  />
                  <StyledSelect
                    label={t('pages.simulation.label.targetYear')}
                    options={targetYears}
                    placeholder={t('pages.simulation.placeholder.targetYear')}
                    styles={{ minWidth: '8.5rem' }}
                    value={params.targetYear}
                    onChange={(e) => handleFilter('targetYear', e)}
                  />
                  <StyledSelect
                    label={t('pages.simulation.label.scenario')}
                    placeholder={t('pages.simulation.placeholder.scenario')}
                    styles={{ minWidth: '19.5rem' }}
                    options={scenariosOptions}
                    value={params.scenario}
                    onChange={(e) => {
                      handleFilter('scenario', e);
                    }}
                  />
                  <StyledSelect
                    label={t('pages.simulation.label.project')}
                    placeholder={t('pages.simulation.placeholder.project')}
                    styles={{ minWidth: '7.5rem' }}
                    options={projects}
                    value={params.project}
                    onChange={(e) => handleFilter('project', e)}
                  />
                  <StyledSelect
                    label={t('pages.simulation.label.category')}
                    placeholder={t('pages.simulation.placeholder.category')}
                    styles={{ minWidth: '7.5rem' }}
                    options={categories}
                    value={params.partType}
                    onChange={(e) => handleFilter('partType', e)}
                  />

                  <StyledButton
                    small
                    onClick={() => fetchSimulation(params)}
                    variant="primary"
                    text={t('common.apply')}
                    disabled={!params.targetYear || !params.scenario}
                  />
                </Row>

                <br />

                {resultData.deprecated ? (
                  <S.RefreshAction>
                    <Tooltip placement="right" title={t('pages.simulation.refreshTooltip')}>
                      <S.MessageRefresh icon={<AiOutlineWarning />}>
                        <AiOutlineWarning />
                        {t('pages.simulation.refreshMessage')}

                        <Popconfirm
                          title={t('pages.simulation.refreshConfirm')}
                          onConfirm={refreshSimulation}
                        >
                          <Button type="primary">{t('pages.simulation.refreshButton')}</Button>
                        </Popconfirm>
                      </S.MessageRefresh>
                    </Tooltip>
                  </S.RefreshAction>
                ) : null}
              </Col>

              <S.ColHidden span={2}>
                {resData?.simulationPartTypes?.length ? <HiddenPricesForm /> : null}
                <br />
              </S.ColHidden>
              <Col span={1}>
                <div>
                  <S.ActionButtonSettings
                    icon={<AiFillSetting style={{ width: 20, height: 24 }} />}
                    onClick={openModalSettings}
                  />
                </div>
                <br />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <S.FilterTagsContainer>
                  {params.project && (
                    <StyledFilterTag
                      label={t('pages.simulation.label.project')}
                      closeicon={<CloseOutlined color={theme.colors.prim} />}
                      title={params.project}
                      closeble
                      onClose={() => {
                        handleFilter('project', null);
                      }}
                    />
                  )}
                  {params.partType && (
                    <StyledFilterTag
                      label={t('pages.simulation.label.category')}
                      closeicon={<CloseOutlined color={theme.colors.prim} />}
                      title={params.partType}
                      closeble
                      onClose={() => {
                        handleFilter('partType', null);
                      }}
                    />
                  )}
                  {params.targetYear && (
                    <StyledFilterTag
                      label={t('pages.simulation.label.targetYear')}
                      closeicon={<CloseOutlined color={theme.colors.prim} />}
                      title={params.targetYear}
                      closeble
                      onClose={() => {
                        handleFilter('targetYear', null);
                      }}
                    />
                  )}
                  {params.scenario && (
                    <StyledFilterTag
                      label={t('pages.simulation.label.scenario')}
                      closeicon={<CloseOutlined color={theme.colors.prim} />}
                      title={
                        scenariosOptions.find((scenario) => scenario.value === params.scenario)
                          ?.label
                      }
                      closeble
                      onClose={() => {
                        handleFilter('scenario', null);
                      }}
                    />
                  )}
                  {(params.scenario || params.targetYear || params.partType || params.project) && (
                    <StyledClearTag onClick={clearAllFilters} />
                  )}
                </S.FilterTagsContainer>
              </Col>
            </Row>
            {isRefreshing && (
              <Row justify="center">
                <Col span={24}>
                  <S.MessageIsRefreshing>
                    <SyncOutlined spin />
                    {t('pages.simulation.isRefreshing')}
                  </S.MessageIsRefreshing>
                </Col>
              </Row>
            )}

            <Row justify="center">
              <Col span={24} />
            </Row>
            {!isLoading ? (
              <Row justify="center">
                <Col span={24}>
                  {isLoading || isRefreshing ? (
                    <Loading />
                  ) : (
                    <ConfigProvider>
                      <S.TableSimulationDiv>
                        <S.TableSimulation
                          rowKey="id"
                          data-cy="table"
                          size="small"
                          expandRowByClick
                          rowClassName={rowClassName}
                          components={{
                            body: {
                              cell: EditableCell,
                              row: EditableRow,
                            },
                          }}
                          scroll={{ x: 'max-content', y: 'auto' }}
                          columns={columnWithQualification()}
                          dataSource={hiddenPrices ? dataHidden : data}
                          pagination={false}
                          locale={{
                            emptyText: (
                              <ResultNoScenarios
                                title={t('common.results.noDataTitle')}
                                subTitle={t('common.results.noDataSubtitle')}
                              />
                            ),
                          }}
                        />
                      </S.TableSimulationDiv>
                    </ConfigProvider>
                  )}
                </Col>
              </Row>
            ) : (
              <ResultNoScenarios />
            )}
          </Col>
        )}
      </Row>
    </main>
  );
}
