import { Layout } from 'antd';
import { Redirect, Route } from 'react-router-dom';
import { PrivateRouteProps } from './types';
import { isAuthenticated, getPermissions, cleanTokens } from '../services/auth';
import { NavBar } from '../components/Layout/NavBar';
import { routesConfig } from './config';
import { NotificationsProvider } from '../context/notification';
import { RoutesPath } from './routesPath';
import { CmsProvider, useCmsContext } from '../context/CmsContext';

const { Content } = Layout;

export function PrivateRoute({ component: Component, ...rest }: PrivateRouteProps) {
  const permissions = getPermissions();

  if (!permissions) {
    cleanTokens();
    return <Redirect to={{ pathname: RoutesPath.login }} />;
  }

  const allowedRoutes = routesConfig.routes.filter((route) => permissions?.includes(route.route));

  const splitPath = rest.path.split('/');
  const path = splitPath[1];

  const removePadding =
    path === 'boms' || path === 'scenarios' || path === 'demands' || path === 'prices';

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated()) {
          if (permissions === 'admin') {
            return (
              <NotificationsProvider>
                <CmsProvider>
                  <Layout>
                    <NavBar />
                    <Layout>
                      <Content style={{ padding: !removePadding ? '1.5rem 2.5rem' : '0' }}>
                        <Component {...props} />
                      </Content>
                    </Layout>
                  </Layout>
                </CmsProvider>
              </NotificationsProvider>
            );
          }

          const allowedRoute = allowedRoutes.find(
            (route) => route.route === path || route.path.slice(1) === path
          );
          if (allowedRoute && allowedRoute.path && allowedRoute.path === rest.path) {
            return (
              <NotificationsProvider>
                <CmsProvider>
                  <Layout>
                    <NavBar />
                    <Layout>
                      <Content style={{ padding: !removePadding ? '1.5rem 2.5rem' : '0' }}>
                        <Component {...props} />
                      </Content>
                    </Layout>
                  </Layout>
                </CmsProvider>
              </NotificationsProvider>
            );
          }

          return (
            <Redirect to={{ pathname: allowedRoutes[0].path, state: { from: props.location } }} />
          );
        }

        return <Redirect to={{ pathname: RoutesPath.login, state: { from: props.location } }} />;
      }}
    />
  );
}
