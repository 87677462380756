// @ts-nocheck

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { PartTypeIcon, PartTypeIconProps } from '../PartTypeIcon';
import * as SC from '../../styles/common';
import * as S from './styles';
import { renderDecimal, renderPrice } from '../../helpers/utils';

interface PartTypeSimulationProps {
  type: PartTypeIconProps;
  row: any;
}

export const PartTypeSimulation = ({ type, row }: PartTypeSimulationProps) => {
  const { t } = useTranslation();
  const simulationClass = row?.removeTransferButtons
    ? `simulation-ptype remove-buttons`
    : `simulation-ptype`;
  // console.log('type=>', type, row);

  return (
    <S.PartTypeSimulation onlyScore={row?.removeTransferButtons}>
      <div className={simulationClass}>
        <div className="partType">
          <PartTypeIcon type={type} /> <span>{type}</span>
        </div>

        <div className="simulation">
          <div className="block block-score">
            <h4>{t('pages.simulation.fieldsHeader.score')}</h4>
            <Tooltip placement="top" title={t('pages.simulation.tooltipCurrentScore')}>
              <SC.ScoreColorSpanNew score="min">
                {` ${renderDecimal(row.parentCurrentScore)} `}
              </SC.ScoreColorSpanNew>
            </Tooltip>
            <span>| </span>
            <Tooltip placement="top" title={t('pages.simulation.tooltipMaxScore')}>
              <SC.ScoreColorSpanNew>{` ${renderDecimal(
                row.parentMaxScore
              )} `}</SC.ScoreColorSpanNew>
            </Tooltip>
            <span>| </span>
            <Tooltip placement="top" title={t('pages.simulation.tooltipPartTypeMaxScore')}>
              <SC.ScoreColorSpanNew score="max">
                {` ${renderDecimal(row.parentPartTypeMaxScore)} `}
              </SC.ScoreColorSpanNew>
            </Tooltip>
          </div>

          <div className="block block-price">
            <h4>{t('pages.simulation.fields.costTotal')}</h4>
            <span className="prices">{renderPrice(row.parentTotalCost)}</span>
          </div>
          <div className="block block-price">
            <h4>{t('pages.simulation.fields.totalGap')}</h4>
            <span className="prices">{renderPrice(row.parentTotalGap)}</span>
          </div>
          <div className="block block-price">
            <h4>{t('pages.simulation.fields.costPerPoint')}</h4>
            <span className="prices">{renderPrice(row.parentCostPerPoint)}</span>
          </div>
        </div>
      </div>
    </S.PartTypeSimulation>
  );
};
