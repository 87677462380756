import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  HourglassOutlined,
  MonitorOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import * as S from './styles';
import StyledTagIndicationProps from './types';

export default function StyledIndicationTag({
  text,
  type,
  onClick,
  ...props
}: StyledTagIndicationProps) {
  if (text === 'FOB') {
    return (
      <S.StyledTagFOB data-testid="styled-indication" onClick={onClick} {...props}>
        <span className="text">{text}</span>
      </S.StyledTagFOB>
    );
  }
  if (text === 'BR') {
    return (
      <S.StyledTagBR data-testid="styled-indication" onClick={onClick} {...props}>
        <span className="text">{text}</span>
      </S.StyledTagBR>
    );
  }
  if (text.toUpperCase() === 'QG') {
    return (
      <S.StyledTagQG data-testid="styled-indication" onClick={onClick} {...props}>
        <span className="icon">
          <HourglassOutlined />
        </span>
        <span className="text">Qg</span>
      </S.StyledTagQG>
    );
  }
  if (text.toUpperCase() === 'QD') {
    return (
      <S.StyledTagQD data-testid="styled-indication" onClick={onClick} {...props}>
        <span className="icon">
          <CheckCircleOutlined />
        </span>
        <span className="text">Qd</span>
      </S.StyledTagQD>
    );
  }
  if (text.toUpperCase() === 'QF') {
    return (
      <S.StyledTagQF data-testid="styled-indication" onClick={onClick} {...props}>
        <span className="icon">
          <WarningOutlined />
        </span>
        <span className="text">Qf</span>
      </S.StyledTagQF>
    );
  }

  if (text.toUpperCase() === 'QC') {
    return (
      <S.StyledTagQC data-testid="styled-indication" onClick={onClick} {...props}>
        <span className="icon">
          <MonitorOutlined />
        </span>
        <span className="text">Qc</span>
      </S.StyledTagQC>
    );
  }
  if (text.toUpperCase() === 'NQ') {
    return (
      <S.StyledTagNQ data-testid="styled-indication" onClick={onClick} {...props}>
        <span className="icon">
          <WarningOutlined />
        </span>
        <span className="text">Nq</span>
      </S.StyledTagNQ>
    );
  }

  if (text.toUpperCase() === 'NP') {
    return (
      <S.StyledTagNP data-testid="styled-indication" onClick={onClick} {...props}>
        <span className="icon">
          <ExclamationCircleOutlined />
        </span>
        <span className="text">Np</span>
      </S.StyledTagNP>
    );
  }
  if (text.toUpperCase() === 'N/A') {
    return (
      <S.StyledTagNA data-testid="styled-indication" onClick={onClick} {...props}>
        <span className="text">{text}</span>
      </S.StyledTagNA>
    );
  }
  if (text === '') {
    return (
      <S.StyledTagNA data-testid="styled-indication-empty" onClick={onClick} {...props}>
        <span className="text">{null}</span>
      </S.StyledTagNA>
    );
  }
  if (type === 'Category') {
    return (
      <S.StyledTagCategory data-testid="styled-indication-empty" onClick={onClick} {...props}>
        <span className="text">{text || '-'}</span>
      </S.StyledTagCategory>
    );
  }
  return (
    <S.StyledTagNP data-testid="styled-indication" onClick={onClick} {...props}>
      <span className="icon">
        <ExclamationCircleOutlined />
      </span>
      <span className="text">{text}</span>
    </S.StyledTagNP>
  );
}
