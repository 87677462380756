import { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, WarningFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ShareProportionItem, ShareProportionDetailsItem } from './types';
import * as S from './styles';
import StyledIndicationTag from '../../../components/IndicationTag';
import theme from '../../../styles/theme';
import { api } from '../../../services/api';
import { getURI, oneAlert } from '../../../helpers/utils';
import { formatValuesToThousands } from '../../../helpers/FormatValues';

export function useContentModalShareProportion() {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [item, setItem] = useState<ShareProportionItem>();

  const handleRenderValuesWithNa = (value: string): string => {
    try {
      if (!value || value === 'N/A') return value;
      return formatValuesToThousands(Number(value))?.toString() || 'N/A';
    } catch (error) {
      return value || 'N/A';
    }
  };

  const columns: ColumnsType<ShareProportionDetailsItem> = [
    {
      title: () => {
        return (
          <S.TitleHeaderTable>{t('pages.inventory.tableHeaders.partNumber')}</S.TitleHeaderTable>
        );
      },
      dataIndex: 'partNumber',
      key: 'partNumber',
      align: 'left',
      render: (_, record) => {
        return <span>{record.partNumber || '-'}</span>;
      },
    },
    {
      title: () => {
        return (
          <S.TitleHeaderTable>{t('pages.inventory.tableHeaders.supplier')}</S.TitleHeaderTable>
        );
      },
      dataIndex: 'supplier',
      key: 'supplier',
      align: 'left',
    },
    {
      title: () => {
        return <S.TitleHeaderTable>%</S.TitleHeaderTable>;
      },
      dataIndex: 'porcentage',
      key: 'porcentage',
      align: 'right',
    },
    {
      title: () => {
        return (
          <S.TitleHeaderTable>{t('pages.shareProportion.modalHeaders.qty')}</S.TitleHeaderTable>
        );
      },
      dataIndex: 'totalQTY',
      key: 'totalQTY',
      align: 'right',
      render: (value: string) => {
        return <span>{handleRenderValuesWithNa(value)}</span>;
      },
    },
    {
      title: () => {
        return (
          <S.TitleHeaderTable>
            {t('pages.shareProportion.modalHeaders.unitcost')}
          </S.TitleHeaderTable>
        );
      },
      dataIndex: 'unitCost',
      key: 'unitCost',
      align: 'right',
    },
    {
      title: () => {
        return (
          <S.TitleHeaderTable>{t('pages.shareProportion.tableHeaders.source')}</S.TitleHeaderTable>
        );
      },
      dataIndex: 'cm',
      key: 'cm',
      align: 'center',
      render: (_, record) => {
        return (
          <S.ContainerTagsTable>
            <StyledIndicationTag text={record.source || ''} />
          </S.ContainerTagsTable>
        );
      },
    },
    {
      title: () => {
        return (
          <S.TitleHeaderTable>{t('pages.shareProportion.modalHeaders.qStatus')}</S.TitleHeaderTable>
        );
      },
      dataIndex: 'qStatus',
      key: 'qStatus',
      align: 'center',
      render: (_, record) => {
        return (
          <S.ContainerTagsTable>
            <StyledIndicationTag text={record.qStatus || ''} />
          </S.ContainerTagsTable>
        );
      },
    },
    {
      title: () => {
        return (
          <S.TitleHeaderTable>{t('pages.shareProportion.modalHeaders.risk')}</S.TitleHeaderTable>
        );
      },
      dataIndex: 'cm',
      key: 'cm',
      align: 'center',
      render: (_, record) => {
        return (
          <S.ContainerTagsTable>
            {record.risk === 'positive' ? (
              <CheckCircleOutlined style={{ color: theme.colors.prim }} />
            ) : (
              <WarningFilled style={{ color: theme.colorsDesignSystem.tagColorOrange }} />
            )}
          </S.ContainerTagsTable>
        );
      },
    },
  ];

  const handleFetchShareProportionDetails = async (
    month: dayjs.Dayjs,
    specification: string,
    supplier: string,
    projectName: string,
    cm: string
  ) => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(
        getURI('/shareProportion/detail', {
          specification,
          supplier,
          month: dayjs(month).format('MMMM/YYYY'),
          projectName,
          cm: cm || 'view all',
        })
      );
      if (status === 200) {
        if (data) {
          setItem(data);
        }
        return;
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    item,
    isLoading,
    columns,
    handleFetchShareProportionDetails,
    handleRenderValuesWithNa,
  };
}
