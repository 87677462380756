import { Col, Row } from 'antd';
import { LegendConfig } from './types';
import * as S from './styles';

export const useBarLineGraphic = () => {
  const handleRenderLegend = (legends: Array<LegendConfig>) => {
    return (
      <S.LegendContainer>
        {legends.map((legend) => {
          return (
            <>
              <S.BarChartItemIndicator size="REGULAR" colorBar={legend.color} />
              <S.LabelsChart>{legend.text}</S.LabelsChart>
            </>
          );
        })}
      </S.LegendContainer>
    );
  };

  const CustomTooltip = ({ active, payload, label, colorBar, colorLine }: any) => {
    if (active && payload && payload.length) {
      return (
        <S.TooltipContainer className="custom-tooltip">
          <Row justify="space-between">
            <Row style={{ gap: '0.25rem' }} align="middle">
              <S.BarChartItemIndicator size="SMALL" colorBar={colorBar} />
              <S.LabelsTooltip weight="REGULAR">Average</S.LabelsTooltip>
            </Row>
            <Row style={{ gap: '0.25rem' }} align="middle">
              <S.LabelsTooltip weight="REGULAR">USD</S.LabelsTooltip>
              <S.LabelsTooltip weight="BOLD">{payload[0].payload.barValue}</S.LabelsTooltip>
            </Row>
          </Row>
          <Row justify="space-between">
            <Row style={{ gap: '0.25rem' }} align="middle">
              <S.BarChartItemIndicator size="SMALL" colorBar={colorLine} />
              <S.LabelsTooltip weight="REGULAR">Price Book</S.LabelsTooltip>
            </Row>
            <Row style={{ gap: '0.25rem' }} align="middle">
              <S.LabelsTooltip weight="REGULAR">USD</S.LabelsTooltip>
              <S.LabelsTooltip weight="BOLD">{payload[0].payload.lineValue}</S.LabelsTooltip>
            </Row>
          </Row>
        </S.TooltipContainer>
      );
    }

    return null;
  };

  const handleRenderColorByMonth = (month: number): boolean => {
    const currentMonth = new Date().getMonth() + 1;
    return currentMonth === month;
  };

  return {
    handleRenderLegend,
    CustomTooltip,
    handleRenderColorByMonth,
  };
};
