import { Divider, Form, Input } from 'antd';
import styled from 'styled-components';

const { TextArea } = Input;

export const Container = styled.div`
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const BodyContainer = styled.main`
  margin: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: 1.8rem;
`;

export const BodyTitle = styled.span`
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1.8rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const MessageBody = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: inherit;
  margin: 0;
`;

export const StrongMessage = styled.span`
  font-size: 1rem;
  font-weight: 700;
  color: inherit;
`;

export const MessageBox = styled(TextArea)`
  font-weight: 400;
  margin-top: 1.1rem;
`;

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  margin-top: 3rem;
  border-top: 0.06rem solid ${({ theme }) => theme.colorsDesignSystem.border};
`;

export const FormItem = styled(Form.Item)`
  margin: 0;
  padding: 0;
`;
