import { Popover } from 'antd';
import { CheckCircleFilled, WarningFilled, SyncOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const PopOver = styled(Popover)`
  background: none;
`;
export const StyledSpanSuccess = styled.span`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;
export const StyledSpanError = styled.span`
  color: ${({ theme }) => theme.colorsDesignSystem.red};
`;
export const StyledSpanProcessing = styled.span`
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
`;
export const CheckIcon = styled(CheckCircleFilled)`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;

export const WarningIcon = styled(WarningFilled)`
  color: ${({ theme }) => theme.colorsDesignSystem.red};
`;
export const ProcessingIcon = styled(SyncOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
`;
export const Title = styled.div`
  display: flex;
  gap: 1rem;
`;
export const Content = styled.div`
  width: 25rem;
  margin-left: 2rem;
`;
