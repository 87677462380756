import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectInput } from '../../LateralFilter/SelectInput';

export function LocalSelect({ ...props }) {
  const { t } = useTranslation();
  const [options, setOptions] = useState([
    { label: 'BR (0)', value: '0' },
    { label: 'FOB (1)', value: '1' },
    { label: 'FOB (2)', value: '2' },
    { label: 'BR (3)', value: '3' },
    { label: 'BR (4)', value: '4' },
    { label: 'BR (5)', value: '5' },
    { label: 'FOB (6)', value: '6' },
    { label: 'FOB (7)', value: '7' },
    { label: 'BR (8)', value: '8' },
  ]);

  return (
    <SelectInput
      placeholder={t('filter.local')}
      name="SelectLocal"
      id="SelectLocal"
      options={options}
      {...props}
    />
  );
}
