import { Skeleton } from 'antd';
import * as S from './styles';
import { ILoadingPage } from './types';

export function Loading({ size = 4 }: ILoadingPage) {
  return (
    <S.LoadingWrapper data-testid="loading">
      <Skeleton active paragraph={{ rows: size }} />
    </S.LoadingWrapper>
  );
}
