import { useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../../services/api';
import { AlertNotification, SKUAlert } from '../../../context/types';
import { useNotifications } from '../../../context/notification';
import { dateFormat, getSortOrder } from '../../../helpers/utils';
import { useTable } from '../../../hooks/useTable';
import { services } from '../../../services/services';
import { HeaderSkusNoMotherboardHeader } from './types';

const initialParams = {
  order: 'projectName',
  orderBy: 'ASC',
  page: 0,
  limit: 25,
};

export function useSKUAlert() {
  const { t } = useTranslation();
  const { id: alertId } = useParams<{ id: string }>();
  const { viewNotification } = useNotifications();
  const { getSortOrderTable, getSortTitle } = useTable();
  const [headerInfo, setHeaderInfo] = useState<HeaderSkusNoMotherboardHeader>();
  const [tableData, setTableData] = useState<SKUAlert[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState(initialParams);

  const handleConvertAlertNotificationToNotificationSku = (
    alert: AlertNotification
  ): SKUAlert[] => {
    try {
      const skuArray: { message: string; data: SKUAlert[] } = JSON.parse(alert.message);
      setHeaderInfo({
        date: dateFormat(alert.creationDateTime).date,
        qtFound: skuArray.data.length.toString(),
        scenario: alert.scenario,
      });
      return skuArray.data;
    } catch (error) {
      return [];
    }
  };

  const fetchAlert = async () => {
    const { data: response }: { data: AlertNotification } = await api.get(
      `${services.events}/list/alerts/${alertId}`
    );

    const skus = handleConvertAlertNotificationToNotificationSku(response);

    setTableData(
      skus.map((item) => ({
        ...item,
        id: `${item.sku}-${item.modelName}`,
        projectName: item.project,
      }))
    );

    await viewNotification(alertId);
    setIsLoading(false);
  };

  const handleChangeTable = (pagination: any, filters: any, sorter: any) => {
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const columns: ColumnsType<SKUAlert> = [
    {
      title: t('pages.skuAlert.fields.sku'),
      dataIndex: 'sku',
      key: 'sku',
      width: 300,
      sorter: (a: any, b: any) => {
        return a.sku.localeCompare(b.sku);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'sku' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'sku', false) },
    },
    {
      title: t('pages.skuAlert.fields.projectName'),
      dataIndex: 'projectName',
      key: 'projectName',
      width: 300,
      defaultSortOrder: 'ascend',
      sorter: (a: any, b: any) => {
        return a.projectName.localeCompare(b.projectName);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'projectName' && getSortOrderTable(params.orderBy),
      showSorterTooltip: {
        title: getSortTitle(params.orderBy, params.order, 'projectName', false),
      },
    },
    {
      title: t('pages.skuAlert.fields.modelName'),
      dataIndex: 'modelName',
      key: 'modelName',
      sorter: (a: any, b: any) => {
        return a.modelName.localeCompare(b.modelName);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'modelName' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'modelName', false) },
    },
  ];

  return {
    isLoading,
    columns,
    tableData,
    params,
    headerInfo,
    fetchAlert,
    handleChangeTable,
  };
}
