import { Col, ConfigProvider, Row, Form } from 'antd';

import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import { HeaderTitle } from '../../components/PageHeader/HeaderTitle';
import { ActionButton } from '../../components/ActionButton';
import { useGroups } from './useGroups';
import { EditableCell } from '../../components/EditableCell';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import * as SC from '../../styles/common';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { Loading } from '../../components/Loading';
import { GroupForm } from './Form/GroupForm';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import StyledModal from '../../components/Common/StyledModal';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledButton from '../../components/Common/StyledButton';

export function GroupsPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.groups.title'));

  const validateMessages = ValidateMessages();

  const {
    data,
    params,
    fetchGroups,
    isLoading,
    form,
    isNewItem,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    mergedColumns,
    handleAddItem,
    isGroupModalOpen,
    save,
    onCloseModal,
  } = useGroups();

  const modalRef = useRef(null);
  const showTagsFilter = params.name || params.email;

  useEffect(() => {
    fetchGroups();
  }, [params]);

  return (
    <main>
      <Row justify="center">
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={24}>
                <StyledHeader
                  title={t('pages.groups.title')}
                  items={data.length}
                  actions={
                    <StyledButton variant="primary" disabled={isNewItem} onClick={handleAddItem}>
                      {t('pages.groups.addGroup')}
                    </StyledButton>
                  }
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <SC.ContainerFilteredTags>
                  {showTagsFilter && <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>}

                  {params.name && (
                    <SC.TagFilter closable onClose={() => handleCloseFilteredTag('name')}>
                      <div className="tag-title">{t('pages.groups.fields.name')}</div>
                      <div className="tag-value">{params.name}</div>
                    </SC.TagFilter>
                  )}
                </SC.ContainerFilteredTags>
              </Col>
            </Row>

            <Row justify="center">
              <Col span={24}>
                <Form form={form} component={false} validateMessages={validateMessages}>
                  <SimpleTable
                    rowKey="id"
                    data-cy="table"
                    striped
                    dataSource={data}
                    columns={mergedColumns}
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    scroll={{ x: 'max-content', y: 'calc(100vh-22rem)' }}
                    pagination={false}
                    locale={{
                      emptyText: (
                        <ResultNoScenarios
                          title={t('common.results.noDataTitle')}
                          subTitle={t('common.results.noDataSubtitle')}
                        />
                      ),
                    }}
                  />
                </Form>
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      <StyledModal
        title={isNewItem ? t('pages.groups.addGroup') : t('pages.groups.editGroup')}
        open={isGroupModalOpen}
        onClose={onCloseModal}
        key="modal-pbb"
        width="48rem"
        ref={modalRef}
        body={<GroupForm onClose={onCloseModal} form={form} save={save} loading={isLoading} />}
        footer={null}
      />
    </main>
  );
}
