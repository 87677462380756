import { ColumnsType } from 'antd/es/table';
import { useHistory } from 'react-router-dom';
import { HourglassOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AVLCTableType } from './types';
import StyledIndicationTag from '../../../components/IndicationTag';
import * as S from './styles';
import { api } from '../../../services/api';
import { oneAlert } from '../../../helpers/utils';

export function useAVLCSimulation() {
  const { t } = useTranslation();
  const [AVLCdata, setAVLCtData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const handleFetchAvlcData = async (avlcId: Array<string>) => {
    setIsLoading(true);
    try {
      const { data, status } = await api.get(`avlc/id/${avlcId}`);
      if (status === 200) {
        setAVLCtData(data.content);
      }
      if (status === 204) {
        setAVLCtData([]);
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };
  const columns: ColumnsType<AVLCTableType> = [
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
      width: '12rem',
    },
    {
      title: 'Category',
      dataIndex: 'partType',
      key: 'partType',
      width: '9rem',
    },
    {
      title: 'Specification',
      dataIndex: 'type',
      key: 'type',
      width: '16.375rem',
    },
    {
      title: 'Part Number',
      dataIndex: 'partNumber',
      key: 'partNumber',
      width: '9rem',
    },
    {
      title: 'Source',
      dataIndex: 'location',
      key: 'location',
      align: 'center',
      width: '87px',
      render: (value) => {
        return <StyledIndicationTag text={value || 'N/A'} />;
      },
    },
    {
      title: 'Manufacturer',
      dataIndex: 'vendor',
      key: 'vendor',
      width: '9rem',
    },
    {
      title: 'Q-Status',
      dataIndex: 'qStatus',
      key: 'qStatus',
      align: 'center',
      width: '98px',
      render: (value) => {
        return <StyledIndicationTag text={value} />;
      },
    },
    {
      title: 'Plan Date',
      dataIndex: 'firstPlanDate',
      key: 'firstPlanDate',
      width: '6.75rem',
    },
  ];

  return {
    columns,
    AVLCdata,
    isLoading,
    handleFetchAvlcData,
  };
}
