import styled from 'styled-components';
import { Row } from 'antd';
import theme from '../../../../styles/theme';

export const container = styled.main`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 1.688rem;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: ${theme.colorsDesignSystem.text};
  padding: 1rem;
  border-bottom: 1px solid ${theme.colorsDesignSystem.border};
  gap: 0.5rem;
`;
export const BlueText = styled.p`
  font-weight: bold;
  color: ${theme.colorsDesignSystem.blue};
  margin: 0;
`;

export const FiltersCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const GraphicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colorsDesignSystem.border};
  width: 25rem;
  height: 22.563rem;
  border-radius: ${theme.dimensions.border_radius_large};
  padding: 1.25rem;
`;

export const TitleCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;
export const PrimaryTitle = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${theme.colorsDesignSystem.text};
`;

export const rowGraphic = styled(Row)`
  padding: 2.375rem 1.625rem 2.375rem 1.625rem;
  gap: 1rem;
`;
