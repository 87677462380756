import { Pagination, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { IStyledPagination } from './types';
import { PagesContent, ShowMessage, TotalContent } from './styles';

export function StyledPagination({ pageNumber, size, totalElements, onChange }: IStyledPagination) {
  const { t } = useTranslation();

  const showingMessage = (total: number, range: number[]) => {
    return (
      <ShowMessage>
        {t('common.pagination.showing')} {range[0]} - {range[1]} {t('common.pagination.ofTotal')}
        <b> {total.toLocaleString(t('common.localeMoney'))}</b>{' '}
        <span>{t('common.pagination.items')}</span>
      </ShowMessage>
    );
  };

  return (
    <Row justify="space-between">
      <TotalContent>
        <Pagination
          total={totalElements}
          current={pageNumber}
          defaultCurrent={1}
          defaultPageSize={25}
          showSizeChanger
          pageSize={size}
          showTotal={showingMessage}
          locale={{
            items_per_page: t('common.pagination.page'),
          }}
          onChange={onChange}
        />
      </TotalContent>
      <PagesContent>
        <Pagination
          total={totalElements}
          current={pageNumber}
          defaultCurrent={1}
          defaultPageSize={25}
          pageSize={size}
          showQuickJumper
          showSizeChanger={false}
          locale={{
            jump_to: t('common.pagination.jumpToPage'),
            page: '',
          }}
          onChange={onChange}
        />
      </PagesContent>
    </Row>
  );
}
