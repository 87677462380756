import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import { AVLCItem } from '../types';
import * as SC from './styles';
import { StatusIcon } from '../../../components/StatusIcon';
import StyledIndicationTag from '../../../components/IndicationTag';

interface AVLCDetailsProps {
  data: AVLCItem | null;
}
export function AVLCDetails({ data, ...rest }: AVLCDetailsProps & unknown) {
  const { t } = useTranslation();

  return (
    <SC.StyledRow gutter={[22, 22]} {...rest}>
      <SC.StyledCol span={24}>
        <div className="inner-col top">
          <SC.StyledRow gutter={12}>
            <Col span={4}>
              <div className="sub-col">
                <h2 className="detail-title">{t('pages.avlc.fields.partNumber')}</h2>
                <span className="detail-data top">{data?.partNumber}</span>
              </div>
            </Col>
            <Col span={10}>
              <div className="sub-col">
                <h2 className="detail-title">{t('pages.avlc.fields.description')}</h2>
                <span className="detail-data top">{data?.description}</span>
              </div>
            </Col>
            <Col span={5}>
              <div className="sub-col">
                <h2 className="detail-title">{t('pages.avlc.fields.partType')}</h2>
                <span className="detail-data top">{data?.partType}</span>
              </div>
            </Col>
            <Col span={5}>
              <div className="sub-col">
                <h2 className="detail-title">{t('pages.avlc.fields.spec')}</h2>
                <span className="detail-data top">{data?.type}</span>
              </div>
            </Col>
          </SC.StyledRow>
        </div>
      </SC.StyledCol>
      <SC.StyledCol span={18}>
        <div
          className="inner-col"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <SC.StyledRow>
            <Col span={3}>
              <h2 className="detail-title">{t('pages.avlc.fields.qStatus')}</h2>
              {data?.qStatus ? (
                <span
                  className="detail-data"
                  style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                >
                  <StatusIcon status={data?.qStatus} /> {data?.qStatus}
                </span>
              ) : (
                ''
              )}
            </Col>
            <Col span={4}>
              <h2 className="detail-title">{t('pages.avlc.fields.modelNumber')}</h2>
              <span className="detail-data">{data?.modelNumber}</span>
            </Col>
            <Col span={5}>
              <h2 className="detail-title">{t('pages.avlc.fields.odm')}</h2>
              <span className="detail-data">{data?.odm}</span>
            </Col>
            <Col span={6}>
              <h2 className="detail-title">{t('pages.avlc.fields.odmSample')}</h2>
              <span className="detail-data">
                {/* {data?.odmsamplereceivedate
                  ? new Intl.DateTimeFormat(lang, {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })?.format(new Date(data.odmsamplereceivedate))
                  : ''} */}
                {data?.odmReceiveDate}
              </span>
            </Col>
            <Col span={6}>
              <h2 className="detail-title">{t('pages.avlc.fields.odmActual')}</h2>
              <span className="detail-data">
                {/* {data?.odmactualsamplereceivedate
                  ? new Intl.DateTimeFormat(lang, {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })?.format(new Date(data.odmactualsamplereceivedate))
                  : ''} */}
                {data?.odmActualReceiveDate}
              </span>
            </Col>
          </SC.StyledRow>
          <SC.StyledRow>
            <Col span={3}>
              <h2 className="detail-title">{t('pages.avlc.fields.location')}</h2>
              <span className="detail-data">
                <StyledIndicationTag text={data?.location || ''} />
              </span>
            </Col>
            <Col span={6}>
              <h2 className="detail-title">{t('pages.avlc.fields.vendor')}</h2>
              <span className="detail-data">{data?.vendor}</span>
            </Col>
            <Col span={3}>
              <h2 className="detail-title">{t('pages.avlc.fields.priority')}</h2>
              <span className="detail-data">{data?.priority}</span>
            </Col>
            <Col span={6}>
              <h2 className="detail-title">{t('pages.avlc.fields.avlcType')}</h2>
              <span className="detail-data">{data?.avlcType}</span>
            </Col>
            <Col span={6}>
              <h2 className="detail-title">{t('pages.avlc.fields.purpose')}</h2>
              <span className="detail-data">{data?.partPurpose}</span>
            </Col>
          </SC.StyledRow>
          <SC.StyledRow gutter={12}>
            <Col span={12}>
              <h2 className="detail-title">{t('pages.avlc.fields.remark')}</h2>
              <span className="detail-data">{data?.remark}</span>
            </Col>
            <Col span={12}>
              <h2 className="detail-title">{t('pages.avlc.fields.lock')}</h2>
              <span className="detail-data">{data?.lock}</span>
            </Col>
          </SC.StyledRow>
        </div>
      </SC.StyledCol>
      <SC.StyledCol span={6}>
        <div className="inner-col">
          <SC.StyledRow gutter={[0, 12]}>
            <Col span={24}>
              <h2 className="detail-title">{t('pages.avlc.fields.firstPlanDate')}</h2>
              <span className="detail-data">
                {/* {data?.firstPlanDate
                  ? new Intl.DateTimeFormat(lang, {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })?.format(new Date(data.firstPlanDate))
                  : ''} */}
                {data?.firstPlanDate}
              </span>
            </Col>
            <Col span={24}>
              <h2 className="detail-title">{t('pages.avlc.fields.rdPlanFinishDate')}</h2>
              <span className="detail-data">
                {/* {data?.rdfinishdateplan
                  ? new Intl.DateTimeFormat(lang, {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })?.format(new Date(data.rdfinishdateplan))
                  : ''} */}
                {data?.rdPlanFinishDate}
              </span>
            </Col>
            <Col span={24}>
              <h2 className="detail-title">{t('pages.avlc.fields.rdActualFinishDate')}</h2>
              <span className="detail-data">
                {/* {data?.rdfinishDateActual
                  ? new Intl.DateTimeFormat(lang, {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })?.format(new Date(data.rdfinishDateActual))
                  : ''} */}
                {data?.rdFinishDate}
              </span>
            </Col>
            <Col span={24}>
              <h2 className="detail-title">{t('pages.avlc.fields.adjustDate')}</h2>
              <span className="detail-data">
                {/* {data?.adjustPlanFinishDate
                  ? new Intl.DateTimeFormat(lang, {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })?.format(new Date(data.adjustPlanFinishDate * 1000))
                  : ''} */}
                {data?.adjustPlanFinishDate}
              </span>
            </Col>
            <Col span={24}>
              <h2 className="detail-title">{t('pages.avlc.fields.uploadTime')}</h2>
              <span className="detail-data">{data?.uploadTime}</span>
            </Col>
            <Col span={24}>
              <h2 className="detail-title">{t('pages.avlc.fields.uploadUser')}</h2>
              <span className="detail-data">{data?.uploadUser}</span>
            </Col>
          </SC.StyledRow>
        </div>
      </SC.StyledCol>
    </SC.StyledRow>
  );
}
