import { SearchOutlined } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import styled from 'styled-components';
import StyledInput from '../../../../components/Common/StyledInput';

export const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: calc(100vh - 20rem);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 1.125rem;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.grayBorder};
    border: solid 0.375rem transparent;
    border-radius: 624rem;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.placeHolder};
    border: solid 0.375rem transparent;
    -webkit-border-radius: 624rem;
    border-radius: 624rem;
  }
`;

export const StepFooter = styled(Row)`
  align-items: center;
  justify-content: space-between;
  border-top: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  padding: 1rem 2.5rem;
`;

export const StepButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ContentCard = styled.div`
  padding: 1rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const SubTitleCard = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  margin: 0;
`;

export const ContainerCardContent = styled.div`
  margin: 1.5rem;
`;

export const StyledDivider = styled(Divider)`
  border-color: ${({ theme }) => theme.colorsDesignSystem.border} !important;
`;

export const InvitedMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
`;

export const StyledSearchIcon = styled(SearchOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;

export const InfoContactConteiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colorsDesignSystem.newTablesColors.colorBackgroundSubItens};
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  border-radius: ${({ theme }) => theme.dimensions.border_radius_medium};
  height: 8rem;
  margin-bottom: 0.625rem;
  padding: 1.406rem 1.406rem 1.406rem 1.406rem;
`;
export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 10fr;
  max-width: max-content;
  width: 100%;
  gap: 0.5rem;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1.5rem;
`;

export const SaveButtonCardContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  padding: 1rem 2.5rem;
`;

export const ContactNameInput = styled(StyledInput)`
  width: 13.75rem;
  color: ${({ theme }) => theme.colorsDesignSystem.black} !important;
  font-size: 0.875rem !important;
`;

export const EmailInput = styled(StyledInput)`
  color: ${({ theme }) => theme.colorsDesignSystem.black} !important;
  font-size: 0.875rem !important;
`;
