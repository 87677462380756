import { t } from 'i18next';
import {
  ValidNotificationMessage,
  Notification,
  SKUAlert,
  AlertNotification,
} from '../context/types';
import { dateFormat } from './utils';

export const parseSKUNotification = (alertNotification: AlertNotification): Notification => {
  const skuArray: { message: string; data: SKUAlert[] } = JSON.parse(alertNotification.message);
  const stamp = alertNotification.creationDateTime
    ? new Date(alertNotification.creationDateTime).toString()
    : new Date().toString();
  const formatedDate = dateFormat(stamp);

  return {
    id: alertNotification.id || `${crypto.randomUUID()}`,
    date: formatedDate.date,
    time: formatedDate.time,
    category: alertNotification.category,
    description: t('notifications.skuDesc', { data: skuArray.data.length }),
    status: alertNotification.visualized,
  };
};
