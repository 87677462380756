import styled from 'styled-components';

export const TotalContent = styled.div`
  ul {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    padding: 0;
    height: 1.5rem;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-item-link,
  .ant-pagination-jump-next,
  .ant-pagination-jump-next-custom-icon,
  .ant-pagination-item {
    display: none;
  }
  .ant-pagination .ant-pagination-options {
    margin-inline-start: 0;
    margin-inline-end: 0.5rem;
  }
  .ant-pagination .ant-pagination-options-size-changer,
  .ant-pagination-total-text {
    height: 1.5rem;
    line-height: 1.375rem;
  }
  svg {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
  }
`;

export const ShowMessage = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  b {
    font-weight: 700;
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
  span {
    font-weight: 700;
  }
`;

export const PagesContent = styled.div`
  .ant-pagination {
    padding-right: 0;
    height: 1.5rem;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-next,
  .ant-pagination .ant-pagination-item {
    height: 1.5rem;
    min-width: 1.5rem;
    max-width: 1.5rem;
    line-height: 1.375rem;
  }
  .ant-pagination .ant-pagination-options {
    margin-inline-start: 0;
  }
  .ant-pagination .ant-pagination-options-quick-jumper {
    height: 1.5rem;
    line-height: 1.375rem;
    input {
      height: 1.5rem;
    }
  }
  .ant-pagination .ant-pagination-item a {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
    padding: 0;
    text-align: center;
  }
  .ant-pagination .ant-pagination-item-active a {
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-weight: 900;
  }
  svg {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
  }
  .ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
    svg {
      color: ${({ theme }) => theme.colorsDesignSystem.grayBorder};
    }
  }
`;
