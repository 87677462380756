import { Col, Row, Form, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { BiDownload, BiPlus } from 'react-icons/bi';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../components/Common/StyledButton';
import { CsvExport } from '../../components/Common/CsvExport';
import { ActionButton } from '../../components/ActionButton';
import { useSupplier } from './useSupplier';
import { EditableCell } from '../../components/EditableCell';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import * as SC from '../../styles/common';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { Loading } from '../../components/Loading';
import { getFilterURL, removeEmptyFilter } from '../../helpers/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import { StyledPagination } from '../../components/Common/StyledPagination';

export function SuppliersPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.suppliers.title'));

  const validateMessages = ValidateMessages();
  const {
    data,
    params,
    pageControl,
    fetchSuppliers,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    isNewItem,
    handleAddItem,
    handleCloseFilteredTag,
    mergedColumns,
    csvHeaders,
    changePage,
  } = useSupplier();

  const showTagsFilter = params.name || params.shortName || params.state;

  useEffect(() => {
    fetchSuppliers();
  }, [params]);

  const actions = [
    {
      key: '1',
      label: (
        <ActionButton
          key={1}
          icon={<BiPlus />}
          disabled={isNewItem}
          onClick={handleAddItem}
          buttonText={`${t('common.add')} ${t('common.item')}`}
        />
      ),
    },
    {
      key: '2',
      label: (
        <ActionButton key={2} className="btn-csv" icon={<BiDownload />}>
          <CsvExport
            url={`suppliers?${getFilterURL(removeEmptyFilter(params))}`}
            filename={['suppliers']}
            limit={pageControl.totalElements}
            headers={csvHeaders}
          />
        </ActionButton>
      ),
    },
  ];

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={24}>
                <StyledHeader
                  title={t('pages.suppliers.title')}
                  items={pageControl.totalElements}
                  actions={
                    <Dropdown key={0} menu={{ items: actions }} placement="bottomRight">
                      <StyledButton variant="primary">
                        {t('common.action')}
                        <DownOutlined />
                      </StyledButton>
                    </Dropdown>
                  }
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <SC.ContainerFilteredTags>
                  {showTagsFilter && <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>}
                  {params.header_name && (
                    <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_name')}>
                      <div className="tag-title">{t('pages.suppliers.fields.name')}</div>
                      <div className="tag-value">{params.header_name}</div>
                    </SC.TagFilter>
                  )}
                  {params.header_shortName && (
                    <SC.TagFilter
                      closable
                      onClose={() => handleCloseFilteredTag('header_shortName')}
                    >
                      <div className="tag-title">{t('pages.suppliers.fields.shortName')}</div>
                      <div className="tag-value">{params.header_shortName}</div>
                    </SC.TagFilter>
                  )}
                  {params.header_codigo && (
                    <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_codigo')}>
                      <div className="tag-title">{t('pages.suppliers.fields.code')}</div>
                      <div className="tag-value">{params.header_codigo}</div>
                    </SC.TagFilter>
                  )}
                  {params.header_state && (
                    <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_state')}>
                      <div className="tag-title">{t('pages.suppliers.fields.state')}</div>
                      <div className="tag-value">{params.header_state}</div>
                    </SC.TagFilter>
                  )}
                </SC.ContainerFilteredTags>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                <Form form={form} component={false} validateMessages={validateMessages}>
                  <SimpleTable
                    rowKey="id"
                    data-cy="table"
                    striped
                    dataSource={data}
                    columns={mergedColumns}
                    onChange={handleChangeTable}
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    scroll={{ x: 'max-content', y: 'calc(100vh - 20rem)' }}
                    pagination={false}
                    locale={{
                      emptyText: (
                        <ResultNoScenarios
                          title={t('common.results.noDataTitle')}
                          subTitle={t('common.results.noDataSubtitle')}
                        />
                      ),
                    }}
                  />
                </Form>

                {pageControl.totalPages > 1 && (
                  <StyledPagination
                    onChange={changePage}
                    pageNumber={params.page + 1}
                    size={params.limit}
                    totalElements={pageControl.totalElements}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
