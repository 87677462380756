import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import * as S from './styles';
import StyledSelect from '../../Common/StyledSelect';
import StyledDatePicker from '../../Common/StyledDatePicker';
import { DragAndDropUploader } from '../../Common/DragAndDropUploader';
import { UploadSheetData } from '../../../pages/UploadsFiles/types';
import { UploadSheetsProps } from './types';
import StyledButton from '../../Common/StyledButton';
import { StyledSelectOptions } from '../../Common/StyledSelect/types';
import { getUsernameId } from '../../../services/auth';

export function UploadSheetsContent({
  listTypeOptions,
  uplodaData,
  onUploadFiles,
  saveFile,
  closeModal,
  loading,
  onClearFile,
}: UploadSheetsProps) {
  const [form] = Form.useForm();
  const handleUploadAgain = () => {
    uplodaData &&
      form.setFieldsValue({
        params: {
          ...uplodaData,
          date: uplodaData.date === '' ? null : dayjs(uplodaData.date, 'MM/YYYY'),
        },
      });
  };

  useEffect(() => {
    handleUploadAgain();
  }, []);

  const { t } = useTranslation();
  const [tempListTypeOptions, SetTempListTypeOptions] = useState<Array<StyledSelectOptions>>([]);
  const [cm, setCm] = useState('');

  const removeFiltersByCM = () => {
    const compalRemoveFilters = ['In Out'];
    const removeBoth = ['Forecast', 'Local Components Price'];

    const filtersToRemove = cm === 'Compal' ? removeBoth.concat(compalRemoveFilters) : removeBoth;

    return filtersToRemove;
  };

  const handleFilterOptionsByCm = () => {
    if (cm && cm !== 'ambos') {
      const filtersToRemove = removeFiltersByCM();
      SetTempListTypeOptions(
        listTypeOptions.filter((item) => {
          return ![...filtersToRemove].includes(String(item.value));
        })
      );
    }
    if (cm && cm === 'ambos') {
      SetTempListTypeOptions(
        listTypeOptions.filter((item) => {
          return ['Forecast', 'Local Components Price'].includes(String(item.value));
        })
      );
    }
  };

  useEffect(() => {
    handleFilterOptionsByCm();
  }, [cm]);

  const CMsOptions: StyledSelectOptions[] = [
    { value: 'Compal', label: 'Compal' },
    { value: 'Foxconn', label: 'Foxconn' },
    { value: 'ambos', label: `${t(`components.modals.uploadSheets.CmOption`)}` },
  ];

  const onFinish = (fields: UploadSheetData) => {
    const values = {
      ...fields,
      params: {
        ...fields.params,
        date: dayjs(fields.params.date).format('MM/YYYY'),
        cognito_username: getUsernameId(),
      },
    };
    saveFile(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <S.UploadSheetsContainer className="upload-sheets-container">
      <Form
        name="UploadSheets"
        style={{ width: '100%' }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <S.ContentContainer>
          <S.FiltersContainer>
            <div>
              <Form.Item
                style={{ marginBottom: '10px' }}
                name={['params', 'cm']}
                rules={[
                  {
                    required: true,
                    message: `${t(`common.validate.required`)}`,
                  },
                ]}
              >
                <StyledSelect
                  placeholder={t('components.modals.uploadSheets.placeholders.CM')}
                  styles={{ width: '6.25rem' }}
                  options={CMsOptions}
                  label="CM"
                  onChange={(value: string) => {
                    setCm(value);
                  }}
                />
              </Form.Item>
            </div>
            <div style={{ width: '100%', lineHeight: '1em' }}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: `${t(`common.validate.required`)}`,
                  },
                ]}
                style={{ marginBottom: '10px' }}
                name={['params', 'listType']}
              >
                <StyledSelect
                  placeholder={t('components.modals.uploadSheets.placeholders.listType')}
                  options={tempListTypeOptions}
                  label={t('components.modals.uploadSheets.labels.listType')}
                  onChange={null}
                  disable={!cm}
                />
              </Form.Item>
            </div>
            <Form.Item
              style={{ marginBottom: '10px' }}
              name={['params', 'date']}
              rules={[
                {
                  required: true,
                  message: `${t(`common.validate.required`)}`,
                },
              ]}
            >
              <StyledDatePicker
                picker="month"
                mode="month"
                format="MM/YYYY"
                placeholder={t('pages.finance.uploadFiles.periodPlaceHolder')}
                styles={{ width: '7.5rem' }}
                label={t('pages.finance.uploadFiles.labelPeriod')}
              />
            </Form.Item>
          </S.FiltersContainer>
          <Form.Item>
            <DragAndDropUploader
              onFile={onUploadFiles}
              directory
              loading={loading}
              onClearFile={onClearFile}
            />
          </Form.Item>
        </S.ContentContainer>
        <S.ButtonsContainer>
          <StyledButton
            variant="secondary"
            text={t('components.modals.uploadSheets.cancel')}
            onClick={closeModal}
          />
          <Form.Item>
            <StyledButton
              text={t('components.modals.uploadSheets.upload')}
              variant="primary"
              htmlType="submit"
            />
          </Form.Item>
        </S.ButtonsContainer>
      </Form>
    </S.UploadSheetsContainer>
  );
}
