import { Form } from 'antd';

import { useTranslation } from 'react-i18next';
import StyledButton from '../../../../components/Common/StyledButton';
import * as S from './styles';
import { CancelBidModalProps } from './types';

export function CancelBidModal({ onCancel, closeModal, handleCancelBid }: CancelBidModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <Form
      form={form}
      onFinish={() => {
        handleCancelBid(form.getFieldValue('messageBox'));
        closeModal(false);
      }}
    >
      <S.Container>
        <S.BodyContainer>
          <S.StyledDivider orientation="left" orientationMargin="0">
            <S.BodyTitle>{t('pages.viewRfq.modalCancellationBid.bodyTitle')}</S.BodyTitle>
          </S.StyledDivider>
          <S.MessageBody>
            {t('pages.viewRfq.modalCancellationBid.bodyMessage')}
            <S.StrongMessage>{t('pages.viewRfq.modalCancellationBid.boldMessage')}</S.StrongMessage>
          </S.MessageBody>
          <S.MessageBody>{t('pages.viewRfq.modalCancellationBid.messageAlert')}</S.MessageBody>
          <S.FormItem
            name="messageBox"
            rules={[
              {
                required: true,
                message: `${t('pages.viewRfq.modalCancellationBid.requiredMessage')}`,
              },
            ]}
          >
            <S.MessageBox
              placeholder={t('pages.viewRfq.modalCancellationBid.messageBoxPlaceholder')}
              maxLength={50}
              minLength={2}
              autoSize={{ minRows: 3 }}
            />
          </S.FormItem>
        </S.BodyContainer>

        <S.FooterContainer>
          <StyledButton variant="secondary" onClick={onCancel}>
            {t('common.cancel')}
          </StyledButton>
          <S.FormItem>
            <StyledButton htmlType="submit" variant="primary">
              {t('common.confirm')}
            </StyledButton>
          </S.FormItem>
        </S.FooterContainer>
      </S.Container>
    </Form>
  );
}
