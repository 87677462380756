import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { AverageCardProps } from './types';

import ExpandableCard from '../../../../components/Common/ExpandableCard';
import SimpleTable from '../../../../components/Tables/SimpleTable';
import HeaderCard from '../HeaderCards';

export function AverageCard({
  openAverageCard,
  setOpenAverageCard,
  columns,
  averageItems,
  isLoading,
  partNumberProps,
}: AverageCardProps) {
  const { t } = useTranslation();
  return (
    <ExpandableCard
      isOpen={openAverageCard}
      headerCard={
        <HeaderCard
          title="Average"
          componentName={partNumberProps?.partNumber || '-'}
          description={partNumberProps?.description}
        />
      }
      onExpandableContent={setOpenAverageCard}
    >
      <SimpleTable
        bordered={false}
        pagination={false}
        dataSource={averageItems}
        rowKey="partNumber"
        columns={columns}
        isLoading={isLoading}
        scroll={{ x: 'auto' }}
        locale={
          partNumberProps?.source === 'FOB' && {
            emptyText: (
              <Empty
                description={t('pages.financePage.average.fobEmptyData')}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ),
          }
        }
      />
    </ExpandableCard>
  );
}
