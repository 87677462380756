import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TRANSLATIONS_EN } from './en/en';
import { TRANSLATIONS_PT_BR } from './pt_br/pt_br';

export const changeLanguage = (lng: string) => {
  i18n.changeLanguage(lng);
  localStorage.setItem('language', lng);
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: TRANSLATIONS_EN,
    },
    pt_br: {
      translation: TRANSLATIONS_PT_BR,
    },
  },
  lng: localStorage.getItem('language') || 'en',
});

declare module 'react-i18next' {
  interface Resources {
    translation: typeof TRANSLATIONS_EN;
  }
}
