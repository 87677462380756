import { Col } from 'antd';
import styled from 'styled-components';

interface IStyledStep {
  noBorder?: boolean;
}

export const StyledStep = styled(Col)<IStyledStep>`
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: ${({ theme }) => theme.colorsDesignSystem.white};
    border: ${({ theme, noBorder: bordered }) =>
      !bordered ? `1px solid ${theme.colorsDesignSystem.primary}` : 'none'};
  }

  .ant-steps .ant-steps-item-wait .ant-steps-item-icon {
    background-color: ${({ theme }) => theme.colorsDesignSystem.white};
    border: ${({ theme, noBorder: bordered }) =>
      !bordered ? `1px solid ${theme.colorsDesignSystem.lightBlack}` : 'none'};
  }

  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    line-height: 1.5rem;
  }

  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${({ theme }) => theme.colorsDesignSystem.lightBlack};
    line-height: 2rem;
  }

  .ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.colorsDesignSystem.lightBlack};
  }

  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: ${({ theme }) => theme.colorsDesignSystem.lightBlack};
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: ${({ theme }) => theme.colorsDesignSystem.lightBlack};
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: ${({ theme }) => theme.colorsDesignSystem.lightBlack};
    font-size: 0.75rem;
    line-height: 1rem;
  }
`;
