import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: 10rem;
  padding: 1rem;
`;

export const Title = styled.p`
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  margin: 0;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 0.875rem;
  margin-left: 1rem;
`;
