import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';

import SimpleTable from '../../../components/Tables/SimpleTable';
import { Loading } from '../../../components/Loading';

import * as S from './styles';
import { useModalTax } from './useModalTax';
import { ModalTaxProps } from './types';

export function TaxConfiguration({ componentType, period, spec, componentTab }: ModalTaxProps) {
  const { t } = useTranslation();
  const { columns, tableData, loading, hasData, handleFecthData } = useModalTax(
    componentType,
    period,
    spec,
    componentTab
  );

  useEffect(() => {
    handleFecthData();
  }, []);

  return (
    <S.Container>
      <S.TitleWithLine>
        <S.TitleText>{t('pages.componentsGroup.modalTax.title')}</S.TitleText>
        <S.TitleLine />
      </S.TitleWithLine>
      {loading ? (
        <Loading />
      ) : hasData ? (
        <SimpleTable
          pagination={false}
          dataSource={[tableData]}
          rowKey="componentType"
          columns={columns}
          scroll={{ x: 'max-content', y: 'auto' }}
        />
      ) : (
        <Empty />
      )}
    </S.Container>
  );
}
