import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { Data } from '../types';
import { useSupplierPricesChart } from './useSupplierPrices';
import theme from '../../../../../styles/theme';

export default function SupplierPriceChart(data: Data) {
  const {
    CustomLegend,
    DataLegend,
    CustomToolTip,
    CustomDataXlabel,
    dataChange,
    formatTick,
    CustomLabel,
  } = useSupplierPricesChart();
  return (
    <ResponsiveContainer width="100%" height="100%" maxHeight={480}>
      <BarChart
        data={dataChange(data)}
        key="month"
        margin={{
          top: 25,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barGap={18}
      >
        <Tooltip content={<CustomToolTip />} cursor={{ fill: 'transparent' }} />
        <Legend payload={DataLegend(data)} content={<CustomLegend />} />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" tickFormatter={(tick) => CustomDataXlabel(tick)} />
        <YAxis tickFormatter={formatTick} />
        <Bar
          barSize={28}
          spacing={15}
          dataKey="supplier1"
          fill={theme.colorsDesignSystem.chartColors.lightGreen}
          label={<CustomLabel />}
        />
        <Bar
          barSize={28}
          spacing={15}
          dataKey="supplier2"
          fill={theme.colorsDesignSystem.chartColors.green}
          label={<CustomLabel />}
        />
        <Bar
          barSize={28}
          spacing={15}
          dataKey="supplier3"
          fill={theme.colorsDesignSystem.chartColors.gray}
          label={<CustomLabel />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
