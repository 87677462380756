import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { HttpStatusCode } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContactTag } from './ContactTag';
import * as S from './styles';
import { ContactData, ContactUsers, InvitedType, Step5Data } from './types';

import StyledButton from '../../../../components/Common/StyledButton';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import StyledSelect from '../../../../components/Common/StyledSelect';
import { StyledSelectOptions } from '../../../../components/Common/StyledSelect/types';
import { getUserNameAndEmail, oneAlert, replaceLineBreaks } from '../../../../helpers/nUtils';
import { api } from '../../../../services/api';
import { services } from '../../../../services/services';
import { IError } from '../../types';
import { CompressedComponent } from '../components/CompressedComponents';

export function useSend(bidId?: string) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const ExpandCategories: { [key: string]: boolean } = {};
  const [expandableItems, setExpandableItems] = useState(ExpandCategories);
  const [step5Data, setStep5Data] = useState<Array<Step5Data>>([]);
  const [contactData, setContactData] = useState<ContactData | null>(null);
  const [contactUsers, setContactUsers] = useState<Array<ContactUsers>>([]);
  const [bitSendModal, setBitSendModal] = useState(false);
  const [isBidFinished, setIsBidFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCard, setLoadingCard] = useState(false);
  const [disableContact, setDisableContact] = useState(false);
  const [loadingContact, setLoadingContact] = useState(false);

  useEffect(() => {
    localStorage.setItem('bidID', JSON.stringify(step5Data));
  }, []);

  useEffect(() => {
    if (step5Data.length > 0) {
      localStorage.setItem('step5data', JSON.stringify(step5Data));
    }
  }, [step5Data]);

  const fetchStepSendData = async () => {
    setLoading(true);
    try {
      const { data, status }: { data: Array<Step5Data>; status: HttpStatusCode } = await api.get(
        `${services.rfq}/bid/send/${bidId}`
      );

      if (status === 200) {
        setStep5Data(data);
      }
      return;
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnList'),
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchContactList = async (supplier: string) => {
    setLoadingCard(true);
    try {
      const { data, status }: { data: Array<ContactUsers>; status: HttpStatusCode } = await api.get(
        `${import.meta.env.VITE_API_LOGIN_UNICO}${
          services.singleLogin
        }/user-consult?empresa=${supplier}`
      );
      if (status === 200) {
        return setContactUsers(data);
      }
      setContactUsers([]);
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnList'),
      });
    } finally {
      setLoadingCard(false);
    }
  };

  const handleValidateContactAlreadyAdded = (card: Step5Data, contactName: string): boolean => {
    return card.invited ? card.invited.some((contact) => contact.name === contactName) : false;
  };

  const handleContactData = async (contactID: string, card: Step5Data) => {
    const contact = contactUsers.find((user) => user.id === contactID);

    if (!contact) {
      setContactData(null);
      return;
    }

    if (handleValidateContactAlreadyAdded(card, contact.name)) {
      oneAlert({
        type: 'info',
        message: t('pages.newRfq.steps.send.messageContactInvited'),
      });
      setContactData(null);
      return;
    }

    if (contact) {
      setContactData({
        name: contact.name,
        email: contact.email,
      });
    }
  };

  const handleSaveContact = async (messageId: string, contact: ContactData) => {
    setLoadingContact(true);
    try {
      const { status } = await api.put(
        `${services.rfq}/bid/send/${bidId}/message/${messageId}/invited`,
        contact
      );
      if (status === 200) {
        setContactData(null);
        fetchStepSendData();
      }

      return;
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnSave'),
      });
    } finally {
      setLoadingContact(false);
    }
  };

  const handleDeleteContact = async (messageId: string, invitedId: string) => {
    try {
      const { data, status } = await api.delete(
        `${services.rfq}/bid/send/${bidId}/message/${messageId}/invited/${invitedId}`
      );
      if (status === 200 || status === 204) {
        fetchStepSendData();
      }

      return;
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnDelete'),
      });
    }
  };

  const getComponentsWithDifferentStatus = () => {
    const storedData = localStorage.getItem('step5data');
    if (storedData) {
      return JSON.parse(storedData)
        .filter((item: Step5Data) => item.saved !== true)
        .map((item: { message: string; messageId: string }) => ({
          message: item.message,
          messageId: item.messageId,
        }));
    }
    return step5Data
      .filter((item: Step5Data) => item.saved !== true)
      .map((item: { message: string; messageId: string }) => ({
        message: item.message,
        messageId: item.messageId,
      }));
  };

  const saveDraftStep5 = async () => {
    const componentsWithDifferentStatus = getComponentsWithDifferentStatus();
    const IDBid = JSON.parse(localStorage.getItem('bidID') || '{}');
    try {
      const response = await api.put(
        `${services.rfq}/bid/send/${IDBid}`,
        componentsWithDifferentStatus
      );
      if (response.status === 200) {
        oneAlert({
          type: 'success',
          message: t('toast.successOnSave'),
        });
        return true;
      }
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.errorOnSave'),
      });
      return false;
    }
  };

  const handleUpdateExpandableViews = (value: string) => {
    setExpandableItems((oldParams: { [key: string]: boolean }) => {
      const newParams = Object.keys(oldParams).reduce((acc: { [key: string]: boolean }, key) => {
        acc[key] = false;
        return acc;
      }, {});
      return {
        ...newParams,
        [value]: !expandableItems[value],
      };
    });
  };

  const handleGetNameUser = (contactsUsers: Array<ContactUsers>) => {
    if (contactUsers.length > 0) {
      const contactOptions: StyledSelectOptions[] = contactsUsers.map((item: ContactUsers) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      return contactOptions;
    }
    return null;
  };

  const handleSaveMessage = async (
    index: number,
    messageId: string,
    invited: Array<InvitedType>,
    message: string
  ) => {
    setLoadingCard(true);
    const saveData = {
      createdBy: getUserNameAndEmail(localStorage.getItem('idToken')),
      invited,
      message,
    };

    try {
      const { status } = await api.put(
        `${services.rfq}/bid/send/${bidId}/message/${messageId}`,
        saveData
      );
      if (status === 204) {
        oneAlert({
          type: 'success',
          message: t('pages.newRfq.steps.send.saveMessageSucess'),
        });
        handleUpdateExpandableViews(index.toString());
        fetchStepSendData();
      }

      return;
    } catch (error) {
      console.error('error', error);
      oneAlert({
        type: 'error',
        message: t('toast.errorOnSave'),
      });
    } finally {
      setLoadingCard(false);
    }
  };

  const verifyStatusStep5 = () => {
    const storedData = localStorage.getItem('step5data');
    if (storedData) {
      return JSON.parse(storedData).some((item: Step5Data) => item.saved !== true);
    }
    return false;
  };

  const sendEmail = async () => {
    setLoading(true);
    try {
      const { status } = await api.post(`${services.rfq}/bid/send/${bidId}/send-email`);
      if (status === 200 || status === 204) {
        setBitSendModal(true);
        setIsBidFinished(true);
      }
    } catch (err) {
      const error = err as IError;
      oneAlert({
        type: 'error',
        message: error.errorResponse.messageDetail || t('toast.erroOnSendEmail'),
      });
    } finally {
      setLoading(false);
    }
  };

  const validateInvited = (invited: Array<InvitedType>) => {
    if (invited && invited.length > 0 && invited.length <= 5) {
      return true;
    }
    oneAlert({
      type: 'error',
      message: t('pages.newRfq.steps.send.invitedRequired'),
    });
    return false;
  };

  const quantitiesInvited = (invited: Array<InvitedType>) => {
    if (invited && invited.length > 5) {
      oneAlert({
        type: 'error',
        message: t('pages.newRfq.steps.send.invitedLimit'),
      });
      return false;
    }
    return true;
  };

  const renderContent = (Data: Array<Step5Data>) => {
    return Data.map((item: Step5Data, index) => {
      console.warn('item', item.message);
      return (
        <S.ContentCard key={item.messageId}>
          {expandableItems && (
            <CompressedComponent
              step="5"
              index={index + 1}
              title={item.supplier}
              isOpen={expandableItems[index]}
              onDelete={() => null}
              onEdit={() => {
                fetchContactList(item.supplier);
                handleUpdateExpandableViews(index.toString());
              }}
              onClose={() => {
                handleUpdateExpandableViews(index.toString());
                setContactData(null);
              }}
              totalDemand={item.totalDemand}
              components={item.components}
              status={item.saved ? 'OK' : 'WARNING'}
            >
              <Form
                form={form}
                initialValues={{ [`message_${index}`]: item.message }}
                onFinish={() => {
                  validateInvited(item.invited) &&
                    handleSaveMessage(
                      index,
                      item.messageId,
                      item.invited,
                      replaceLineBreaks(form.getFieldValue(`message_${index}`))
                    );
                }}
              >
                {loadingCard ? (
                  <StyledLoading height={15} />
                ) : (
                  <S.ContainerCardContent>
                    <S.InvitedMessageContainer>
                      <S.StyledDivider orientation="left" orientationMargin="0">
                        <S.SubTitleCard>{t('pages.newRfq.steps.send.cardSubtitle')}</S.SubTitleCard>
                      </S.StyledDivider>

                      <StyledSelect
                        label="Registered Contacts"
                        styles={{ width: '20rem' }}
                        disable={disableContact}
                        showSearch
                        value={contactData?.name}
                        options={handleGetNameUser(contactUsers) || []}
                        onChange={(value: string) => {
                          handleContactData(value, item);
                        }}
                      />
                      {contactData && (
                        <S.InfoContactConteiner>
                          {loadingContact ? (
                            <StyledLoading height={15} />
                          ) : (
                            <>
                              <S.InputContainer>
                                <S.ContactNameInput
                                  label={t('pages.newRfq.steps.send.contactName')}
                                  value={contactData.name}
                                  disabled
                                />
                                <S.EmailInput
                                  label={t('pages.newRfq.steps.send.email')}
                                  value={contactData.email}
                                  disabled
                                />
                              </S.InputContainer>
                              <S.StyledButtonContainer>
                                <StyledButton variant="slim" onClick={() => setContactData(null)}>
                                  {t('common.cancel')}
                                </StyledButton>
                                <StyledButton
                                  variant="primary"
                                  onClick={() => {
                                    quantitiesInvited(item.invited) &&
                                      handleSaveContact(item.messageId, contactData);
                                  }}
                                >
                                  {t('common.save')}
                                </StyledButton>
                              </S.StyledButtonContainer>
                            </>
                          )}
                        </S.InfoContactConteiner>
                      )}
                      <S.TagContainer>
                        {item.invited &&
                          item.invited.map((tag: InvitedType) => {
                            return (
                              <ContactTag
                                key={tag.id}
                                contact={tag.name}
                                supplier={item.supplier}
                                onDelete={() => handleDeleteContact(item.messageId, tag.id)}
                              />
                            );
                          })}
                      </S.TagContainer>
                    </S.InvitedMessageContainer>
                    <S.InvitedMessageContainer>
                      <S.StyledDivider orientation="left" orientationMargin="0">
                        <S.SubTitleCard>{t('pages.newRfq.steps.send.message')}</S.SubTitleCard>
                      </S.StyledDivider>
                      <Form.Item
                        name={`message_${index}`}
                        rules={[
                          {
                            required: true,
                            message: t('pages.newRfq.steps.send.messageRequired'),
                          },
                        ]}
                      >
                        <TextArea maxLength={500} minLength={5} style={{ minHeight: '7rem' }} />
                      </Form.Item>
                    </S.InvitedMessageContainer>
                  </S.ContainerCardContent>
                )}
                <Form.Item style={{ margin: '0' }}>
                  <S.SaveButtonCardContainer>
                    <StyledButton variant="primary" htmlType="submit">
                      {t('common.save')}
                    </StyledButton>
                  </S.SaveButtonCardContainer>
                </Form.Item>
              </Form>
            </CompressedComponent>
          )}
        </S.ContentCard>
      );
    });
  };

  return {
    bitSendModal,
    step5Data,
    loading,
    setBitSendModal,
    isBidFinished,
    fetchStepSendData,
    renderContent,
    verifyStatusStep5,
    saveDraftStep5,
    sendEmail,
  };
}
