/* eslint-disable react/no-unstable-nested-components */
import { Row, Tooltip as Hint } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import React from 'react';
import * as S from './styles';
import theme from '../../../styles/theme';
import { AllDataChart } from './types';
import { useBarLineGraphic } from './useBarLineGraphic';

const BarLineGraphicFunction = ({
  config,
  labels,
  showTooltip,
  titleChart,
  subTitleChart,
  chartData,
  width,
  height,
  toolTip,
  themeGraphic,
}: AllDataChart) => {
  const { handleRenderLegend, CustomTooltip } = useBarLineGraphic();
  const { t } = useTranslation();

  return (
    <S.container data-testid="chart-line-graph">
      <S.TitleCardContainer>
        <Row>
          <S.PrimaryTitle>{titleChart}</S.PrimaryTitle>
        </Row>
        <Row style={{ gap: '0.188rem' }}>
          <S.SecondaryTitle>{subTitleChart}</S.SecondaryTitle>
          {showTooltip && (
            <Hint placement="top" title={toolTip || t('common.emptyTooltip')}>
              <InfoCircleFilled
                data-testid="icon-hint"
                style={{ color: theme.colors.dark_green }}
              />
            </Hint>
          )}
        </Row>
      </S.TitleCardContainer>
      <ResponsiveContainer width={width || '100%'} height={height || '100%'}>
        <ComposedChart
          width={100}
          height={100}
          data={chartData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: -25,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey={config.labelColumn} />
          <YAxis />
          <Tooltip
            data-testid="tooltip-graph"
            content={
              <CustomTooltip
                colorBar={themeGraphic.colorCurrentMonth}
                colorLine={themeGraphic.colorLineChart}
              />
            }
          />
          <Legend align="right" content={handleRenderLegend(labels)} />
          <Bar barSize={27} dataKey={config.dataKeyBar} label={{ position: 'top' }}>
            {chartData.map((entry, index) => (
              <Cell
                key={entry.barValue}
                fill={index === 1 ? themeGraphic.colorCurrentMonth : themeGraphic.colorOthersMonths}
              />
            ))}
          </Bar>
          <Line type="monotone" dataKey={config.dataKeyLine} stroke={themeGraphic.colorLineChart} />
        </ComposedChart>
      </ResponsiveContainer>
    </S.container>
  );
};

export const BarlineGraphic = React.memo(BarLineGraphicFunction);
