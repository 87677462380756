import styled from 'styled-components';
import { DollarOutlined, HistoryOutlined, TruckOutlined } from '@ant-design/icons';
import { Row } from 'antd';

export const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: calc(100vh - 20rem);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 1.125rem;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.grayBorder};
    border: solid 0.375rem transparent;
    border-radius: 624rem;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.placeHolder};
    border: solid 0.375rem transparent;
    -webkit-border-radius: 624rem;
    border-radius: 624rem;
  }
`;
export const ContentCard = styled.div`
  padding: 1rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const StepConteinerTable = styled.div`
  margin: 1.5rem;
`;

export const Step3InfoConteiner = styled.div`
  background-color: ${({ theme }) =>
    theme.colorsDesignSystem.newTablesColors.colorBackgroundSubItens};
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  border-radius: ${({ theme }) => theme.dimensions.border_radius_medium};
  height: 6.25rem;
  margin-bottom: 0.625rem;
  padding: 1.406rem 0 1.406rem 1.25rem;
  line-height: 0;
`;
export const DestinyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.125rem;
  height: 1.75rem;
  border-radius: ${({ theme }) => theme.dimensions.border_radius_medium};
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.backgroundDestiny};
`;

export const TruckIcon = styled(TruckOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
  font-size: 1rem;
`;
export const Label = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  margin: 0;
  padding: 0;
`;

export const DestinyValue = styled.p`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  margin: 0;
  padding: 0;
`;

export const DemandPeriodStartingPrice = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 1.5rem;
`;
export const DemandPeriodStartingPriceValue = styled.p`
  font-size: 1rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
`;

export const PeriodIcon = styled(HistoryOutlined)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const StartPriceIcon = styled(DollarOutlined)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const ContainerPeriodStartingPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: 0;
`;

export const HeaderTable = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.headerSubTitleTableText};
  margin: 0;
  padding-left: 0.5rem;
`;

export const ValueTable = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.tableValues};
  margin: 0;
  padding-left: 0.5rem;
`;

export const DemandLink = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  margin: 0;
  padding-left: 0.5rem;
  cursor: pointer;
`;

export const CardFooter = styled(Row)`
  align-items: center;
  justify-content: end;
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
  padding: 1rem 2.5rem;
`;

export const StepFooter = styled(Row)`
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
  padding: 1rem 2.5rem;
`;

export const StepButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const RemovedNote = styled.p`
  margin: 0;
`;
