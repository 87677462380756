import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
`;

export const Title = styled.span`
  font-size: 1.25rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};

  b {
    font-weight: 700;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }
`;

export const CommentsTable = styled.div`
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 0.375rem;
`;

export const TableHeader = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem 0.375rem 0 0;
  background-color: ${({ theme }) => theme.colorsDesignSystem.tag_background};
  font-size: 0.875rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  svg {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
    font-size: 1rem;
  }
`;

export const TableContent = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 0 0 0.375rem 0.375rem;
`;

export const CommentContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Author = styled.span<{ author?: boolean }>`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme, author }) =>
    author ? theme.colorsDesignSystem.inputLabelColor : theme.colorsDesignSystem.secondaryGrayText};
`;

export const Comment = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;
