import { useTranslation } from 'react-i18next';
import { months } from '../helpers/utils';

export function useTable() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const getSortOrderTable = (orderMethod) => {
    switch (orderMethod) {
      case 'ASC':
        return 'ascend';

      case 'DESC':
        return 'descend';

      default:
        return orderMethod;
    }
  };

  const getSortTitle = (orderMethod, currentOrder, key, cancelState = true) => {
    if (currentOrder === key && orderMethod === 'ASC') {
      return t('sort.orderByDesc');
    }
    if (currentOrder === key && orderMethod === 'DESC') {
      return cancelState ? t('sort.cancelSort') : t('sort.orderByAsc');
    }

    return t('sort.orderByAsc');
  };

  const canBeEditable = (key, editable, scenarioYear) => {
    const locked = months.some((month) => {
      const getMonthByName = new Date(`${month} 1, ${currentYear}`).getMonth() + 1;
      const isActualOrForwardMonth = getMonthByName >= currentMonth;
      const isForwardForecast = scenarioYear > currentYear;

      return key === month && isForwardForecast ? false : key === month && !isActualOrForwardMonth;
    });

    return editable && !locked;
  };

  return {
    getSortTitle,
    getSortOrderTable,
    canBeEditable,
  };
}
