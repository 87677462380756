import styled from 'styled-components';

interface BarChartType {
  colorBar: string;
}

export const Container = styled.main`
  display: flex;
  margin-top: 2.813rem;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  justify-content: end;
`;

export const BarChartItem = styled.span<BarChartType>`
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  background-color: ${(props) => props.colorBar};
`;

export const LabelsChart = styled.div`
  color: 'rgba(0, 0, 0, 0.60)';
`;

export const TooltipContainer = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.95;
  filter: drop-shadow(0px 4px 4px ${({ theme }) => theme.colors.text_disabled});
`;
