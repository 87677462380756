import styled from 'styled-components';

export const container = styled.div`
  font-size: 0.875rem;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  padding: 0;
  margin: 0;
  margin-right: 1rem;
  gap: 0.75rem;
  cursor: pointer !important;

  p {
    margin: 0;
    cursor: pointer;
  }
`;
