import { t } from 'i18next';
import { useEffect } from 'react';
import { FieldTimeOutlined } from '@ant-design/icons';
import { Flex, Row } from 'antd';
import dayjs from 'dayjs';
import { CardCountDownProps } from './types';
import * as S from './styles';
import { useCardCountDown } from './useCardCountDown';

export default function CardCountDown({
  endDate,
  showEndDate = true,
  showTime = false,
  stop = false,
}: CardCountDownProps) {
  const { UpdateCountDown, timeLeft, urgency } = useCardCountDown();

  useEffect(() => {
    UpdateCountDown(endDate);

    const intervalId = setInterval(() => {
      UpdateCountDown(endDate);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endDate]);
  return (
    <S.Container>
      {showEndDate && (
        <S.ContainerLeft>
          <Flex vertical gap={2} justify="flex-start">
            <span>{t('components.CardCountDown.respond').toUpperCase()}</span>
            <b>{dayjs(endDate).format('DD/MM/YYYY')}</b>
            {showTime && <b>{dayjs(endDate).format('HH:mm:ss')}</b>}
          </Flex>
        </S.ContainerLeft>
      )}
      {stop ? (
        <S.ContainerRight urgency="STOP">
          <Flex gap={8}>
            <FieldTimeOutlined style={{ fontSize: 26 }} />
            <Flex vertical gap={8}>
              <span>{t('components.CardCountDown.timeClose').toUpperCase()}</span>
              <Row>
                <span className="destak">0D 00:00:00</span>
              </Row>
              <span>UTC -3. Brasilia</span>
            </Flex>
          </Flex>
        </S.ContainerRight>
      ) : (
        <S.ContainerRight urgency={urgency.urgency}>
          <Flex gap={8}>
            <FieldTimeOutlined style={{ fontSize: 26 }} />
            <Flex vertical gap={1}>
              <span>{t('components.CardCountDown.timeClose').toUpperCase()}</span>
              <Row>
                <span className="destak">{timeLeft}</span>
              </Row>
              <span>UTC -3. Brasilia</span>
            </Flex>
          </Flex>
        </S.ContainerRight>
      )}
    </S.Container>
  );
}
