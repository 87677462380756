import styled from 'styled-components';
import { Input } from 'antd';

const { Search } = Input;

export const container = styled.div`
  display: 'flex';
  flex-direction: 'column';
  width: 100%;
`;

export const styledInput = styled(Input)`
  border-radius: ${({ theme }) => theme.dimensions.border_radius};
  width: 100%;
  font-weight: 400;

  font-size: 0.75rem;
  height: 2rem;
  color: ${({ theme }) => theme.colors.black};

  &::placeholder {
    color: ${({ theme }) => theme.colors.inputPlaceHolder} !important;
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    margin-top: 0.125rem;
    font-weight: 400;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const SearchBox = styled(Search)`
  /* display: flex;

  border: 0 !important;

  border-radius: ${({ theme }) => theme.dimensions.border_radius};

  border: 1px solid ${({ theme }) => theme.colors.gray_border};

  width: 100%;

  font-weight: 400;

  font-size: 0.75rem;

  height: 2rem;

  color: ${({ theme }) => theme.colors.black}; */

  /* background-color: red; */

  /* padding: 0.5rem 0.75rem; */

  ::placeholder {
    color: ${({ theme }) => theme.colors.inputPlaceHolder} !important;

    font-size: 0.75rem !important;

    font-weight: 400 !important;

    margin-top: 0.125rem;

    font-weight: 400;

    font-size: 1rem;

    color: ${({ theme }) => theme.colors.black};
  }
`;
