import { Row, Divider as AtdDivider } from 'antd';
import styled from 'styled-components';

export const Content = styled(Row)`
  width: 100%;
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
  font-size: 1.25rem;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 1.25rem;
`;

export const SubTitle = styled.span`
  font-size: 1.25rem;
  margin-left: 0.5rem;
`;

export const Divider = styled(AtdDivider)`
  background-color: ${({ theme }) => theme.colorsDesignSystem.text};
  height: 1.25rem;
`;

export const Items = styled.span`
  font-size: 1.25rem;
  b {
    font-weight: 700;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
`;
