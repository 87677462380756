import styled from 'styled-components';

export const EditorContainer = styled.div``;

export const EditorContent = styled.div`
  margin-top: 1rem;
  border-radius: 0.375rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  .editor-input {
    height: 10rem;
    padding: 1rem;
    overflow-y: auto;
  }

  .editor-input:focus-visible {
    outline: none;
    border-radius: 0.375rem;
    border: 1px solid ${({ theme }) => theme.colorsDesignSystem.primary};
  }

  .editor-text-bold {
    font-weight: bold;
  }

  .editor-text-italic {
    font-style: italic;
  }

  .editor-text-underline {
    text-decoration: underline;
  }

  .editor-paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;
  }

  .editor-paragraph:last-child {
    margin-bottom: 0;
  }

  .editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 0.5rem;
  }

  .editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 0.5rem;
  }

  .editor-listitem {
    margin: 0.5rem 1rem;
  }

  .editor-nested-listitem {
    list-style-type: none;
  }
`;

export const EditorPlaceholder = styled.div`
  color: ${({ theme }) => theme.colorsDesignSystem.placeholderText};
  position: absolute;
  top: 13.5rem;
  left: 4.75rem;
  font-size: 0.875rem;
  display: inline-block;
`;
