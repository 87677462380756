import { TransferItem } from 'antd/es/transfer';
import { Transfer, type TableColumnsType, type TransferProps, Table } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { Key } from 'react';
import { Content } from './styles';

export interface TableTransferProps<T> extends TransferProps<T> {
  dataSource: T[];
  leftColumns: TableColumnsType<T>;
  rightColumns: TableColumnsType<T>;
  loading: boolean;
}

export function TableTransfer<T extends TransferItem>({
  leftColumns,
  rightColumns,
  loading,
  ...restProps
}: TableTransferProps<T>) {
  return (
    <Content>
      <Transfer {...restProps}>
        {({
          direction,
          filteredItems,
          onItemSelect,
          onItemSelectAll,
          selectedKeys: listSelectedKeys,
        }) => {
          const columns = direction === 'left' ? leftColumns : rightColumns;

          const rowSelection: TableRowSelection<T> = {
            onChange(selectedRowKeys: Key[]) {
              onItemSelectAll(selectedRowKeys as string[], 'replace');
            },
            selectedRowKeys: listSelectedKeys,
            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
          };

          return (
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredItems}
              pagination={false}
              size="small"
              loading={loading}
              onRow={({ key }) => ({
                onClick: () => {
                  key && onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
            />
          );
        }}
      </Transfer>
    </Content>
  );
}
