import { ConfigProvider, Spin } from 'antd';
import * as S from './styles';
import StyledLabel from '../StyledLabel';
import { StyledSelectProps } from './types';
import arrowDown from '../../../assets/arrow_down.svg';
import { antTheme } from '../../../styles/antTheme';
import theme from '../../../styles/theme';

export default function StyledMultipleSelect({
  label,
  options,
  suffixIcon,
  placeholder,
  onChange,
  styles,
  defaultValue,
  value,
  showSearch,
  disable,
  loading,
  allowClear,
  ...restProps
}: StyledSelectProps) {
  return (
    <ConfigProvider
      theme={{
        ...antTheme,
        components: {
          Select: {
            controlItemBgActive: theme.colorsDesignSystem.multipleSelectOptionActive,
          },
        },
      }}
    >
      <S.Container>
        {label && <StyledLabel title={label || ''} />}
        <S.StyledSelect
          data-testid="styled-miltiple-select"
          showSearch={showSearch}
          defaultValue={defaultValue}
          size="middle"
          suffixIcon={suffixIcon || <img alt="icone" src={arrowDown} />}
          placeholder={<S.placeholder>{placeholder}</S.placeholder>}
          value={value}
          filterOption={(input, option) => {
            return String(option?.value).toUpperCase().includes(input.toUpperCase());
          }}
          onChange={onChange}
          maxTagCount="responsive"
          options={options}
          style={styles}
          disabled={disable}
          mode="multiple"
          loading={loading}
          allowClear={allowClear}
          notFoundContent={loading ? <Spin size="small" /> : null}
          {...restProps}
        />
      </S.Container>
    </ConfigProvider>
  );
}
