import { SearchOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StyledSelect from '../../../../components/Common/StyledSelect';
import { Loading } from '../../../../components/Loading';
import CostTrendChart from './chartCostTrend';
import SupplierPriceChart from './chartSupplierPrice';
import * as S from './styles';
import { ComponenteData } from './types';
import { useSupplierPriceCostTrend } from './useSupplierPricesCostTrend';

export default function SupplierPricesCostTrend({
  isLoading,
  level,
  project,
  date,
}: ComponenteData) {
  const { t } = useTranslation();
  const {
    supplierPriceData,
    costTrendData,
    themeChart,
    labelChart,
    configChart,
    optionsProjectName,
    optionsComponentGroup,
    optionsSpec,
    quarterOptions,
    params,
    fetchSupplierPriceCostTrendData,
    fetchHeaderSupplierPriceCostTrend,
    updateParams,
  } = useSupplierPriceCostTrend({ level, project, date });

  useEffect(() => {
    params.project && fetchSupplierPriceCostTrendData();
  }, [params.project, params.componentGroup, params.specification, params.quarter, project]);

  useEffect(() => {
    fetchHeaderSupplierPriceCostTrend();
  }, [params.project, params.componentGroup, params.specification, params.quarter, project]);

  useEffect(() => {
    updateParams('quarter', `${dayjs(date, 'MM/YYYY').quarter()}`);
  }, [date]);
  return (
    <S.SupplierPricesCostTrendConstainer>
      <S.SubCardHeader>
        <S.SubCardTitleContainer>
          <S.SubCardHeaderText>
            {t('pages.procurement.supplierPriceCostTrend.cardTitle.supplierPrices')}
          </S.SubCardHeaderText>
          <S.SubCardHeaderText>vs</S.SubCardHeaderText>
          <S.SubCardHeaderText>
            {t('pages.procurement.supplierPriceCostTrend.cardTitle.costTrend')}
          </S.SubCardHeaderText>
          <S.SubCardHeaderText>|</S.SubCardHeaderText>
          <S.SubCardHeaderTextBlue>
            {project !== '' ? project : params.project}
          </S.SubCardHeaderTextBlue>
          <S.SubCardHeaderText>|</S.SubCardHeaderText>
          <S.SubCardHeaderTextBlue>{dayjs(date, 'MM/YYYY').format('YY')}</S.SubCardHeaderTextBlue>
        </S.SubCardTitleContainer>
        <S.CardsFiltersContainer>
          <StyledSelect
            styles={{ minWidth: '8.625rem', textAlign: 'left' }}
            placeholder={t('pages.procurement.supplierPriceCostTrend.placeHolder.projectName')}
            showSearch
            disable={project !== ''}
            options={optionsProjectName}
            value={project || params.project || null}
            onChange={(value: string) => {
              updateParams('project', value || project || '');
            }}
            suffixIcon={
              <S.SearchButton>
                <SearchOutlined className="icon" />
              </S.SearchButton>
            }
          />
          <StyledSelect
            styles={{ minWidth: '8.625rem', textAlign: 'left' }}
            showSearch
            options={optionsComponentGroup}
            value={params.componentGroup || null}
            onChange={(value: string) => {
              updateParams('componentGroup', value);
            }}
            suffixIcon={
              <S.SearchButton>
                <SearchOutlined className="icon" />
              </S.SearchButton>
            }
          />
          <StyledSelect
            styles={{ minWidth: '8.625rem', textAlign: 'left' }}
            placeholder={t('pages.procurement.supplierPriceCostTrend.placeHolder.specification')}
            showSearch
            options={optionsSpec}
            value={params.specification || null}
            onChange={(value: string) => {
              updateParams('specification', value);
            }}
            suffixIcon={
              <S.SearchButton>
                <SearchOutlined className="icon" />
              </S.SearchButton>
            }
          />
          <StyledSelect
            styles={{ minWidth: '6.25rem', textAlign: 'left' }}
            options={quarterOptions}
            value={params.quarter}
            onChange={(value: string) => {
              updateParams('quarter', value);
            }}
          />
        </S.CardsFiltersContainer>
      </S.SubCardHeader>
      <S.BodyGraphContainer>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <S.GraphContainer>
              <S.TitleGraphContainer>
                <S.TitleGraph>
                  {t('pages.procurement.supplierPriceCostTrend.cardTitle.supplierPrices')}
                </S.TitleGraph>
                <S.TitleGraph>|</S.TitleGraph>
                <S.TitleGraph>USD</S.TitleGraph>
              </S.TitleGraphContainer>
              <S.SubTitleGraphContainer>
                {params.componentGroup && (
                  <S.SubTitleGraph>{params.componentGroup}</S.SubTitleGraph>
                )}
                {params.componentGroup && params.specification && (
                  <S.SubTitleGraph>|</S.SubTitleGraph>
                )}
                {params.specification && <S.SubTitleGraph>{params.specification}</S.SubTitleGraph>}
              </S.SubTitleGraphContainer>
              {supplierPriceData?.length > 0 ? (
                <SupplierPriceChart data={supplierPriceData} />
              ) : (
                <S.EmptyContainer>
                  <Empty />
                </S.EmptyContainer>
              )}
            </S.GraphContainer>
            <S.GraphContainer>
              <S.TitleGraphContainer>
                <S.TitleGraph>
                  {t('pages.procurement.supplierPriceCostTrend.cardTitle.costTrend')}
                </S.TitleGraph>
                <S.TitleGraph>|</S.TitleGraph>
                <S.TitleGraph>USD</S.TitleGraph>
              </S.TitleGraphContainer>
              <S.SubTitleGraphContainer>
                {params.componentGroup && (
                  <S.SubTitleGraph>{params.componentGroup}</S.SubTitleGraph>
                )}
                {params.componentGroup && params.specification && (
                  <S.SubTitleGraph>|</S.SubTitleGraph>
                )}
                {params.specification && <S.SubTitleGraph>{params.specification}</S.SubTitleGraph>}
              </S.SubTitleGraphContainer>
              {costTrendData.length > 0 ? (
                <CostTrendChart
                  chartData={costTrendData}
                  themeGraphic={themeChart}
                  labels={labelChart}
                  config={configChart}
                />
              ) : (
                <S.EmptyContainer>
                  <Empty />
                </S.EmptyContainer>
              )}
            </S.GraphContainer>
          </>
        )}
      </S.BodyGraphContainer>
    </S.SupplierPricesCostTrendConstainer>
  );
}
