import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { IStyledPageHeader } from './types';

export function StyledHeader({ title, items, subtitle, actions }: IStyledPageHeader) {
  const { t } = useTranslation();
  return (
    <S.Content>
      <Row align="middle">
        <S.Title>{title} </S.Title>
        {subtitle && <S.SubTitle>/ {subtitle}</S.SubTitle>}
        {items && (
          <>
            <S.Divider type="vertical" />
            <S.Items>
              <b>{items.toLocaleString(t('common.localeMoney'))}</b> {t('common.items')}
            </S.Items>
          </>
        )}
      </Row>
      {actions}
    </S.Content>
  );
}
