/* eslint-disable no-underscore-dangle */
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode } from '@lexical/rich-text';
import { TextNode } from 'lexical';
import { oneAlert } from '../../../helpers/nUtils';
import { CharacterLimit } from './Plugins/CharacterLimit';
import { ClearEditor } from './Plugins/ClearEditor';
import { ExtendedTextNode } from './Plugins/ExtentedTextNode';
import { LexicalToHTML } from './Plugins/LexicalToHTML';
import { LoadState } from './Plugins/LoadState';
import { Toolbar } from './Plugins/Toolbar';
import * as S from './styles';
import { IStyledEditor } from './types';

export function StyledEditor({
  editorName,
  placeholder,
  maxLength,
  value,
  onChangeHTML,
  onChangeText,
  countCharacters,
}: IStyledEditor) {
  const editorConfig: InitialConfigType = {
    namespace: editorName,
    nodes: [
      ExtendedTextNode,
      {
        replace: TextNode,
        with: (node: TextNode) => new ExtendedTextNode(node.__text),
        withKlass: ExtendedTextNode,
      },
      HeadingNode,
      ListNode,
      ListItemNode,
    ],
    onError(error: Error) {
      oneAlert({ type: 'error', message: String(error.cause) || error.message });
    },
    theme: {
      list: {
        listitem: 'editor-listitem',
        nested: {
          listitem: 'editor-nested-listitem',
        },
        ol: 'editor-list-ol',
        ul: 'editor-list-ul',
      },
      ltr: 'ltr',
      paragraph: 'editor-paragraph',
      rtl: 'rtl',
      text: {
        bold: 'editor-text-bold',
        italic: 'editor-text-italic',
        underline: 'editor-text-underline',
      },
    },
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <S.EditorContainer className="editor-container">
        <Toolbar />
        <ListPlugin />
        <S.EditorContent>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className="editor-input"
                aria-placeholder={placeholder}
                placeholder={<S.EditorPlaceholder>{placeholder}</S.EditorPlaceholder>}
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          {value && <LoadState value={value} />}
          <LexicalToHTML
            onChange={(editorState, html) => {
              onChangeHTML && onChangeHTML(html);

              onChangeText && onChangeText(JSON.stringify(editorState));
            }}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
        </S.EditorContent>
        <CharacterLimit maxCharacters={maxLength} countCharacters={countCharacters} />
        <ClearEditor />
      </S.EditorContainer>
    </LexicalComposer>
  );
}
