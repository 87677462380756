import React from 'react';
import { Space, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { getTagColor, renderPrice, months } from '../../../helpers/utils';
import * as S from './styles';
import * as SC from '../../../styles/common';
import { ResultNoScenarios } from '../../ResultNoScenarios';

export function TablePF({ dataSource, showGap = false }) {
  const { t } = useTranslation();
  const noData = !dataSource?.length;

  const priceFactorCells = (row, local) => {
    return months.map((month) => (
      <React.Fragment key={month}>
        <Tooltip title={t('pages.prices.fields.price')}>
          <S.PriceCell>{row[month]?.price?.value}</S.PriceCell>
        </Tooltip>
        <Tooltip title={t('pages.factors.fields.factor')}>
          <S.PriceCell bdRight local={!showGap && local}>
            {row[month]?.factor?.value}
          </S.PriceCell>
        </Tooltip>
      </React.Fragment>
    ));
  };

  const totalCells = (row) => {
    return months.map((month) => (
      <S.TotalCell key={month} colSpan={2} align="center" bdRight>
        <span>
          <Tooltip title="Total">{renderPrice(row[month].total)}</Tooltip>
          <br />
          {showGap && (
            <Tooltip title="Gap" placement="bottom">
              <S.Gap>
                ($
                {row[month].gap >= 0 ? (
                  <SC.SpanRed> {row[month].gap}</SC.SpanRed>
                ) : (
                  <SC.SpanBlue> {row[month].gap}</SC.SpanBlue>
                )}
                )
              </S.Gap>
            </Tooltip>
          )}
        </span>
      </S.TotalCell>
    ));
  };

  return (
    <S.ContainerTable>
      <S.Table>
        <tbody>
          <S.TableRow>
            <S.TableHeadCell rowSpan={2} noBorderBottom>
              {t('pages.prices.fields.supplier')}
            </S.TableHeadCell>

            {months.map((month) => (
              <S.TableHeadCell colSpan={2} align="center" key={month}>
                {t(`common.months.${month}`)}
              </S.TableHeadCell>
            ))}
          </S.TableRow>

          <S.TableRow>
            {months.map((month) => (
              <React.Fragment key={month}>
                <S.TableHeadCellSecondary>$</S.TableHeadCellSecondary>
                <S.TableHeadCellSecondary>F</S.TableHeadCellSecondary>
              </React.Fragment>
            ))}
          </S.TableRow>
          <S.SpaceRow />
          {noData && (
            <S.NoData>
              <S.TableCell colSpan={26} align="center">
                <ResultNoScenarios
                  title={t('common.results.noDataTitle')}
                  subTitle={t('common.results.noDataSubtitle')}
                />
              </S.TableCell>
            </S.NoData>
          )}
          {dataSource.map((spec) => {
            return (
              <React.Fragment key={spec.id}>
                <S.TableRow>
                  <S.SupplierCell colSpan={25}>
                    <Space>
                      <span>{t('pages.factors.fields.spec')}:</span>
                      {spec.spec}
                    </Space>
                  </S.SupplierCell>
                </S.TableRow>
                {spec.children.map((price) => (
                  <React.Fragment key={price.id}>
                    <S.TableRow>
                      <S.SupplierCell colSpan={25} type="partNumber">
                        <Space>
                          <span>{t('pages.prices.fields.partNumber')}:</span>
                          {price.partNumber}
                        </Space>
                      </S.SupplierCell>
                    </S.TableRow>

                    <S.TableRow>
                      <S.TableCell rowSpan={2}>
                        {price.supplier.shortName}
                        <br />
                        <Tag color={getTagColor(price.supplier.place === 'PPB')}>
                          {price.supplier.place}
                        </Tag>
                      </S.TableCell>
                      {priceFactorCells(price, price.supplier.place)}
                    </S.TableRow>
                    <S.TableRow>{totalCells(price)}</S.TableRow>
                    <S.SpaceRow />
                  </React.Fragment>
                ))}
                <S.SpaceRow />
              </React.Fragment>
            );
          })}
        </tbody>
      </S.Table>
    </S.ContainerTable>
  );
}
