import styled from 'styled-components';
import theme from '../../../../../styles/theme';

interface BarChartType {
  colorBar: string;
  size: 'SMALL' | 'REGULAR';
}

interface LabelToolTipeWeight {
  weight: 'BOLD' | 'REGULAR';
}

export const TooltipContainer = styled.main`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colorsDesignSystem.backgroundTooltipChart};
  border: 0.063rem solid ${theme.colorsDesignSystem.backgroundTooltipChart};
  box-shadow: 0 0.25rem 0.625rem ${theme.colorsDesignSystem.boxshadowTooltipChart};
  width: 9.563rem;
  padding: 0.75rem;
  justify-content: space-between;
  border-radius: 0.313rem;
  :hover {
    background-color: none;
  }
`;

export const LabelsTooltip = styled.span<LabelToolTipeWeight>`
  font-size: 0.75rem;
  color: ${theme.colorsDesignSystem.text};
  font-weight: ${(props) => (props.weight === 'REGULAR' ? '400' : '700')};
`;
export const BarChartItemIndicator = styled.span<BarChartType>`
  height: ${(props) => (props.size === 'REGULAR' ? '0.75rem' : '0.313rem')};
  width: ${(props) => (props.size === 'REGULAR' ? '0.75rem' : '0.313rem')};
  border-radius: 50%;
  background-color: ${(props) => props.colorBar};
`;
export const BlendedIndication = styled.p`
  font-size: 0.7rem;
  font-weight: 700;
  color: ${theme.colorsDesignSystem.grayAcer};
`;
