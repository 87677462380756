import styled from 'styled-components';
import theme from '../../../styles/theme';

interface BarChartType {
  colorBar: string;
  size: 'SMALL' | 'REGULAR';
}
interface LabelToolTipeWeight {
  weight: 'BOLD' | 'REGULAR';
}

export const container = styled.main`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colorsDesignSystem.border};
  min-width: 25rem;
  flex: 1;
  height: 22.563rem;
  border-radius: ${theme.dimensions.border_radius_large};
  padding: 1.25rem;
`;

export const TitleCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const PrimaryTitle = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${theme.colorsDesignSystem.text};
`;

export const SecondaryTitle = styled.span`
  font-weight: bold;
  color: ${theme.colorsDesignSystem.blue};
  font-size: ${theme.dimensions.font_regular};
`;

// estilização da labels customizadas do gráfico
export const LegendContainer = styled.main`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  justify-content: end;
`;

export const LabelsChart = styled.div`
  color: 'rgba(0, 0, 0, 0.60)';
`;

export const BarChartItemIndicator = styled.span<BarChartType>`
  height: ${(props) => (props.size === 'REGULAR' ? '0.75rem' : '0.313rem')};
  width: ${(props) => (props.size === 'REGULAR' ? '0.75rem' : '0.313rem')};
  border-radius: 50%;
  background-color: ${(props) => props.colorBar};
`;

//estilização do Tooltip
export const TooltipContainer = styled.main`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  width: 9.563rem;
  padding: 0.75rem;
  height: 3.688rem;
  justify-content: space-between;
`;

export const LabelsTooltip = styled.span<LabelToolTipeWeight>`
  font-size: 0.75rem;
  color: ${theme.colorsDesignSystem.text};
  font-weight: ${(props) => (props.weight === 'REGULAR' ? '400' : '700')};
`;
