import { Input, Form } from 'antd';
import React from 'react';

function getRule(inputType, required, limit, min = 0) {
  switch (inputType) {
    case 'numberWithZero':
      return {
        pattern: /^[0-9]*$/,
        required,
      };
    case 'number':
      return {
        pattern: /^[1-9][0-9]*$/,
        required,
      };
    case 'money':
      return {
        pattern: /^[0-9]{1,8}[.]*[0-9]{0,5}$/,
        required,
      };
    case 'factor':
      return {
        pattern: /^[0-9]{1,1}[.]*[0-9]{0,5}$/,
        required,
      };
    case 'stringOnly':
      return {
        type: 'string',
        max: limit || 100,
        required,
      };
    case 'stringAndSpecial':
      return {
        type: 'string',
        max: limit || 100,
        min,
        pattern: /.+/,
        required,
      };
    default:
      return {
        pattern: /[\w]+[\s\w]?$/,
        type: 'string',
        max: limit || 100,
        required,
      };
  }
}

export function EditableCell({
  editing,
  editable,
  dataIndex,
  title,
  inputType,
  required = true,
  newInputNode,
  record,
  index,
  children,
  limit,
  min = 0,
  ...restProps
}) {
  let inputNode = React.FC;

  switch (inputType) {
    case 'money':
      inputNode = <Input maxLength={20} />;
      break;
    case 'factor':
      inputNode = <Input maxLength={10} />;
      break;
    default:
      inputNode = newInputNode || <Input />;
  }

  return (
    <td {...restProps}>
      {editing && editable ? (
        <Form.Item
          name={dataIndex}
          messageVariables={{ label: title }}
          style={{
            margin: 0,
          }}
          rules={[getRule(inputType, required, limit, min)]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}
