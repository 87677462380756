import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getErrorMessage, oneAlert } from '../../../helpers/utils';
import { api } from '../../../services/api';

export function useScenariosSettings({ simulationModal }) {
  const history = useHistory();
  const { id: paramId, year: paramYear } = useParams();
  const scenarioId = simulationModal ? simulationModal.id : paramId;
  const scenarioYear = simulationModal ? simulationModal.year : paramYear;

  const { t } = useTranslation();
  const initialForm = {
    componentPurchaseMax: false,
    componentPurchaseMin: false,
    componentPurchaseNotNational: false,
    componentWithoutPrice: false,
    componentWithQualificationOngoing: false,
  };

  const [formValue, setFormValue] = useState(initialForm);
  const [isLoading, setIsLoading] = useState(true);
  const [scenarioData, setScenarioData] = useState(null);

  const fetchSettings = async () => {
    try {
      const { data: content } = await api.get(`scenarios/${scenarioId}/optimizationSetup`);

      setFormValue({
        componentPurchaseMax: content.componentPurchaseMax,
        componentPurchaseMin: content.componentPurchaseMin,
        componentPurchaseNotNational: content.componentPurchaseNotNational,
        componentWithoutPrice: content.componentWithoutPrice,
        componentWithQualificationOngoing: content.componentWithQualificationOngoing,
      });
    } catch (error) {
      console.log('Error Fetch Settings', error);
    }
    setIsLoading(false);
  };

  const fetchScenarios = async () => {
    setIsLoading(true);
    try {
      const { data: content } = await api.get(`scenarios/lite?targetYear=${scenarioYear}`);
      const scenario = content.find((item) => item.id === scenarioId);

      if (scenario) {
        setScenarioData(scenario);
        await fetchSettings();
      }
    } catch (error) {
      console.log('Error Fetch Scenario', error);
    }
    setIsLoading(false);
  };

  const onFinish = (values) => {
    console.log('values=>', values);
  };

  const onCancel = () => {
    history.goBack();
  };

  const handleChangeSettings = async (type) => {
    setIsLoading(true);
    try {
      const settings = {
        ...formValue,
        [type]: !formValue[type],
      };

      const response = await api.put(`scenarios/${scenarioId}/optimizationSetup`, settings);
      if (response.status !== 201) throw Error();

      setFormValue(settings);
      oneAlert('success', t('toast.successOnSave'));

      if (simulationModal && type === 'componentWithoutPrice') {
        await simulationModal.fetchSimulationParams();
        simulationModal.closeModalSettings();
      }
      if (simulationModal && type === 'componentWithQualificationOngoing') {
        simulationModal.closeModalSettings();
        await simulationModal.fetchSimulationParams();
      }
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnSave'));
    }
    setIsLoading(false);
  };

  return {
    fetchScenarios,
    scenarioData,
    isLoading,
    formValue,
    setFormValue,
    handleChangeSettings,
    onCancel,
    onFinish,
  };
}
