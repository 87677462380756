import { Col, Row, Table } from 'antd';
import styled from 'styled-components';

export const SearchFilter = styled(Row)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  .col-filters {
    display: flex;
    justify-content: flex-end;
  }
`;

export const SpotTable = styled(Table)`
  .ant-table {
    background-color: transparent;
  }

  .ant-table-content {
    table {
      border-spacing: 0 4px;

      thead,
      th {
        font-weight: 500;
        padding: 8px 16px;

        &::before {
          display: none;
        }
      }

      tbody > tr,
      tbody > tr.data-row-editing {
        background-color: ${({ theme }) => theme.colors.shape};
        &:hover > td {
          background-color: ${({ theme }) => theme.colors.shape};
        }

        td {
          color: ${({ theme }) => theme.colors.text_dark};
          border-bottom: 1px solid ${({ theme }) => theme.colors.border};
          border-top: 1px solid ${({ theme }) => theme.colors.border};
          padding: 14px;
          font-weight: 500;

          &:first-child {
            border-bottom-left-radius: 8px;
            border-left: 1px solid ${({ theme }) => theme.colors.border}!important;
            border-top-left-radius: 8px;
            padding: 14px 40px;
          }

          &:last-child {
            border-bottom-right-radius: 8px;
            border-top-right-radius: 8px;
          }

          .ant-form-item-control-input-content {
            color: #000;
          }
        }
      }

      tr.ant-table-row-level-0 {
        background-color: ${({ theme }) => theme.colors.tableDark};

        td,
        td.td.ant-table-column-sort {
          background-color: ${({ theme }) => theme.colors.tableDark};
          color: ${({ theme }) => theme.colors.shape};
          font-weight: 500;
        }

        &:hover > td,
        td.td.ant-table-column-sort {
          background-color: ${({ theme }) => theme.colors.tableDark};
        }

        .ant-form-item-control-input-content {
          color: #fff;
        }
      }
    }

    .ant-table-row-level-0 {
      td:nth-child(1) {
        .ant-table-row-expand-icon {
          float: left;
        }
      }
    }

    .ant-table-row-level-1 {
      td {
        font-weight: 500;
      }

      td:nth-child(1) {
        .ant-table-row-expand-icon {
          float: right;
        }
      }
    }
  }
`;

export const SpotTotalScore = styled(Col)`
  font-weight: bold;
  span {
    color: ${({ theme }) => theme.colors.red};
  }
`;
