/* eslint-disable react/button-has-type */
import StyledLabel from '../StyledLabel';
import * as S from './styles';
import { StyledInputProps } from './types';

export default function StyledInput({
  label,
  placeholder,
  prefix,
  suffix,
  styles,
  disabled,
  defaultValue,
  value,
  readonly,
  addonAfter,
  autoComplete,
  ...restProps
}: StyledInputProps) {
  return (
    <S.container>
      <StyledLabel title={label || ''} />
      <S.styledInput
        readOnly={readonly}
        defaultValue={defaultValue}
        prefix={prefix}
        suffix={suffix}
        disabled={disabled}
        placeholder={placeholder}
        addonAfter={addonAfter}
        style={styles}
        value={value}
        autoComplete={autoComplete}
        {...restProps}
      />
    </S.container>
  );
}
