import { Row, Col } from 'antd';
import styled from 'styled-components';
import { StyledStep } from '../../components/Common/StyledSteps/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  margin-bottom: 2.25rem;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  height: 100%;
`;

export const StepBox = styled(StyledStep)`
  height: max-content;
  width: 20rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  padding: 1.5rem;
  border: ${({ theme }) => `1px solid ${theme.colorsDesignSystem.border}`};
  border-radius: 0.378rem;
`;

export const ContentSteps = styled(Col)`
  width: calc(100vw - 21.5rem);
  height: max-content;
  height: 100%;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border: ${({ theme }) => `1px solid ${theme.colorsDesignSystem.border}`};
  border-radius: 0.378rem;
`;

export const ContentForm = styled.div`
  padding: 1rem 2.5rem;
`;

export const StepTitle = styled(Row)`
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
  padding: 1rem 2.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const StepFooter = styled(Row)`
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
  padding: 1rem 2.5rem;
`;
