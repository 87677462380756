import { useState } from 'react';

export function useModal(): [boolean, () => void, () => void, () => void] {
  const [visible, setVisible] = useState<boolean>(false);

  function openModal(): void {
    setVisible(true);
  }

  function closeModal(): void {
    localStorage.removeItem('@scenarios-modal');
    setVisible(false);
  }

  function toggleModal(): void {
    setVisible((state: boolean) => !state);
  }

  return [visible, openModal, closeModal, toggleModal];
}
