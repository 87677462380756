import { Form, Input, InputNumber, message } from 'antd';
import { EditableCellProps } from './types';

const EditableTableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  maxWidth,
  messageRequired,
  required,
  typeRule,
  ...restProps
}) => {
  const inputNode = <Input style={{ maxWidth }} />;

  function getRule(): any {
    switch (inputType) {
      case 'numberWithZero':
        return {
          pattern: /^[0-9]*$/,
          required,
          message: `${messageRequired}   ${title}`,
        };
      case 'numberWithoutZero':
        return {
          pattern: /^(0*[1-9]\d*)$/,
          required,
          message: `${messageRequired}   ${title}`,
        };
      case 'number':
        return {
          pattern: /^[1-9][0-9]*$/,
          required,
          message: `${messageRequired}   ${title}`,
        };
      case 'money':
        return {
          pattern: /^[0-9]{1,8}[.]*[0-9]{0,5}$/,
          required,
          message: `${messageRequired}   ${title}`,
        };

      case 'stringOnly':
        return {
          type: 'string',
          required,
          message: `${messageRequired}   ${title}`,
        };
      case 'stringAndSpecial':
        return {
          type: 'string',
          message: `${messageRequired}   ${title}`,
          pattern: /.+/,
          required,
        };
      default:
        return {
          pattern: /[\w]+[\s\w]?$/,
          type: 'string',
          message: `${messageRequired}   ${title}`,
          required,
        };
    }
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={[getRule()]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableTableCell;
