import styled from 'styled-components';

export const container = styled.main`
  font-size: 0.875rem;
  font-weight: normal;
  min-width: 23.063rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  /* align-content: center; */
  gap: 0.75rem;
  cursor: pointer !important;

  p {
    margin: 0;
    cursor: pointer;
  }

  div {
    height: 1rem !important;
    width: 1rem !important;
    min-width: 0;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colorsDesignSystem.primary};
    color: white;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// export const containerBadge = styled.div`
//   height: 1rem !important;
//   width: 1rem !important;
//   border-radius: 50%;
//   background-color: ${({ theme }) => theme.colorsDesignSystem.primary};
//   color: white;
//   font-size: 0.75rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;
