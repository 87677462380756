import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Popconfirm, Row, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import EditableTableCell from '../../components/Common/EditableTableCell';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import StyledIndicationTag from '../../components/IndicationTag';
import SimpleTable from '../../components/Tables/SimpleTable';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import { getURI, oneAlert } from '../../helpers/utils';
import { api } from '../../services/api';
import theme from '../../styles/theme';
import ExpandableTable from '../ComponentsPrices/ExpandableTable';
import { ModalContentProps } from './ContentModalShareProportion/types';
import * as S from './styles';
import {
  ParamsPropsShareProportion,
  PayloadSendEditedRecord,
  ShareProportionData,
  ShareProportionDataItens,
  ShareProportionDataItensReceivedBack,
} from './types';
import { useCmsContext } from '../../context/CmsContext';

export function useShareProportion() {
  const { t } = useTranslation();
  const { handleGetCms, handleSelectDefaultCm } = useCmsContext();
  const CMs = handleGetCms();
  const [isLoading, setIsLoading] = useState(false);
  const initialParams: ParamsPropsShareProportion = {
    cm: handleSelectDefaultCm('Foxconn'),
    category: null,
    project: null,
    partNumber: null,
    spec: null,
    supplier: null,
    source: null,
    month: dayjs().subtract(1, 'day'),
    page: 0,
    limit: 0,
  };
  const [params, setParams] = useState<ParamsPropsShareProportion>(initialParams);
  const [openCard, setOpenCard] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [categories, setCategories] = useState<Array<StyledSelectOptions>>([]);
  const [projects, setProjects] = useState<Array<StyledSelectOptions>>([]);
  const [specifications, setSpecifications] = useState<Array<StyledSelectOptions>>([]);
  const [partNumbers, setPartNumbers] = useState<Array<StyledSelectOptions>>([]);
  const [suppliers, setSuppliers] = useState<Array<StyledSelectOptions>>([]);
  const ExpandCategories: any = {};
  const [form] = Form.useForm();
  const [keyToEditRecord, setKeyToEditRecord] = useState('');
  const [expandableViewStatus, setExpandableViewStatus] = useState(ExpandCategories);
  const [shareProportionComponents, setShareProportionComponents] = useState<
    Array<ShareProportionData>
  >([]);

  const Sources = [
    { value: 'FOB', label: 'FOB' },
    { value: 'BR', label: 'BR' },
  ];
  const products = [
    { value: 'Notebook', label: 'Notebook' },
    { value: 'Desktop', label: 'Desktop' },
  ];

  const [modalProps, setModalProps] = useState<ModalContentProps>();

  const isEditing = (record: ShareProportionDataItens) => `${record.id}` === keyToEditRecord;

  const columns: ColumnsType<ShareProportionDataItens> = [
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.specification')}</strong>;
      },
      dataIndex: 'itemType',
      key: 'itemType',
      align: 'center',
      render: (_, record) => {
        return <span>{record.itemType || '-'}</span>;
      },
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.partNumber')}</strong>;
      },
      dataIndex: 'acerPn',
      key: 'acerPn',
      align: 'center',
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.supplier')}</strong>;
      },
      dataIndex: 'supplier',
      key: 'supplier',
      align: 'center',
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.source')}</strong>;
      },
      dataIndex: 'source',
      key: 'source',
      align: 'center',
      render: (_, record) => {
        return <StyledIndicationTag text={record.source || ''} style={{ marginInlineEnd: 0 }} />;
      },
    },
  ];

  const handleConvertStringToSelectOptions = (
    values: Array<string>
  ): Array<StyledSelectOptions> => {
    return values.map((value) => {
      return {
        label: value,
        value,
      };
    });
  };

  const handleFetchHeaders = async () => {
    try {
      const { data, status } = await api.get(
        getURI('/shareProportion/dynamicfilters', {
          ...params,
          month: dayjs(params.month).format('MMMM/YYYY'),
        })
      );
      if (status === 200) {
        if (data) {
          setPartNumbers(handleConvertStringToSelectOptions(data.partNumber || []));
          setCategories(handleConvertStringToSelectOptions(data.category || []));
          setSpecifications(handleConvertStringToSelectOptions(data.specification || []));
          setSuppliers(handleConvertStringToSelectOptions(data.supplier || []));
          setProjects(handleConvertStringToSelectOptions(data.project || []));
        }
        return;
      }
      if (status === 204) {
        oneAlert('info', t('pages.shareProportion.noContent'));
        setPartNumbers(handleConvertStringToSelectOptions([]));
        setCategories(handleConvertStringToSelectOptions([]));
        setSpecifications(handleConvertStringToSelectOptions([]));
        setSuppliers(handleConvertStringToSelectOptions([]));
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const hasParams = () => {
    return (
      params.category ||
      params.project ||
      params.partNumber ||
      params.spec ||
      params.supplier ||
      params.source
    );
  };

  const handleFetchComponents = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(
        getURI('/shareProportion/findAllByFilters', {
          ...params,
          month: dayjs(params.month).format('MMMM/YYYY'),
        })
      );
      if (status === 200) {
        setShareProportionComponents(data || []);
        if (hasParams()) {
          setExpandableViewStatus({ [data[0].category]: true });
        } else setExpandableViewStatus({});
      }
      status === 204 && setShareProportionComponents([]);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
      setShareProportionComponents([]);
    } finally {
      setKeyToEditRecord('');
      setIsLoading(false);
    }
  };

  const handleUpdateFilters = (field: string, value: string | null) => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        [field]: value,
      };
    });
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {params.project && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.project')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.project}
            closeble
            onClose={() => {
              handleUpdateFilters('project', null);
            }}
          />
        )}
        {/* {params.cm && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.cm')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.cm}
            closeble
            onClose={() => {
              handleUpdateFilters('cm', '');
            }}
          />
        )} */}
        {params.category && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.category')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.category}
            closeble
            onClose={() => {
              handleUpdateFilters('category', null);
            }}
          />
        )}
        {params.spec && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.specification')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.spec}
            closeble
            onClose={() => {
              handleUpdateFilters('spec', null);
            }}
          />
        )}
        {params.partNumber && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.partNumber')}
            closeicon={<CloseOutlined />}
            title={params.partNumber}
            closeble
            onClose={() => {
              handleUpdateFilters('partNumber', null);
            }}
          />
        )}
        {params.source && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.source')}
            closeicon={<CloseOutlined />}
            title={params.source}
            closeble
            onClose={() => {
              handleUpdateFilters('source', null);
            }}
          />
        )}
        {params.supplier && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.supplier')}
            closeicon={<CloseOutlined />}
            title={params.supplier}
            closeble
            onClose={() => {
              handleUpdateFilters('supplier', null);
            }}
          />
        )}
      </>
    );
  };

  const clearAllFilters = () => {
    setParams(initialParams);
  };

  const handleRenderClearAllFilters = () => {
    if (
      params.project ||
      // params.cm ||
      params.category ||
      params.spec ||
      params.partNumber ||
      params.source ||
      params.supplier
    ) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  const handleUpdateExpandableViews = (value: string) => {
    setExpandableViewStatus((oldParams: any) => {
      return {
        ...oldParams,
        [value]: !expandableViewStatus[value],
      };
    });
  };

  const handleRenderSourceTag = (items: Array<ShareProportionDataItens>, type: 'FOB' | 'BR') => {
    if (items.some((item) => item.source === type)) {
      return <StyledIndicationTag text={type} />;
    }
  };

  // const disabledDate = (current: any) => {
  //   return current && current >= dayjs().subtract(1, 'day');
  // };

  const prepareToEdit = (record: ShareProportionDataItens) => {
    form.setFieldsValue({ ...record });
    setKeyToEditRecord(record.id);
  };

  const handleConvertDataToPayloadToSend = (): Array<PayloadSendEditedRecord> => {
    try {
      const valueForm = form.getFieldsValue();
      const payload: Array<PayloadSendEditedRecord> = [];
      Object.keys(valueForm)
        .filter((key) => valueForm[key] !== null && valueForm[key] !== '')
        .forEach((key) => {
          payload.push({
            porcentage: Number(valueForm[key].replace('%', '')),
            projectName: key,
          });
        });

      return payload;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  };

  const SendEditedRecord = async (record: ShareProportionDataItens) => {
    try {
      const { status } = await api.put(
        `shareProportion/${record.id}`,
        handleConvertDataToPayloadToSend()
      );
      if (status === 200) {
        oneAlert('success', t('toast.successOnSave'));
        handleFetchComponents();
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnSave'));
      setIsLoading(false);
    } finally {
      setKeyToEditRecord('');
    }
  };

  const handleExtractProjectsArray = (
    valuesReceived: Array<ShareProportionDataItensReceivedBack>
  ): Array<ShareProportionDataItens> => {
    const valuesConverted: Array<ShareProportionDataItens> = [];

    try {
      valuesReceived.forEach((value) => {
        const newValue: ShareProportionDataItens = {
          id: value.id,
          itemType: value.itemType,
          acerPn: value.acerPn,
          supplier: value.supplier,
          category: value.category,
          source: value.source,
        };

        if (value.proportionProjectFoxconnList) {
          value.proportionProjectFoxconnList.forEach((project) => {
            const key = project.projectName;
            newValue[key] = project.shareProportionValue;
          });
        }

        if (value.proportionProjectCompalList) {
          value.proportionProjectCompalList.forEach((project) => {
            const key = project.projectName;
            newValue[key] = project.shareProportionValue;
          });
        }

        valuesConverted.push(newValue);
      });

      return valuesConverted;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleRenderProjectCellWithLink = (
    record: ShareProportionDataItens,
    project: string,
    value: string
  ): ReactNode => {
    if (params.cm === 'Compal') {
      return (
        <S.ContainerNoContent>
          <span>{value || '-'}</span>
        </S.ContainerNoContent>
      );
    }

    return (
      <S.LabelButton
        onClick={() => {
          setModalProps({
            cm: params.cm || '',
            month: params.month,
            projectName: project,
            specification: record.itemType,
            supplier: record.supplier,
            onCancel: () => {
              setVisibleModal(false);
            },
          });
          setVisibleModal(true);
        }}
      >
        {value || '-'}
      </S.LabelButton>
    );
  };

  const handleConvertProjectsToColumns = (component: Array<ShareProportionDataItens>) => {
    const columnsConverted: ColumnsType<ShareProportionDataItens> = [];

    try {
      if (!component.length) {
        return [];
      }

      Object.keys(component[0])
        .filter(
          (key) =>
            key !== 'acerPn' &&
            key !== 'source' &&
            key !== 'itemType' &&
            key !== 'supplier' &&
            key !== 'id' &&
            key !== 'category'
        )
        .forEach((key) => {
          columnsConverted.push({
            title: () => {
              return <strong>{key}</strong>;
            },
            dataIndex: `${key}`,
            key: `${key}`,

            align: 'center',
            render: (value, record) => {
              return handleRenderProjectCellWithLink(record, key, value);
            },
            onCell: (record) => ({
              record,
              inputType: 'numberWithZero',
              messageRequired: t('pages.inventory.mandatoryField'),
              require: false,
              dataIndex: `${key}`,
              editing: isEditing(record),
              title: `${key}`,
            }),
          });
        });

      columnsConverted.push({
        title: '',
        key: 'action',
        align: 'center',
        width: 40,
        render: (_, record) => {
          const editable = isEditing(record);
          const actions = [
            {
              key: '1',
              label: (
                <S.EditButton
                  icon={<EditOutlined />}
                  type="link"
                  onClick={() => {
                    prepareToEdit(record);
                  }}
                  data-cy="edit"
                >
                  {t('common.edit')}
                </S.EditButton>
              ),
            },
          ];

          return editable ? (
            <Space direction="horizontal" size={12}>
              <Button
                type="primary"
                style={{ borderRadius: '8px' }}
                onClick={() => {
                  SendEditedRecord(record);
                }}
                data-cy="save"
              >
                {t('common.save')}
              </Button>
              <Popconfirm
                title={t('common.cancelMessage')}
                onConfirm={() => {
                  setKeyToEditRecord('');
                }}
                onClick={() => {
                  setKeyToEditRecord('');
                }}
              >
                <Button shape="circle" icon={<AiOutlineClose />} data-cy="cancel" />
              </Popconfirm>
            </Space>
          ) : (
            <TableAction dropdownItems={actions} placement="bottom" />
          );
        },
      });

      return columnsConverted;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleRenderTablesComponents = () => {
    return shareProportionComponents.map((item: ShareProportionData) => {
      const itensConverted = handleExtractProjectsArray(item.items);
      return (
        <S.TablesContainer key={item.category} isOpen={expandableViewStatus[item.category]}>
          <S.HeaderExpandableItem>
            <ExpandableTable
              isOpen={expandableViewStatus[item.category]}
              onClick={() => {
                handleUpdateExpandableViews(item.category);
              }}
            />
            <S.HeaderExpandableContainer>
              <S.HeaderCardContainer>
                <Row>
                  <span>{item.category}</span>
                  {handleRenderSourceTag(itensConverted, 'FOB')}
                  {handleRenderSourceTag(itensConverted, 'BR')}
                </Row>
              </S.HeaderCardContainer>
            </S.HeaderExpandableContainer>
          </S.HeaderExpandableItem>
          {expandableViewStatus[item.category] && (
            <S.TableContent>
              <Form form={form} component={false}>
                <SimpleTable
                  striped
                  bordered={false}
                  pagination={false}
                  dataSource={itensConverted}
                  rowKey={item.category}
                  scroll={{ x: 'max-content', y: 'auto' }}
                  columns={columns.concat(handleConvertProjectsToColumns(itensConverted))}
                  components={{
                    body: {
                      cell: EditableTableCell,
                    },
                  }}
                  noBorder
                  scrollable
                />
              </Form>
            </S.TableContent>
          )}
        </S.TablesContainer>
      );
    });
  };

  return {
    isLoading,
    params,
    columns,
    openCard,
    CMs,
    products,
    categories,
    specifications,
    partNumbers,
    projects,
    Sources,
    suppliers,
    shareProportionComponents,
    visibleModal,
    modalProps,
    setOpenCard,
    handleFetchHeaders,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
    handleUpdateFilters,
    handleFetchComponents,
    handleRenderTablesComponents,
    // disabledDate,
    setVisibleModal,
  };
}
