import { Button, Form, Input, Popconfirm, Space, Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import { AiOutlineClose, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  getSortOrder,
  oneAlert,
  getURI,
  getErrorMessage,
  debounce,
  isFobOrBr,
} from '../../../helpers/utils';
import { api } from '../../../services/api';
import { ComponentsTypeSelect } from '../../../components/forms/ComponentsTypeSelect';
import ComponentsSelect from '../../../components/forms/ComponentsSelect';
import SuppliersSelect from '../../../components/forms/SuppliersSelect';

export function useDataSettings({ scenarioData, modalData, simulationModal }) {
  const initialParams = {
    order: '',
    orderBy: '',
    page: 0,
    limit: 10,
    partType: [],
    spec: [],
    partNumber: [],
    supplierShortName: [],
    description: '',
    value: '',
    local: '',
  };

  const { t } = useTranslation();
  const { id: paramId, year: paramYear } = useParams();
  const scenarioId = simulationModal ? simulationModal.id : paramId;

  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [isEditAction, setIsEditAction] = useState(false);
  const [isNewItem, setIsNewItem] = useState(false);
  const [params, setParams] = useState(initialParams);
  const [searchValue, setSearchValue] = useState('');
  const [refetchData, setRefetchData] = useState({
    spec: [],
    partType: [],
    partNumber: [],
    local: '',
  });
  const [expandedRowKeys, setExpandedRowKeys] = useState(null);
  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });

  const resetControls = () => {
    setEditingKey('');
    setIsEditAction(false);
    setIsNewItem(false);
    setExpandedRowKeys(null);

    setRefetchData({
      partType: [],
      spec: [],
      partNumber: [],
      local: '',
    });

    form.setFieldsValue({
      partType: [],
      spec: [],
      partNumber: [],
      local: '',
    });
  };

  const fetchSettings = async () => {
    try {
      if (!isLoading) setIsLoading(true);

      const { data: content } = await api.get(
        getURI(`scenarios/${scenarioId}/optimizationSetup/${modalData.type}`)
      );

      const dataContent = content?.length ? content : [];
      setData(dataContent);

      resetControls();
      setIsLoading(false);
    } catch (error) {
      setData([]);
      setIsLoading(false);
    }
  };

  const changePageValue = (page, type) => {
    if (type === 'input' || type === 'navigation') {
      setParams({ ...params, page: page - 1 });
      setPageControl({ ...pageControl, pageLabel: page });
    } else {
      setPageControl({ ...pageControl, pageLabel: page });
    }
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    if (data.length === 0) return;
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const cancelNewItem = (record, key = null) => {
    const keyId = key || editingKey;
    const newData = data.filter((item) => item.id !== keyId);
    setData(newData);
    resetControls();
  };

  const cancel = (record, event) => {
    event.stopPropagation();
    if (isNewItem) cancelNewItem(record);
    resetControls();
  };

  const handleAddItem = (event) => {
    const newItem = {
      id: data.length + 1,
      partType: [],
      spec: [],
      partNumber: [],
      supplierShortName: [],
      description: '',
      value: '',
      local: '',
    };

    setIsNewItem(true);
    form.setFieldsValue({ ...newItem });
    setData([newItem, ...data]);
    setEditingKey(newItem.id);
    setExpandedRowKeys([newItem.id]);
  };

  const save = async (record, e) => {
    e.stopPropagation();

    await form
      .validateFields()
      .then(async (row) => {
        try {
          setIsLoading(true);
          let newRow;

          if (modalData.type === 'componentPurchaseNotNational') {
            newRow = {
              componentId: row.partNumber,
            };
          } else {
            newRow = {
              componentId: row.partNumber,
              supplierId: row.supplierShortName,
              value: row.value,
            };
          }

          if (isNewItem) {
            const response = await api.post(
              getURI(`scenarios/${scenarioId}/optimizationSetup/${modalData.type}`),
              newRow
            );
            if (response.status !== 200) throw Error();
          } else {
            const response = await api.put(
              getURI(`scenarios/${scenarioId}/optimizationSetup/${modalData.type}/${record.id}`),
              newRow
            );
            if (response.status !== 200) throw Error();
          }

          await fetchSettings();
          resetControls();
          oneAlert('success', t('toast.successOnSave'));
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          oneAlert('error', getErrorMessage(error) || t('toast.errorOnSave'));
        }
      })
      .catch((error) => {
        console.log('Validate Failed: ', error);
      });
  };

  const deleteItem = async (record, event) => {
    try {
      event.stopPropagation();
      setIsLoading(true);
      const response = await api.delete(
        `scenarios/${scenarioId}/optimizationSetup/${modalData.type}/${record.id}`
      );
      if (response.status !== 200) throw Error();

      await fetchSettings();
      resetControls();
      oneAlert('info', t('toast.successOnDelete'));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnDelete'));
    }
  };

  const isEditing = (record) => record.id === editingKey;

  const edit = (record, event) => {
    event.stopPropagation();

    setRefetchData({
      partType: record.partType,
      spec: record.spec,
      partNumber: record.partNumber,
      supplierShortName: record.supplier,
      description: record.description,
      value: record.value,
      local: record.local,
    });

    form.setFieldsValue({ ...record, supplierShortName: record.supplier });
    setEditingKey(record.id);
    setIsEditAction(true);
  };

  const debouncedEventHandler = useMemo(
    () =>
      debounce((e) => {
        const value = !e.target.value ? '' : e.target.value.toString().toLowerCase().trim();
        setSearchValue(value);
      }, 300),
    []
  );

  const onValuesChange = (changedValues) => {
    if (changedValues.partType) {
      setRefetchData({
        ...refetchData,
        partType: changedValues.partType,
        spec: [],
        partNumber: [],
        local: '',
      });

      form.setFieldsValue({
        spec: [],
        partNumber: [],
        local: '',
      });
    }

    if (changedValues.spec) {
      setRefetchData({ ...refetchData, spec: changedValues.spec, partNumber: [], local: '' });
      form.setFieldsValue({
        partNumber: [],
        local: '',
      });
    }
  };

  const handleLocalData = async (value) => {
    const row = form.getFieldValue();

    const {
      data: { content },
    } = await api.get(
      getURI('components', {
        specEquals: btoa(row.spec),
        order: 'spec',
        orderBy: 'ASC',
      })
    );

    const component = content?.find((comp) => comp.partNumber === value);
    if (component && component?.local?.length) {
      setRefetchData({ ...refetchData, local: component?.local });
      form.setFieldsValue({ ...row, local: component?.local });
    }
  };

  const baseColumns = [
    {
      title: t('pages.scenarios.settings.fields.partType'),
      label: t('pages.scenarios.settings.fields.partType'),
      dataIndex: 'partType',
      key: 'partType',
      editable: true,
      required: true,
      width: '200px',
    },
    {
      title: t('pages.scenarios.settings.fields.spec'),
      label: t('pages.scenarios.settings.fields.spec'),
      dataIndex: 'spec',
      key: 'spec',
      editable: true,
      required: true,
    },
    {
      title: t('pages.scenarios.settings.fields.partNumber'),
      label: t('pages.scenarios.settings.fields.partNumber'),
      dataIndex: 'partNumber',
      key: 'partNumber',
      editable: true,
      required: true,
    },
  ];

  const titleAmount =
    modalData.type === 'componentPurchaseMax'
      ? t('pages.scenarios.settings.fields.amountMax')
      : t('pages.scenarios.settings.fields.amountMin');

  const maxMinColumns =
    modalData.type === 'componentPurchaseNotNational'
      ? [
          {
            title: t('pages.scenarios.settings.fields.description'),
            label: t('pages.scenarios.settings.fields.description'),
            dataIndex: 'description',
            key: 'description',
            editable: false,
            required: true,
            width: '300px',
          },
        ]
      : [
          {
            title: t('pages.scenarios.settings.fields.supplier'),
            label: t('pages.scenarios.settings.fields.supplier'),
            dataIndex: 'supplierShortName',
            key: 'supplierShortName',
            editable: true,
            required: true,
            width: '160px',
          },
          {
            title: titleAmount,
            label: titleAmount,
            dataIndex: 'value',
            key: 'value',
            editable: true,
            required: true,
            width: '160px',
            align: 'center',
          },
          {
            title: t('pages.scenarios.settings.fields.description'),
            label: t('pages.scenarios.settings.fields.description'),
            dataIndex: 'description',
            key: 'description',
            editable: false,
            required: true,
            width: '300px',
          },
        ];

  const columns = [...baseColumns, ...maxMinColumns];

  const inputNode = (column) => {
    const row = form.getFieldValue();
    const local = row?.local || refetchData?.local;
    const description = row?.description || refetchData?.description;

    switch (column) {
      case 'partType':
        return (
          <ComponentsTypeSelect
            id="partTypeSelect"
            scenario={`scenarios/${scenarioId}`}
            allowClear={false}
            placeholder={t('pages.simulation.placeholder.category')}
            intable="intable"
            search={searchValue}
            onKeyUp={debouncedEventHandler}
            onClick={(e) => e.stopPropagation()}
          />
        );
      case 'spec':
        return (
          <ComponentsSelect
            id="specSelect"
            intable="intable"
            placeholder={t('filter.spec')}
            disabled={!refetchData?.partType?.length && !row?.partType?.length}
            componentType={refetchData.partType || row?.partType}
            onClick={(e) => e.stopPropagation()}
          />
        );
      case 'partNumber':
        return (
          <ComponentsSelect
            id="partNumberSelect"
            intable="intable"
            placeholder={t('filter.partNumber')}
            disabled={!refetchData.spec?.length && !row?.spec?.length}
            spec={refetchData.spec || row?.spec}
            onSelect={handleLocalData}
            onClick={(e) => e.stopPropagation()}
          />
        );
      case 'supplierShortName':
        return (
          <SuppliersSelect
            id="supplierSelect"
            intable="intable"
            placeholder={t('filter.supplier')}
            valueAsId
            onClick={(e) => e.stopPropagation()}
          />
        );
      case 'description':
        return description || '-';
      case 'local':
        return local ? <Tooltip title={local}>{isFobOrBr(local)}</Tooltip> : '-';
      default:
        return <Input onClick={(e) => e.stopPropagation()} />;
    }
  };

  const columnsWithAction = [
    ...columns,
    {
      title: t('common.action'),
      key: 'action',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button type="primary" onClick={(e) => save(record, e)} data-cy="save">
              {t('common.save')}
            </Button>
            <Popconfirm
              title={t('common.cancelMessage')}
              onConfirm={(e) => {
                cancel(record, e);
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Button shape="circle" default icon={<AiOutlineClose />} />
            </Popconfirm>
          </Space>
        ) : (
          <Space direction="horizontal" size={12}>
            <Tooltip placement="top" title={t('common.edit')} onClick={(e) => e.stopPropagation()}>
              <Button
                shape="circle"
                icon={<AiOutlineEdit />}
                disabled={editingKey !== ''}
                onClick={(e) => edit(record, e)}
                data-cy="edit"
              />
            </Tooltip>

            <Tooltip
              placement="top"
              title={t('common.delete')}
              onClick={(e) => e.stopPropagation()}
            >
              <Popconfirm
                title={t('common.deleteMessage')}
                onConfirm={(e) => deleteItem(record, e)}
                onClick={(e) => e.stopPropagation()}
              >
                <Button
                  shape="circle"
                  icon={<AiOutlineDelete />}
                  danger
                  disabled={editingKey !== ''}
                  data-cy="delete"
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columnsWithAction.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          editable: col.editable,
          inputType: col.dataIndex === 'value' ? 'number' : 'text',
          limit: col.dataIndex === 'description' ? '255' : '100',
          required: col.required,
          newInputNode: inputNode(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  return {
    mergedColumns,
    handleChangeTable,
    params,
    pageControl,
    changePageValue,
    onValuesChange,
    form,
    data,
    editingKey,
    handleAddItem,
    fetchSettings,
  };
}
