import styled from 'styled-components';
import { DownCircleOutlined } from '@ant-design/icons';

export const ContainerTable = styled.div`
  max-width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`;

export const TableRow = styled.tr`
  td,
  th {
    padding: 0.5rem;
  }
`;
export const SpaceRow = styled.tr`
  background-color: ${(props) => props.theme.colors.background};
  height: 0.25rem;
`;

export const NoData = styled(TableRow)`
  background-color: ${({ theme }) => theme.colors.shape};
  height: 152px;
`;

export const TableHeadCell = styled.th`
  color: ${(props) => props.theme.colors.title};
  background-color: ${({ theme }) => theme.colors.gray_header};
  padding: 0.5rem;
  font-weight: 500;
  font-size: 0.8rem;
  /* border-top: 1px solid ${({ theme }) => theme.colors.border}; */
  text-align: ${({ align }) =>
    align === 'center' ? 'center' : align === 'right' ? 'right' : 'left'};
  border-top: 1px solid ${({ theme }) => theme.colors.gray_border};

  &:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-left: 1px solid ${({ theme }) => theme.colors.gray_border};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray_border};
  }
  &:last-child {
    border-top-right-radius: 0.25rem;
    border-right: solid 1px ${({ theme }) => theme.colors.gray_border};
  }
`;

export const TableHeadCellSecondary = styled.th`
  background-color: ${({ theme }) => theme.colors.gray_header};
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  border-top: ${({ theme }) => `1px solid ${theme.colors.border}`};
  border-right: 1px solid ${({ theme }) => theme.colors.gray_border};

  &:last-child {
    border-bottom-right-radius: 0.25rem;
  }
  &:first-child {
    border-left: 1px solid ${({ theme }) => theme.colors.gray_border};
  }
`;

export const TableCell = styled.td`
  font-weight: 500;
  font-size: 0.75rem;
  text-align: ${({ align }) =>
    align === 'center' ? 'center' : align === 'right' ? 'right' : 'left'};
  background-color: ${({ theme }) => theme.colors.shape};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.text};

  &:first-child {
    border-bottom-left-radius: 0.5rem;
    border-left: 1px solid ${({ theme }) => theme.colors.border}!important;
    border-top-left-radius: 0.5rem;
  }

  &:last-child {
    border-bottom-right-radius: 0.5rem;
    border-right: 1px solid ${({ theme }) => theme.colors.border}!important;
    border-top-right-radius: 0.5rem;
  }
`;

export const SupplierCell = styled(TableCell)`
  background-color: ${({ theme, type }) =>
    type ? theme.colors.table_pf_specification : theme.colors.table_pf_partnumber};
  font-weight: 500;
  color: ${(props) => props.theme.colors.text};
  border-top: none;
  border-bottom: 0.25rem solid ${(props) => props.theme.colors.background};
  padding-top: 10px;
  padding-bottom: 10px;

  &:first-child {
    border-left: 1px solid ${({ theme }) => theme.colors.background}!important;
  }

  &:last-child {
    border-right: 1px solid ${({ theme }) => theme.colors.background}!important;
  }
`;

export const PriceCell = styled.td`
  background-color: ${({ theme }) => theme.colors.shape};
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  border-right: 1px solid
    ${({ bdRight, theme }) => (bdRight ? `${theme.colors.border}` : '#f0f0f0')};
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
  font-size: 0.8rem;
  color: ${({ local, theme }) =>
    local === 'PPB' ? theme.colors.ppb : local === 'FOB' ? theme.colors.fob : 'inherit'};
  &:last-child {
    border-top-right-radius: 0.5rem;
    border-right: 1px solid ${({ theme }) => theme.colors.border}!important;
  }
`;

export const TotalCell = styled.td`
  background-color: ${({ theme }) => theme.colors.shape};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  border-right: 1px solid
    ${({ bdRight, theme }) => (bdRight ? `${theme.colors.border}` : '#f0f0f0')};
  font-weight: 500;
  font-size: 0.8rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};

  &:last-child {
    border-bottom-right-radius: 8px;
    border-right: 1px solid ${({ theme }) => theme.colors.border}!important;
  }
`;

export const Gap = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  /* color: #999999; */
  color: ${({ theme }) => theme.colors.border};
`;

export const DownIcon = styled(DownCircleOutlined)`
  color: ${({ theme }) => theme.colors.link};
  font-size: 1rem;
`;
