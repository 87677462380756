import React from 'react';
import { Button, Checkbox, Form, Input, Space } from 'antd';
import i18n from 'i18next';
import * as SC from '../styles/common';

export const getColumnSearchProps = ({ dataIndex, search, reset, filterData, searchInput }) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys }) => {
    return (
      <SC.HeaderSearch data-cy="filter-header">
        <Input
          ref={searchInput}
          placeholder={i18n.t('common.search')}
          defaultValue={filterData[`header_${dataIndex}`]}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => search(dataIndex, selectedKeys[0])}
          data-cy="filter-header-input"
          className={`header_${dataIndex}`}
        />
      </SC.HeaderSearch>
    );
  },
  onFilterDropdownOpenChange: (visible) => {
    if (visible) setTimeout(() => searchInput.current?.select(), 100);
  },
  filterIcon: () => (
    <SC.SearchOutlined
      filtered={(!!filterData[`header_${dataIndex}`]?.length).toString()}
      data-cy="filter-header-search-icon"
    />
  ),
});

export const getColumnOptionProps = ({ dataIndex, options, search, reset, filterData }) => ({
  filterDropdown: () => {
    let paramValue = filterData[`header_${dataIndex}`];
    if (dataIndex === 'local' && paramValue?.length) {
      paramValue = paramValue.split(',');
    }

    return (
      <SC.HeaderSearch>
        <Form
          name="form_local"
          onFinish={(formValue) => search(dataIndex, formValue)}
          initialValues={{ [dataIndex]: paramValue }}
          data-cy="filter-header-options"
        >
          <div className="input-container">
            <Form.Item name="local">
              <Checkbox.Group options={options} value={paramValue} data-cy="checkbox-group" />
            </Form.Item>
          </div>
          <Space>
            <Button onClick={() => reset(dataIndex)} data-cy="filter-header-clear" size="small">
              {i18n.t('common.clear')}
            </Button>
            <Button htmlType="submit" type="primary" data-cy="filter-header-search" size="small">
              {i18n.t('common.filter')}
            </Button>
          </Space>
        </Form>
      </SC.HeaderSearch>
    );
  },
  filterIcon: () => (
    <SC.FilterOutlined filtered={(!!filterData[`header_${dataIndex}`]?.length).toString()} />
  ),
});

export const getGenericColumnOptionProps = ({
  dataIndex,
  options,
  search,
  reset,
  filterData,
  name,
}) => ({
  filterDropdown: () => {
    let paramValue = filterData[`header_${dataIndex}`];
    if (dataIndex === name && paramValue?.length) {
      paramValue = paramValue.split(',');
    }

    return (
      <SC.HeaderSearch>
        <Form
          name={`form_${name}`}
          onFinish={(formValue) => search(dataIndex, formValue)}
          initialValues={{ [dataIndex]: paramValue }}
          data-cy="filter-header-options"
        >
          <div className="input-container">
            <Form.Item name={name}>
              <Checkbox.Group options={options} value={paramValue} data-cy="checkbox-group" />
            </Form.Item>
          </div>
          <Space>
            <Button onClick={() => reset(dataIndex)} data-cy="filter-header-clear" size="small">
              {i18n.t('common.clear')}
            </Button>
            <Button htmlType="submit" type="primary" data-cy="filter-header-search" size="small">
              {i18n.t('common.filter')}
            </Button>
          </Space>
        </Form>
      </SC.HeaderSearch>
    );
  },
  filterIcon: () => (
    <SC.FilterOutlined filtered={(!!filterData[`header_${dataIndex}`]?.length).toString()} />
  ),
});
