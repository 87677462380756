import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import StyledModal from '../../../../../../components/Common/StyledModal';
import { Component, SkuModal } from '../types';
import { CopyAndPasteContent } from './CopyAndPasteContent';
import * as S from './styles';
import { ModalProps } from './types';

const PartNumberDescription = (data: SkuModal) => {
  return (
    <S.Box>
      <S.Cell style={{ width: '10%' }}>
        {i18n.t('common.partNumber')}
        <CopyAndPasteContent text={data?.partNumber || '-'} />
      </S.Cell>
      <S.Cell style={{ width: '15%' }}>
        {i18n.t('common.specification')}
        <CopyAndPasteContent text={data?.specification || '-'} />
      </S.Cell>
      <S.Cell style={{ width: '42%' }}>
        {i18n.t('common.description')}
        <CopyAndPasteContent text={data.description || '-'} />
      </S.Cell>
      <S.Cell style={{ width: '8%' }}>
        {i18n.t('common.source')}
        <CopyAndPasteContent text={data?.source || '-'} />
      </S.Cell>
      <S.Cell style={{ width: '25%' }}>
        {i18n.t('common.manufacturer')}
        <CopyAndPasteContent text={data?.manufacturer || '-'} />
      </S.Cell>
    </S.Box>
  );
};

const ModalBody = (data?: Component) => {
  const { t } = useTranslation();
  if (data?.skuComponentModalDtos) {
    return (
      <S.BodyContainer>
        <S.BlueTextSubTitle>{data?.name}</S.BlueTextSubTitle>
        {data?.skuComponentModalDtos?.map((item) => PartNumberDescription(item))}
      </S.BodyContainer>
    );
  }
  return (
    <S.BodyContainer>
      <S.BlueBox>
        <S.StyledInfoIcon />
        {t('notifications.noDataForRequest')}
      </S.BlueBox>
    </S.BodyContainer>
  );
};
export const SkuComponentsModal = ({ data, ...props }: ModalProps) => {
  return (
    <StyledModal
      title={`${data?.sku} - ${data?.project} - ${data?.cm}`}
      body={ModalBody(data)}
      width="75rem"
      footer={null}
      {...props}
    />
  );
};
