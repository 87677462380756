// @ts-nocheck

import adapter from '../../assets/adapter.svg';
import lan from '../../assets/lan.svg';
import battery from '../../assets/battery.svg';
import hdd from '../../assets/hdd.svg';
import ssd from '../../assets/ssd.svg';
import memory from '../../assets/memory.svg';
import motherboard from '../../assets/mobo.svg';
import assembly from '../../assets/assembly.svg';
import tests from '../../assets/tests.svg';

export interface PartTypeIconProps {
  type:
    | 'ADAPTER'
    | 'BATTERY'
    | 'WIRELESS LAN'
    | 'HDD'
    | 'MEM'
    | 'SSD'
    | 'MOTHERBOARD'
    | 'FINAL ASSEMBLING'
    | 'PRODUCT TESTS';
}

const icons = {
  ADAPTER: adapter,
  BATTERY: battery,
  'WIRELESS LAN': lan,
  HDD: hdd,
  SSD: ssd,
  MOTHERBOARD: motherboard,
  MEM: memory,
  'FINAL ASSEMBLING': assembly,
  'PRODUCT TESTS': tests,
};

export const PartTypeIcon = ({ type }: PartTypeIconProps) => {
  const lowerType = type.toLowerCase();
  const typeKey = lowerType.includes('assembling')
    ? 'FINAL ASSEMBLING'
    : lowerType.includes('motherboard')
    ? 'MOTHERBOARD'
    : lowerType.includes('test')
    ? 'PRODUCT TESTS'
    : lowerType.includes('hdd')
    ? 'HDD'
    : lowerType.includes('adapter')
    ? 'ADAPTER'
    : lowerType.includes('ram')
    ? 'MEM'
    : lowerType.includes('battery')
    ? 'BATTERY'
    : lowerType.includes('ssd')
    ? 'SSD'
    : lowerType.includes('wlan')
    ? 'WIRELESS LAN'
    : type.split('-')[0].trim();

  return <img src={icons[typeKey]} width={24} height={24} alt={type} />;
};
