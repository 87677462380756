import styled from 'styled-components';
import { Row as AntdRow, Switch as AntSwitch, Table as AntTable } from 'antd';

export const Row = styled(AntdRow)`
  height: calc(100vh - 50px);

  &.isModal {
    width: 100%;
    height: auto;
  }

  .btn-cancel {
    &:hover,
    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.danger}!important;
      color: ${({ theme }) => theme.colors.danger}!important;
    }
  }
`;

export const RowSettings = styled(AntdRow)`
  height: 30px;
  margin-bottom: 50px;

  .text-tooltip {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > div {
      margin-right: 8px;
    }
  }

  .ant-col {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;

      .ant-form-item {
        margin: 0;
      }
    }
  }

  .col-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    button {
      padding: 0;
    }
  }
`;

export const Switch = styled(AntSwitch)`
  && {
    // background-color: ${(props) => props.theme.colors.link};
  }
`;
