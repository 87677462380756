import { Form } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getFilterURL,
  removeEmptyFilter,
  getSortOrder,
  oneAlert,
  clearRequestParams,
} from '../../helpers/utils';
import { api } from '../../services/api';

const initialParams = {
  order: 'modifiedDate',
  orderBy: 'ASC',
  page: 0,
  limit: 25,
  message: '',
  status: '',
};

export function useFileErros() {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [params, setParams] = useState(initialParams);
  const [canUpdate, setCanUpdate] = useState(true);
  //const [filterFormValues, setFilterFormValues] = useState({});
  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedToProcess, setSelectedToProcess] = useState([]);

  const statusSelect = [
    { label: 'ERRO_INTEGRACAO', value: 'ERRO_INTEGRACAO' },
    { label: 'PENDENTE', value: 'PENDENTE' },
    { label: 'PROCESSADO', value: 'PROCESSADO' },
  ];

  const fetchFileErrors = async () => {
    setIsLoading(true);
    const allParams = { ...params };

    const queryParams = getFilterURL(removeEmptyFilter(clearRequestParams(allParams)));
    try {
      const {
        data: { content, totalElements, totalPages },
      } = await api.get(`arquivo?${queryParams}`);

      const dataContent = content?.length ? content : [];

      setData(dataContent);

      setPageControl({
        pageLabel: params.page + 1,
        totalPages,
        totalElements,
      });
    } catch (error) {
      console.log('Error on the fetch FileErrors');
    }

    setIsLoading(false);
  };

  const changePageValue = (page, pageSize) => {
    setParams({ ...params, page: page - 1, limit: pageSize });
    setPageControl({ ...pageControl, pageLabel: page });
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    if (data.length === 0) return;

    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const handleProcessFiles = async () => {
    setIsLoading(true);

    try {
      const response = await api.put('arquivo/reprocessar', { ids: selectedToProcess.toString() });

      if (response.status !== 200) throw Error();

      await fetchFileErrors();
      oneAlert('success', t('pages.fileerrors.successMessage'));
      setSelectedRowKeys([]);
      setSelectedToProcess([]);
    } catch (error) {
      oneAlert('error', t('toast.errorOnSave'));
    }
    setIsLoading(false);
  };

  const save = async (record) => {
    if (selectedToProcess.length > 0) await handleProcessFiles();
    else {
      setIsLoading(true);
      try {
        const response = await api.put(`arquivo/${record.id}/reprocessar`);
        if (response.status !== 200) throw Error();

        await fetchFileErrors();
        oneAlert('success', t('pages.fileerrors.successMessage'));
      } catch (error) {
        oneAlert('error', t('toast.errorOnSave'));
      }
      setIsLoading(false);
    }
  };

  const handleFilter = (filterType, value) => {
    setParams((prev) => {
      return {
        ...prev,
        [filterType]: value,
      };
    });
    setCanUpdate(true);
  };

  const searchFilter = async (newFilters, filterName = '') => {
    setCanUpdate(filterName === 'submit');

    const filterParams = {
      ...params,
      ...newFilters,
    };

    setParams(filterParams);
  };

  const clearFilter = async () => {
    setCanUpdate(false);

    setParams({
      ...initialParams,
      status: '',
    });
  };

  const handleCloseFilteredTag = (field, idx) => {
    let newValue;

    if (Array.isArray(params[field])) {
      newValue = [...params[field]];
      newValue.splice(idx, 1);
    } else {
      newValue = '';
    }

    setParams({
      ...params,
      [field]: newValue,
    });
  };

  const handleDeleteLogs = async () => {
    setIsLoading(true);
    try {
      const response = await api.delete(`fileLogs`, { data: { ids: selectedRowKeys.toString() } });
      if (response.status !== 200) throw Error();

      await fetchFileErrors();
      oneAlert('success', t('pages.fileerrors.successDeleteMessage'));
      setSelectedRowKeys([]);
    } catch (error) {
      oneAlert('error', t('toast.errorOnDelete'));
    }
    setIsLoading(false);
  };

  const handleSelectRow = (record, selected) => {
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.id]);
      if (record.status === 'ERRO_INTEGRACAO') setSelectedToProcess((keys) => [...keys, record.id]);
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record.id);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
      setSelectedToProcess((keys) => {
        const index = keys.indexOf(record.id);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
    }
  };

  const handleSelectAllRows = () => {
    setSelectedRowKeys((keys) => (keys.length === data.length ? [] : data.map((r) => r.id)));
    setSelectedToProcess((keys) =>
      keys.length === data.length
        ? []
        : data.filter((r) => r.status === 'ERRO_INTEGRACAO').map((r) => r.id)
    );
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'checkbox',
    fixed: true,
    onSelect: handleSelectRow,
    columnTitle: <> </>,
  };

  return {
    data,
    params,
    pageControl,
    canUpdate,
    fetchFileErrors,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    save,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    rowSelection,
    handleSelectAllRows,
    selectedRowKeys,
    selectedToProcess,
    handleDeleteLogs,
    handleProcessFiles,
    statusSelect,
    handleFilter,
  };
}
