import styled from 'styled-components';
import { Row as AntdRow, Button } from 'antd';

export const Container = styled.div`
  height: 100vh;
`;

export const Main = styled(AntdRow)`
  height: calc(100vh - 50px);
`;

export const DivHeader = styled.div`
  text-align: center;
  h1 {
    font-size: 26px;
  }
`;

export const ButtonLogin = styled(Button)`
  background: ${({ theme }) => theme.colors.link};
  border: none;
  border-radius: 16px;
  color: #fff;
  font-weight: 500;

  &:hover,
  &:focus {
    filter: brightness(0.9);
    background: ${({ theme }) => theme.colors.link};
  }
`;

export const ButtonLink = styled(Button)`
  padding: 16px;
  color: ${({ theme }) => theme.colors.link};

  &:hover,
  &:focus {
    filter: brightness(0.9);
    color: ${({ theme }) => theme.colors.link};
  }
`;
