/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useTranslation } from 'react-i18next';
import { oneAlert } from '../../../../../../../helpers/utils';
import { CopyAndPasteProps } from './types';
import * as S from './styles';

export const CopyAndPasteContent = ({ text }: CopyAndPasteProps) => {
  const { t } = useTranslation();

  if (text !== '-') {
    return (
      <S.CopyAndPastContainer
        onDoubleClick={() => {
          navigator.clipboard.writeText(text);
          oneAlert('info', t('notifications.copyMade'));
        }}
      >
        {text}
      </S.CopyAndPastContainer>
    );
  }
  return <div>{text}</div>;
};
