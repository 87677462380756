import { Tag } from 'antd';
import styled from 'styled-components';

export const UserTag = styled(Tag)`
  border-radius: 50px !important;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.gray_light};
  background-color: transparent;
  border: 1px solid #dfdfdf;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  .ant-tag-close-icon {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
`;
