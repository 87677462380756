import styled from 'styled-components';

export const UpdatedInfo = styled.span`
  margin-top: 5px;
  padding-left: 6px;
  font-size: 12px !important;
  font-weight: 500;
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  gap: 4px;

  .ant-breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      color: ${(props) => props.theme.colors.title};
      font-size: 18px !important;
      font-weight: 500 !important;

      a {
        color: ${({ theme }) => theme.colors.link};
        font-weight: 400 !important;

        &:hover {
          color: ${({ theme }) => theme.colors.link};
        }

        svg {
          padding-top: 2px;
        }
      }
    }
  }
`;
