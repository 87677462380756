// import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

export const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  min-width: 550px;
  width: 48%;
  margin: 0 0 2% 0;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.link};
  }

  h3 {
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    font-weight: 400;
  }

  section {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;

    .line {
      display: flex;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      &.align-left {
        .block {
          &:last-child {
            width: 100%;
          }
        }
      }
    }

    .block {
      margin-right: 20px;

      //&:first-child {
      //  width: 62%;
      //}

      &.scenario-name {
        width: 210px;
        display: inline-block;
      }

      &:last-child {
        width: 38%;
        margin-left: auto;
        margin-right: 0;
      }

      h3 {
        margin-bottom: 0;
        font-weight: 400;
      }

      button {
        width: 145px;
        max-width: 100%;
      }

      div {
        font-weight: 600;
        color: #000;
      }

      &.block-action {
        display: flex;
        .btn-action {
          width: 38px;
          margin-right: 16px;

          svg {
            font-size: 18px;
          }
        }

        .btn-delete {
          border: 1px solid ${({ theme }) => theme.colors.danger};
          color: ${({ theme }) => theme.colors.danger};

          &:hover {
            border: 1px solid ${({ theme }) => theme.colors.danger};
            color: ${({ theme }) => theme.colors.danger};

            svg {
              color: ${({ theme }) => theme.colors.danger};
            }
          }
        }
      }
    }
  }

  aside {
    border-left: 2px dotted ${({ theme }) => theme.colors.border};
    width: 25%;
    padding: 16px;
    position: relative;

    h3 {
      line-height: 1.4;
    }

    button {
      display: block;
      padding: 0;
      margin: 0 0 8px;
      line-height: 1.5;
      height: auto;

      &.rules {
        position: absolute;
        bottom: 25px;
        font-weight: bold;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const ScoreColor = styled.div`
  color: ${({ score }) => {
    if (score < 42) {
      return '#FF0000!important';
    }

    if (score > 42) {
      return '#FF9900!important';
    }

    return '#0B5394!important';
  }};
`;
