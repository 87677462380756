import styled from 'styled-components';

export const ContainerTitle = styled.div`
  margin-left: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const TitleAVLC = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
`;
export const TitleScenarios = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.textEditable};
`;

export const QGTableData = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.563rem;
  color: ${({ theme }) => theme.colorsDesignSystem.textEditable};
`;
