import { DownOutlined, UpOutlined } from '@ant-design/icons';

export function useSimpleTable() {
  const rowClassNameStripe = (rowClassName: string | undefined, index: number | undefined) => {
    let rowStripe = '';
    if (index) {
      rowStripe = index % 2 ? 'dark-row' : 'light-row';
    }
    return rowClassName ? `${rowClassName}${rowStripe}` : rowStripe;
  };
  const customExpandIcon = (props: any) => {
    const { record } = props;

    if (!record.children || record.children.length === 0) {
      return;
    }
    if (props.expanded) {
      return (
        <UpOutlined
          style={{
            cursor: 'pointer',
            float: 'right',
            marginTop: '2px',
            marginRight: '5px',
          }}
        />
      );
    }
    return (
      <DownOutlined
        style={{ cursor: 'pointer', float: 'right', marginTop: '2px', marginRight: '6px' }}
      />
    );
  };

  return {
    rowClassNameStripe,
    customExpandIcon,
  };
}
