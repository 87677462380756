/* eslint-disable no-undefined */
/* eslint-disable react/no-array-index-key */
import { MessageOutlined } from '@ant-design/icons';
import { Badge, Flex, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import StyledIndicationTag from '../../../../components/Common/IndicationTag';
import { addClassification, handleFormatValueFields } from '../../../../helpers/nUtils';
import theme from '../../../../styles/theme';
import { VerticalCard } from '../VerticalCard';
import * as S from './styles';
import { ISupplierResponseCard } from './types';

export function SupplierResponseCard(props: ISupplierResponseCard) {
  const { t } = useTranslation();

  return (
    <S.SupplierResponseCard onAnimationEnd={props.setOptionChanged} changed={props.optionChanged}>
      <S.Padding>
        <Flex justify="space-between" align="center" style={{ width: '100%' }}>
          <VerticalCard
            title={t('pages.viewRfq.openCard.supplier')}
            value={props.supplier}
            font="LG"
          />

          <S.Classification>
            {props.position ? addClassification(String(props.position)) : '-'}
          </S.Classification>
        </Flex>
        <Flex vertical style={{ marginTop: '1rem' }}>
          <Flex justify="space-between" align="center">
            <S.Title>{t('pages.viewRfq.openCard.partNumber')}</S.Title>
            <Flex>
              {props.partNumber ? (
                <Flex align="center">
                  <StyledIndicationTag
                    style={{ marginInlineEnd: '0px' }}
                    type={props.partNumber.qstatus}
                    text={props.partNumber.qstatus || ''}
                  />
                  <S.PartNumber qStatus={props.partNumber.qstatus}>
                    {props.partNumber.partNumber}
                  </S.PartNumber>
                </Flex>
              ) : (
                <S.PartNumber qStatus="-">-</S.PartNumber>
              )}
            </Flex>
          </Flex>
          <S.Divider />
          <Flex justify="space-between" align="center">
            <Flex gap={40}>
              <VerticalCard
                title={t('pages.viewRfq.openCard.capacity')}
                value={props.capacity || '-'}
                gap={8}
                fontTitle="SM"
              />
              <VerticalCard
                title={t('pages.viewRfq.openCard.leadTime')}
                value={props.leadTime ? `${props.leadTime} ${t('common.days')}` : '-'}
                gap={8}
                fontTitle="SM"
              />
            </Flex>
            <Badge
              dot
              color={theme.colorsDesignSystem.tagColorBlue}
              showZero={props.showCommentsButton}
              count={props.totalComments || 0}
            >
              <MessageOutlined
                style={{ color: theme.colorsDesignSystem.primary, fontSize: '1rem' }}
                onClick={(event) => {
                  event.stopPropagation();
                  props.showCommentsButton ? props.handleDetailsModal() : undefined;
                }}
                disabled={props.showCommentsButton}
              />
            </Badge>
          </Flex>
          <S.Divider variant="dashed" />
          {/* last gross */}
          <Flex gap={8} vertical>
            {props.showGross ? (
              <Flex align="center" justify="space-between">
                <S.Span>{t('pages.viewRfq.openCard.lastGross')}</S.Span>
                <S.Value>
                  {props.lastGross
                    ? `$ ${handleFormatValueFields({ number: props.lastGross })}`
                    : '-'}
                </S.Value>
              </Flex>
            ) : (
              <S.EmptyRow />
            )}
            {/* gross price | net price */}
            <Flex align="center" justify="space-between">
              {props.showGross ? (
                <>
                  <S.Span>{t('pages.viewRfq.openCard.grossPrice')}</S.Span>
                  <S.Value color={props.grossPrice ? 'blue' : 'normal'}>
                    {props.grossPrice
                      ? `$ ${handleFormatValueFields({ number: props.grossPrice })}`
                      : '-'}
                  </S.Value>
                </>
              ) : (
                <>
                  <S.Span>{t('pages.viewRfq.openCard.netPrice')}</S.Span>
                  <S.Value color={props.netPrice ? 'blue' : 'normal'}>
                    {props.netPrice
                      ? `$ ${handleFormatValueFields({ number: props.netPrice })}`
                      : '-'}
                  </S.Value>
                </>
              )}
            </Flex>
            {/* gap gross */}
            {props.showGross ? (
              <Flex align="center" justify="space-between">
                <S.Span>{t('pages.viewRfq.openCard.gapGross')}</S.Span>
                <Flex align="center" gap={8}>
                  <S.Percentage>{props.gapGrossPercentage}</S.Percentage>
                  {props.gapGross ? (
                    <S.GapGross value={props.gapGross || 0}>
                      $ {handleFormatValueFields({ number: props.gapGross })}
                    </S.GapGross>
                  ) : (
                    '-'
                  )}
                </Flex>
              </Flex>
            ) : (
              <S.EmptyRow />
            )}
            {/* extended gross | extended net */}
            <Flex align="center" justify="space-between" style={{ marginTop: '0.25rem' }}>
              {props.showGross ? (
                <>
                  <S.Span isBold>{t('pages.viewRfq.openCard.totalGross')}</S.Span>
                  <Tooltip
                    title={
                      props.totalGross
                        ? `$ ${handleFormatValueFields({
                            number: props.totalGross,
                          })}`
                        : '-'
                    }
                  >
                    <S.Value isBold>
                      {props.totalGross
                        ? `$ ${handleFormatValueFields({
                            number: props.totalGross,
                            notation: 'compact',
                          })}`
                        : '-'}
                    </S.Value>
                  </Tooltip>
                </>
              ) : (
                <>
                  <S.Span isBold>{t('pages.viewRfq.openCard.totalNet')}</S.Span>
                  <Tooltip
                    title={
                      props.totalNet
                        ? `$ ${handleFormatValueFields({
                            number: props.totalNet,
                          })}`
                        : '-'
                    }
                  >
                    <S.Value isBold>
                      {props.totalNet
                        ? `$ ${handleFormatValueFields({
                            number: props.totalNet,
                            minFractional: 1,
                            maxFactional: 2,
                            notation: 'compact',
                          })}`
                        : '-'}
                    </S.Value>
                  </Tooltip>
                </>
              )}
            </Flex>
          </Flex>

          <S.Divider variant="dashed" />
          <Flex gap={8} vertical>
            <Flex align="center" justify="space-between">
              <S.Span>{t('pages.viewRfq.openCard.baseline')}</S.Span>
              <S.Value>
                {props.baselinePrice
                  ? `$ ${handleFormatValueFields({ number: props.baselinePrice })}`
                  : '-'}
              </S.Value>
            </Flex>
            <Flex align="center" justify="space-between">
              <S.Span isBold>{t('pages.viewRfq.openCard.totalBaseline')}</S.Span>
              <Tooltip
                title={
                  props.totalBaseline
                    ? `$ ${handleFormatValueFields({
                        number: props.totalBaseline,
                      })}`
                    : '-'
                }
              >
                <S.Value isBold>
                  {props.totalBaseline
                    ? `$ ${handleFormatValueFields({
                        number: props.totalBaseline,
                        notation: 'compact',
                      })}`
                    : '-'}
                </S.Value>
              </Tooltip>
            </Flex>
          </Flex>
        </Flex>
      </S.Padding>
      <S.ImpactSavingDiv>
        {props.showGross ? (
          <Flex align="center" justify="space-between">
            <S.Span isSemiBold>{t('pages.viewRfq.openCard.impactSaving')}</S.Span>
            <Flex align="center" gap={8}>
              <S.Percentage isBold>
                {props.impactSavingGrossPercentage ? `${props.impactSavingGrossPercentage}%` : '-'}
              </S.Percentage>
              {props.impactSavingGross ? (
                <Tooltip
                  title={
                    props.impactSavingGross
                      ? `$ ${handleFormatValueFields({
                          number: props.impactSavingGross,
                        })}`
                      : '-'
                  }
                >
                  <S.ImpactValue value={Number(props.impactSavingGross) || 0}>
                    ${' '}
                    {handleFormatValueFields({
                      number: props.impactSavingGross,
                      minFractional: 1,
                      maxFactional: 2,
                      notation: 'compact',
                    })}
                  </S.ImpactValue>
                </Tooltip>
              ) : (
                '-'
              )}
            </Flex>
          </Flex>
        ) : (
          <Flex align="center" justify="space-between">
            <S.Span isSemiBold>{t('pages.viewRfq.openCard.impactSaving')}</S.Span>
            <Flex align="center" gap={8}>
              <S.Percentage isBold>
                {props.impactSavingNetPercentage ? `${props.impactSavingNetPercentage}%` : '-'}
              </S.Percentage>
              {props.impactSavingNet ? (
                <Tooltip
                  title={
                    props.impactSavingNet
                      ? `$ ${handleFormatValueFields({
                          number: props.impactSavingNet,
                        })}`
                      : '-'
                  }
                >
                  <S.ImpactValue value={Number(props.impactSavingNet) || 0}>
                    ${' '}
                    {handleFormatValueFields({
                      number: props.impactSavingNet,
                      minFractional: 1,
                      maxFactional: 2,
                      notation: 'compact',
                    })}
                  </S.ImpactValue>
                </Tooltip>
              ) : (
                '-'
              )}
            </Flex>
          </Flex>
        )}
      </S.ImpactSavingDiv>
    </S.SupplierResponseCard>
  );
}
