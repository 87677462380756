import { Col, Row } from 'antd';
import styled from 'styled-components';

export const StyledCol = styled(Col)``;

export const StyledRow = styled(Row)`
  width: 100%;

  .detail-title {
    font-size: 14px;
    color: #999;
    font-weight: 400;
  }

  .detail-data {
    &.top {
      color: #3d85c6;
    }
    font-weight: 600;
    font-size: 14px;
    color: #5f676c;
  }

  .inner-col {
    padding: 10px 20px;
    width: 100%;
    border: 1px solid #f0f2f5;
    border-radius: 5px;
    &.top {
      background: #f8f8f8;
    }
  }
`;
