import { Col, ConfigProvider, Dropdown, Form, Popconfirm, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus, BiDownload, BiUpload } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { DownOutlined } from '@ant-design/icons';
import { ActionButton } from '../../components/ActionButton';
import { Button } from '../../components/Button';
import { csvArrayToObj, transformDataPrices, getURI } from '../../helpers/utils';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import { EditableCell } from '../../components/EditableCell';
import { usePrices } from './usePrices';
import { TablePF } from '../../components/Tables/TablePF';
import { FilterViewPF } from '../../components/FilterViewPF';
import { useModal } from '../../components/Modal/useModal';
import CsvUploadReader from '../../components/CsvUploadReader';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import PricesFilter from './PricesFilter';
import { Loading } from '../../components/Loading';
import * as SC from '../../styles/common';
import * as S from './styles';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import { CsvExport } from '../../components/Common/CsvExport';
import StyledModal from '../../components/Common/StyledModal';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledButton from '../../components/Common/StyledButton';
import { StyledPagination } from '../../components/Common/StyledPagination';

export function PricesPage({ view = null, onClose = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.prices.title'));

  const validateMessages = ValidateMessages();

  const [preview, setPreview] = useState([]);
  const [visiblePreview, openModalPreview, closeModalPreview] = useModal();
  const [visiblePrice, openModalPrice, closeModalPrice] = useModal();

  const {
    data,
    params,
    pageControl,
    scenariosOptions,
    selectedScenario,
    fetchScenarios,
    fetchPrices,
    changePageValue,
    onValuesChange,
    isLoading,
    form,
    isNewItem,
    handleAddItem,
    editingKey,
    deleteAll,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    groupBySpec,
    setGroupBySpec,
    optionsViewsFilter,
    setViewFilter,
    viewFilter,
    canUpdate,
    handleUpload,
    isLoadingUpload,
    dataHidden,
    hiddenPrices,
    HiddenPricesForm,
    mergedColumns,
    headersPrices,
    headersFactors,
    columnsPreview,
    expandedRowKeys,
    setExpandedRowKeys,
  } = usePrices(dataModal);

  const title = optionsViewsFilter.find(({ value }) => value === viewFilter)?.label;
  const disabledActions = hiddenPrices || !(viewFilter === 'price' || viewFilter === 'factor');

  const scenarioName = selectedScenario
    ? `${params?.targetYear} - ${selectedScenario?.label}`
    : t('common.results.noScenarioTitle');

  const modifiedDate = selectedScenario
    ? (dataModal && dataModal.modified) || selectedScenario?.updated?.split('T')[0]
    : '';

  const showTagsFilter =
    params.header_spec?.length ||
    params.header_partNumber?.length ||
    params.header_supplier?.length;

  useEffect(() => {
    if (params.targetYear) {
      fetchScenarios();
    }
  }, [params.targetYear]);

  useEffect(() => {
    if (params.targetYear && params.scenario && canUpdate) {
      fetchPrices();
    }
  }, [params]);

  const expandRowIds = (expanded, record) => {
    const keys = [];

    if (record.partNumber) return; // if is a children, don't close the row

    if (expanded && record.spec) {
      keys.push(record.id);
    }

    setExpandedRowKeys(keys);
  };

  const handlePreview = async (dataPreview) => {
    const arrayToObj = csvArrayToObj(dataPreview);
    if (arrayToObj?.length) {
      setPreview(arrayToObj);
      closeModalPrice();
      openModalPreview();
    } else {
      closeModalPrice();
      closeModalPreview();
    }
  };

  const actions = [
    {
      key: '1',
      label: (
        <ActionButton
          buttonText={`${t('common.add')} ${t('common.item')}`}
          disabled={
            disabledActions || isNewItem || dataModal?.base === null || viewFilter === 'factor'
          }
          onClick={handleAddItem}
          icon={<BiPlus />}
          key={1}
        />
      ),
    },
    {
      key: '2',
      label: (
        <ActionButton
          icon={<BiUpload />}
          key={2}
          disabled={
            disabledActions || (!data?.length && viewFilter !== 'price') || dataModal?.base === null
          }
          onClick={openModalPrice}
        >
          {`${t('common.fields.uploadFile')}`}
        </ActionButton>
      ),
    },
    {
      key: '3',
      label: (
        <ActionButton icon={<BiDownload />} disabled={disabledActions} key={3}>
          <CsvExport
            url={getURI(`scenarios/${params.scenario}/prices`, {
              ...params,
              order: 'spec',
              orderBy: 'ASC',
            })}
            filename={[viewFilter, selectedScenario?.label]}
            limit={pageControl.totalElements}
            headers={viewFilter === 'factor' ? headersFactors : headersPrices}
            transformData={transformDataPrices}
          />
        </ActionButton>
      ),
    },
    {
      key: '4',
      label: (
        <Popconfirm key={4} title={t('common.deleteMessageAll')} onConfirm={() => deleteAll()}>
          <ActionButton
            buttonText={`${t('common.delete')} ${t('common.all')}`}
            disabled={disabledActions || dataModal?.base === null}
            icon={<AiOutlineDelete />}
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <S.MainContainer>
      <Row>
        {visiblePrice && (
          <StyledModal
            title={title}
            open={visiblePrice}
            onClose={closeModalPrice}
            body={
              <CsvUploadReader
                getCsvData={async (csvData) => {
                  await handlePreview(csvData);
                }}
              />
            }
            footer={null}
            width="46rem"
          />
        )}

        {visiblePreview && (
          <StyledModal
            title={`Preview - ${title}`}
            open={visiblePreview}
            width="95%"
            onClose={closeModalPreview}
            body={
              <S.PricePreviewTable
                rowKey="id"
                data-cy="table"
                striped
                columns={columnsPreview}
                dataSource={preview}
                pagination={false}
              />
            }
            footer={
              <SC.ModalFooter>
                <Button onClick={closeModalPreview}>{t('common.cancel')}</Button>
                <Button
                  type="primary"
                  loading={isLoadingUpload}
                  onClick={async () => {
                    await handleUpload(preview, closeModalPrice, closeModalPreview);
                  }}
                >
                  {t('common.fields.uploadFile')}
                </Button>
              </SC.ModalFooter>
            }
          />
        )}

        {view ? null : (
          <PricesFilter
            title={title}
            clearFilter={clearFilter}
            searchFilter={searchFilter}
            scenariosOptions={scenariosOptions}
            values={params}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <S.Container span={23} className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <StyledHeader
                  title={title}
                  subtitle={scenarioName}
                  items={pageControl.totalElements}
                  actions={
                    <Dropdown key={0} menu={{ items: actions }} placement="bottomRight">
                      <StyledButton variant="primary">
                        {t('common.action')}
                        <DownOutlined />
                      </StyledButton>
                    </Dropdown>
                  }
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <SC.ContainerViewsAndTags>
                  <FilterViewPF
                    value={viewFilter}
                    setValue={setViewFilter}
                    groupBySpec={groupBySpec}
                    setGroupBySpec={setGroupBySpec}
                    options={optionsViewsFilter}
                  />
                  <SC.ContainerFilteredTags>
                    {showTagsFilter && (
                      <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>
                    )}
                    {params.header_spec && (
                      <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_spec')}>
                        <div className="tag-title">{t('pages.prices.fields.spec')}</div>
                        <div className="tag-value">{params.header_spec}</div>
                      </SC.TagFilter>
                    )}
                    {params.header_partNumber && (
                      <SC.TagFilter
                        closable
                        onClose={() => handleCloseFilteredTag('header_partNumber')}
                      >
                        <div className="tag-title">{t('pages.prices.fields.partNumber')}</div>
                        <div className="tag-value">{params.header_partNumber}</div>
                      </SC.TagFilter>
                    )}
                    {params.header_supplier && (
                      <SC.TagFilter
                        closable
                        onClose={() => handleCloseFilteredTag('header_supplier')}
                      >
                        <div className="tag-title">{t('pages.prices.fields.supplier')}</div>
                        <div className="tag-value">{params.header_supplier}</div>
                      </SC.TagFilter>
                    )}
                  </SC.ContainerFilteredTags>

                  <HiddenPricesForm disabledActions={editingKey !== ''} />
                </SC.ContainerViewsAndTags>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                {viewFilter === 'price' || viewFilter === 'factor' ? (
                  <Form
                    form={form}
                    component={false}
                    onValuesChange={onValuesChange}
                    validateMessages={validateMessages}
                  >
                    <ConfigProvider>
                      <SimpleTable
                        rowKey="id"
                        data-cy="table"
                        size="small"
                        striped
                        components={{
                          body: {
                            cell: EditableCell,
                          },
                        }}
                        scroll={{ x: 'max-content', y: 'calc(100vh - 24rem)' }}
                        columns={mergedColumns}
                        dataSource={hiddenPrices ? dataHidden : data}
                        pagination={false}
                        expandRowByClick
                        defaultExpandedRowKeys={showTagsFilter ? [data[0]?.id] : null}
                        expandable={{ expandedRowKeys, indentSize: 0 }}
                        onExpand={expandRowIds}
                        locale={{
                          emptyText: (
                            <ResultNoScenarios
                              title={t('common.results.noDataTitle')}
                              subTitle={t('common.results.noDataSubtitle')}
                            />
                          ),
                        }}
                      />
                    </ConfigProvider>
                  </Form>
                ) : (
                  <TablePF
                    dataSource={hiddenPrices ? dataHidden : data}
                    showGap={viewFilter === 'price, factor and gap'}
                    bordered={false}
                    locale={{
                      emptyText: (
                        <ResultNoScenarios
                          title={t('common.results.noDataTitle')}
                          subTitle={t('common.results.noDataSubtitle')}
                        />
                      ),
                    }}
                  />
                )}

                {pageControl.totalPages > 1 && (
                  <StyledPagination
                    onChange={changePageValue}
                    pageNumber={params.page + 1}
                    size={params.limit}
                    totalElements={pageControl.totalElements}
                  />
                )}
              </Col>
            </Row>
          </S.Container>
        )}
      </Row>
    </S.MainContainer>
  );
}
