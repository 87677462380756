import StyledTabBarProps from './types';
import * as S from './styles';

export default function StyledTabs({ tabs, setSelectedTab }: StyledTabBarProps) {
  const handleClickStyledBar = (title: string) => {
    const newTabs = tabs.map((tab) =>
      tab.title === title ? { ...tab, selected: true } : { ...tab, selected: false }
    );
    setSelectedTab(newTabs);
  };

  const handleRenderTabs = () => {
    return (
      <>
        {tabs.map((tab) =>
          tab.selected ? (
            <S.StyledTabActive
              onClick={() => {
                handleClickStyledBar(tab.title);
              }}
              data-testid="selected-tab"
              key={tab.title}
            >
              {tab.title}
            </S.StyledTabActive>
          ) : (
            <S.StyledTabInactive
              onClick={() => {
                handleClickStyledBar(tab.title);
              }}
              key={tab.title}
              data-testid={tab.title}
            >
              {tab.title}
            </S.StyledTabInactive>
          )
        )}
      </>
    );
  };

  return <S.Container>{handleRenderTabs()}</S.Container>;
}
