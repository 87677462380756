import { Divider, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { MessageOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import * as S from './styles';
import { ICommentModal } from './types';

export function CommentsModal({ comments, altGroup, supplier }: ICommentModal) {
  const { t } = useTranslation();
  return (
    <S.Content>
      <Divider orientation="left" orientationMargin="0">
        <S.Title>
          <b>{supplier}</b> | {altGroup}
        </S.Title>
      </Divider>

      <S.CommentsTable>
        <S.TableHeader>
          <MessageOutlined />
          {t('pages.viewRfq.openCard.comments')}
        </S.TableHeader>
        <S.TableContent>
          <S.CommentContent>
            {comments.map((comment) => {
              return (
                <Flex key={comment.dateTime} gap={4} vertical>
                  <Flex gap={8}>
                    <S.Author author>
                      {comment.name} - {comment.supplier}
                    </S.Author>
                    <S.Author>{dayjs(comment.dateTime).format('YYYY/DD/MM')}</S.Author>
                    <S.Author>{dayjs(comment.dateTime).format('HH:mm')}</S.Author>
                  </Flex>
                  <S.Comment>{comment.comment}</S.Comment>
                </Flex>
              );
            })}
          </S.CommentContent>
        </S.TableContent>
      </S.CommentsTable>
    </S.Content>
  );
}
