import { t } from 'i18next';
import * as S from './styles';

export function PopConfirmUpdateBid() {
  return (
    <S.Container>
      <S.Title>
        {t('pages.bidResponse.popConfirm.title')}
        <br />
      </S.Title>
      <S.Subtitle>
        {t('pages.bidResponse.popConfirm.messageTitle')}
        <br />
        {t('pages.bidResponse.popConfirm.messageBody')}
      </S.Subtitle>
      <S.Subtitle>{t('pages.bidResponse.popConfirm.messageContinue')}</S.Subtitle>
    </S.Container>
  );
}
