import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { renderDecimal, renderPrice } from '../../../helpers/utils';
import { dataSimulationModal } from '../../../mocks/data';
import * as SC from '../../../styles/common';

export function useSimulationForm(scenarioName, resultData, params) {
  const { t } = useTranslation();
  const [paramsModal, setParamsModal] = useState(params);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const simulationFormForTable = (dataSimulation) => {
    // console.log('dataSimulation=>', dataSimulation);
    const dataClone = structuredClone(dataSimulation);
    const processedData = dataClone.map((item, index) => {
      return {
        id: item.id,
        partType: item.partType,
        currentScore: item.currentScore,
        maxScore: item.maxScore,
        investmentPoints: item.investmentPoints,
        kcFob: item.kcFob,
        kcBr: item.kcBr,
        kcTotal: item.kcTotal,
        totalGap: item.totalGap,
        totalCost: item.totalCost,
      };
    });
    return processedData;
  };

  const fetchSimulationModal = async () => {
    setData(resultData?.simulationPartTypes);
  };

  const columns = [
    {
      dataIndex: 'partType',
      title: t('pages.simulation.fields.category'),
      // render: (value) => value?.toLowerCase(),
    },
    {
      dataIndex: 'currentScore',
      title: <div dangerouslySetInnerHTML={{ __html: t('pages.simulation.fields.scoreModal') }} />,
      align: 'center',
      render: (currentScore, row) => {
        return (
          <>
            <SC.ScoreColorSpanNew score="min">
              {` ${renderDecimal(currentScore)} `}
            </SC.ScoreColorSpanNew>
            <span>| </span>
            <SC.ScoreColorSpanNew>{` ${renderDecimal(row?.maxScore)} `}</SC.ScoreColorSpanNew>
          </>
        );
      },
    },
    {
      dataIndex: 'costPerPoint',
      title: t('pages.simulation.fields.titleInvest'),
      align: 'center',
      render: (value) => renderPrice(value, false),
    },
    {
      dataIndex: 'kcFob',
      title: 'FOB',
      align: 'center',
      render: (value) => value,
    },
    {
      dataIndex: 'kcBr',
      title: 'BR',
      align: 'center',
      render: (value) => value,
    },
    {
      dataIndex: 'kcTotal',
      title: 'KC Total',
      align: 'center',
      render: (value) => value,
    },
    {
      dataIndex: 'totalGap',
      title: t('pages.simulation.fields.titleCostGap'),
      align: 'center',
      render: (value) => renderPrice(value, false),
    },
    {
      dataIndex: 'totalCost',
      title: t('pages.simulation.fields.titleCostTotal'),
      align: 'center',
      render: (value) => renderPrice(value, false),
    },
  ];

  return {
    data,
    isLoading,
    columns,
    paramsModal,
    fetchSimulationModal,
  };
}
