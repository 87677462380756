import React, { forwardRef } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalStyled = styled(Modal)`
  width: ${({ area, width }) => {
    switch (area) {
      case 'createScenarios':
      case 'PricesAndFactors':
      case 'Users':
        return '415px!important';

      case 'SimulationResult':
      case 'Alert':
        return '99%!important';

      default:
        return width ? `${width}${typeof width === 'number' ? 'px' : ''}` : 'auto !important';
    }
  }};

  min-width: ${({ area, style }) => {
    switch (area) {
      case 'createScenarios':
      case 'Users':
        return '415px!important';

      case 'Alert':
        return '1200px!important';

      case 'UploadSheets':
        return '30%!important';

      default:
        return style?.minWidth
          ? `${style?.minWidth}${typeof style?.minWidth === 'number' ? 'px' : ''}`
          : '750px';
    }
  }};

  max-width: ${({ area }) => {
    switch (area) {
      case 'SimulationResult':
        return '99%!important';

      default:
        return '92%';
    }
  }};

  .ant-modal-header {
    padding: 16px 24px;
  }

  .ant-modal-content {
    padding: 0px;
  }

  .ant-modal-body {
    display: flex;
    padding: 24px;
    justify-content: center;
    width: 100%;
    min-height: ${({ area }) => {
      return area === 'SimulationResult' ? '400px' : '350px';
    }};

    tr {
      td {
        padding: ${({ area }) => (area === 'SimulationResult' ? '7px 3px !important' : 'inherit')};
        font-size: ${({ area }) => (area === 'SimulationResult' ? '14px' : 'inherit')};
        color: ${({ area }) => (area === 'SimulationResult' ? '#444444' : 'inherit')};
      }
    }

    .ant-table-wrapper {
      width: 100%;
    }

    main {
      width: 100%;

      > .ant-row {
        gap: 0;
        padding: 0;
        margin: 0;
      }

      .filter-page {
        padding: 0;
      }

      .ant-col-23 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .ant-table-content > table {
        border-top: 0 !important;
      }

      .ant-table-container {
        border-left: 0 !important;
      }
    }
  }
`;

const ModalContainer = ({ isVisible, onClose, title, children, area = null, ...rest }, ref) => {
  return (
    <ModalStyled
      title={title}
      open={isVisible}
      onCancel={onClose}
      onOk={onClose}
      footer={null}
      width={area === 'UploadSheets' ? '45%' : null}
      area={area}
      {...rest}
    >
      {children}
    </ModalStyled>
  );
};

export const ModalWrapper = forwardRef(ModalContainer);
