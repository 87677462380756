import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { oneAlert } from '../../helpers/utils';
import { api } from '../../services/api';
import { RoutesPath } from '../../routes/routesPath';

export function useForgotPassword() {
  const history = useHistory();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [codeSended, setCodeSended] = useState(false);

  const handleNavigateBack = () => {
    history.goBack();
  };

  const onFinish = async (values) => {
    setIsLoading(true);

    try {
      const response = await api.post(`${import.meta.env.VITE_API_LOGIN_UNICO}/forgot-password`, {
        email: values.email,
      });

      console.log('onFinish/Forgot=>', response);

      if (response.status !== 200) throw Error();
      setCodeSended(true);
      oneAlert('success', t('pages.forgotPassword.codeSentMessage'));
    } catch (errorData) {
      oneAlert('error', t('toast.errorOnLogin'));
    }
    setIsLoading(false);
  };

  const onChangePassword = async (values) => {
    setIsLoading(true);

    try {
      const { email, password, confirmationCode } = values;
      const response = await api.post(
        `${import.meta.env.VITE_API_LOGIN_UNICO}/forgot-password-confirm`,
        {
          email,
          password,
          confirmationCode,
        }
      );

      if (response.status !== 200) throw Error();

      oneAlert('success', t('pages.forgotPassword.passwordChangedMessage'));
      history.push(RoutesPath.login);
    } catch (errorData) {
      oneAlert('error', t('toast.errorOnLogin'));
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    error,
    codeSended,
    onFinish,
    onChangePassword,
    handleNavigateBack,
  };
}
