import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const HeaderExpandableItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  span {
    color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
    font-weight: bold;
  }

  p {
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
    font-weight: bold;
    margin: 0;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 1.125rem;
`;

export const SubCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
`;

export const SubCard = styled.div`
  border-radius: 0.75rem;
  width: 30%;
  box-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.25);
`;

export const SubCardHeader = styled.div`
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  font-size: 0.75rem;
  border-bottom: solid 1px ${({ theme }) => theme.colorsDesignSystem.grayDivider};
  padding: 0.8rem 1.4rem;
`;

export const SubCardBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  padding: 1.2rem 1.4rem;
`;
export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const BlueText = styled.span`
  color: #0c6dff;
`;

export const ArrowLeft = styled(ArrowLeftOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  font-size: 0.7rem;
  padding: 0.4rem;
  border: solid 0.08rem ${({ theme }) => theme.colorsDesignSystem.grayBorder};
  border-radius: 0.5rem;
  box-shadow: 0px 0px 0.1rem rgba(0, 0, 0, 0.15);
  &:hover {
    border-color: ${({ theme }) => theme.colorsDesignSystem.primary};
    box-shadow: 0px 0px 0px 0.15rem rgba(128, 195, 67, 0.2);
  }
`;

export const ArrowRight = styled(ArrowRightOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  font-size: 0.7rem;
  padding: 0.4rem;
  border: solid 0.08rem ${({ theme }) => theme.colorsDesignSystem.grayBorder};
  border-radius: 0.5rem;
  box-shadow: 0px 0px 0.1rem rgba(0, 0, 0, 0.15);
  &:hover {
    border-color: ${({ theme }) => theme.colorsDesignSystem.primary};
    box-shadow: 0px 0px 0px 0.15rem rgba(128, 195, 67, 0.2);
  }
`;
