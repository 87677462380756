import { Col, ConfigProvider, Dropdown, Form, MenuProps, Row } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus, BiDownload } from 'react-icons/bi';
import { DownOutlined } from '@ant-design/icons';
import { ActionButton } from '../../components/ActionButton';
import { EditableCell } from '../../components/EditableCell';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import { useComponentsGroup } from './useComponentsGroup';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import * as SC from '../../styles/common';
import * as SB from './styles';
import { Loading } from '../../components/Loading';
import { getURI } from '../../helpers/utils';
import { ModalStyled } from '../../components/Modal';
import { SpecDetails } from './SpecDetails';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import { CsvExport } from '../../components/Common/CsvExport';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledButton from '../../components/Common/StyledButton';
import { StyledPagination } from '../../components/Common/StyledPagination';

export function ComponentsGroupPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.componentsGroup.title'));

  const validateMessages = ValidateMessages();

  const {
    data,
    params,
    pageControl,
    selectedProduct,
    fetchComponentGroups,
    handleChangeTable,
    isLoading,
    form,
    handleAddItem,
    onValuesChange,
    editingKey,
    handleCloseFilteredTag,
    expandedRowKeys,
    canUpdate,
    rowClassName,
    mergedColumns,
    transformData,
    headerCsv,
    setExpandedRowKeys,
    getQueryParams,
    modalData,
    onModalClose,
    handleRowClick,
    getActionLevel,
    fetchProducts,
    removeEmptyChildren,
    changePage,
  } = useComponentsGroup();

  const showTagsFilter =
    params.header_groupName?.length || params.header_partType?.length || params.header_spec?.length;

  const initialFetch = async () => {
    await fetchProducts();
    await fetchComponentGroups();
  };

  useEffect(() => {
    if (params.product && canUpdate && params?.productId?.length) {
      fetchComponentGroups();
    } else if ((!params.product || !params.product.includes('$')) && canUpdate) {
      initialFetch();
    }
  }, [params]);

  const expandRowIds = (expanded: boolean, record: any) => {
    const level = getActionLevel(record);
    const keys = [];
    const type = `${record.id.split('$')[0]}$${record.id.split('$')[1]}`;
    if (level === 'partType') {
      if (expanded) keys.push(record.id);
    } else if (level === 'groupName') {
      expanded ? keys.push(record.id, type) : keys.push(type);
    } else {
      const group = `${record.id.split('$')[0]}$${record.id.split('$')[1]}$${
        record.id.split('$')[2]
      }`;
      expanded ? keys.push(record.id, group, type) : keys.push(group, type);
    }

    setExpandedRowKeys(keys);
  };

  const actions: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <ActionButton
          key={1}
          icon={<BiPlus />}
          onClick={(e) => handleAddItem(e)}
          disabled={editingKey !== '' || isLoading}
          buttonText={`${t('common.add')} ${t('common.item')}`}
        />
      ),
    },
    {
      key: '2',
      label: (
        <ActionButton
          key={2}
          className="btn-csv"
          disabled={editingKey !== ''}
          icon={<BiDownload />}
        >
          <CsvExport
            url={getURI(`components/grouping/componentType`, getQueryParams())}
            filename={['components_grouping', selectedProduct?.label]}
            limit={pageControl.totalElements}
            headers={headerCsv}
            transformData={transformData}
          />
        </ActionButton>
      ),
    },
  ];

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <StyledHeader
                title={t('pages.componentsGroup.title')}
                items={pageControl.totalElements}
                actions={
                  <Dropdown key={0} menu={{ items: actions }} placement="bottomRight">
                    <StyledButton variant="primary">
                      {t('common.action')}
                      <DownOutlined />
                    </StyledButton>
                  </Dropdown>
                }
              />
            </Row>

            <Row justify="center">
              <SB.SearchFilter>
                <Col className="col-filters">
                  <SC.ContainerFilteredTags>
                    {showTagsFilter && (
                      <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>
                    )}
                    {params.header_partType?.length && (
                      <SC.TagFilter
                        closable
                        onClose={() => handleCloseFilteredTag('header_partType')}
                      >
                        <div className="tag-title">
                          {t('pages.componentsGroup.fields.partType')}
                        </div>
                        <div className="tag-value">{params.header_partType}</div>
                      </SC.TagFilter>
                    )}
                    {params.header_groupName?.length && (
                      <SC.TagFilter
                        closable
                        onClose={() => handleCloseFilteredTag('header_groupName')}
                      >
                        <div className="tag-title">
                          {t('pages.componentsGroup.fields.groupName')}
                        </div>
                        <div className="tag-value">{params.header_groupName}</div>
                      </SC.TagFilter>
                    )}
                    {params.header_spec?.length && (
                      <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_spec')}>
                        <div className="tag-title">{t('pages.componentsGroup.fields.spec')}</div>
                        <div className="tag-value">{params.header_spec}</div>
                      </SC.TagFilter>
                    )}
                  </SC.ContainerFilteredTags>
                </Col>
              </SB.SearchFilter>
            </Row>

            <Row justify="center">
              <Form
                form={form}
                component={false}
                onValuesChange={onValuesChange}
                validateMessages={validateMessages}
              >
                <ConfigProvider>
                  <SimpleTable
                    rowKey="id"
                    data-cy="table"
                    striped
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    scroll={{ x: 'max-content', y: 'calc(100-22rem)' }}
                    columns={mergedColumns}
                    dataSource={removeEmptyChildren(data)}
                    onRow={handleRowClick}
                    rowClassName={rowClassName}
                    onChange={handleChangeTable}
                    pagination={false}
                    expandRowByClick
                    defaultExpandedRowKeys={showTagsFilter ? [data[0]?.id] : []}
                    expandedRowKeys={expandedRowKeys}
                    indentSize={0}
                    onExpand={expandRowIds}
                    locale={{
                      emptyText: (
                        <ResultNoScenarios
                          title={t('common.results.noDataTitle')}
                          subTitle={t('common.results.noDataSubtitle')}
                          icon={null}
                        />
                      ),
                    }}
                  />
                </ConfigProvider>
              </Form>

              {pageControl.totalPages > 1 && (
                <StyledPagination
                  size={params.limit}
                  totalElements={pageControl.totalElements}
                  pageNumber={params.page}
                  onChange={changePage}
                />
              )}
              <ModalStyled
                key="modal-specs"
                title={modalData?.spec}
                open={modalData !== null}
                onCancel={onModalClose}
                onOk={onModalClose}
                width={600}
                footer={null}
              >
                <SpecDetails data={modalData} />
              </ModalStyled>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
