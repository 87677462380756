import { Tooltip } from 'recharts';
import { Row } from 'antd';
import { dataType } from '../../types';
import { Title, TooltipContainer, TooltipText } from './styles';

export const CustomTooltip = ({ active, payload, skusNames }: any) => {
  if (!active || !payload || !payload.length) {
    return null;
  }
  return (
    <TooltipContainer>
      <Title>{payload[0].payload.month}</Title>
      <Row style={{ gap: '0.3rem' }}>
        <p style={{ color: payload[0].color, fontSize: '1.6rem', marginBottom: '0.2rem' }}>•</p>
        <TooltipText>
          {skusNames[0]} : {payload[0].value}
        </TooltipText>
      </Row>
      {payload[1] && (
        <Row style={{ gap: '0.3rem' }}>
          <p style={{ color: payload[1].color, fontSize: '1.6rem', marginBottom: '0.2rem' }}>•</p>
          <TooltipText>
            {skusNames[1]} : {payload[1].value}
          </TooltipText>
        </Row>
      )}
      {payload[2] && (
        <Row style={{ gap: '0.3rem' }}>
          <p style={{ color: payload[2].color, fontSize: '1.6rem', marginBottom: '0.2rem' }}>•</p>
          <TooltipText>
            {skusNames[2]} : {payload[2].value}
          </TooltipText>
        </Row>
      )}
    </TooltipContainer>
  );
};
