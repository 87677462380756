import { Spin } from 'antd';
import * as S from './styles';

interface ILoading {
  height: number;
}

export function StyledLoading({ height }: ILoading) {
  return (
    <S.Loading height={height}>
      <Spin />
    </S.Loading>
  );
}
