import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../../services/api';
import {
  getURI,
  removeNullOptions,
  removeValuesDuplicates,
  useDidMountEffect,
} from '../../../helpers/utils';
import { SelectInput } from '../../LateralFilter/SelectInput';

function fetchComponents(
  componentType: any,
  valueAsId: boolean,
  search = null,
  spec: null | string = null,
  emptySearch = null
) {
  /********************
   *  simulador/components?partType=MEM&spec=SO8GBIV&order=spec&orderBy=ASC
   *
   * Se passar só o partType como filtro, o backend retorna só o primeiro componente (PartNumber) do spec
   * Se passar o spec como filtro, o backend retorna todos os componentes (PartNumber) do spec
   ********************/

  let params;
  if (search) {
    params = { spec: encodeURIComponent(search), order: 'spec', orderBy: 'ASC' };
  } else if (spec) {
    params = {
      specEquals: btoa(spec),
      order: 'spec',
      orderBy: 'ASC',
    };
  } else if (componentType) {
    params = { partType: encodeURIComponent(componentType), order: 'spec', orderBy: 'ASC' };
  }

  if (!params && emptySearch) {
    params = { order: 'spec', orderBy: 'ASC' };
  }

  if (!params) {
    return new Promise((resolve) => {
      resolve([]);
    });
  }

  return api.get(getURI('components', params)).then((resp) => {
    // console.log('resp=>', spec, params, resp?.data?.content);
    return resp?.data?.content?.map((comp: any) => ({
      label: spec ? comp.partNumber : comp.spec,
      value: valueAsId ? comp.id : spec ? comp.partNumber : comp.spec,
      key: comp.id,
    }));
  });
}

export default function ComponentsSelect({
  componentType,
  valueAsId = false,
  search = null,
  spec = null,
  handleLocal = null,
  emptySearch = null,
  ...props
}: any) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();
  // console.log('ComponentsSelect/Init=>', props?.id, componentType, spec, search);

  useDidMountEffect(() => {
    setFetching(true);
    fetchComponents(null, valueAsId, search, null, emptySearch)
      .then((choices) => {
        const optionsFiltered = !valueAsId
          ? removeValuesDuplicates(removeNullOptions(choices))
          : removeNullOptions(choices);
        setOptions(optionsFiltered);
        setFetching(false);
      })
      .catch((err) => {
        console.log('err/search=>', err);
        setOptions([]);
        setFetching(false);
      });
  }, [search]);

  useEffect(() => {
    setFetching(true);
    fetchComponents(componentType, valueAsId, null, null, emptySearch)
      .then((choices) => {
        const optionsFiltered = !valueAsId
          ? removeValuesDuplicates(removeNullOptions(choices))
          : removeNullOptions(choices);

        setOptions(optionsFiltered);
        setFetching(false);
      })
      .catch((err) => {
        console.log('err/componentType=>', err);
        setOptions([]);
        setFetching(false);
      });
  }, [componentType]);

  useEffect(() => {
    if (!spec?.length) {
      setFetching(false);
    } else {
      setFetching(true);
      fetchComponents(null, valueAsId, null, spec, emptySearch)
        .then((choices) => {
          const optionsFiltered = !valueAsId
            ? removeValuesDuplicates(removeNullOptions(choices))
            : removeNullOptions(choices);

          setOptions(optionsFiltered);
          setFetching(false);
        })
        .catch((err) => {
          console.log('err/spec=>', err);
          setOptions([]);
          setFetching(false);
        });
    }
  }, [spec]);

  return (
    <SelectInput
      showSearch
      filterOption={(input: string, option: any) => {
        return option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
      }}
      loading={fetching}
      placeholder={props.placeholder || t('filter.spec')}
      options={[{ label: t('common.none'), value: '' }, ...options]}
      {...props}
      style={{
        width: '100%',
      }}
    />
  );
}
