import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd/lib';
import { Tooltip } from 'antd';
import ExpandableItem from '../ExpandableItem';
import { useQuotes } from '../useQuotes';
import * as S from './styles';
import { TableHeaderTitle } from '../TableHeaderTitle/inxes';

interface TableQuotesProps {
  DATA: Array<any>;
  level: 'L10' | 'L6';
  cm: string;
  fxRate: string;
  location: string;
  date: any;
  hideLink: boolean;
}

export default function TableQuotes({
  DATA,
  level,
  cm,
  fxRate,
  location,
  date,
  hideLink,
}: TableQuotesProps) {
  const { t } = useTranslation();
  const CM_DEFAULT_LINK = 'Foxconn';

  const {
    handleRenderTableHeaderProjects,
    handleRenderTableHeaderSKus,
    handleRenderTableHeaderForecasts,
    handleRenderSubFieldsByTag,
    handleRenderFieldsBodyByFieldName,
    handleRenderRateFieldByTag,
    findSubItensForMemoryBadge,
    findSubItensForHDDBadge,
    findSubItensForSSDBadge,
  } = useQuotes();

  const [expandableView, setExpandableView] = useState({
    materialFob: false,
    materialLanded: false,
    localBy: false,
    materialCost: false,
    net: false,
    salesPrice: false,
    sellingPrice: false,
    memoryMaterialFob: false,
    hddMaterialFob: false,
    memoryLocalBy: false,
    hddLocalBy: false,
    ssdMaterialFob: false,
    ssdLocalBy: false,
  });

  const handleShowExpandableRows = (field: string) => {
    setExpandableView((prevState: any) => {
      return {
        ...prevState,
        [field]: !prevState[field],
      };
    });
  };

  const handleGetDescriptiomByItem = (project: any, group: string, subTag: string): string => {
    try {
      const value = project[group].find((subItem: any) => subItem.item === subTag);
      return value?.description || t('pages.quotes.labels.emptyTooltip');
    } catch (error) {
      console.log(error);
      return '';
    }
  };

  const handleRenderSubItensMemoryCategory = (subItens: Array<string>, category: string) => {
    return subItens.map((item, index) => {
      return (
        <tr style={{ width: '100%' }} key={item}>
          <Tooltip placement="top" title={handleGetDescriptiomByItem(DATA[0], category, item)}>
            <div className="table-body">
              {t('pages.quotes.tableHeaders.memory')}
              {index + 1}
            </div>
          </Tooltip>

          {handleRenderRateFieldByTag(category, item, DATA)}
          {handleRenderSubFieldsByTag(
            date,
            category,
            item,
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
      );
    });
  };

  const handleRenderMemoryCategory = (category: string): ReactNode => {
    const isMaterialFob = category === 'materialFobDtos';
    const itens = findSubItensForMemoryBadge(DATA, category);

    return itens.length === 0 ? (
      <tr>
        <Tooltip placement="top" title={handleGetDescriptiomByItem(DATA[0], category, 'memory')}>
          <div className="table-body">{t('pages.quotes.tableHeaders.memory')}</div>
        </Tooltip>
        {handleRenderRateFieldByTag(category, 'Memory', DATA)}
        {handleRenderSubFieldsByTag(
          date,
          category,
          'Memory',
          DATA,
          cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
          level
        )}
      </tr>
    ) : (
      <>
        {isMaterialFob && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#f6f8fa',
            }}
          >
            <S.HeaderCategories>
              <ExpandableItem
                showIcon
                isOpen={expandableView.memoryMaterialFob}
                badge={itens.length}
                title={t('pages.quotes.tableHeaders.memory')}
                onClick={() => {
                  handleShowExpandableRows('memoryMaterialFob');
                }}
              />
            </S.HeaderCategories>
            {expandableView.memoryMaterialFob &&
              handleRenderSubItensMemoryCategory(itens, category)}
          </div>
        )}
        {!isMaterialFob && (
          <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f6f8fa' }}>
            <S.HeaderCategories>
              <ExpandableItem
                showIcon
                isOpen={expandableView.memoryLocalBy}
                badge={itens.length}
                title={t('pages.quotes.tableHeaders.memory')}
                onClick={() => {
                  handleShowExpandableRows('memoryLocalBy');
                }}
              />
            </S.HeaderCategories>
            {expandableView.memoryLocalBy && handleRenderSubItensMemoryCategory(itens, category)}
          </div>
        )}
      </>
    );
  };

  const handleRenderSubItensSSDCategory = (subItens: Array<string>, category: string) => {
    return subItens.map((item, index) => {
      return (
        <tr style={{ width: '100%' }} key={item}>
          <Tooltip placement="top" title={handleGetDescriptiomByItem(DATA[0], category, item)}>
            <div className="table-body">
              {t('pages.quotes.tableHeaders.ssd')}
              {index + 1}
            </div>
          </Tooltip>
          {handleRenderRateFieldByTag(category, item, DATA)}
          {handleRenderSubFieldsByTag(
            date,
            category,
            item,
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
      );
    });
  };

  const handleRenderSSDCategory = (category: string): ReactNode => {
    const isMaterialFob = category === 'materialFobDtos';
    const itens = findSubItensForSSDBadge(DATA, category);

    return itens.length === 0 ? (
      <tr>
        <Tooltip placement="top" title={handleGetDescriptiomByItem(DATA[0], category, 'ssd')}>
          <div className="table-body">{t('pages.quotes.tableHeaders.ssd')}</div>
        </Tooltip>

        {handleRenderRateFieldByTag(category, 'ssd', DATA)}
        {handleRenderSubFieldsByTag(
          date,
          category,
          'ssd',
          DATA,
          cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
          level
        )}
      </tr>
    ) : (
      <>
        {isMaterialFob && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#f6f8fa',
            }}
          >
            <S.HeaderCategories>
              <ExpandableItem
                showIcon
                isOpen={expandableView.ssdMaterialFob}
                badge={itens.length}
                title={t('pages.quotes.tableHeaders.ssd')}
                onClick={() => {
                  handleShowExpandableRows('ssdMaterialFob');
                }}
              />
            </S.HeaderCategories>
            {expandableView.ssdMaterialFob && handleRenderSubItensSSDCategory(itens, category)}
          </div>
        )}
        {!isMaterialFob && (
          <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f6f8fa' }}>
            <S.HeaderCategories>
              <ExpandableItem
                showIcon
                isOpen={expandableView.ssdLocalBy}
                badge={itens.length}
                title={t('pages.quotes.tableHeaders.ssd')}
                onClick={() => {
                  handleShowExpandableRows('ssdLocalBy');
                }}
              />
            </S.HeaderCategories>
            {expandableView.ssdLocalBy && handleRenderSubItensSSDCategory(itens, category)}
          </div>
        )}
      </>
    );
  };

  const handleRenderSubItensHDDCategory = (subItens: Array<string>, category: string) => {
    return subItens.map((item, index) => {
      return (
        <tr style={{ width: '100%' }} key={item}>
          <Tooltip placement="top" title={handleGetDescriptiomByItem(DATA[0], category, item)}>
            <div className="table-body">
              {t('pages.quotes.tableHeaders.hdd')}
              {index + 1}
            </div>
          </Tooltip>
          {handleRenderRateFieldByTag(category, item, DATA)}
          {handleRenderSubFieldsByTag(
            date,
            category,
            item,
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
      );
    });
  };

  const handleRenderHDDCategory = (category: string): ReactNode => {
    const isMaterialFob = category === 'materialFobDtos';

    const itens = findSubItensForHDDBadge(DATA, category);

    return itens.length === 0 ? (
      <tr>
        <div className="table-body">{t('pages.quotes.tableHeaders.hdd')}</div>
        {handleRenderRateFieldByTag(category, 'HDD', DATA)}
        {handleRenderSubFieldsByTag(
          date,
          category,
          'HDD',
          DATA,
          cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
          level
        )}
      </tr>
    ) : (
      <>
        {isMaterialFob && (
          <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
            <S.HeaderCategories style={{ backgroundColor: 'white' }}>
              <ExpandableItem
                showIcon
                isOpen={expandableView.hddMaterialFob}
                badge={itens.length}
                title={t('pages.quotes.tableHeaders.hdd')}
                onClick={() => {
                  handleShowExpandableRows('hddMaterialFob');
                }}
              />
            </S.HeaderCategories>
            {expandableView.hddMaterialFob && handleRenderSubItensHDDCategory(itens, category)}
          </div>
        )}
        {!isMaterialFob && (
          <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
            <S.HeaderCategories style={{ backgroundColor: 'white' }}>
              <ExpandableItem
                showIcon
                isOpen={expandableView.hddLocalBy}
                badge={itens.length}
                title={t('pages.quotes.tableHeaders.hdd')}
                onClick={() => {
                  handleShowExpandableRows('hddLocalBy');
                }}
              />
            </S.HeaderCategories>
            {expandableView.hddLocalBy && handleRenderSubItensHDDCategory(itens, category)}
          </div>
        )}
      </>
    );
  };

  const handleEmptyTableL6L10 = () => {
    return (
      <S.TableContainer>
        <TableHeaderTitle location="Manaus - AM" code={level} cm={cm} fxRate={fxRate} />
        <S.EmptyTbody>
          <Empty description={t('common.noRecords')} />
        </S.EmptyTbody>
      </S.TableContainer>
    );
  };

  const handleRenderSubItensMaterialFobTableL6 = () => {
    return (
      <S.TableBodyExpandable>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'CPU')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.cpu')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'CPU', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'CPU',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'Chipset')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.chipset')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'Chipset', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'Chipset',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'VGA')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.vga')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('materialFobDtos', 'VGA', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'VGA',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'VRAM')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.vram')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'VRAM', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'VRAM',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>

        {handleRenderMemoryCategory('materialFobDtos')}

        {handleRenderHDDCategory('materialFobDtos')}

        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'EE')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.ee')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'EE', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'EE',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'freightCkd')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.freightCkd')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'freightCkd', DATA)}
          {handleRenderSubFieldsByTag(date, 'materialFobDtos', 'freightCkd', DATA, false, level)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'insuranceMaterial')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.insuranceMaterial')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'insuranceMaterial', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'insuranceMaterial',
            DATA,
            false,
            level
          )}
        </tr>
      </S.TableBodyExpandable>
    );
  };

  const handleRenderSubItensMaterialFobTableL10 = () => {
    return (
      <S.TableBodyExpandable>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'Adapter')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.adapter')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'Adapter', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'Adapter',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'ME')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.me')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'ME', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'ME',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'VGA')}
            // title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'VGA')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.vga')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'VGA', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'VGA',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'CPU')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.cpu')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'CPU', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'CPU',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'wifi')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.wifi')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'WIFI', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'WIFI',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>

        {handleRenderHDDCategory('materialFobDtos')}

        {handleRenderSSDCategory('materialFobDtos')}

        {/* <tr>
          <div className="table-body">{t('pages.quotes.tableHeaders.ssd')}</div>
          {handleRenderRateFieldByTag('materialFobDtos', 'ssd', DATA)}
          {handleRenderSubFieldsByTag(date,'materialFobDtos', 'ssd', DATA, true)}
        </tr> */}

        {handleRenderMemoryCategory('materialFobDtos')}

        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'LCM')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.lcm')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'LCM', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'LCM',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'Battery')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.battery')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'Battery', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'Battery',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'EE')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.ee')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'EE', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'EE',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'VRAM')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.vram')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'VRAM', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'VRAM',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'Packing')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.packing')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'Packing', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'Packing',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'freightCkd')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.freightCkd')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'freightCkd', DATA)}
          {handleRenderSubFieldsByTag(date, 'materialFobDtos', 'freightCkd', DATA, false, level)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'materialFobDtos', 'insuranceMaterial')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.insuranceMaterial')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('materialFobDtos', 'insuranceMaterial', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'materialFobDtos',
            'insuranceMaterial',
            DATA,
            false,
            level
          )}
        </tr>
      </S.TableBodyExpandable>
    );
  };

  const handleRenderSubItensMaterialLandedL6 = () => {
    return (
      <S.TableBodyExpandable>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'importedMaterialLandedDtos', 'importTax')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.importTax')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('importedMaterialLandedDtos', 'importTax', DATA)}
          {handleRenderSubFieldsByTag(date, 'importedMaterialLandedDtos', 'importTax', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(
              DATA[0],
              'importedMaterialLandedDtos',
              'suframaTaxTCIF'
            )}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.suframaTax')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('importedMaterialLandedDtos', 'suframaTaxTCIF', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'importedMaterialLandedDtos',
            'suframaTaxTCIF',
            DATA,
            false
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(
              DATA[0],
              'importedMaterialLandedDtos',
              'otherExpenses'
            )}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.otherExpenses')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('importedMaterialLandedDtos', 'otherExpenses', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'importedMaterialLandedDtos',
            'otherExpenses',
            DATA,
            false
          )}
        </tr>
      </S.TableBodyExpandable>
    );
  };

  const handleRenderSubItensMaterialLandedL10 = () => {
    return (
      <S.TableBodyExpandable>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'importedMaterialLandedDtos', 'importTax')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.importTax')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('importedMaterialLandedDtos', 'importTax', DATA)}
          {handleRenderSubFieldsByTag(date, 'importedMaterialLandedDtos', 'importTax', DATA, false)}
        </tr>
        <tr className="table-body">
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(
              DATA[0],
              'importedMaterialLandedDtos',
              'importCofins'
            )}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.importCofins')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('importedMaterialLandedDtos', 'importCofins', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'importedMaterialLandedDtos',
            'importCofins',
            DATA,
            false
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(
              DATA[0],
              'importedMaterialLandedDtos',
              'otherExpenses'
            )}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.otherExpenses')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('importedMaterialLandedDtos', 'otherExpenses', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'importedMaterialLandedDtos',
            'otherExpenses',
            DATA,
            false
          )}
        </tr>
      </S.TableBodyExpandable>
    );
  };

  const handleRenderSubItensLocalByL10 = () => {
    return (
      <S.TableBodyExpandable>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'localBuyDtos', 'EE')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.ee')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('localBuyDtos', 'EE', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'localBuyDtos',
            'EE',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'localBuyDtos', 'me')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.me')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('localBuyDtos', 'me', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'localBuyDtos',
            'me',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        {handleRenderHDDCategory('localBuyDtos')}

        {handleRenderSSDCategory('localBuyDtos')}

        {handleRenderMemoryCategory('localBuyDtos')}
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'localBuyDtos', 'wifi')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.wifi')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('localBuyDtos', 'Wifi', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'localBuyDtos',
            'Wifi',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'localBuyDtos', 'Adapter')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.adapter')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('localBuyDtos', 'Adapter', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'localBuyDtos',
            'Adapter',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>

        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'localBuyDtos', 'Battery')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.battery')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('localBuyDtos', 'Battery', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'localBuyDtos',
            'Battery',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'localBuyDtos', 'Packing_Local')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.packingLocal')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('localBuyDtos', 'Packing_Local', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'localBuyDtos',
            'Packing_Local',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'localBuyDtos', 'packingMaterial')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.packingMaterial')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('localBuyDtos', 'packingMaterial', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'localBuyDtos',
            'packingMaterial',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'localBuyDtos', 'motherboard')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.motherboard')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('localBuyDtos', 'motherboard', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'localBuyDtos',
            'motherboard',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'localBuyDtos', 'pisCofins')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.pisCofins')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('localBuyDtos', 'pisCofins', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'localBuyDtos',
            'pisCofins',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'localBuyDtos', 'ipiCredit')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.ipiCredit')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('localBuyDtos', 'ipiCredit', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'localBuyDtos',
            'ipiCredit',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
      </S.TableBodyExpandable>
    );
  };

  const handleRenderSubItensLocalByL6 = () => {
    return (
      <S.TableBodyExpandable>
        {handleRenderMemoryCategory('localBuyDtos')}

        {handleRenderHDDCategory('localBuyDtos')}

        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'localBuyDtos', 'EE')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.ee')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('localBuyDtos', 'EE', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'localBuyDtos',
            'EE',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'localBuyDtos', 'packingMaterial')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.packingMaterial')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('localBuyDtos', 'packingMaterial', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'localBuyDtos',
            'packingMaterial',
            DATA,
            cm.toUpperCase() === CM_DEFAULT_LINK.toUpperCase() ? !hideLink : false,
            level
          )}
        </tr>
      </S.TableBodyExpandable>
    );
  };

  const handleRenderSubItensNetL10 = () => {
    return (
      <S.TableBodyExpandable>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netDtos', 'EMS manufacturing cost')}
          >
            <div className="table-body">
              {t('pages.quotes.tableHeaders.emsManufacturingCost', DATA)}
            </div>
          </Tooltip>

          {handleRenderRateFieldByTag('netDtos', 'EMS manufacturing cost', DATA)}
          {handleRenderSubFieldsByTag(date, 'netDtos', 'EMS manufacturing cost', DATA, false)}
        </tr>
        {/* <tr>
          <div>{t('pages.quotes.tableHeaders.mvaUsdPart')}</div>
          {handleRenderRateFieldByTag('netDtos', 'mvaUSDPart', DATA)}
          {handleRenderSubFieldsByTag(date,'netDtos', 'mvaUSDPart', DATA, false)}
        </tr> */}
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netDtos', 'Finance')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.finance')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('netDtos', 'Finance', DATA)}
          {handleRenderSubFieldsByTag(date, 'netDtos', 'Finance', DATA, false)}
        </tr>
        <tr>
          <Tooltip placement="top" title={handleGetDescriptiomByItem(DATA[0], 'netDtos', 'Hedge')}>
            <div className="table-body">{t('pages.quotes.tableHeaders.hedgeDto')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('netDtos', 'Hedge', DATA)}
          {handleRenderSubFieldsByTag(date, 'netDtos', 'Hedge', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netDtos', 'Freight from Manaus to Jundiai')}
          >
            <div className="table-body">
              {t('pages.quotes.tableHeaders.freigthJundiaiAcer', DATA)}
            </div>
          </Tooltip>
          {handleRenderRateFieldByTag('netDtos', 'Freight from Manaus to Jundiai', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'netDtos',
            'Freight from Manaus to Jundiai',
            DATA,
            false
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netDtos', 'projectInvestiments')}
          >
            <div className="table-body">
              {t('pages.quotes.tableHeaders.projectInvestiments', DATA)}
            </div>
          </Tooltip>

          {handleRenderRateFieldByTag('netDtos', 'projectInvestiments', DATA)}
          {handleRenderSubFieldsByTag(date, 'netDtos', 'projectInvestiments', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netDtos', 'nationalCost')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.nationalCost')}</div>
          </Tooltip>

          {handleRenderRateFieldByTag('netDtos', 'nationalCost', DATA)}
          {handleRenderSubFieldsByTag(date, 'netDtos', 'nationalCost', DATA, false)}
        </tr>
      </S.TableBodyExpandable>
    );
  };

  const handleRenderSubItensNetL6 = () => {
    return (
      <S.TableBodyExpandable>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netDtos', 'EMS manufacturing cost')}
          >
            <div className="table-body">
              {t('pages.quotes.tableHeaders.emsManufacturingCost', DATA)}
            </div>
          </Tooltip>

          {handleRenderRateFieldByTag('netDtos', 'EMS manufacturing cost', DATA)}
          {handleRenderSubFieldsByTag(date, 'netDtos', 'EMS manufacturing cost', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netDtos', 'Finance')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.finance')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('netDtos', 'Finance', DATA)}
          {handleRenderSubFieldsByTag(date, 'netDtos', 'Finance', DATA, false)}
        </tr>
        <tr>
          <Tooltip placement="top" title={handleGetDescriptiomByItem(DATA[0], 'netDtos', 'Hedge')}>
            <div className="table-body">{t('pages.quotes.tableHeaders.hedgeDto')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('netDtos', 'Hedge', DATA)}
          {handleRenderSubFieldsByTag(date, 'netDtos', 'Hedge', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netDtos', 'Freight from Manaus to Jundiai')}
          >
            <div className="table-body">
              {t('pages.quotes.tableHeaders.freigthJundiaiAcer', DATA)}
            </div>
          </Tooltip>
          {handleRenderRateFieldByTag('netDtos', 'Freight from Manaus to Jundiai', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'netDtos',
            'Freight from Manaus to Jundiai',
            DATA,
            false
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netDtos', 'projectInvestmentNRE')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.others', DATA)}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('netDtos', 'projectInvestmentNRE', DATA)}
          {handleRenderSubFieldsByTag(date, 'netDtos', 'projectInvestmentNRE', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netDtos', 'nationalCost')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.nationalCost')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('netDtos', 'nationalCost', DATA)}
          {handleRenderSubFieldsByTag(date, 'netDtos', 'nationalCost', DATA, false)}
        </tr>
      </S.TableBodyExpandable>
    );
  };

  const handleRenderSubItensNetSalesPriceL10 = () => {
    return (
      <S.TableBodyExpandable>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netSalesPriceDtos', 'PPB benefit')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.PPBBenefit')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('netSalesPriceDtos', 'PPB benefit', DATA)}
          {handleRenderSubFieldsByTag(date, 'netSalesPriceDtos', 'PPB benefit', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netSalesPriceDtos', 'R&D')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.RD')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('netSalesPriceDtos', 'R&D', DATA)}
          {handleRenderSubFieldsByTag(date, 'netSalesPriceDtos', 'R&D', DATA, false)}
        </tr>
      </S.TableBodyExpandable>
    );
  };

  const handleRenderSubItensNetSalesPriceL6 = () => {
    return (
      <S.TableBodyExpandable>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netSalesPriceDtos', 'ICMS Rebate')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.icmsRebate')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('netSalesPriceDtos', 'ICMS Rebate', DATA)}
          {handleRenderSubFieldsByTag(date, 'netSalesPriceDtos', 'ICMS Rebate', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(
              DATA[0],
              'netSalesPriceDtos',
              'PIS&COFINS Over ICMS Rebate'
            )}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.pisCofinsIcmsRebate')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('netSalesPriceDtos', 'PIS&COFINS Over ICMS Rebate', DATA)}
          {handleRenderSubFieldsByTag(
            date,
            'netSalesPriceDtos',
            'PIS&COFINS Over ICMS Rebate',
            DATA,
            false
          )}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'netSalesPriceDtos', 'R&D')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.RD')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('netSalesPriceDtos', 'R&D', DATA)}
          {handleRenderSubFieldsByTag(date, 'netSalesPriceDtos', 'R&D', DATA, false)}
        </tr>
      </S.TableBodyExpandable>
    );
  };

  const handleRenderSubItensGrossSellingPriceL10 = () => {
    return (
      <S.TableBodyExpandable>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'grossSellingPriceDtos', 'ICMS')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.icms')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('grossSellingPriceDtos', 'ICMS', DATA)}
          {handleRenderSubFieldsByTag(date, 'grossSellingPriceDtos', 'ICMS', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'grossSellingPriceDtos', 'icmsst')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.icmsst')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('grossSellingPriceDtos', 'icmsst', DATA)}
          {handleRenderSubFieldsByTag(date, 'grossSellingPriceDtos', 'icmsst', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'grossSellingPriceDtos', 'PIS')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.pis')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('grossSellingPriceDtos', 'PIS', DATA)}
          {handleRenderSubFieldsByTag(date, 'grossSellingPriceDtos', 'PIS', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'grossSellingPriceDtos', 'Cofins')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.cofins')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('grossSellingPriceDtos', 'Cofins', DATA)}
          {handleRenderSubFieldsByTag(date, 'grossSellingPriceDtos', 'Cofins', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'grossSellingPriceDtos', 'IPI')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.ipi')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('grossSellingPriceDtos', 'IPI', DATA)}
          {handleRenderSubFieldsByTag(date, 'grossSellingPriceDtos', 'IPI', DATA, false)}
        </tr>
      </S.TableBodyExpandable>
    );
  };

  const handleRenderSubItensGrossSellingPriceL6 = () => {
    return (
      <S.TableBodyExpandable>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'grossSellingPriceDtos', 'ICMS')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.icms')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('grossSellingPriceDtos', 'ICMS', DATA)}
          {handleRenderSubFieldsByTag(date, 'grossSellingPriceDtos', 'ICMS', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'grossSellingPriceDtos', 'PIS')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.pis')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('grossSellingPriceDtos', 'PIS', DATA)}
          {handleRenderSubFieldsByTag(date, 'grossSellingPriceDtos', 'PIS', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'grossSellingPriceDtos', 'Cofins')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.cofins')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('grossSellingPriceDtos', 'Cofins', DATA)}
          {handleRenderSubFieldsByTag(date, 'grossSellingPriceDtos', 'Cofins', DATA, false)}
        </tr>
        <tr>
          <Tooltip
            placement="top"
            title={handleGetDescriptiomByItem(DATA[0], 'grossSellingPriceDtos', 'IPI')}
          >
            <div className="table-body">{t('pages.quotes.tableHeaders.ipi')}</div>
          </Tooltip>
          {handleRenderRateFieldByTag('grossSellingPriceDtos', 'IPI', DATA)}
          {handleRenderSubFieldsByTag(date, 'grossSellingPriceDtos', 'IPI', DATA, false)}
        </tr>
      </S.TableBodyExpandable>
    );
  };

  return (
    <>
      {DATA.length > 0 && (
        <S.container>
          <S.TableContainer>
            <TableHeaderTitle location={location} code={level} cm={cm} fxRate={fxRate} />
            <S.TableHeaderProjectModelName>
              <div>{t('pages.quotes.tableHeaders.projectModelName')}</div>
              {handleRenderTableHeaderProjects(DATA)}
            </S.TableHeaderProjectModelName>
            <S.TableHeaderSkus>
              <div>{t('pages.quotes.labels.sku')}</div>
              {handleRenderTableHeaderSKus(DATA)}
            </S.TableHeaderSkus>
            <S.TableHeaderForecast>
              <S.EstimatedForecastContainer style={{ justifyContent: 'space-between' }}>
                <span>{t('pages.quotes.labels.estimatedDemand')}</span>
                <span>Rate</span>
              </S.EstimatedForecastContainer>

              {handleRenderTableHeaderForecasts(DATA)}
            </S.TableHeaderForecast>
            <S.HeaderCategories>
              <ExpandableItem
                showIcon
                onClick={() => {
                  handleShowExpandableRows('materialFob');
                }}
                isOpen={expandableView.materialFob}
                title={t('pages.quotes.tableHeaders.materialFob').replace(/ /g, '\u00a0')}
              />
              {handleRenderFieldsBodyByFieldName('totalImportedMaterialFob', DATA)}
            </S.HeaderCategories>
            {expandableView.materialFob &&
              level === 'L10' &&
              handleRenderSubItensMaterialFobTableL10()}
            {expandableView.materialFob &&
              level === 'L6' &&
              handleRenderSubItensMaterialFobTableL6()}
            <S.HeaderCategories>
              <ExpandableItem
                showIcon
                onClick={() => {
                  handleShowExpandableRows('materialLanded');
                }}
                isOpen={expandableView.materialLanded}
                title={t('pages.quotes.tableHeaders.materialLanded')}
              />
              {handleRenderFieldsBodyByFieldName('totalImportedMaterialLanded', DATA)}
            </S.HeaderCategories>
            {expandableView.materialLanded &&
              level === 'L6' &&
              handleRenderSubItensMaterialLandedL6()}
            {expandableView.materialLanded &&
              level === 'L10' &&
              handleRenderSubItensMaterialLandedL10()}
            <S.HeaderCategories>
              <ExpandableItem
                showIcon
                isOpen={expandableView.localBy}
                title={t('pages.quotes.tableHeaders.localBuyIpiRebate').replace(/ /g, '\u00a0')}
                onClick={() => {
                  handleShowExpandableRows('localBy');
                }}
              />
            </S.HeaderCategories>
            {expandableView.localBy && level === 'L10' && handleRenderSubItensLocalByL10()}
            {expandableView.localBy && level === 'L6' && handleRenderSubItensLocalByL6()}

            <S.HeaderCategories>
              <ExpandableItem
                isOpen={expandableView.materialCost}
                title={t('pages.quotes.tableHeaders.totalCost')}
                onClick={() => {
                  handleShowExpandableRows('materialCost');
                }}
              />
              {handleRenderFieldsBodyByFieldName('totalMaterialCost', DATA)}
              {/* <p>{t('pages.quotes.tableHeaders.totalCost')}</p> */}
            </S.HeaderCategories>
            <S.HeaderCategories>
              <ExpandableItem
                showIcon
                onClick={() => {
                  handleShowExpandableRows('net');
                }}
                isOpen={expandableView.net}
                title={t('pages.quotes.tableHeaders.net')}
              />
              {handleRenderFieldsBodyByFieldName('totalNet', DATA)}
            </S.HeaderCategories>
            {expandableView.net && level === 'L10' && handleRenderSubItensNetL10()}
            {expandableView.net && level === 'L6' && handleRenderSubItensNetL6()}
            <S.HeaderCategories>
              <ExpandableItem
                showIcon
                isOpen={expandableView.salesPrice}
                title={t('pages.quotes.tableHeaders.netSalesPrice')}
                onClick={() => {
                  handleShowExpandableRows('salesPrice');
                }}
              />
              {handleRenderFieldsBodyByFieldName('totalNetSalesPrice', DATA)}
            </S.HeaderCategories>
            {expandableView.salesPrice && level === 'L10' && handleRenderSubItensNetSalesPriceL10()}
            {expandableView.salesPrice && level === 'L6' && handleRenderSubItensNetSalesPriceL6()}
            <S.HeaderCategories>
              <ExpandableItem
                showIcon
                isOpen={expandableView.sellingPrice}
                title={t('pages.quotes.tableHeaders.grossSellingPrice')}
                onClick={() => {
                  handleShowExpandableRows('sellingPrice');
                }}
              />
              {handleRenderFieldsBodyByFieldName('totalGrossSellingPriceDto', DATA)}
            </S.HeaderCategories>
            {expandableView.sellingPrice &&
              level === 'L10' &&
              handleRenderSubItensGrossSellingPriceL10()}
            {expandableView.sellingPrice &&
              level === 'L6' &&
              handleRenderSubItensGrossSellingPriceL6()}
          </S.TableContainer>
        </S.container>
      )}

      {/* renderizacão de tabela sem conteúdo */}
      {!DATA.length && handleEmptyTableL6L10()}
    </>
  );
}
