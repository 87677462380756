import { Tag } from 'antd';
import styled from 'styled-components';

import theme from '../../../styles/theme';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
`;

export const TitleWithLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TitleText = styled.span`
  font-size: 1.25rem;
  font-weight: 400;
  margin-right: 1rem;
`;

export const TitleLine = styled.div`
  flex-grow: 1;
  height: 0.063rem;
  background-color: ${theme.colorsDesignSystem.grayBorder};
`;

export const ColumnHeader = styled.p`
  color: ${theme.colorsDesignSystem.headerSubTitleTableText};
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0;
`;
export const HeaderRecovery = styled.p`
  color: ${theme.colorsDesignSystem.headerSubTitleTableText};
  font-size: 0.625rem;
  font-weight: 700;
  margin: 0;
`;

export const ContainerHeaderRecovery = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PercentData = styled.p`
  color: ${theme.colorsDesignSystem.blue};
  margin: 0;
`;
export const RecoveryPercentData = styled.p`
  color: ${theme.colorsDesignSystem.blue};
  margin: 0;
`;

export const SpecTag = styled(Tag)`
  background-color: ${theme.colorsDesignSystem.tagColorBlueBackground};
  border-radius: 0.25rem !important;
  border-color: ${theme.colorsDesignSystem.tagColorBlue};
  color: ${theme.colorsDesignSystem.tagColorBlue};
  .text {
    font-size: 0.75rem;
    color: ${theme.colorsDesignSystem.tagColorBlue};
    font-weight: 600;
  }
`;
