import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

export function CustomPagination({ page, pageLabel, limit, total, totalPages, changePageValue }) {
  const { t } = useTranslation();

  const limitXpage = limit * page;
  const from = page > 1 ? limitXpage + 1 - limit : page;
  const to = limitXpage > total ? total : limitXpage;
  const showing = `${t('common.showing')}: ${from}-${to} ${t('common.of')} ${total}`;

  const onChangeInput = (event) => {
    const { value } = event.target;
    const pageInput = parseInt(value, 10);

    //only send with enter key
    if (event.which === 13 || event.keyCode === 13) {
      if (typeof pageInput === 'number' && pageInput > 0) {
        const pageNumber = pageInput > totalPages ? totalPages : pageInput;
        changePageValue(pageNumber, 'input');
      }
    }
  };

  return (
    <S.Pagination>
      <div className="total">{showing}</div>

      <div className="controls">
        <S.PageBtn
          type="button"
          className="previous"
          page={page}
          totalPages={totalPages}
          onClick={() => page > 1 && changePageValue(page - 1, 'navigation')}
        >
          <LeftOutlined /> {t('common.previous')}
        </S.PageBtn>
        <span className="page-text">{t('common.page')}</span>

        <div className="input-wrapper">
          <input
            type="text"
            className="input-pagination"
            maxLength={3}
            placeholder={pageLabel}
            value={pageLabel}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              e.target.select();
            }}
            onChange={(e) => changePageValue(e.target.value)}
            onKeyPress={onChangeInput}
          />
        </div>

        <span className="page-of-text">
          {t('common.of')} {totalPages}
        </span>
        <S.PageBtn
          type="button"
          className="next"
          page={page}
          totalPages={totalPages}
          onClick={() => page < totalPages && changePageValue(page + 1, 'navigation')}
        >
          {t('common.next')} <RightOutlined />
        </S.PageBtn>
      </div>
    </S.Pagination>
  );
}
