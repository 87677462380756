import React, { useEffect } from 'react';
import { Form, ConfigProvider } from 'antd';
import { useSimulationForm } from './useSimulationForm';
import * as S from './styles';
import { Loading } from '../../../components/Loading';

export function SimulationData({ onClose, scenarioName, resultData, params }) {
  const { isLoading, columns } = useSimulationForm(scenarioName, resultData, params);

  const modalData = resultData?.simulationPartTypes;

  return (
    <S.Row justify="center">
      {isLoading ? (
        <Loading size={2} />
      ) : (
        <S.Row>
          <S.H4>
            Summary with total and percentage in PPB, Quantity and Simulation and by grouping
          </S.H4>

          <Form component={false} name="form_result">
            <ConfigProvider>
              <S.Table
                rowKey="id"
                data-cy="table"
                size="small"
                bordered
                columns={columns}
                dataSource={modalData}
                pagination={false}
              />
            </ConfigProvider>
          </Form>
        </S.Row>
      )}
    </S.Row>
  );
}
