import {
  DeleteOutlined,
  InfoCircleFilled,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { message, Spin, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDragAndDropUploader } from './useDragAndDropUploader';
import * as S from './styles';
import { DropFilesProp } from './types';
import theme from '../../../styles/theme';

export function DragAndDropUploader({ loading, onFile, onClearFile }: DropFilesProp) {
  const { Dragger } = Upload;
  const { handleFileUpload, files } = useDragAndDropUploader();
  const { t } = useTranslation();
  const spinIcon = <LoadingOutlined style={{ fontSize: '2.625rem' }} spin />;
  const KB_SIZE = 1000;
  const validate = (fileType: string | undefined) => {
    if (
      fileType === 'application/vnd.ms-excel' ||
      fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      return true;
    }
    return false;
  };

  const handleClearFileSelected = () => {
    //limpando file no componente
    handleFileUpload(null);
    //limpando file na tela
    onClearFile();
  };

  const props: UploadProps = {
    name: 'file',
    customRequest: handleFileUpload,
    accept:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',

    beforeUpload(file) {
      const isExcel = validate(file.type);
      isExcel
        ? onFile(file)
        : message.error(t('components.modals.uploadSheets.dragAndDrop.fileError'));
    },
    onDrop(file) {
      const isExcel = validate(file.dataTransfer.files[0].type);
      isExcel
        ? onFile(file.dataTransfer.files[0])
        : message.error(t('components.modals.uploadSheets.dragAndDrop.fileError'));
    },
  };
  const isExcel = validate(files?.file.type);
  return files ? (
    isExcel ? (
      <S.FileVisualization>
        {!loading ? (
          <>
            <S.StyledCheckCircleOutlined />
            <S.DeleteButton onClick={handleClearFileSelected}>
              <DeleteOutlined />
            </S.DeleteButton>
          </>
        ) : (
          <Spin indicator={spinIcon} />
        )}
        <S.TextContainerFileVisualization>
          <S.TextFileName>{files.file.name}</S.TextFileName>
          <p className="fileSize">{files.file.size / KB_SIZE} KB</p>
        </S.TextContainerFileVisualization>
      </S.FileVisualization>
    ) : (
      <S.FileTypeError>
        <S.ExclamationIcon />
        <S.DeleteButton onClick={handleClearFileSelected}>
          <DeleteOutlined />
        </S.DeleteButton>
        <S.TextContainerFileVisualization>
          <S.TextFileName>{files.file.name}</S.TextFileName>
          <p className="fileSize">{files.file.size / KB_SIZE} KB</p>
          <p className="unsupported">Unsupported file format</p>
        </S.TextContainerFileVisualization>
      </S.FileTypeError>
    )
  ) : (
    <S.DraggerContainer>
      <Dragger height={180} {...props}>
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">{t('components.modals.uploadSheets.dragAndDrop.title')}</p>
        <p style={{ color: theme.colorsDesignSystem.grayText }}>
          {t('components.modals.uploadSheets.dragAndDrop.subTitle')}
        </p>
      </Dragger>
    </S.DraggerContainer>
  );
}
