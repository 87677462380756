import styled from 'styled-components';
import theme from '../../../../../styles/theme';

interface LegendIndex {
  index: number;
}

export const TooltipContainer = styled.div`
  min-width: 11.25rem;
  border: 0.063rem solid ${theme.colorsDesignSystem.backgroundTooltipChart};
  border-radius: 0.313rem;
  background-color: ${theme.colorsDesignSystem.backgroundTooltipChart};
  box-shadow: 0 0.25rem 0.625rem ${theme.colorsDesignSystem.boxshadowTooltipChart};
  :hover {
    background-color: none;
  }
`;
export const TooltipBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
`;
export const SupplierName = styled.p`
  margin: 0 0 0.25rem 0;
  color: ${theme.colorsDesignSystem.text};
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
`;
export const PartNumber = styled.p`
  color: ${theme.colorsDesignSystem.blue};
  font-size: 0.625rem;
  font-weight: 700;
  margin: 0 0 0.25rem 0;
`;
export const ShareProportionValue = styled.div`
  margin: 0 0 0.5rem 0;
  color: ${theme.colorsDesignSystem.grayAcer};
  font-size: 0.75rem;
  font-weight: 700;
`;

export const ShareProportion = styled.div`
  margin: 0 0 0 0;
  color: ${theme.colorsDesignSystem.text};
  font-size: 0.75rem;
  font-weight: 400;
`;

export const ShareProportionContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
export const SupplierContentContainer = styled.div`
  /* border-bottom: 1px solid ${theme.colorsDesignSystem.grayBorder}; */
  /* margin-top: 0.5rem; */
`;

export const LegendTooltip = styled.span<LegendIndex>`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  margin-top: 0.125rem;
  background-color: ${({ index }) => {
    if (index === 0) {
      return `${theme.colorsDesignSystem.chartColors.lightGreen}`;
    }
    if (index === 1) {
      return `${theme.colorsDesignSystem.chartColors.green}`;
    }
    if (index === 2) {
      return `${theme.colorsDesignSystem.chartColors.gray}`;
    }
  }};
`;
export const ItensContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  border-bottom: 1px solid ${theme.colorsDesignSystem.grayBorder};
  &:last-child {
    border: none;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  justify-content: end;
  margin: 1rem 1.875rem 0 0;
`;
export const TextLegend = styled.span`
  color: ${theme.colorsDesignSystem.grayAcer};
`;

export const IndicatorLegend = styled.div<LegendIndex>`
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  background-color: ${({ index }) => {
    if (index === 0) {
      return `${theme.colorsDesignSystem.chartColors.lightGreen}`;
    }
    if (index === 1) {
      return `${theme.colorsDesignSystem.chartColors.green}`;
    }
    if (index === 2) {
      return `${theme.colorsDesignSystem.chartColors.gray}`;
    }
  }};
`;
