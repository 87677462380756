/* eslint-disable react/require-default-props */
import { useTranslation } from 'react-i18next';
import { renderDate } from '../../../helpers/utils';
import * as S from './styles';
import * as SC from '../../../styles/common';

interface HeaderTitleProps {
  title: string;
  subtitle?: string | null;
  updated?: string | null;
  breadcrumb?: string | null;
}
export function HeaderTitle({
  title,
  subtitle = null,
  updated = null,
  breadcrumb = null,
}: HeaderTitleProps) {
  const { t } = useTranslation();

  return (
    <S.HeaderTitleContainer>
      {!breadcrumb ? (
        <>
          {title || null}{' '}
          {subtitle && (
            <>
              - <SC.SpanBlueBig>{subtitle}</SC.SpanBlueBig>
            </>
          )}
        </>
      ) : (
        breadcrumb
      )}
      {updated && (
        <S.UpdatedInfo>
          {t('common.updated')} {renderDate(updated, '2-digit')}
        </S.UpdatedInfo>
      )}
    </S.HeaderTitleContainer>
  );
}
