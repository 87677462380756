import styled from 'styled-components';

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-end;
  margin-bottom: 0.75rem;
  margin-top: 2rem;
`;

export const FiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.938rem;
  margin-bottom: 2.75rem;
  gap: 0.375rem;
`;

export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  .icon {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
    font-size: 0.875rem;
  }
`;
