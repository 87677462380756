import styled from 'styled-components';

interface cardsQuantities {
  arrayLength: number;
  isSelected?: boolean;
  percentErro?: boolean;
}

export const Container = styled.main<cardsQuantities>`
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 1rem;
  gap: 0.5rem;

  @media (max-width: 1360px) {
    gap: ${({ arrayLength }) => (arrayLength > 2 ? `0.5rem` : `1rem`)};
  }
`;

export const ItemContainer = styled.div<cardsQuantities>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ isSelected, theme }) =>
    isSelected
      ? `${theme.colorsDesignSystem.cardBidSelected}`
      : `${theme.colorsDesignSystem.tag_background}`};
  padding: 1rem 1.1rem;
  border: 0.06rem solid
    ${({ isSelected, theme }) =>
      isSelected
        ? `${theme.colorsDesignSystem.cardBorder}`
        : `${theme.colorsDesignSystem.tertiaryBorder}`};
  border-radius: 0.8rem;
  line-height: 1.5rem;
  gap: 1.5rem;
  width: ${({ arrayLength }) => (arrayLength > 2 ? `33%` : `50%`)};
  cursor: pointer;

  @media (max-width: 1360px) {
    gap: ${({ arrayLength }) => (arrayLength > 2 ? `0.5rem` : `1.5rem`)};
    padding: ${({ arrayLength }) => (arrayLength > 2 ? `1.3rem 0.8rem;` : `1rem 1.1rem`)};
  }

  :hover {
    border: 0.04rem solid ${({ theme }) => theme.colorsDesignSystem.primary};
    box-shadow: 0 0 2.5px 0.3px ${({ theme }) => theme.colorsDesignSystem.primary};
  }
`;

export const Label = styled.span`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
`;

export const PartNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 0.06rem solid ${({ theme }) => theme.colorsDesignSystem.boxshadowTooltipChart};
  padding-right: 2rem;
`;

export const PartNumberValue = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  @media (max-width: 1360px) {
    font-size: 1.2rem;
  }
`;

export const SourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`;

export const SupplierContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`;

export const SupllierValue = styled.span<cardsQuantities>`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};

  @media (max-width: 1360px) {
    font-size: ${({ arrayLength }) => (arrayLength > 2 ? `0.7rem;` : `1rem;`)};
  }
`;

export const PercentValue = styled.span<cardsQuantities>`
  font-size: 2rem;
  font-weight: 600;
  color: ${({ percentErro, theme }) =>
    percentErro ? `${theme.colorsDesignSystem.red}` : `${theme.colorsDesignSystem.text}`};

  @media (max-width: 1360px) {
    font-size: ${({ arrayLength }) => (arrayLength > 2 ? `1.5rem;` : `2rem;`)};
  }
`;
