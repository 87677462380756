import { Tooltip } from 'antd';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Cell,
  Tooltip as RTooltip,
} from 'recharts';
import dayjs from 'dayjs';
import { InfoCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CustomTooltipProps, GraphicProps } from '../types';
import * as S from './styles';
import theme from '../../../../../styles/theme';

const formatValue = (value: number) => {
  if (value < 10000) {
    return value.toLocaleString('en-US');
  }

  const suffixes = ['', 'K', 'M', 'B', 'T', 'P'];
  const digits = Math.floor(Math.log10(value)) + 1;

  const suffixIndex = Math.floor((digits - 1) / 3);
  let shortValue = value / 1000 ** suffixIndex;
  shortValue = parseFloat(shortValue.toFixed(1));

  return shortValue + suffixes[suffixIndex];
};

function highlightColor(valor: number, rgbString?: string) {
  if (rgbString) {
    const matches = rgbString?.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);

    if (!matches) return rgbString;

    let redValue = Number(matches[1]);
    let greenValue = Number(matches[2]);
    let blueValue = Number(matches[3]);

    redValue = Math.min(255, redValue - valor);
    greenValue = Math.min(255, greenValue - valor);
    blueValue = Math.min(255, blueValue - valor);

    return `rgb(${redValue}, ${greenValue}, ${blueValue})`;
  }
}

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <S.TooltipContainer>
        <p>{`${label}`}</p>
        {payload.map((entry, index: number) => {
          const formattedLabel = entry.value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return (
            // eslint-disable-next-line react/no-array-index-key
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {`$${formattedLabel}`}
            </p>
          );
        })}
      </S.TooltipContainer>
    );
  }
  return null;
};

const Customized = (props: any) => {
  const { payload, x, y } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} textAnchor="end" fill="#666">
        ${formatValue(payload.value)}
      </text>
    </g>
  );
};

export function Graphic({
  data,
  endColor,
  startColor,
  heightProps = '100%',
  widthProps = '100%',
  partNumber,
}: GraphicProps) {
  const { t } = useTranslation();

  const verticalCoordinatesGenerator = ({ width, height, xAxis, yAxis }: any) => {
    const numberOfCategories = data.graphic.length;
    let interval = width / numberOfCategories;
    const coordinates = [];

    for (let i = 1; i < numberOfCategories; i++) {
      interval = i === 1 ? interval + 40 : interval - 40;
      coordinates.push(i * interval);
    }

    return coordinates;
  };

  const renderColorfulLegendText = (value: string, entry: any) => {
    return <span style={{ color: theme.colors.inputPlaceHolder }}>{value}</span>;
  };

  return (
    <S.Graphic key={data.sku} data-testid="graphic-component">
      <S.TableHeader>
        <p data-testid="graphic-title">{data.sku}</p>
        <span data-testid="graphic-category">{data.category}</span>
        {data.spec && <span data-testid="graphic-spec"> | {data.spec}</span>}
        <Tooltip
          arrow={false}
          placement="rightTop"
          title={partNumber?.map((item) => (
            <p key={item}>{item}</p>
          ))}
        >
          <InfoCircleFilled
            style={{
              color: theme.colorsDesignSystem.primary,
              fontSize: 14,
              marginLeft: '0.375rem',
            }}
          />
        </Tooltip>
      </S.TableHeader>
      <ResponsiveContainer width={widthProps} height={heightProps}>
        <BarChart
          role="graphics-symbol"
          barCategoryGap={40}
          barGap={12}
          data={data.graphic}
          margin={{
            right: 20,
            bottom: 16,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            verticalCoordinatesGenerator={verticalCoordinatesGenerator}
          />
          <XAxis dataKey="name" />
          <YAxis
            tickLine={false}
            tick={<Customized />}
            axisLine={false}
            type="number"
            domain={([dataMin, dataMax]) => {
              const valorForMultiple = dataMax < 1000 ? 1000 : 1500;
              const min = Math.floor(dataMin / 1000) * 1000;
              let max = Math.ceil(dataMax / 1000) * valorForMultiple;
              max = max === dataMax ? max + 1000 : max;
              return [min, max];
            }}
          />
          <RTooltip content={<CustomTooltip />} />
          <Legend align="right" iconType="circle" formatter={renderColorfulLegendText} />
          <Bar
            dataKey="start"
            fill={startColor}
            name={t('pages.finance.dashboard.startvsend.legendStart')}
            barSize={26}
            label={{
              position: 'top',
              fill: theme.colors.inputPlaceHolder,
              formatter: (value: number) => `${formatValue(value)}`,
            }}
            radius={[4, 4, 0, 0]}
          >
            {data.graphic.map((entry) => {
              return (
                <Cell
                  key={entry.name}
                  fill={
                    entry.name === dayjs().format('MMM/YYYY').toUpperCase()
                      ? highlightColor(60, startColor)
                      : startColor
                  }
                />
              );
            })}
          </Bar>
          <Bar
            dataKey="end"
            fill={endColor}
            barSize={26}
            name={t('pages.finance.dashboard.startvsend.legendEnd')}
            label={{
              position: 'top',
              fill: theme.colors.inputPlaceHolder,
              formatter: (value: number) => `${formatValue(value)}`,
            }}
            radius={[4, 4, 0, 0]}
            color="orange"
          />
        </BarChart>
      </ResponsiveContainer>
    </S.Graphic>
  );
}
