import { Form, Input, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRegisterPassword } from '../useRegisterPassword';
import * as S from './styles';
import { LoginHeader } from '../../../components/Layout/LoginHeader';
import { getQueryString } from '../../../helpers/utils';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

export function ResetPasswordPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.user.registerPasswordForm.title'));

  const { onFinish, error, isLoading } = useRegisterPassword();
  const userFirstPassword = getQueryString('first');

  return (
    <S.Container>
      <LoginHeader />
      <S.Main justify="center" align="middle">
        <Col xs={20} sm={16} md={12} xl={8}>
          {userFirstPassword && (
            <S.DivHeader>
              <h1
                dangerouslySetInnerHTML={{
                  __html: t('pages.user.registerPasswordForm.titleFirst'),
                }}
              />
            </S.DivHeader>
          )}

          <Card title={t('pages.user.registerPasswordForm.title')}>
            <Form name="register" layout="vertical" size="large" onFinish={onFinish}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    pattern:
                      '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[\\w~@#$%^&*+=`|{}:;!.?\\"()\\[\\]-]{8,25}$',
                    message:
                      'A senha deve conter letra maiuscula, minuscula, número e pelo menos 8 caracteres.',
                    // message: t('pages.user.registerPasswordForm.password.messageRequired'),
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder={t('pages.user.registerPasswordForm.password.label')} />
              </Form.Item>

              <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: t('pages.user.registerPasswordForm.confirm.messageRequired'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t('pages.user.registerPasswordForm.confirm.messageDontMatch'))
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={t('pages.user.registerPasswordForm.confirm.label')} />
              </Form.Item>

              <Form.Item>
                <S.ButtonLogin
                  type="primary"
                  block
                  htmlType="submit"
                  className="login-form-button"
                  disabled={(error && true) || isLoading}
                  data-cy="save"
                >
                  {t('common.save')}
                </S.ButtonLogin>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </S.Main>
    </S.Container>
  );
}
