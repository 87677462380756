import styled from 'styled-components';
import { Select } from 'antd';

export const SelectFilter = styled(Select)`
  margin: ${({ intable }) => (intable ? '4px 0 8px' : '0')};
  width: 100%;

  &.filter-ctype {
    width: 150px;
  }
`;
