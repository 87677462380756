import dayjs from 'dayjs';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { capitalizeFirstLetter } from '../../../../../helpers/utils';

export const useCostTrendChart = () => {
  const { t } = useTranslation();
  const handleFormatValueFields = (number: string | number): string => {
    try {
      return Number(number).toLocaleString(t('common.localeMoney'), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } catch (error) {
      console.log(error);
      return '-';
    }
  };

  const CustomTooltip = ({ active, payload, label, colorLine, colorBar, data }: any) => {
    let blended = false;
    data.chartData.map((item: { isBlended: boolean; labelColumn: string }) => {
      if (item.labelColumn === label) {
        blended = item.isBlended;
      }
      return blended;
    });

    if (active && payload && payload.length) {
      return (
        <S.TooltipContainer className="custom-tooltip">
          {blended && (
            <Row>
              <S.BlendedIndication>
                {t('pages.procurement.supplierPriceCostTrend.blended')}
              </S.BlendedIndication>
            </Row>
          )}
          <Row justify="space-between">
            <Row style={{ gap: '0.25rem' }} align="middle">
              <S.BarChartItemIndicator size="SMALL" colorBar={colorLine} />
              <S.LabelsTooltip weight="REGULAR">
                {t('pages.procurement.supplierPriceCostTrend.average')}
              </S.LabelsTooltip>
            </Row>
            <Row style={{ gap: '0.25rem' }} align="middle">
              <S.LabelsTooltip weight="REGULAR">
                {t('pages.procurement.supplierPriceCostTrend.usd')}
              </S.LabelsTooltip>
              <S.LabelsTooltip weight="BOLD">
                {handleFormatValueFields(payload[0].payload.lineValue)}
              </S.LabelsTooltip>
            </Row>
          </Row>
          <Row justify="space-between">
            <Row style={{ gap: '0.25rem' }} align="middle">
              <S.BarChartItemIndicator size="SMALL" colorBar={colorBar} />
              <S.LabelsTooltip weight="REGULAR">
                {t('pages.procurement.supplierPriceCostTrend.priceBook')}
              </S.LabelsTooltip>
            </Row>
            <Row style={{ gap: '0.25rem' }} align="middle">
              <S.LabelsTooltip weight="REGULAR">
                {t('pages.procurement.supplierPriceCostTrend.usd')}
              </S.LabelsTooltip>
              <S.LabelsTooltip weight="BOLD">
                {handleFormatValueFields(payload[0].payload.barValue)}
              </S.LabelsTooltip>
            </Row>
          </Row>
        </S.TooltipContainer>
      );
    }
    return null;
  };
  const CustomDataXlabel = (tickFormater: string) => {
    return dayjs(capitalizeFirstLetter(tickFormater.split('/')[0]), 'MMMM', 'en')
      .locale(t('common.dayjs'))
      .format('MMMM');
  };

  const formatTick = (number: string) => {
    const num = Number(number);
    if (num >= 1e3) {
      return `$${num / 1e3}K`;
    }
    if (num >= 1e6) {
      return `$${num / 1e6}M`;
    }
    return `$${number}`;
  };
  return { CustomTooltip, CustomDataXlabel, formatTick };
};
