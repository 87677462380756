import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Form } from 'antd';
import dayjs from 'dayjs';
import {
  filterBaseScenarios,
  filterUserScenarios,
  getErrorMessage,
  getPpbOptions,
  getURI,
  getVersion,
  oneAlert,
  parseBaseScenarios,
  parseScenarioName,
  parseScenarios2,
} from '../../../helpers/utils';
import { api } from '../../../services/api';
import { RoutesPath } from '../../../routes/routesPath';

export function useScenariosForm() {
  const history = useHistory();
  const { id: scenarioId, year: scenarioYear } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [formValue, setFormValue] = useState({
    name: '',
    targetYear: scenarioYear ? dayjs(scenarioYear) : dayjs(new Date()),
    scenario: '',
    scenarioVersion: '',
    ppbRule: '',
    price: '',
    pricesFile: [],
    factorsFile: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [fillEditData, setFillEditData] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [ppbData, setPpbData] = useState([]);
  const [ppbOptions, setPpbOptions] = useState([]);
  const [scenariosBase, setScenariosBase] = useState([]);
  const [scenariosUser, setScenariosUser] = useState([]);
  const [scenariosOptionsBase, setScenariosOptionsBase] = useState([]);
  const [scenariosOptionsUser, setScenariosOptionsUser] = useState([]);

  const updateUserScenarios = (baseScenario) => {
    try {
      setIsLoading(true);
      if (baseScenario && scenariosBase.length && scenariosUser.length) {
        const getScenario = scenariosBase.find((item) => item.id === baseScenario);
        const getScenarioName = parseScenarioName(getScenario);

        const options = parseScenarios2(scenariosUser, getScenarioName, 'select');
        setScenariosOptionsUser(options);
      }
    } catch (error) {
      console.log('Error Fetch User Scenario', error);
    }
    setIsLoading(false);
  };

  const fetchBaseScenarios = async (year) => {
    try {
      const yearValue = year === 'AllYears' ? '' : year || formValue?.targetYear?.format('YYYY');

      const {
        data: { content },
      } = await api.get(`scenarios?targetYear=${yearValue}`);

      const baseScenarios = filterBaseScenarios(content);

      if (content && baseScenarios?.length) {
        const userScenarios = filterUserScenarios(content);
        const selectScenarios = parseBaseScenarios(baseScenarios);

        if (selectScenarios && selectScenarios.length) {
          let selectedScenario = formValue.scenario;
          if (!selectedScenario && !scenarioId) {
            selectedScenario = selectScenarios[0].value;
            setFormValue({
              ...formValue,
              scenario: selectedScenario,
              scenarioVersion: getVersion(selectScenarios[0].label),
              price: '',
              lastUpdate: new Date(),
            });
          }

          if (scenarioId && !fillEditData) {
            const scenario = userScenarios.find((item) => item.id === scenarioId);

            setFormValue({
              ...formValue,
              name: scenario.name,
              targetYear: moment(scenario.targetYear.toString()),
              scenario: scenario.base.id,
              scenarioVersion: scenario.base.version,
              ppbRule: scenario?.ppb?.id,
              lastUpdate: scenario.lastUpdate,
            });
            setFillEditData(true);
          }

          setScenariosBase(baseScenarios);
          setScenariosUser(userScenarios);
          setScenariosOptionsBase(selectScenarios);
          updateUserScenarios(selectedScenario);
        }
      } else {
        setFormValue({
          ...formValue,
          scenario: '',
          scenarioVersion: '',
          price: '',
          lastUpdate: new Date(),
        });

        setScenariosBase([]);
        setScenariosUser([]);
        setScenariosOptionsBase([]);
      }
    } catch (error) {
      console.log('Error Fetch Scenario', error);
    }
  };

  const fetchPPBandScenarios = async () => {
    setIsLoading(true);
    try {
      const {
        data: { content },
      } = await api.get(getURI(`ppb`));
      const getPpbOpt = getPpbOptions(content);

      setPpbData(content);
      setPpbOptions(getPpbOpt);

      await fetchBaseScenarios(formValue?.targetYear?.format('YYYY'));
    } catch (error) {
      console.log('Error on the fetch PPB');
    }
    setIsLoading(false);
  };

  const handleChangeTag = (key) => {
    setActiveTab(key);
  };

  const onFinishStep1 = (values) => {
    setActiveTab('2');
  };

  const onFinishStep2 = async () => {
    setIsLoading(true);

    //validate fields
    if (formValue?.factorsFile?.length) {
      if (!formValue?.pricesFile?.length) {
        oneAlert('error', t('toast.requiredPricesFactors'));
        setIsLoading(false);
        return;
      }
    }

    try {
      const data = {
        targetYear: formValue.targetYear.format('YYYY'),
        name: formValue.name,
        ppb: formValue.ppbRule,
        prices: formValue.price,
        pricesFile: formValue.pricesFile,
        factorsFile: formValue.factorsFile,
      };

      if (!scenarioId) {
        data.idScenarioBase = formValue.scenario;
        const response = await api.post('scenarios', { ...data });
        if (response.status !== 201) throw Error();
      } else {
        const response = await api.put(`scenarios/${scenarioId}`, { ...data });
        if (response.status !== 201) throw Error();
      }

      oneAlert('success', t('toast.successOnSave'));
      setIsLoading(false);
      history.push(RoutesPath.scenarios);
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnSave'));
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  return {
    scenarioId,
    activeTab,
    isLoading,
    form,
    formValue,
    setFormValue,
    scenariosUser,
    scenariosBase,
    scenariosOptionsBase,
    scenariosOptionsUser,
    ppbOptions,
    ppbData,
    fetchPPBandScenarios,
    fetchBaseScenarios,
    updateUserScenarios,
    onCancel,
    onFinishStep1,
    onFinishStep2,
    handleChangeTag,
  };
}
