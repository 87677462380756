import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../../StyledButton';
import * as S from './styles';

export function ClearEditor() {
  const [editor] = useLexicalComposerContext();
  const { t } = useTranslation();
  return (
    <S.Content>
      <StyledButton
        text={t('common.clearAll')}
        variant="secondary"
        onClick={() => {
          editor.update(() => {
            $getRoot().clear();
          });
        }}
      />
    </S.Content>
  );
}
