import styled from 'styled-components';

export interface ContainerDataTableProps {
  align: 'left' | 'center';
  bidName?: boolean;
}

export const Container = styled.main`
  background-attachment: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.background};
  min-height: 95vh;
  margin: 0.5rem;
  overflow-x: auto;
  box-sizing: content-box;
  .pageTitle {
    margin-bottom: 2.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const TitleRfqPage = styled.span`
  font-size: ${({ theme }) => theme.dimensions.font_title_large};
  color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
`;

export const ContainerDataTable = styled.div<ContainerDataTableProps>`
  display: flex;
  flex-direction: row;
  gap: 0.863rem;
  align-items: center;
  justify-content: ${({ align }) => align};

  span {
    font-size: 0.75rem;
    color: ${({ theme, bidName }) => bidName && theme.colorsDesignSystem.primary};
  }
`;

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-end;
  margin-bottom: 0.75rem;
  margin-top: 2rem;
`;

export const FiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.938rem;
  gap: 0.375rem;
  margin-bottom: 1rem;
`;
