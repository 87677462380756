import { useTranslation } from 'react-i18next';
import { Row } from './styles';
import { handleFormatDate } from '../../../helpers/nUtils';

interface IUpdateDate {
  date: string;
}

export function UpdateDate({ date }: IUpdateDate) {
  const { t } = useTranslation();
  return (
    <Row>
      {t('common.updated')}
      <b>{handleFormatDate(date)}</b>
    </Row>
  );
}
