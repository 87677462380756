import { Col, ConfigProvider, Row, Form, Dropdown } from 'antd';
import { BiDownload, BiPlus } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { SearchOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledButton from '../../components/Common/StyledButton';

import { useSKU } from './useSKU';
import { EditableCell } from '../../components/EditableCell';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import * as S from './styles';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { ActionButton } from '../../components/ActionButton';
import { CsvExport } from '../../components/Common/CsvExport';
import { getFilterURL, removeEmptyFilter } from '../../helpers/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import StyledSelect from '../../components/Common/StyledSelect';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import theme from '../../styles/theme';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';
import { StyledPagination } from '../../components/Common/StyledPagination';

export function SKUPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.sku.title'));

  const validateMessages = ValidateMessages();
  const {
    data,
    params,
    pageControl,
    fetchSKUs,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    mergedColumns,
    csvHeaders,
    handleAddItem,
    CMs,
    products,
    projects,
    handleFilters,
    clearAllFilters,
    skus,
    modelName,
    changePage,
    resetPage,
  } = useSKU();

  const actions = [
    {
      key: '1',
      label: (
        <ActionButton
          key={0}
          icon={<BiPlus />}
          onClick={(e) => handleAddItem(e)}
          buttonText={`${t('common.add')} ${t('common.item')}`}
        />
      ),
    },
    {
      key: '2',
      label: (
        <ActionButton key={1} className="btn-csv" icon={<BiDownload />}>
          <CsvExport
            url={`sku?${getFilterURL(removeEmptyFilter(params))}`}
            filename={['sku']}
            limit={pageControl.totalElements}
            headers={csvHeaders}
          />
        </ActionButton>
      ),
    },
  ];

  return (
    <main>
      <Row>
        <Col span={24} className="filter-page">
          <Row justify="center">
            <Col span={24}>
              <StyledHeader
                title={t('pages.sku.title')}
                items={pageControl.totalElements}
                actions={
                  <Dropdown key={0} menu={{ items: actions }} placement="bottomRight">
                    <StyledButton variant="primary">
                      {t('common.action')}
                      <DownOutlined />
                    </StyledButton>
                  </Dropdown>
                }
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <S.FiltersContainer>
                <StyledSelect
                  showSearch
                  label={t('pages.sku.filters.cm')}
                  placeholder={t('pages.sku.filters.cmPlaceholder')}
                  allowClear
                  disable
                  style={{ width: 'auto', minWidth: '8.5rem' }}
                  options={CMs}
                  value={params.cm}
                />
                <StyledSelect
                  showSearch
                  label={t('pages.sku.filters.product')}
                  placeholder={t('pages.sku.filters.productPlaceholder')}
                  allowClear
                  style={{ width: 'auto', minWidth: '8.5rem' }}
                  options={products}
                  value={params.product}
                  onChange={(e) => {
                    handleFilters('product', e);
                  }}
                />
                <StyledSelect
                  showSearch
                  label={t('pages.sku.filters.project')}
                  placeholder={t('pages.sku.filters.projectPlaceholder')}
                  allowClear
                  style={{ width: 'auto', minWidth: '8.5rem' }}
                  options={projects}
                  value={params.project}
                  onChange={(e) => {
                    handleFilters('project', e);
                  }}
                  suffixIcon={
                    <S.SearchButton>
                      <SearchOutlined className="icon" />
                    </S.SearchButton>
                  }
                />
                <StyledSelect
                  showSearch
                  label={t('pages.sku.filters.sku')}
                  placeholder={t('pages.sku.filters.skuPlaceholder')}
                  allowClear
                  style={{ width: 'auto', minWidth: '8.5rem' }}
                  options={skus}
                  value={params.sku}
                  onChange={(e) => {
                    handleFilters('sku', e);
                  }}
                  suffixIcon={
                    <S.SearchButton>
                      <SearchOutlined className="icon" />
                    </S.SearchButton>
                  }
                />
                <StyledSelect
                  showSearch
                  label={t('pages.sku.filters.modelName')}
                  placeholder={t('pages.sku.filters.modelNamePlaceholder')}
                  allowClear
                  style={{ width: 'auto', minWidth: '8.5rem' }}
                  options={modelName}
                  value={params.modelName}
                  onChange={(e) => {
                    handleFilters('modelName', e);
                  }}
                  suffixIcon={
                    <S.SearchButton>
                      <SearchOutlined className="icon" />
                    </S.SearchButton>
                  }
                />
                <StyledButton
                  small
                  onClick={() => {
                    params.page === 0 ? fetchSKUs() : resetPage();
                  }}
                  variant="primary"
                  text={t('common.apply')}
                />
              </S.FiltersContainer>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <S.FilterTagsContainer>
                {params.project && (
                  <StyledFilterTag
                    label={t('pages.sku.fields.projectName')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.project}
                    closeble
                    onClose={() => {
                      handleFilters('project', '');
                    }}
                  />
                )}
                {params.sku && (
                  <StyledFilterTag
                    label={t('pages.sku.fields.sku')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.sku}
                    closeble
                    onClose={() => {
                      handleFilters('sku', '');
                    }}
                  />
                )}
                {params.modelName && (
                  <StyledFilterTag
                    label={t('pages.sku.fields.modelName')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.modelName}
                    closeble
                    onClose={() => {
                      handleFilters('modelName', '');
                    }}
                  />
                )}
                {(params.project || params.sku || params.modelName) && (
                  <StyledClearTag onClick={clearAllFilters} />
                )}
              </S.FilterTagsContainer>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <Form form={form} component={false} validateMessages={validateMessages}>
                <ConfigProvider>
                  <SimpleTable
                    isLoading={isLoading}
                    rowKey="id"
                    data-cy="table"
                    striped
                    dataSource={data}
                    columns={mergedColumns}
                    onChange={handleChangeTable}
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    scroll={{ x: 'max-content', y: 'calc(100vh - 22rem)' }}
                    pagination={false}
                    locale={{
                      emptyText: (
                        <ResultNoScenarios
                          title={t('common.results.noDataTitle')}
                          subTitle={t('common.results.noDataSubtitle')}
                        />
                      ),
                    }}
                  />
                </ConfigProvider>
              </Form>

              <StyledPagination
                pageNumber={params.page + 1}
                numberOfElements={params.limit}
                size={params.limit}
                totalElements={pageControl.totalElements}
                totalPages={pageControl.totalPages}
                onChange={changePage}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
}
