import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useEffect } from 'react';
import ExpandableItem from '../../../../components/Common/ExpandableItem';
import StyledSelect from '../../../../components/Common/StyledSelect';
import theme from '../../../../styles/theme';
import * as S from './styles';
import { useCostTrend } from './useCostTrend';

import { StyledLoading } from '../../../../components/Common/StyledLoading';
import { CostTrendCardProps } from './types';

export const CostTrend = ({
  selectedSkus,
  cm,
  date,
  level,
  isFirstRendering,
}: CostTrendCardProps) => {
  const { t } = useTranslation();

  const {
    open,
    params,
    categories,
    setOpen,
    handleFetchData,
    updateParams,
    handleRenderGraphic,
    handleRenderTitleCard,
    handleFetchFiltersData,
    isLoadingDataGraphic,
  } = useCostTrend();

  useEffect(() => {
    !isFirstRendering &&
      selectedSkus.length > 0 &&
      handleFetchFiltersData(cm, level, date, selectedSkus);
  }, [selectedSkus, cm, date, level]);

  useEffect(() => {
    !isFirstRendering && selectedSkus.length > 0 && handleFetchData(cm, level, date, selectedSkus);
  }, [params.category, cm, level, date, selectedSkus]);

  return (
    <S.container>
      <S.CardHeader>
        <S.FiltersCardContainer>
          <ExpandableItem
            isOpen={open}
            onClick={() => {
              setOpen(!open);
            }}
          />
          <strong>{t('pages.finance.dashboard.supplierCostTrend.titleCard')}</strong> |{' '}
          {handleRenderTitleCard(
            selectedSkus.map((skuFull) => skuFull.sku),
            date
          )}
        </S.FiltersCardContainer>
        <S.FiltersCardContainer>
          <StyledSelect
            styles={{ minWidth: '7.5rem' }}
            value={params.category}
            options={categories}
            placeholder={t(
              'pages.finance.dashboard.supplierCostTrend.filters.placeholder.category'
            )}
            suffixIcon={<SearchOutlined style={{ color: theme.colorsDesignSystem.primary }} />}
            onChange={(value: string) => {
              updateParams('category', value);
            }}
          />
        </S.FiltersCardContainer>
      </S.CardHeader>
      {open && (
        <S.rowGraphic justify="space-between">
          {isLoadingDataGraphic ? (
            <StyledLoading height={22.5} />
          ) : (
            handleRenderGraphic(selectedSkus.map((skuFull) => skuFull.sku))
          )}
        </S.rowGraphic>
      )}
    </S.container>
  );
};
