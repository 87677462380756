import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

import { ShareProportionsProps } from './types';
import { useShareProportionCard } from './useShareProportion';

import ExpandableCard from '../../../../components/Common/ExpandableCard';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import HeaderCard from '../HeaderCards';

export function ShareProportionCard({
  category,
  data,
  openShareProportioncard,
  isLoading,
  toogleShareProportionCard,
  clickCard,
}: ShareProportionsProps) {
  const { t } = useTranslation();
  const { renderCartItems } = useShareProportionCard(clickCard);
  return (
    <ExpandableCard
      isOpen={openShareProportioncard}
      headerCard={
        <HeaderCard
          title={t('pages.financePage.shareProportion.title')}
          componentName={category || '-'}
        />
      }
      onExpandableContent={toogleShareProportionCard}
    >
      {isLoading ? <StyledLoading height={6} /> : data.length ? renderCartItems(data) : <Empty />}
    </ExpandableCard>
  );
}
