import styled from 'styled-components';

export const Container = styled.main`
  background-attachment: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.background};
  min-height: 95vh;
  box-sizing: content-box;
  .pageTitle {
    margin-bottom: 1rem;
  }
`;

export const TitleConponentsPrice = styled.span`
  font-size: ${({ theme }) => theme.dimensions.font_title_large};
  color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
`;
