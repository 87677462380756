import React, { useState } from 'react';

import { useCSVReader, lightenDarkenColor, formatFileSize } from 'react-papaparse';
import { useTranslation } from 'react-i18next';
import { CloudUploadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import * as S from './styles';

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);
const GREY_DIM = '#686868';

const styles = {
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};

export default function CsvUploadReader({
  getCsvData,
  onRemoveFile = () => {
    console.log('Nothing removed');
  },
}) {
  const { CSVReader } = useCSVReader();
  const { t } = useTranslation();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);

  return (
    <div style={{ paddingBottom: '1.5rem' }}>
      <CSVReader
        onUploadAccepted={(results) => {
          const data = results.data.filter((item) => item.length && item[0] !== '');
          getCsvData(data);
          setZoneHover(false);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }) => (
          <S.UploadArea {...getRootProps()}>
            {acceptedFile ? (
              <div style={styles.file}>
                <div style={styles.info}>
                  <span style={styles.size}>{formatFileSize(acceptedFile.size)}</span>
                  <span style={styles.name}>{acceptedFile.name}</span>
                </div>
                <div style={styles.progressBar}>
                  <ProgressBar />
                </div>
                <div
                  {...getRemoveFileProps()}
                  style={styles.remove}
                  onFocus={(event) => {
                    event.preventDefault();
                    setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                  }}
                  onBlur={(event) => {
                    event.preventDefault();
                    setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                  }}
                >
                  {onRemoveFile ? (
                    <span>
                      <CloseCircleOutlined onClick={onRemoveFile} style={{ color: 'red' }} />
                    </span>
                  ) : (
                    <Remove color={removeHoverColor} />
                  )}
                </div>
              </div>
            ) : (
              <>
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">{t('common.fields.uploadText')}</p>
              </>
            )}
          </S.UploadArea>
        )}
      </CSVReader>
    </div>
  );
}
