import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  MailOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Form, Input, Card, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { useForgotPassword } from './useForgotPassword';
import { LoginHeader } from '../../components/Layout/LoginHeader';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export function ForgotPasswordPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.forgotPassword.title'));

  const { onFinish, error, isLoading, handleNavigateBack, onChangePassword, codeSended } =
    useForgotPassword();

  const visibleIcon = (visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />);

  return (
    <S.Container>
      <LoginHeader />
      <S.Main justify="center" align="middle">
        <Col xs={20} sm={16} md={12} xl={8}>
          <Card title={t('pages.forgotPassword.title')}>
            {!codeSended ? (
              <Form size="large" onFinish={onFinish}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('pages.forgotPassword.fields.email.messageRequired'),
                    },
                    {
                      type: 'email',
                      message: t('pages.forgotPassword.fields.email.messageValidity'),
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder={t('pages.forgotPassword.fields.email.label')}
                  />
                </Form.Item>

                <Row gutter={[32, 12]}>
                  <Col xs={24} md={10}>
                    <S.ButtonSecondary block onClick={handleNavigateBack}>
                      {t('common.cancel')}
                    </S.ButtonSecondary>
                  </Col>

                  <Col xs={24} md={14}>
                    <S.Button
                      type="primary"
                      htmlType="submit"
                      block
                      disabled={(error && true) || isLoading}
                    >
                      {t('pages.forgotPassword.fields.btnRetrievePW')}
                    </S.Button>
                  </Col>
                </Row>
              </Form>
            ) : (
              <Form size="large" onFinish={onChangePassword}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('pages.forgotPassword.fields.email.messageRequired'),
                    },
                    {
                      type: 'email',
                      message: t('pages.forgotPassword.fields.email.messageValidity'),
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder={t('pages.forgotPassword.fields.email.label')}
                  />
                </Form.Item>

                <Form.Item
                  name="confirmationCode"
                  rules={[
                    {
                      required: true,
                      message: t('pages.forgotPassword.fields.confirmationCode.messageRequired'),
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t('pages.forgotPassword.fields.confirmationCode.label')}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: t('pages.login.fields.password.messageRequired') },
                  ]}
                >
                  <Input.Password
                    placeholder={t('pages.login.fields.password.label')}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    iconRender={(visible) => visibleIcon(visible)}
                  />
                </Form.Item>

                <Row gutter={[32, 12]}>
                  <Col xs={24} md={10}>
                    <S.ButtonSecondary block onClick={handleNavigateBack}>
                      {t('common.cancel')}
                    </S.ButtonSecondary>
                  </Col>

                  <Col xs={24} md={14}>
                    <S.Button
                      type="primary"
                      htmlType="submit"
                      block
                      disabled={(error && true) || isLoading}
                    >
                      {t('pages.forgotPassword.fields.titleChange')}
                    </S.Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Card>
        </Col>
      </S.Main>
    </S.Container>
  );
}
