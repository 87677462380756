import { useTranslation } from 'react-i18next';
import * as S from './styles';
import StyledInput from '../../../components/Common/StyledInput';
import { SkuDetailsProps } from './types';

export default function ContentModalNewForecast({ sku }: SkuDetailsProps) {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.Title>{sku?.sku}</S.Title>
      <S.InfoContainer>
        <S.InfoItemContainer>
          <S.Labels>{t('pages.newDemand.modalFields.cm')}</S.Labels>
          <S.TextContent>{sku?.cm}</S.TextContent>
        </S.InfoItemContainer>
        <S.InfoItemContainer>
          <S.Labels>{t('pages.newDemand.modalFields.projectName')}</S.Labels>
          <S.TextContent>{sku?.project}</S.TextContent>
        </S.InfoItemContainer>
        <S.InfoItemContainer>
          <S.Labels>{t('pages.newDemand.modalFields.modelName')}</S.Labels>
          <S.TextContent>{sku?.modelName}</S.TextContent>
        </S.InfoItemContainer>
        <S.InfoItemContainer>
          <S.Labels>{t('pages.newDemand.modalFields.description')}</S.Labels>
          <S.TextContent>{sku?.description}</S.TextContent>
        </S.InfoItemContainer>
      </S.InfoContainer>
      <S.SeparatorContainer>
        <S.Labels>{t('pages.newDemand.modalFields.components')}</S.Labels>
        <S.Separator />
      </S.SeparatorContainer>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '4rem', marginBottom: '3.75rem' }}>
        <S.ContainerComponents>
          <S.ContainerItemComponents>
            <S.LabelsFields>{t('pages.newDemand.modalFields.adapters')}</S.LabelsFields>
            <StyledInput label="01" readonly defaultValue={sku?.adapter_1} />
            <StyledInput label="02" readonly defaultValue={sku?.adapter_2} />
          </S.ContainerItemComponents>
          <S.ContainerItemComponents>
            <S.LabelsFields>{t('pages.newDemand.modalFields.batteries')}</S.LabelsFields>
            <StyledInput label="01" readonly defaultValue={sku?.battery_1} />
            <StyledInput label="02" readonly defaultValue={sku?.battery_2} />
          </S.ContainerItemComponents>
          <S.ContainerItemComponents>
            <S.LabelsFields>{t('pages.newDemand.modalFields.memory')}</S.LabelsFields>
            <StyledInput label="01" readonly defaultValue={sku?.memory_1} />
            <StyledInput label="02" readonly defaultValue={sku?.memory_2} />
          </S.ContainerItemComponents>
          <S.ContainerItemComponents>
            <S.LabelsFields>{t('pages.newDemand.modalFields.ssd')}</S.LabelsFields>
            <StyledInput label="01" readonly defaultValue={sku?.ssd_1} />
            <StyledInput label="02" readonly defaultValue={sku?.ssd_2} />
          </S.ContainerItemComponents>
        </S.ContainerComponents>

        <S.ContainerComponents>
          <S.ContainerItemComponents>
            <S.LabelsFields>{t('pages.newDemand.modalFields.wirelessLan')}</S.LabelsFields>
            <StyledInput label="01" readonly defaultValue={sku?.wirelessLan_1} />
            <StyledInput label="02" readonly defaultValue={sku?.wirelessLan_2} />
          </S.ContainerItemComponents>
          <S.ContainerItemComponents>
            <S.LabelsFields>{t('pages.newDemand.modalFields.vram')}</S.LabelsFields>
            <StyledInput label="01" readonly defaultValue={sku?.vram_1} />
            <StyledInput label="02" readonly defaultValue={sku?.vram_2} />
          </S.ContainerItemComponents>
        </S.ContainerComponents>
      </div>
      <S.Separator />
    </S.Container>
  );
}
