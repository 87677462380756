import { DownOutlined, UpOutlined } from '@ant-design/icons';
import ExpandableItensProps from './types';
import * as S from './styles';
import theme from '../../../styles/theme';

export default function ExpandableItem({
  isOpen,
  title,
  onClick,
  showIcon,
  badge,
}: ExpandableItensProps) {
  return (
    <>
      {showIcon && (
        <S.container onClick={onClick}>
          {isOpen ? (
            <UpOutlined
              style={{
                color: theme.colorsDesignSystem.primary,
                transform: `rotate (${isOpen ? 180 : 0})deg`,
                transition: 'all 0.5s',
              }}
            />
          ) : (
            <DownOutlined
              style={{
                color: theme.colorsDesignSystem.primary,
                transform: `rotate (${isOpen ? 180 : 0})deg`,
                transition: 'all 0.5s',
              }}
            />
          )}
          <p>{title}</p>
          {badge && <div>{badge}</div>}
        </S.container>
      )}
      {!showIcon && (
        <S.container onClick={onClick}>
          <p>{title}</p>
        </S.container>
      )}
    </>
  );
}
