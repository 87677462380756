import { DownOutlined, UpOutlined } from '@ant-design/icons';
import ExpandableItensProps from './types';
import * as S from './styles';
import theme from '../../../styles/theme';

export default function ExpandableItem({ isOpen, onClick }: ExpandableItensProps) {
  return (
    <S.container onClick={onClick} data-testid="expandable-item">
      {isOpen ? (
        <UpOutlined
          style={{
            color: theme.colorsDesignSystem.primary,
            transform: `rotate (${isOpen ? 180 : 0})deg`,
            transition: 'all 0.5s',
          }}
        />
      ) : (
        <DownOutlined
          style={{
            color: theme.colorsDesignSystem.primary,
            transform: `rotate (${isOpen ? 180 : 0})deg`,
            transition: 'all 0.5s',
          }}
        />
      )}
    </S.container>
  );
}
