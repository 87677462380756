import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

export const SubCardValueView = ({ skus, skusData }: any) => {
  const { t } = useTranslation();
  return (
    <S.BodyContainer>
      {skus?.[0]?.id && (
        <S.ContainerRow>
          <S.ColorBar style={{ backgroundColor: skus[0].color }} />
          <div>
            <S.UsdContainer>
              <S.UsdText>USD</S.UsdText>
              <S.NumberValueText>
                {Number(skusData?.[0]?.usd ? skusData[0].usd : 0).toLocaleString(
                  t('common.localeMoney'),
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </S.NumberValueText>
            </S.UsdContainer>
            <S.SkuText>{skus?.[0].sku}</S.SkuText>
          </div>
        </S.ContainerRow>
      )}
      {skus?.[1]?.id && (
        <S.ContainerRow style={{ gap: '0.5rem' }}>
          <S.ColorBar style={{ backgroundColor: skus[1].color }} />
          <div>
            <S.UsdContainer>
              <S.UsdText>USD</S.UsdText>
              <S.NumberValueText>
                {Number(skusData?.[1]?.usd ? skusData?.[1].usd : 0).toLocaleString(
                  t('common.localeMoney'),
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </S.NumberValueText>
            </S.UsdContainer>
            <S.SkuText>{skus?.[1].sku}</S.SkuText>
          </div>
        </S.ContainerRow>
      )}
      {skus?.[2]?.id && (
        <S.ContainerRow style={{ gap: '0.5rem' }}>
          <S.ColorBar style={{ backgroundColor: skus[2].color }} />
          <div>
            <S.UsdContainer>
              <S.UsdText>USD</S.UsdText>
              <S.NumberValueText>
                {Number(skusData?.[2]?.usd ? skusData?.[2].usd : 0).toLocaleString(
                  t('common.localeMoney'),
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </S.NumberValueText>
            </S.UsdContainer>
            <S.SkuText>{skus?.[2].sku}</S.SkuText>
          </div>
        </S.ContainerRow>
      )}
    </S.BodyContainer>
  );
};
