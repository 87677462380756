import styled from 'styled-components';
import theme from '../../../../styles/theme';

export const SupplierPricesCostTrendConstainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SubCardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-bottom: solid 1px ${theme.colorsDesignSystem.grayDivider};
  padding: 0 0 0.5rem 0;
`;
export const SubCardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 1rem 1.83rem;
`;
export const SubCardHeaderText = styled.div`
  color: ${theme.colorsDesignSystem.fontColor};
  font-weight: 700;
  font-size: 1rem;
`;
export const SubCardHeaderTextBlue = styled.div`
  color: ${theme.colorsDesignSystem.blue};
  font-weight: 700;
  font-size: 1rem;
`;

export const BodyGraphContainer = styled.div`
  max-height: 40rem;
  margin: 1.5rem;
  display: flex;
  border: 0.063rem solid ${theme.colorsDesignSystem.border};
  border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
`;
export const TitleGraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
`;
export const TitleGraph = styled.div`
  font-size: 1.5rem;
  margin-top: 1.5rem;

  &:nth-child(1) {
    margin-left: 3.75rem;
    font-weight: 600;
  }
  &:nth-child(3) {
    font-weight: 400;
  }
`;
export const SubTitleGraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  gap: 0.313rem;
`;
export const SubTitleGraph = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${theme.colorsDesignSystem.blue};
  &:nth-child(1) {
    margin-left: 3.75rem;
  }
`;

export const GraphContainer = styled.div`
  width: 50%;
  max-height: 40rem;
  padding-bottom: 2.188rem;
  &:nth-child(1) {
    border-right: 0.063rem solid ${theme.colorsDesignSystem.border};
  }
`;
export const CardsFiltersContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 1rem;
  margin-right: 1rem;
`;

export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  .icon {
    color: ${theme.colorsDesignSystem.primary};
    font-size: 0.875rem;
  }
`;
export const EmptyContainer = styled.div`
  height: 30rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
