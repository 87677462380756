import axios, { AxiosRequestConfig } from 'axios';
import { refreshToken, logout, isAuthRequest, getIdToken, getPermissionsToken } from './auth';
import { normalizeObjectError } from '../helpers/utils';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

api.interceptors.request.use(
  async (request: any) => {
    if (!isAuthRequest(request?.url)) {
      try {
        await refreshToken(() => {
          request.headers.Authorization = `Bearer ${getIdToken()}`;
          request.headers.Permissions = getPermissionsToken();
        });
      } catch (error) {
        console.log('REFRESH_ERROR=>', error);
        await logout();
      }
    }
    return request;
  },
  (error) => Promise.reject(normalizeObjectError(error))
);

api.interceptors.response.use(
  async (response) => response,
  (error) => Promise.reject(normalizeObjectError(error))
);

export { api };
