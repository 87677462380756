import { Col, ConfigProvider, Row, Form, Dropdown, DatePicker } from 'antd';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useLogUsers } from './useLogUsers';
import * as SC from '../../styles/common';
import { EditableCell } from '../../components/EditableCell';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { Loading } from '../../components/Loading';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { ActionButton } from '../../components/ActionButton';
import SimpleTable from '../../components/Tables/SimpleTable';
import { StyledPagination } from '../../components/Common/StyledPagination';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledButton from '../../components/Common/StyledButton';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import theme from '../../styles/theme';

const { RangePicker } = DatePicker;

export function LogUsersPage({ view = null, onClose = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.logusers.title'));

  const validateMessages = ValidateMessages();
  const {
    data,
    params,
    pageControl,
    canUpdate,
    fetchLogUsers,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    mergedColumns,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    rowSelection,
    selectedRowKeys,
    handleSelectAllRows,
    handleDeleteLogs,
  } = useLogUsers();

  const showTagsFilter = params.header_user?.length || params.header_area?.length;

  const selectButtonText =
    selectedRowKeys.length === data.length
      ? `${t('common.unselect')} ${t('common.all')}`
      : `${t('common.select')} ${t('common.all')}`;

  useEffect(() => {
    if (canUpdate) {
      fetchLogUsers();
    }
  }, [params]);

  const actions = [
    {
      key: '1',
      label: (
        <ActionButton
          key={1}
          onClick={handleSelectAllRows}
          buttonText={selectButtonText}
          disabled={isLoading}
        />
      ),
    },
    {
      key: '2',
      label: (
        <ActionButton
          key={2}
          onClick={handleDeleteLogs}
          buttonText={`${t('common.delete')} ${t('common.selected')}`}
          disabled={selectedRowKeys.length <= 0 || isLoading}
        />
      ),
    },
  ];

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={24}>
                <StyledHeader
                  title={t('pages.logusers.title')}
                  items={pageControl.totalElements}
                  actions={
                    <Dropdown key={0} menu={{ items: actions }} placement="bottomRight" open>
                      <StyledButton variant="primary">
                        {t('common.action')}
                        <DownOutlined />
                      </StyledButton>
                    </Dropdown>
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <RangePicker
                  onChange={(e) => searchFilter(e)}
                  picker="day"
                  value={[
                    params.start_date ? dayjs(params.start_date) : null,
                    params.end_date ? dayjs(params.end_date) : null,
                  ]}
                  label={{
                    start: t('pages.logusers.fields.startDate'),
                    end: t('pages.logusers.fields.endDate'),
                  }}
                />
              </Col>
            </Row>

            <Row justify="center">
              <Col span={24}>
                <SC.ContainerFilteredTags>
                  {params.header_user && (
                    <StyledFilterTag
                      onClose={() => handleCloseFilteredTag('header_user')}
                      closeble
                      title={params.header_user}
                      label={t('pages.logusers.fields.user')}
                      closeicon={<CloseOutlined color={theme.colors.link} />}
                    />
                  )}
                  {params.header_area && (
                    <StyledFilterTag
                      onClose={() => handleCloseFilteredTag('header_area')}
                      closeble
                      title={params.header_area}
                      label={t('pages.logusers.fields.area')}
                      closeicon={<CloseOutlined color={theme.colors.link} />}
                    />
                  )}
                </SC.ContainerFilteredTags>
              </Col>
            </Row>

            <Row justify="center">
              <Col span={24}>
                <Form form={form} component={false} validateMessages={validateMessages}>
                  <ConfigProvider>
                    <SimpleTable
                      rowKey="id"
                      data-cy="table"
                      striped
                      dataSource={data}
                      columns={mergedColumns}
                      onChange={handleChangeTable}
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      scroll={{ x: 'mas-content', y: 'calc(100vh - 22rem)' }}
                      rowSelection={{ ...rowSelection }}
                      pagination={false}
                      locale={{
                        emptyText: (
                          <ResultNoScenarios
                            title={t('common.results.noDataTitle')}
                            subTitle={t('common.results.noDataSubtitle')}
                          />
                        ),
                      }}
                    />
                  </ConfigProvider>
                </Form>

                {pageControl.totalPages > 1 && (
                  <StyledPagination
                    onChange={changePageValue}
                    pageNumber={params.page + 1}
                    size={params.limit}
                    totalElements={pageControl.totalElements}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
