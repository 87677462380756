import { FolderViewOutlined } from '@ant-design/icons';
import { Checkbox, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../components/Common/StyledButton';
import StyledDatePicker from '../../components/Common/StyledDatePicker';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledSelect from '../../components/Common/StyledSelect';
import StyledTabs from '../../components/Common/StyledTabs';
import { StyledTab } from '../../components/Common/StyledTabs/types';
import { CustomPagination } from '../../components/CustomPagination';
import { Loading } from '../../components/Loading';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import theme from '../../styles/theme';
import * as S from './styles';
import TableQuotes from './TableQuotes';
import { ParamsProps } from './types';
import { useQuotes } from './useQuotes';

export function QuotesPage(props: any) {
  const { t } = useTranslation();

  useDocumentTitle(t('pages.quotes.title'));
  const filtersRef = useRef<ParamsProps | null>(null);
  const fetchDataEarlier = useRef(false);
  //variável que controla a exibição dos  headers para o caso de exibição da página dentro do modal de finance view
  const [visibilityHeaders, setVisibilityHeader] = useState(true);

  if (props.location) {
    filtersRef.current = props.location.state?.filters;
  }

  const [tabs, setTabs] = useState<StyledTab[]>([
    { selected: true, title: t('pages.quotes.tabTitles.quotes') },
  ]);

  const {
    DATA_L10,
    DATA_L6,
    CMs,
    levels,
    params,
    fxRate,
    isLoading,
    pageControl,
    dataFile,
    projectsFilter,
    products,
    tableLevel,
    skus,
    handleFetchFiltersData,
    setTableLevel,
    changePageValue,
    handleUpdateFilters,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    setParams,
    handleFetchQuotes,
    isFirstRendering,
  } = useQuotes();

  //validando se a tela está fazendo fetch ao retornar de sku details e se trata de primeira chamada aplicada aos filtros recebidos
  const handleVerifyIsFirstFetchFromSkuDetails = (): boolean => {
    if (!filtersRef.current || fetchDataEarlier.current) return true;
    return false;
  };

  useEffect(() => {
    if (!isFirstRendering) {
      handleVerifyIsFirstFetchFromSkuDetails() && !props.hideHeaders && handleFetchQuotes();
    }
  }, [params.page, isFirstRendering]);

  //retornando para primeira página sempre que qualquer filtro for alterado
  useEffect(() => {
    params.page !== 0 &&
      setParams((prevState: ParamsProps) => {
        return {
          ...prevState,
          page: 0,
        };
      });
  }, [params.cm, params.project, params.sku, params.date, params.level]);

  useEffect(() => {
    handleVerifyIsFirstFetchFromSkuDetails() && !props.hideHeaders && handleFetchFiltersData();
  }, [params.product, params.cm, params.date, params.project, params.sku, params.level]);

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {/* validando se os headers devem ser exibidos, para o caso da tela estar sendo exibida dentro de um modal
            nesse caso, apresenta-se apenas as tabelas sem os headers da pagina
          */}
          {!props.hideHeaders && (
            <>
              <StyledHeader title="Project Name" />
              <StyledTabs tabs={tabs} setSelectedTab={setTabs} />
              <S.ContainerFilters>
                <StyledSelect
                  label={t('pages.quotes.labels.cm')}
                  placeholder={t('pages.quotes.placeholders.cm')}
                  onChange={(value: string) => {
                    handleUpdateFilters('cm', value);
                    handleUpdateFilters('project', '');
                    handleUpdateFilters('sku', '');
                    handleUpdateFilters('date', null);
                  }}
                  options={CMs}
                  value={params.cm}
                  styles={{ minWidth: '7.25rem' }}
                />
                <StyledSelect
                  options={products}
                  label={t('pages.quotes.labels.product')}
                  placeholder={t('pages.quotes.placeholders.product')}
                  disable
                  onChange={(value: string) => {
                    handleUpdateFilters('product', value);
                  }}
                  styles={{ minWidth: '7.25rem' }}
                  value={params.product}
                />
                <StyledDatePicker
                  label={t('pages.quotes.labels.date')}
                  placeholder={t('pages.quotes.placeholders.date')}
                  picker="month"
                  onChange={(date, _) => {
                    handleUpdateFilters('date', date);
                    handleUpdateFilters('project', '');
                    handleUpdateFilters('sku', '');
                  }}
                  defaultValue={params.date}
                  styles={{ minWidth: '7.25rem' }}
                  format="MM/YYYY"
                />
                <StyledSelect
                  options={projectsFilter}
                  showSearch
                  label={t('pages.quotes.labels.project')}
                  placeholder={t('pages.quotes.placeholders.project')}
                  onChange={(value: string) => {
                    handleUpdateFilters('project', value);
                    handleUpdateFilters('sku', '');
                  }}
                  styles={{ minWidth: '7.25rem' }}
                  value={params.project}
                />
                <StyledSelect
                  options={skus}
                  showSearch
                  label={t('pages.quotes.labels.sku')}
                  placeholder={t('pages.quotes.placeholders.sku')}
                  onChange={(value: any) => {
                    handleUpdateFilters('sku', value);
                  }}
                  styles={{ minWidth: '8.25rem' }}
                  value={params.sku}
                />
                <StyledSelect
                  label={t('pages.quotes.labels.level')}
                  placeholder={t('pages.quotes.placeholders.level')}
                  onChange={(value: any) => {
                    handleUpdateFilters('level', value);
                  }}
                  options={levels}
                  value={params.level}
                  styles={{ minWidth: '7.25rem' }}
                />
                <div>
                  <Checkbox
                    checked={params.withForecast}
                    style={{ padding: '0.375rem' }}
                    onChange={() => {
                      handleUpdateFilters('withForecast', !params.withForecast);
                    }}
                  >
                    {t('pages.quotes.labels.withDemand')}
                  </Checkbox>
                </div>
                <StyledButton
                  small
                  onClick={() => {
                    handleFetchQuotes();
                    setTableLevel(params.level);
                  }}
                  variant="primary"
                  text={t('pages.quotes.titleButons.apply')}
                />
              </S.ContainerFilters>
              <S.FiltersTagContainer>
                {handleRenderFiltersTags()}
                {handleRenderClearAllFilters()}
              </S.FiltersTagContainer>
            </>
          )}
          <S.TableLabelsContainer>
            <Row style={{ gap: '4px' }} align="middle">
              <FolderViewOutlined style={{ color: theme.colors.prim, fontSize: '0.875rem' }} />
              <S.QuoteFilesLabel>{t('pages.quotes.labels.quoteFiles')}</S.QuoteFilesLabel>
              <span>{dataFile}</span>
            </Row>
            <span>{`${t('pages.quotes.labels.amount')} - (USD)`}</span>
          </S.TableLabelsContainer>
          <S.TableContainer>
            {(tableLevel === '' || tableLevel === 'L6') && (
              <TableQuotes
                DATA={DATA_L6}
                hideLink={props.hideHeaders}
                level="L6"
                cm={params.cm}
                fxRate={fxRate}
                date={params.date}
                location="Manaus - AM"
              />
            )}

            {(tableLevel === '' || tableLevel === 'L10') && (
              <TableQuotes
                hideLink={props.hideHeaders}
                DATA={DATA_L10}
                level="L10"
                cm={params.cm}
                fxRate={fxRate}
                location="Jundiaí - SP"
                date={params.date}
              />
            )}
          </S.TableContainer>
        </>
      )}
      {pageControl.totalPages > 1 && (
        <CustomPagination
          limit={params.limit}
          page={params.page + 1}
          pageLabel={pageControl.pageLabel}
          total={pageControl.totalElements}
          totalPages={pageControl.totalPages}
          changePageValue={changePageValue}
        />
      )}
    </S.Container>
  );
}
