import React, { useEffect } from 'react';
import { Col, ConfigProvider, Form, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { BiPlus } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { HeaderTitle } from '../../../components/PageHeader/HeaderTitle';
import { ActionButton } from '../../../components/ActionButton';
import { EditableCell } from '../../../components/EditableCell';
import { ResultNoScenarios } from '../../../components/ResultNoScenarios';
import { CustomPagination } from '../../../components/CustomPagination';
import { useDataSettings } from './useDataSettings';
import * as S from './styles';
import SimpleTable from '../../../components/Tables/SimpleTable';

export function DataSettings({ scenarioData, modalData, simulationModal }) {
  const { t } = useTranslation();
  const {
    mergedColumns,
    handleChangeTable,
    params,
    pageControl,
    changePageValue,
    onValuesChange,
    form,
    data,
    handleAddItem,
    fetchSettings,
    editingKey,
  } = useDataSettings({ scenarioData, modalData, simulationModal });

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <Col span={24} className="filter-page">
      <Row justify="center">
        <Col span={24}>
          <PageHeader
            title={<HeaderTitle title={modalData.title} />}
            className="pl-0 pr-0"
            extra={[
              <ActionButton
                key={1}
                icon={<BiPlus />}
                onClick={(e) => handleAddItem(e)}
                disabled={editingKey !== ''}
                buttonText={`${t('common.add')} ${t('common.item')}`}
              />,
            ]}
          />
        </Col>
      </Row>

      <Row justify="center">
        <Col span={24}>
          <Form form={form} component={false} onValuesChange={onValuesChange}>
            <ConfigProvider>
              <SimpleTable
                rowKey="id"
                data-cy="table"
                dataSource={data}
                columns={mergedColumns}
                onChange={handleChangeTable}
                // expandRowByClick
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                pagination={false}
                locale={{
                  emptyText: (
                    <ResultNoScenarios
                      title={t('common.results.noDataTitle')}
                      subTitle={t('common.results.noDataSubtitle')}
                    />
                  ),
                }}
              />
            </ConfigProvider>
          </Form>

          {pageControl.totalPages > 1 && (
            <CustomPagination
              limit={params.limit}
              page={params.page + 1}
              pageLabel={pageControl.pageLabel}
              total={pageControl.totalElements}
              totalPages={pageControl.totalPages}
              changePageValue={changePageValue}
            />
          )}
        </Col>
      </Row>
    </Col>
  );
}
