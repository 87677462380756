import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { InOutCard } from './InOut';
import { InOutDashboardProps } from './types';

export function InOutDashboard({ data, date }: { data: InOutDashboardProps; date: string }) {
  const { t } = useTranslation();
  const [year, setYear] = useState(dayjs(date, 'MM/YYYY').year());
  const [quarter, setQuarter] = useState(`Q${dayjs(date, 'MM/YYYY').quarter()}`);

  const handleYearChange = (direction: number) => {
    setYear((prevYear) => prevYear + direction);
  };

  const handleQuarterChange = (event: any) => {
    setQuarter(event.target.value);
  };
  return (
    <S.Container>
      <S.InfoContainer>
        <S.PartNumberContainer>
          <S.LabelInfoContainer>{t('pages.inOut.labels.partNumber')}</S.LabelInfoContainer>
          <S.PartNumberInfoContainer>{data.partNumber}</S.PartNumberInfoContainer>
        </S.PartNumberContainer>
        <S.OtherInformationsContainer>
          <S.SingleInformationContainer>
            <S.LabelInfoContainer>{t('pages.inOut.labels.category')}</S.LabelInfoContainer>
            <S.InfoContainerText>{data.category}</S.InfoContainerText>
          </S.SingleInformationContainer>
          <S.SingleInformationContainer>
            <S.LabelInfoContainer>{t('pages.inOut.labels.cm')}</S.LabelInfoContainer>
            <S.InfoContainerText>{data.cm}</S.InfoContainerText>
          </S.SingleInformationContainer>
          <S.SingleInformationContainer>
            <S.LabelInfoContainer>{t('pages.inOut.labels.source')}</S.LabelInfoContainer>
            <S.InfoContainerText>{data.source}</S.InfoContainerText>
          </S.SingleInformationContainer>
          <S.SingleInformationContainer>
            <S.LabelInfoContainer>{t('pages.inOut.labels.specification')}</S.LabelInfoContainer>
            <S.InfoContainerText>{data.specification || '-'}</S.InfoContainerText>
          </S.SingleInformationContainer>
          <S.SingleInformationContainer>
            <S.LabelInfoContainer>{t('pages.inOut.labels.description')}</S.LabelInfoContainer>
            <S.InfoContainerText>{data.description}</S.InfoContainerText>
          </S.SingleInformationContainer>
        </S.OtherInformationsContainer>
      </S.InfoContainer>
      <S.FilterQuarterContainer>
        <S.YearContainer>
          <span style={{ margin: '0 0.5rem' }}>{year}</span>
          <S.ButtonContainer>
            <S.StyledButton
              onClick={() => handleYearChange(-1)}
              icon={<LeftOutlined style={{ margin: '0', padding: '0' }} />}
            />
            <S.StyledButton
              onClick={() => handleYearChange(1)}
              icon={<RightOutlined style={{ margin: '0', padding: '0' }} />}
            />
          </S.ButtonContainer>
        </S.YearContainer>
        <S.RadioGroupContainer value={quarter} onChange={handleQuarterChange}>
          <S.StyledRadio value="Q1">Q1</S.StyledRadio>
          <S.StyledRadio value="Q2">Q2</S.StyledRadio>
          <S.StyledRadio value="Q3">Q3</S.StyledRadio>
          <S.StyledRadio value="Q4">Q4</S.StyledRadio>
        </S.RadioGroupContainer>
      </S.FilterQuarterContainer>
      <InOutCard partNumber={data.partNumber} quarter={`${quarter}/${year}`} />
    </S.Container>
  );
}
