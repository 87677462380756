import { TableColumnProps } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSelectOptions } from '../../../../../components/Common/StyledSelect/types';
import StyledIndicationTag from '../../../../../components/IndicationTag';
import { getURI, oneAlert } from '../../../../../helpers/utils';
import { api } from '../../../../../services/api';
import { dataType } from '../../types';
import * as S from './styles';
import { Component, SkuComponentsParams, SkuComponentsProject } from './types';

export const useSkuCompoents = () => {
  const { t } = useTranslation();
  const [datareceived, setData] = useState<Array<SkuComponentsProject>>([]);
  const [params, setParams] = useState<SkuComponentsParams>({ withGap: false });
  const [modalData, setModalData] = useState<Component>();
  const [modalQuotesVisibility, setModalQuotesVisibility] = useState(false);
  const [categories, setCategories] = useState<Array<StyledSelectOptions>>([]);
  const [isLoadingDataTable, setIsLoadingDataTable] = useState<boolean>(false);

  const columns: Array<TableColumnProps<any>> = [
    {
      title: () => {
        return (
          <S.TitleTableContainer type="LEFT">
            {t('pages.finance.dashboard.skuComponents.table.headers.category')}
          </S.TitleTableContainer>
        );
      },
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record) => {
        return <S.ComponentText onClick={() => setModalData(record)}>{text}</S.ComponentText>;
      },
    },
    {
      title: () => {
        return (
          <S.TitleTableContainer type="CENTER">
            {t('pages.finance.dashboard.skuComponents.table.headers.source')}
          </S.TitleTableContainer>
        );
      },
      dataIndex: 'source',
      key: 'source',
      render: (text: string) => {
        return (
          <S.StyledIndicationTagContainer>
            {text && <StyledIndicationTag text={text} />}
          </S.StyledIndicationTagContainer>
        );
      },
    },
    {
      title: () => {
        return (
          <S.TitleTableContainer type="RIGTH">
            {t('pages.finance.dashboard.skuComponents.table.headers.cmQuote')}
          </S.TitleTableContainer>
        );
      },
      render(_, record) {
        return (
          <S.CmQuotesAlligner>
            <S.StyledCmQuotesContainer>
              <S.LegendPrice type={record?.status} />
              {record?.source
                ? record?.price?.toLocaleString(t('common.localeMoney'), {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '-'}
            </S.StyledCmQuotesContainer>
          </S.CmQuotesAlligner>
        );
      },
      align: 'right',
      dataIndex: 'price',
      key: 'price',
    },
  ];

  const handleGetSkusString = (skus: dataType[] | undefined): string => {
    try {
      return skus ? skus.map((sku) => sku.sku).join(',') : '';
    } catch (error) {
      console.log(error);
      return '';
    }
  };

  const handleConvertCategoriesDataToStyledSelectOptions = (
    data: Array<string>
  ): Array<StyledSelectOptions> => {
    const dataConverted = data.map((category) => {
      return {
        label: category,
        value: category,
      };
    });

    dataConverted.unshift({ label: 'View All', value: '' });
    return dataConverted;
  };

  const handleFetchCategories = async (
    skus: Array<dataType> | undefined,
    date: string,
    level: string,
    cm: string | undefined
  ) => {
    try {
      if (level && date && skus) {
        const { status, data } = await api.get('dashboard/finance/skucomponents/category', {
          params: {
            ...(cm && { cm }),
            date,
            level,
            skus: handleGetSkusString(skus),
          },
        });

        if (status === 200) {
          setCategories(handleConvertCategoriesDataToStyledSelectOptions(data) || []);
        }
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchSkuComponents = (period: string, level: string, cm?: string, skus?: dataType[]) => {
    setIsLoadingDataTable(true);
    api
      .get(
        getURI('/dashboard/finance/skucomponents', {
          skus: handleGetSkusString(skus),
          period,
          level,
          cm,
          ...params,
        })
      )
      .then((response: any) => {
        //Temporary data treatment ultil the backend fix the response
        const unformatedData: SkuComponentsProject[] = response.data;
        const formatedData = unformatedData.map((project: SkuComponentsProject) => {
          project.components?.map((component: Component) => {
            component.sku = project.sku;
            component.cm = project.cm;
            component.project = project.project;
            component.skuComponentModalDtos = component.skuComponentModalDtos?.map((modal) => {
              modal.source = component.source;

              return modal;
            });
            return component;
          });
          return project;
        });
        setData(formatedData);
      })
      .catch(() => {
        setData([]);
      })
      .finally(() => {
        setIsLoadingDataTable(false);
      });
  };

  const handleCalculateAmountSkuByCm = (skus: dataType[]): string => {
    try {
      let foxconn = 0;
      let compal = 0;
      skus.forEach((sku) => {
        sku.cm.toUpperCase() === 'FOXCONN' && (foxconn += 1);
        sku.cm.toUpperCase() === 'COMPAL' && (compal += 1);
      });

      return foxconn >= compal ? 'FOXCONN' : 'COMPAL';
    } catch (error: any) {
      console.log(error);
      return '';
    }
  };

  return {
    columns,
    datareceived,
    params,
    modalData,
    modalQuotesVisibility,
    categories,
    handleGetSkusString,
    setModalQuotesVisibility,
    handleFetchCategories,
    setParams,
    setModalData,
    setData,
    fetchSkuComponents,
    handleCalculateAmountSkuByCm,
    isLoadingDataTable,
  };
};
