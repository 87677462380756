import styled from 'styled-components';

export const CancelBidContainer = styled.div`
  width: 100%;
  min-height: 30rem;
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  background-color: ${({ theme }) => theme.colorsDesignSystem.secondaryGrayBackground};
  border-radius: ${({ theme }) => theme.dimensions.border_radius_large};
`;

export const CancelBidHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 3.5rem;
  border-bottom: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  gap: 0.75rem;
  font-size: 1.25rem;
  font-weight: 600;
  padding-left: 1.5rem;
  padding: 0 1.5rem;
`;

export const CancelBidContent = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25rem;
  padding: 1.5rem 4.375rem;
`;

export const CancelImage = styled.img`
  width: 14rem;
  margin-bottom: 1.5rem;
`;

export const CancelMessage = styled.span`
  font-size: 1.5rem;
  font-weight: 400;
`;

export const CancelNameBid = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
`;
export const CancelSubMessage = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;
