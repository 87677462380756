import { Col, ConfigProvider, Row } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { Loading } from '../../components/Loading';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useAlert } from './useAlert';
import { EditAlert } from './EditAlert';
import SimpleTable from '../../components/Tables/SimpleTable';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import StyledModal from '../../components/Common/StyledModal';

export function AlertPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.alert.title'));

  const {
    isLoading,
    columns,
    data,
    fetchAlerts,
    editingAlert,
    handleStopEditing,
    handleEditAlert,
    visible,
    pageControl,
  } = useAlert();

  useEffect(() => {
    fetchAlerts();
  }, []);

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={24}>
                <StyledHeader title={t('pages.alert.title')} items={pageControl.totalElements} />
              </Col>
            </Row>

            <Row justify="center">
              <Col span={24}>
                <ConfigProvider>
                  <SimpleTable
                    rowKey="id"
                    data-cy="table"
                    striped
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    locale={{
                      emptyText: (
                        <ResultNoScenarios
                          title={t('common.results.noDataTitle')}
                          subTitle={t('common.results.noDataSubtitle')}
                          icon={null}
                        />
                      ),
                    }}
                  />
                </ConfigProvider>
              </Col>
            </Row>

            <StyledModal
              key="modal-alert"
              title={t('pages.alert.fields.alert')}
              open={visible}
              onClose={handleStopEditing}
              footer={null}
              width="77.5rem"
              body={
                <EditAlert
                  alert={editingAlert}
                  onClose={handleStopEditing}
                  onSuccess={handleEditAlert}
                />
              }
            />
          </Col>
        )}
      </Row>
    </main>
  );
}
