import styled from 'styled-components';

export const Title = styled.span<{ font?: 'SM' | 'MD' }>`
  font-size: ${({ font }) => (font === 'SM' ? '0.625rem' : '0.875rem')};
  font-weight: 400;
  line-height: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const Value = styled.span<{ isBlue?: boolean; font?: 'SM' | 'MD' | 'LG' }>`
  font-size: ${({ font }) => (font === 'LG' ? '1.25rem' : font === 'MD' ? '1rem' : '0.875rem')};
  font-weight: 700;
  line-height: 1.375rem;
  color: ${({ isBlue, theme }) =>
    isBlue ? theme.colorsDesignSystem.blue : theme.colorsDesignSystem.text};
`;
