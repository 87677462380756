import styled from 'styled-components';

export const Conteiner = styled.div`
  display: flex;
  flex-direction: column;
`;

export const containerContent = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 2rem;
  border-radius: ${({ theme }) => theme.dimensions.border_radius};
  border: 1px solid ${({ theme }) => theme.colors.gray_border};

  &:focus-within {
    border: 1px solid ${({ theme }) => theme.colors.prim};
  }
`;
export const SearchInput = styled.input`
  width: 100%;
  font-weight: 400;
  margin: 0;
  border: none;
  font-size: 0.75rem;

  color: ${({ theme }) => theme.colors.black};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.inputPlaceHolder} !important;
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    font-weight: 400;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.black};
  }
`;
