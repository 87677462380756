import { useEffect, useState } from 'react';
import { Tabs, Col, Form, Input, Row, DatePicker, Select, Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { AiOutlineCheck } from 'react-icons/ai';
import { StyledPageHeader } from '../../../components/Common/StyledPageHeader/styles';
import { Button } from '../../../components/Button';
import { useScenariosForm } from './useScenariosForm';
import { useModal } from '../../../components/Modal/useModal';
import {
  csvArrayToObj,
  getVersion,
  parsePpbName,
  parseScenarioName,
  useDidMountEffect,
} from '../../../helpers/utils';
import { Loading } from '../../../components/Loading';
import CsvUploadReader from '../../../components/CsvUploadReader';
import * as S from './styles';
import * as SC from '../../../styles/common';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import SimpleTable from '../../../components/Tables/SimpleTable';
import StyledModal from '../../../components/Common/StyledModal';

const { TabPane } = Tabs;

export function ScenariosForm() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.scenarios.title'));

  const [visiblePrice, openModalPrice, closeModalPrice] = useModal();
  const [visibleFactor, openModalFactor, closeModalFactor] = useModal();
  const [titlePreview, setTitlePreview] = useState('Prices');
  const [preview, setPreview] = useState([]);
  const [visiblePreview, openModalPreview, closeModalPreview] = useModal();

  const {
    scenarioId,
    activeTab,
    isLoading,
    formValue,
    setFormValue,
    scenariosUser,
    scenariosBase,
    scenariosOptionsBase,
    scenariosOptionsUser,
    ppbOptions,
    ppbData,
    form,
    fetchPPBandScenarios,
    fetchBaseScenarios,
    updateUserScenarios,
    onFinishStep1,
    onFinishStep2,
    onCancel,
    handleChangeTag,
  } = useScenariosForm();

  useEffect(() => {
    fetchPPBandScenarios();
  }, []);

  useEffect(() => {
    form.setFieldsValue(formValue);
  }, [formValue]);

  useDidMountEffect(() => {
    updateUserScenarios(formValue.scenario);
  }, [scenariosUser]);

  useDidMountEffect(() => {
    fetchBaseScenarios(formValue?.targetYear?.format('YYYY'));
  }, [formValue.targetYear]);

  const columnsPreview = [
    {
      title: t('pages.prices.fields.spec'),
      label: t('pages.prices.fields.spec'),
      dataIndex: 'spec',
    },
    {
      title: t('pages.prices.fields.partNumber'),
      label: t('pages.prices.fields.partNumber'),
      dataIndex: 'partNumber',
    },
    {
      title: t('pages.prices.fields.supplier'),
      label: t('pages.prices.fields.supplier'),
      dataIndex: 'supplier',
    },
    {
      title: t('common.months.january'),
      label: t('common.months.january'),
      dataIndex: 'january',
    },
    {
      title: t('common.months.february'),
      label: t('common.months.february'),
      dataIndex: 'february',
    },
    {
      title: t('common.months.march'),
      label: t('common.months.march'),
      dataIndex: 'march',
    },
    {
      title: t('common.months.april'),
      label: t('common.months.april'),
      dataIndex: 'april',
    },
    {
      title: t('common.months.may'),
      label: t('common.months.may'),
      dataIndex: 'may',
    },
    {
      title: t('common.months.june'),
      label: t('common.months.june'),
      dataIndex: 'june',
    },
    {
      title: t('common.months.july'),
      label: t('common.months.july'),
      dataIndex: 'july',
    },
    {
      title: t('common.months.august'),
      label: t('common.months.august'),
      dataIndex: 'august',
    },
    {
      title: t('common.months.september'),
      label: t('common.months.september'),
      dataIndex: 'september',
    },
    {
      title: t('common.months.october'),
      label: t('common.months.october'),
      dataIndex: 'october',
    },
    {
      title: t('common.months.november'),
      label: t('common.months.november'),
      dataIndex: 'november',
    },
    {
      title: t('common.months.december'),
      label: t('common.months.december'),
      dataIndex: 'december',
    },
  ];

  const validateMessages = {
    required: `\${label} ${t('common.validate.isRequired')}`,
  };

  const handleChangePrice = (value) => {
    setFormValue({
      ...formValue,
      price: value,
    });
  };

  const handlePreview = (data, fileIndex) => {
    const arrayToObj = csvArrayToObj(data);
    if (arrayToObj?.length) {
      setPreview(arrayToObj);
      setFormValue({
        ...formValue,
        [fileIndex]: arrayToObj,
      });

      if (fileIndex === 'pricesFile') {
        closeModalPrice();
      } else {
        closeModalFactor();
      }

      openModalPreview();
    } else {
      closeModalPrice();
      closeModalFactor();
      closeModalPreview();
    }
  };

  const closePreview = (action) => {
    //if user cancel the upload, clear the state
    if (action === 'cancel') {
      const fileIndex = titlePreview === 'Prices' ? 'pricesFile' : 'factorsFile';

      setFormValue({
        ...formValue,
        [fileIndex]: [],
      });
    }

    closeModalPreview();
  };

  return (
    <S.Row justify="center">
      {visiblePreview && (
        <StyledModal
          title={`Preview - ${titlePreview}`}
          open={visiblePreview}
          onClose={() => {
            closePreview('cancel');
          }}
          footer={
            <SC.ModalFooter>
              <Button
                onClick={() => {
                  closePreview('cancel');
                }}
              >
                {t('common.cancel')}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  closePreview('upload');
                }}
              >
                {t('common.fields.uploadFile')}
              </Button>
            </SC.ModalFooter>
          }
          body={
            <SimpleTable
              rowKey="id"
              data-cy="table"
              size="small"
              bordered
              columns={columnsPreview}
              dataSource={preview}
              pagination={false}
            />
          }
          width="46rem"
        />
      )}

      {visiblePrice && (
        <StyledModal
          area="createScenarios"
          title={t('pages.prices.title')}
          open={visiblePrice}
          onClose={closeModalPrice}
          body={
            <CsvUploadReader
              getCsvData={async (csvData) => {
                await handlePreview(csvData, 'pricesFile');
              }}
            />
          }
          footer={null}
          width="30rem"
        />
      )}

      {visibleFactor && (
        <StyledModal
          area="createScenarios"
          title={t('pages.factors.title')}
          open={visibleFactor}
          onClose={closeModalFactor}
          body={
            <CsvUploadReader
              getCsvData={async (csvData) => {
                await handlePreview(csvData, 'factorsFile');
              }}
            />
          }
          footer={null}
          width="30rem"
        />
      )}

      <Col span={16}>
        <StyledPageHeader
          title={
            scenarioId ? t('pages.scenarios.registerUserUpdate') : t('pages.scenarios.registerUser')
          }
          className="pl-0 pr-0"
        />

        {isLoading ? (
          <Loading size={2} />
        ) : (
          <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={handleChangeTag}>
            <TabPane tab={t('pages.scenarios.step1')} key="1">
              <Form
                name="form_scenarios"
                layout="vertical"
                onFinish={onFinishStep1}
                validateMessages={validateMessages}
                initialValues={formValue}
                form={form}
              >
                <Row>
                  <Col sm={24} md={14}>
                    <Row gutter={[24, 16]}>
                      <Col span={12}>
                        <Form.Item
                          name="targetYear"
                          label={t('pages.scenarios.fields.targetYear')}
                          rules={[{ required: true }]}
                        >
                          <DatePicker
                            picker="year"
                            mode="year"
                            style={{ width: '100%' }}
                            placeholder={t('pages.scenarios.fields.targetYear')}
                            onChange={(value) => {
                              setFormValue({ ...formValue, targetYear: value, scenario: '' });
                            }}
                            disabled={!!scenarioId}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          name="scenario"
                          label={t('pages.scenarios.fields.base')}
                          rules={[{ required: true }]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('pages.scenarios.fields.base')}
                            options={scenariosOptionsBase}
                            disabled={!!scenarioId}
                            onChange={(value) => {
                              const getScenario = scenariosBase.find((item) => item.id === value);
                              const getScenarioName = parseScenarioName(getScenario);
                              const version = getVersion(getScenarioName);

                              setFormValue({
                                ...formValue,
                                scenario: value,
                                scenarioVersion: version,
                                price: '',
                              });

                              updateUserScenarios(value);
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          name="name"
                          label={t('pages.scenarios.fields.name')}
                          rules={[{ required: true }]}
                        >
                          <Input
                            placeholder={t('pages.scenarios.fields.name')}
                            onChange={(e) => setFormValue({ ...formValue, name: e.target.value })}
                            maxLength={100}
                            disabled={!scenarioId && !formValue.scenario}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          name="ppbRule"
                          label={`${t('pages.scenarios.fields.ppbRule')} - ${t(
                            'pages.scenarios.fields.targetScore'
                          )}`}
                          rules={[{ required: true }]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('pages.scenarios.fields.ppbRule')}
                            onChange={(value) => setFormValue({ ...formValue, ppbRule: value })}
                            options={ppbOptions}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Divider />

                <Row>
                  <Col sm={24} md={24}>
                    <Row justify="space-between">
                      <Col sm={12} md={4}>
                        <Button htmlType="button" danger onClick={onCancel}>
                          {t('common.cancel')}
                        </Button>
                      </Col>
                      <Col style={{ width: '150px' }}>
                        <Form.Item>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="primary" htmlType="submit" className="btn-scenario-next">
                              {t('pages.scenarios.nextStep')}
                            </Button>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </TabPane>

            <TabPane tab={t('pages.scenarios.step2')} key="2" disabled={activeTab < 2}>
              <S.StepScenario>
                <header>
                  <div className="block">
                    <h3>{t('pages.scenarios.fields.targetYear')}</h3>
                    <div>{formValue?.targetYear?.format('YYYY')}</div>
                  </div>
                  <div className="block">
                    <h3>{t('pages.scenarios.fields.version')}</h3>
                    <div>{formValue.scenarioVersion}</div>
                  </div>
                  <div className="block">
                    <h3>
                      {scenarioId
                        ? t('pages.scenarios.fields.updateScenario')
                        : t('pages.scenarios.fields.newScenario')}
                    </h3>
                    <div>{formValue.name}</div>
                  </div>
                  <div className="block">
                    <h3>
                      {scenarioId
                        ? t('pages.scenarios.fields.modified')
                        : t('pages.scenarios.fields.created')}
                    </h3>
                    <div>{moment(formValue.lastUpdate).format('MM/DD/YYYY')}</div>
                  </div>
                  <div className="block">
                    <h3>{t('pages.scenarios.fields.ppbRule')}</h3>
                    <div>
                      {formValue.ppbRule &&
                        parsePpbName(ppbData.find((ppb) => formValue.ppbRule === ppb.id))}
                    </div>
                  </div>
                </header>

                <Form
                  name="form_scenarios2"
                  layout="vertical"
                  onFinish={onFinishStep2}
                  validateMessages={validateMessages}
                  initialValues={formValue}
                  className="form-step2"
                >
                  <h4
                    className="title-prices"
                    dangerouslySetInnerHTML={{
                      __html: scenarioId
                        ? t('pages.scenarios.fields.titlePriceUpdate')
                        : t('pages.scenarios.fields.titlePrice'),
                    }}
                  />
                  <Row>
                    <Col sm={24} md={24}>
                      <Row gutter={[24, 16]}>
                        <Col span={6}>
                          <Form.Item name="price">
                            <Select
                              style={{ width: '100%' }}
                              placeholder={t('pages.scenarios.fields.selectPrice')}
                              onChange={(value) => handleChangePrice(value)}
                              options={scenariosOptionsUser}
                              value={formValue.price}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="btn-wrapper">
                          <span className="or">{t('common.or')}</span>
                          <Button
                            className="btn-upload"
                            htmlType="button"
                            onClick={() => {
                              setTitlePreview('Prices');
                              openModalPrice();
                            }}
                            disabled={formValue.price}
                            icon={formValue?.pricesFile?.length ? <AiOutlineCheck /> : false}
                          >
                            {t('pages.scenarios.fields.addPrice')}
                          </Button>
                          <Button
                            className="btn-upload"
                            htmlType="button"
                            onClick={() => {
                              setTitlePreview('Factors');
                              openModalFactor();
                            }}
                            disabled={formValue.price}
                            icon={formValue?.factorsFile?.length ? <AiOutlineCheck /> : false}
                          >
                            {t('pages.scenarios.fields.addFactor')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Divider />

                  <Row>
                    <Col sm={24} md={24}>
                      <Row justify="space-between">
                        <Col sm={12} md={4}>
                          <Button htmlType="button" onClick={() => handleChangeTag('1')}>
                            {t('pages.scenarios.prevStep')}
                          </Button>
                        </Col>
                        <Col style={{ width: '150px' }}>
                          <Form.Item>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="btn-scenario-create"
                              >
                                {scenarioId
                                  ? t('pages.scenarios.registerUpdate')
                                  : t('pages.scenarios.register')}
                              </Button>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </S.StepScenario>
            </TabPane>
          </Tabs>
        )}
      </Col>
    </S.Row>
  );
}
