import styled from 'styled-components';

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
export const CriticalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  color: ${({ theme }) => theme.colorsDesignSystem.red};
  font-size: 0.75rem;
  .divider {
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-weight: bolder;
    font-size: 0.75rem;
  }
`;

export const HighContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  color: ${({ theme }) => theme.colorsDesignSystem.tagColorOrange};
  font-size: 0.75rem;
  .divider {
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-weight: bolder;
    font-size: 0.75rem;
  }
`;

export const GoodContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  font-size: 0.75rem;
  .divider {
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-weight: bolder;
    font-size: 0.75rem;
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: start;
  margin-left: 2rem;
  padding-right: 0;
  margin-right: 0;
  width: 7rem;
`;
