import { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { CloseOutlined, CodepenOutlined } from '@ant-design/icons';
import { Row, Tooltip } from 'antd';
import * as S from './styles';
import { InventoryData, InventoryDataItens, ParamsPropsInventory } from './types';
import StyledIndicationTag from '../../../components/IndicationTag';
import { getURI, oneAlert } from '../../../helpers/utils';
import { api } from '../../../services/api';
import { StyledSelectOptions } from '../../ComponentsPrices/PricePage/types';
import StyledFilterTag from '../../../components/Common/StyledFilterTag';
import theme from '../../../styles/theme';
import StyledClearTag from '../../../components/Common/StyledFilterTag/StyledClearTag';
import ExpandableTable from '../../ComponentsPrices/ExpandableTable';
import SimpleTable from '../../../components/Tables/SimpleTable';
import cm from '../../../assets/CM.png';
import { useCmsContext } from '../../../context/CmsContext';

export function useInventory() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { handleGetCms, handleSelectDefaultCm } = useCmsContext();
  const CMs = handleGetCms();
  const initialParams: ParamsPropsInventory = {
    product: 'Notebook',
    cm: handleSelectDefaultCm('Foxconn'),
    category: '',
    spec: '',
    partNumber: '',
    source: '',
    supplier: '',
    date: dayjs().subtract(1, 'day'),
    page: 0,
    limit: 0,
  };
  const [params, setParams] = useState<ParamsPropsInventory>(initialParams);
  const [openCard, setOpenCard] = useState(false);
  const [categories, setCategories] = useState<Array<StyledSelectOptions>>([]);
  const [specifications, setSpecifications] = useState<Array<StyledSelectOptions>>([]);
  const [partNumbers, setPartNumbers] = useState<Array<StyledSelectOptions>>([]);
  const [suppliers, setSuppliers] = useState<Array<StyledSelectOptions>>([]);
  const ExpandCategories: any = {};
  const [expandableViewStatus, setExpandableViewStatus] = useState(ExpandCategories);
  const [inventoryComponents, setInventoryComponents] = useState<Array<InventoryData>>([]);
  const [isFirstRendering, setIsFirstRendering] = useState(true);
  const [lastData, setLastData] = useState<dayjs.Dayjs>(dayjs().subtract(1, 'day'));

  const Sources = [
    { value: 'FOB', label: 'FOB' },
    { value: 'BR', label: 'BR' },
  ];
  const products = [
    { value: 'Notebook', label: 'Notebook' },
    { value: 'Desktop', label: 'Desktop' },
  ];

  const handleGenerateProjectTooltipTex = (projects: Array<string>): string => {
    return projects ? `Projects  ${projects.join(', ')}` : t('common.emptyTooltip');
  };

  const handleFormatValueRemoveDecimalPrecision = (value: string): string => {
    try {
      return value.endsWith('.000') ? value.slice(0, -4).replace(',', '.') : value;
    } catch (error) {
      console.log(error);
      return '-';
    }
  };

  const columns: ColumnsType<InventoryDataItens> = [
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.partNumber')}</strong>;
      },
      dataIndex: 'partNumber',
      key: 'partNumber',
      align: 'center',
      render: (_, record) => {
        return (
          <Tooltip placement="right" title={record.description}>
            <S.PartNumberHeader>
              <span>{record.partNumber || '-'}</span>
            </S.PartNumberHeader>
          </Tooltip>
        );
      },
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.specification')}</strong>;
      },
      dataIndex: 'specification',
      key: 'specification',
      align: 'center',
      render: (_, record) => {
        return <span>{record.specification || '-'}</span>;
      },
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.project')}</strong>;
      },
      dataIndex: 'qtProjects',
      key: 'qtProjects',
      align: 'center',
      render: (_, record) => {
        return (
          <Tooltip placement="bottom" title={handleGenerateProjectTooltipTex(record.projects)}>
            <S.PartNumberHeader>
              <span>{record.qtProjects || '-'}</span>
            </S.PartNumberHeader>
          </Tooltip>
        );
      },
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.cm')}</strong>;
      },
      dataIndex: 'cm',
      key: 'cm',
      align: 'left',
      render: (_, record) => {
        return (
          <Row align="middle">
            <img src={cm} alt="ícon" width={10.5} height={9.75} />
            {record.cm.toUpperCase() === 'FOXCONN' ? 'FBR' : 'CEB'}
          </Row>
        );
      },
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.source')}</strong>;
      },
      dataIndex: 'source',
      key: 'source',
      align: 'center',
      width: '4.625rem',
      render: (_, record) => {
        return (
          <S.RowCell>
            <StyledIndicationTag text={record.source || ''} />
          </S.RowCell>
        );
      },
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.supplier')}</strong>;
      },
      dataIndex: 'supplier',
      key: 'supplier',
      align: 'center',
      render: (_, record) => {
        return <span>{record.supplier || '-'}</span>;
      },
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.totalOnHand')}</strong>;
      },
      dataIndex: 'totalOnHand',
      key: 'totalOnHand',
      align: 'center',
      render: (_, record) => {
        return <span>{handleFormatValueRemoveDecimalPrecision(record.totalOnHand) || '-'}</span>;
      },
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.allocated')}</strong>;
      },
      dataIndex: 'allocatedOrders',
      key: 'allocatedOrders',
      align: 'center',
      width: '7rem',
      render: (_, record) => {
        return (
          <span>{handleFormatValueRemoveDecimalPrecision(record.allocatedOrders) || '-'}</span>
        );
      },
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.freeQty')}</strong>;
      },
      dataIndex: 'freeQty',
      key: 'freeQty',
      align: 'center',
      className: 'highlighted',
      render: (_, record) => {
        return <span>{handleFormatValueRemoveDecimalPrecision(record.freeQty) || '-'}</span>;
      },
    },
    {
      title: () => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <strong>{t('pages.inventory.tableHeaders.value')}</strong>
            <span>{t('pages.inventory.tableHeaders.valueUsd')}</span>
          </div>
        );
      },
      dataIndex: 'value',
      key: 'value',
      align: 'center',
      width: '5rem',
      render: (_, record) => {
        return <span>{record.value || '-'}</span>;
      },
    },
  ];

  const handleConvertStringToSelectOptions = (
    values: Array<string>
  ): Array<StyledSelectOptions> => {
    return values.map((value) => {
      return {
        label: value,
        value,
      };
    });
  };

  const handleFetchHeaders = async () => {
    try {
      const { data, status } = await api.get(
        getURI('/inventory/header', { ...params, date: dayjs(params.date).format('DD-MM-YYYY') })
      );
      if (status === 200) {
        if (data) {
          setPartNumbers(handleConvertStringToSelectOptions(data.partNumber));
          setCategories(handleConvertStringToSelectOptions(data.category));
          setSpecifications(handleConvertStringToSelectOptions(data.specification));
          setSuppliers(handleConvertStringToSelectOptions(data.supplier));
        }
        return;
      }
      if (status === 204) {
        oneAlert('info', t('pages.inventory.noContent'));
        setPartNumbers(handleConvertStringToSelectOptions([]));
        setCategories(handleConvertStringToSelectOptions([]));
        setSpecifications(handleConvertStringToSelectOptions([]));
        setSuppliers(handleConvertStringToSelectOptions([]));
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const hasParams = () => {
    return params.category || params.spec || params.partNumber || params.source || params.supplier;
  };

  const handleFetchComponents = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(
        getURI('/inventory', { ...params, date: dayjs(params.date).format('DD-MM-YYYY') })
      );
      if (status === 200) {
        setInventoryComponents(data || []);
        if (hasParams() || data.length === 1) {
          setExpandableViewStatus({ [data[0].category]: true });
        } else {
          setExpandableViewStatus({});
        }
      }
      status === 204 && setInventoryComponents([]);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
      setInventoryComponents([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchLastDate = async () => {
    try {
      const { data, status } = await api.get(
        getURI('/inventory/lastRecord', { date: dayjs().format('DD-MM-YYYY') })
      );
      if (status === 200) {
        const stringToDate = `${data.split('-')[2]}-${data.split('-')[1]}-${data.split('-')[0]}`;
        const formattedDate = dayjs(stringToDate).format('MM/DD/YYYY');
        setLastData(dayjs(formattedDate));
        setParams({
          ...params,
          date: dayjs(formattedDate),
        });
        return;
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
      setIsFirstRendering(false);
    }
  };

  const handleUpdateFilters = (field: string, value: string) => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        [field]: value,
      };
    });
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {/* {params.product && (
          <StyledFilterTag
            label={t('pages.inventory.labels.product')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.product}
            closeble
            onClose={() => {
              handleUpdateFilters('product', '');
            }}
          />
        )}
        {params.cm && (
          <StyledFilterTag
            label={t('pages.inventory.labels.cm')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.cm}
            closeble
            onClose={() => {
              handleUpdateFilters('cm', '');
            }}
          />
        )} */}
        {params.category && (
          <StyledFilterTag
            label={t('pages.inventory.labels.category')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.category}
            closeble
            onClose={() => {
              handleUpdateFilters('category', '');
            }}
          />
        )}
        {params.spec && (
          <StyledFilterTag
            label={t('pages.inventory.labels.specification')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.spec}
            closeble
            onClose={() => {
              handleUpdateFilters('spec', '');
            }}
          />
        )}
        {params.partNumber && (
          <StyledFilterTag
            label={t('pages.inventory.labels.partNumber')}
            closeicon={<CloseOutlined />}
            title={params.partNumber}
            closeble
            onClose={() => {
              handleUpdateFilters('partNumber', '');
            }}
          />
        )}
        {params.source && (
          <StyledFilterTag
            label={t('pages.inventory.labels.source')}
            closeicon={<CloseOutlined />}
            title={params.source}
            closeble
            onClose={() => {
              handleUpdateFilters('source', '');
            }}
          />
        )}
        {params.supplier && (
          <StyledFilterTag
            label={t('pages.inventory.labels.supplier')}
            closeicon={<CloseOutlined />}
            title={params.supplier}
            closeble
            onClose={() => {
              handleUpdateFilters('supplier', '');
            }}
          />
        )}
      </>
    );
  };

  const clearAllFilters = () => {
    setParams({ ...initialParams, date: lastData });
  };

  const handleRenderClearAllFilters = () => {
    if (
      // params.product ||
      // params.cm ||
      params.category ||
      params.spec ||
      params.partNumber ||
      params.source ||
      params.supplier
    ) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  const handleUpdateExpandableViews = (value: string) => {
    setExpandableViewStatus((oldParams: any) => {
      return {
        ...oldParams,
        [value]: !expandableViewStatus[value],
      };
    });
  };

  const handleRenderSourceTag = (items: Array<InventoryDataItens>, type: 'FOB' | 'BR') => {
    if (items.some((item) => item.source === type)) {
      return <StyledIndicationTag text={type} />;
    }
  };

  const disabledDate = (current: dayjs.Dayjs) => {
    return current && current >= dayjs();
  };

  const handleRenderTablesComponents = () => {
    return inventoryComponents.map((item: InventoryData) => {
      return (
        <S.TablesContainer key={item.category} isOpen={expandableViewStatus[item.category]}>
          <S.HeaderExpandableItem>
            <ExpandableTable
              isOpen={expandableViewStatus[item.category]}
              onClick={() => {
                handleUpdateExpandableViews(item.category);
              }}
            />
            <S.HeaderExpandableContainer>
              <div>
                <span>{item.category}</span>
                <Row>
                  {handleRenderSourceTag(item.items, 'FOB')}
                  {handleRenderSourceTag(item.items, 'BR')}
                </Row>
              </div>
              <div>
                <S.ComponentsQntContainer>
                  <p>
                    <CodepenOutlined style={{ fontSize: 28 }} />
                  </p>
                  <p>{item.items.length}</p>
                  <p>{t('pages.componentsPrices.tableHeaders.components')}</p>
                </S.ComponentsQntContainer>
              </div>
            </S.HeaderExpandableContainer>
          </S.HeaderExpandableItem>
          {expandableViewStatus[item.category] && (
            <S.TableContent>
              <SimpleTable
                striped
                bordered={false}
                scroll={{ x: 'max-content', y: 'auto' }}
                pagination={false}
                dataSource={item.items}
                rowKey="partNumber"
                columns={columns}
                noBorder
                scrollable
              />
            </S.TableContent>
          )}
        </S.TablesContainer>
      );
    });
  };

  return {
    isLoading,
    params,
    columns,
    openCard,
    CMs,
    products,
    categories,
    specifications,
    partNumbers,
    Sources,
    suppliers,
    inventoryComponents,
    setOpenCard,
    handleFetchHeaders,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
    handleUpdateFilters,
    handleFetchComponents,
    handleRenderTablesComponents,
    disabledDate,
    handleFetchLastDate,
    isFirstRendering,
  };
}
