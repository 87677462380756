import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

import { $getRoot, $insertNodes } from 'lexical';
import { ILoadState } from './types';

export function LoadState({ value }: ILoadState) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(value, 'text/html');

      const nodes = $generateNodesFromDOM(editor, dom);

      $getRoot().select();

      $insertNodes(nodes);
    });
  }, []);

  return null;
}
