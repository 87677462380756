import styled from 'styled-components';
import theme from '../../../../../styles/theme';

export const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 0.938rem;
  background-color: ${theme.colorsDesignSystem.newTablesColors.fontColorHeader};
  box-sizing: border-box;
  border: 0.063rem solid;
  border-color: ${theme.colorsDesignSystem.newTablesColors.borderColor};
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  margin-bottom: 1rem;
`;
export const HeaderExpandableItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.125rem;

  span {
    color: ${theme.colorsDesignSystem.fontColor};
    font-weight: 600;
    font-size: 1rem;
  }

  p {
    color: ${theme.colorsDesignSystem.blue};
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
`;

export const HeaderExpandableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div:nth-child(1) {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    p {
      font-size: 1rem;
      font-weight: 600;
      min-width: 1.125rem;
      text-align: right;
    }
  }
`;

export const ChartContainer = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  @media (max-width: 1870px) {
    flex-wrap: wrap;
    gap: 0.5rem;
  }
`;
