import styled from 'styled-components';

export const ContainerHeaderTableTitles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.headerTitleTable};
  border: solid ${({ theme }) => theme.colorsDesignSystem.border};
  border-width: 1px 1px 0px 1px;
  height: 3.438rem;
  justify-content: space-between;
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  padding-right: 1.5rem;
`;

export const StyledSpanBlue = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
  min-width: 11.75rem;
  border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
  margin-right: 1rem;
`;

export const fxRateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: flex-end;
  gap: 0.875rem;
`;
