import { DollarOutlined, ExclamationCircleFilled } from '@ant-design/icons';

import { t } from 'i18next';
import * as S from './styles';
import { ItemsQuotation } from './types';

import ExclamationCircle from '../../../assets/exclamation-circle.svg';
import {
  formatNumberForTowDecimalPlaces,
  formatNumberToOrdinal,
} from '../../../helpers/FormatValues';
import theme from '../../../styles/theme';

export default function StyledBidCardList(data: Array<ItemsQuotation>) {
  return data.map((item) => {
    return (
      <S.Container key={item.category}>
        <span>{item.category}</span>
        {item.items.map((items, index) => {
          return (
            <S.ItemContainer key={crypto.randomUUID()}>
              <S.CardContainer>
                <S.CardTitle>
                  {!items.grossPrice ? (
                    <ExclamationCircleFilled
                      style={{ color: theme.colorsDesignSystem.tagColorYellow }}
                    />
                  ) : (
                    <img
                      src={ExclamationCircle}
                      alt="Exclamation Circle Icon"
                      style={{ width: '1.2rem' }}
                    />
                  )}
                  <span className="blue">{(index + 1).toString().padStart(2, '0')}</span>
                  <S.AltGroup>{items.name}</S.AltGroup>
                </S.CardTitle>
                {items.grossPrice && (
                  <S.Classification>
                    {formatNumberToOrdinal(items.rankingPosition || 0)}
                  </S.Classification>
                )}
              </S.CardContainer>
              {items.grossPrice && (
                <S.GrossPriceContainer>
                  <S.GrossPriceLabel>
                    <DollarOutlined />
                    {t('pages.bidSupplierRFQ.grossPrice')}
                  </S.GrossPriceLabel>
                  <S.GrossPriceValue>
                    ${formatNumberForTowDecimalPlaces(Number(items.grossPrice))}
                  </S.GrossPriceValue>
                </S.GrossPriceContainer>
              )}
            </S.ItemContainer>
          );
        })}
      </S.Container>
    );
  });
}
