import { Row } from 'antd/lib';
import { Percentage, ProjectName, TooltipContainer, TooltipText } from './styles';
import { tooltipProps } from './types';

export const CustomTooltip = ({ active, payload, label }: tooltipProps) => {
  const formatPorcentage = () => {
    const { porcentage } = payload[0].payload;
    return `${Number(porcentage).toFixed(2)}%`;
  };
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <ProjectName>{payload[0].payload.project}</ProjectName>
        <Row style={{ gap: '0.3rem' }}>
          <span
            style={{ color: payload[0].payload.fill, fontSize: '1.6rem', marginBottom: '0.2rem' }}
          >
            •
          </span>
          <TooltipText>{payload[0].payload.sku}</TooltipText>
          <Percentage>{formatPorcentage()}</Percentage>
        </Row>
      </TooltipContainer>
    );
  }

  return null;
};
