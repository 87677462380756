import { Dropdown, Menu } from 'antd';
import { DropdownTrigger, MenuIcon } from './styles';

const TableAction = ({ dropdownItems, placement, ...props }) => {
  return (
    <Dropdown
      placement={placement}
      overlay={
        <Menu>
          {dropdownItems.map(
            (item) => item.label && <Menu.Item key={item.key}>{item.label}</Menu.Item>
          )}
        </Menu>
      }
      trigger={['click']}
      arrow={{ pointAtCenter: true }}
      data-cy="action-box"
      {...props}
    >
      <DropdownTrigger onClick={(e) => e.stopPropagation()} data-cy="action">
        <MenuIcon />
      </DropdownTrigger>
    </Dropdown>
  );
};

export { TableAction };
