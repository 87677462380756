import styled from 'styled-components';

interface border {
  noBorder?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.75rem;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.shape};
  border-radius: ${({ theme }) => theme.dimensions.border_radius_medium};
  box-shadow: 0px 0.3rem 0.6rem ${({ theme }) => theme.colorsDesignSystem.boxShadow};
  cursor: pointer;
`;

export const Header = styled.div<border>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-bottom: ${({ theme, noBorder }) =>
    noBorder ? `1px solid ${theme.colorsDesignSystem.border}` : 'none'};
`;

export const Content = styled.main`
  padding: 1rem;
`;
