import moment from 'moment';
import { useState } from 'react';
import { CountDownProps } from './types';

export function useCardCountDown() {
  const [timeLeft, setTimeLeft] = useState('');
  const [urgency, setUrgency] = useState<CountDownProps>({ urgency: 'LOW' });

  const getTimeUtc = (): string => {
    const now = new Date();

    const offset = now.getTimezoneOffset();
    const offsetHours = -offset / 60;
    const formattedOffset = `UTC${offsetHours >= 0 ? '+' : ''}${offsetHours}`;
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Desconhecido';

    return `${formattedOffset} ${timeZoneName}`;
  };

  const UpdateCountDown = (endDate: string) => {
    try {
      const now = moment();
      const duration = moment.duration(moment(endDate).diff(now));

      if (duration.asSeconds() <= 0) {
        setTimeLeft('0D 00:00:00');
        setUrgency({ urgency: 'STOP' });
        return;
      }

      const days = Math.floor(duration.asDays());
      days > 2 && setUrgency({ urgency: 'LOW' });
      days <= 1 && setUrgency({ urgency: 'MEDIUM' });
      days === 0 && setUrgency({ urgency: 'HIGHT' });

      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      setTimeLeft(
        `${days}D ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
          seconds
        ).padStart(2, '0')}`
      );
    } catch (error) {
      console.log(error);
      setTimeLeft('0D 00:00:00');
    }
  };

  return {
    getTimeUtc,
    UpdateCountDown,
    urgency,
    timeLeft,
  };
}
