import { Col, Row } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const Content = styled.div`
  padding: 1.875rem 2.5rem;
`;

export const SummaryContent = styled(Col)<{ width?: string }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.header};
  border-radius: 0.375rem;
  padding: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
`;

export const LeftLabel = styled(Col)`
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 400;
  width: 6rem;
  color: ${({ theme }) => theme.colors.input_labels};
`;

export const LeftValue = styled(Col)`
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text};

  b {
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
`;

export const RightLabel = styled.span`
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};

  b {
    font-size: 1rem;
    font-weight: 700;
  }
`;

export const Footer = styled(Row)`
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  padding: 1rem 1.25rem;
`;
