import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button } from '../../Button';

export const FileVisualization = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  max-height: 11.25rem;
  padding: 2.25rem 1rem 3rem 1rem;
  border-radius: ${({ theme }) => theme.dimensions.border_radius};
  background-color: ${({ theme }) => theme.colors.gray_background};
  border: 1px solid ${({ theme }) => theme.colors.gray_border};
`;

export const TextFileName = styled.div`
  margin-bottom: 0px;
`;

export const StyledCheckCircleOutlined = styled(CheckCircleOutlined)`
  color: ${({ theme }) => theme.colors.prim};
  font-size: 2.625rem;
`;

export const DraggerContainer = styled.div`
  width: 100%;
`;

export const TextContainerFileVisualization = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .unsupported {
    color: ${({ theme }) => theme.colorsDesignSystem.red};
    font-size: 1rem;
    font-weight: 600;
    padding: 0rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .fileSize {
    color: ${({ theme }) => theme.colorsDesignSystem.grayText};
  }
`;

export const FileTypeError = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  max-height: 11.25rem;
  padding: 2.25rem 1rem 3rem 1rem;
  border-radius: ${({ theme }) => theme.dimensions.border_radius};
  background-color: ${({ theme }) => theme.colorsDesignSystem.gray_background};
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.red};
`;
export const ExclamationIcon = styled(ExclamationCircleOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.red};
  font-size: 2.625rem;
`;

export const DeleteButton = styled(Button)`
  font-size: 1rem;
  border: none;
  background-color: ${({ theme }) => theme.colors.gray_background};
  border-radius: 100%;
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  :hover {
    background: none;
  }
`;
