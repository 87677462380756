import styled from 'styled-components';
import { Row } from 'antd';
import {
  CloseCircleOutlined as CloseCircle,
  WarningFilled,
  ExclamationCircleFilled,
  CheckCircleFilled,
} from '@ant-design/icons';

export const CloseCircleOutlined = styled(CloseCircle)`
  color: ${({ theme }) => theme.colors.danger};
`;

export const VariablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LabelContainer = styled.span`
  display: flex;
  gap: 0.625rem;
  margin-bottom: 0.563rem;
  margin-top: 1rem;
  margin-left: 0.25rem;
  padding: 0;
`;

export const ItensContainer = styled(Row)`
  gap: 2.125rem;
`;

export const VariablesTitle = styled.span`
  margin: 0.5rem;
`;
export const ErrorLabel = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  text-align: center;
  font-size: 0.75rem;
`;
export const InputContainer = styled.div`
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const WarningIcon = styled(WarningFilled)`
  color: ${({ theme }) => theme.colorsDesignSystem.tagColorOrange};
`;
export const ExclamationIcon = styled(ExclamationCircleFilled)`
  color: ${({ theme }) => theme.colorsDesignSystem.red};
`;
export const CheckIcon = styled(CheckCircleFilled)`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  border-top: 0.063rem solid ${({ theme }) => theme.colors.gray_border};
  padding: 0.625rem 1.5rem 0rem 1.5rem;
`;
