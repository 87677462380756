import styled from 'styled-components';
import { MoreOutlined } from '@ant-design/icons';

export const DropdownTrigger = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.black};

  transition: all 0.2 ease;

  :hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const MenuIcon = styled(MoreOutlined)`
  color: ${({ theme }) => theme.colors.dark_green};
  font-size: 30px;

  :hover {
    filter: brightness(0.8);
  }
`;
