import styled from 'styled-components';
import { Select } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const placeholder = styled.p`
  color: ${({ theme }) => theme.colors.inputPlaceHolder} !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
`;

export const StyledSelect = styled(Select)`
  width: 100%;

  div {
    border-radius: ${({ theme }) => theme.dimensions.border_radius} !important;
  }

  .ant-select-selection-placeholder {
    font-size: 0.75rem;
    font-weight: 400;
  }
`;
