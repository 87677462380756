import { DatePicker } from 'antd';
import styled from 'styled-components';

export const container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const containerOnlyDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
`;

export const datePicker = styled(DatePicker)`
  height: 2rem;
  min-width: 8.75rem;
  border-radius: ${({ theme }) => theme.dimensions.border_radius};
  .ant-picker-suffix {
    width: 0.75rem;
    height: 0.75rem;
  }
  input {
    &::placeholder {
      color: ${({ theme }) => theme.colors.inputPlaceHolder} !important;
    }
    font-size: 0.75rem !important;
    font-weight: 400 !important;
  }
`;
