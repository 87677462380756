import { useState } from 'react';
import { MinMaxTypes, VariablesTypes } from './types';

export function useCrateRules() {
  const [criticalMax, setCriticalMax] = useState<number>();
  const [criticalMin, setCriticalMin] = useState<number>();
  const [highMin, setHighMin] = useState<number>();
  const [highMax, setHighMax] = useState<number>();
  const [goodMin, setGoodMin] = useState<number>();
  const [goodMax, setGoodMax] = useState<number>();
  const [isBonus, setIsBonus] = useState<boolean>();

  const initialMinMax: MinMaxTypes = { min: '', max: '' };
  const [statusCritical, setStatusCritical] = useState<MinMaxTypes>(initialMinMax);
  const [statusHigh, setStatusHigh] = useState<MinMaxTypes>(initialMinMax);
  const [statusGood, setStatusGood] = useState<MinMaxTypes>(initialMinMax);

  const verifyMinMax = (min: number, max: number) => {
    return min >= max;
  };

  const HandleCriticalStatus = (formData: VariablesTypes) => {
    if (formData?.criticalMin !== null && formData?.criticalMax !== null) {
      if (verifyMinMax(formData.criticalMin, formData.criticalMax)) {
        return setStatusCritical({ min: 'error', max: '' });
      }
      if (verifyMinMax(formData.criticalMax, formData.highMin) && formData.highMin !== null) {
        return setStatusCritical({ min: '', max: 'error' });
      }
    }
    return setStatusCritical({ min: '', max: '' });
  };

  const HandleHighStatus = (formData: VariablesTypes) => {
    if (formData?.highMin !== null && formData?.highMax !== null) {
      if (verifyMinMax(formData.highMin, formData.highMax)) {
        return setStatusHigh({ min: 'error', max: '' });
      }
      if (verifyMinMax(formData.highMax, formData.goodMin) && formData.goodMin !== null) {
        return setStatusHigh({ min: '', max: 'error' });
      }
    }
    return setStatusHigh({ min: '', max: '' });
  };
  const HandleGoodStatus = (formData: VariablesTypes) => {
    if (
      verifyMinMax(formData?.goodMin, formData?.goodMax) &&
      formData?.goodMin !== null &&
      formData?.goodMax !== null
    ) {
      return setStatusGood({ min: 'error', max: '' });
    }

    return setStatusGood({ min: '', max: '' });
  };

  return {
    statusCritical,
    statusHigh,
    statusGood,
    isBonus,
    criticalMax,
    criticalMin,
    highMin,
    highMax,
    goodMin,
    goodMax,
    setIsBonus,
    setGoodMin,
    setHighMax,
    setGoodMax,
    setHighMin,
    setCriticalMin,
    setCriticalMax,
    HandleCriticalStatus,
    HandleHighStatus,
    HandleGoodStatus,
    // HandleDisableVariablesForm,
  };
}
