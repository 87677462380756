import { EnvironmentOutlined } from '@ant-design/icons';
import * as S from './styles';
import { StyledSpan } from '../styles';
import dollar from '../../../assets/dollar.svg';

interface TableHeaderTitleProps {
  location: string;
  code: string;
  cm: string;
  fxRate: string;
}

export const TableHeaderTitle = ({ location, code, cm, fxRate }: TableHeaderTitleProps) => {
  return (
    <S.ContainerHeaderTableTitles>
      <div style={{ display: 'flex' }}>
        <S.LocationContainer>
          <EnvironmentOutlined />
          <StyledSpan>Manufacturing Location</StyledSpan>
        </S.LocationContainer>
        <S.StyledSpanBlue>{location}</S.StyledSpanBlue>
        <S.StyledSpanBlue>{code}</S.StyledSpanBlue>
        <S.StyledSpanBlue>{cm}</S.StyledSpanBlue>
      </div>
      <S.fxRateContainer>
        <img src={dollar} alt="dólar icon" />
        <span>{fxRate}</span>
      </S.fxRateContainer>
    </S.ContainerHeaderTableTitles>
  );
};
