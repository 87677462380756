import { useTranslation } from 'react-i18next';
import * as S from './styles';
import StyledClearTagProps from './types';

export default function StyledClearTag({ onClick }: StyledClearTagProps) {
  const { t } = useTranslation();
  return (
    <S.clearTags onClick={onClick} type="default" size="small" data-testid="clear-tag">
      <span data-testid="clear-text">{t('common.clearFilters')}</span>
    </S.clearTags>
  );
}
