import styled from 'styled-components';

export const TitleHeader = styled.span`
  font-weight: 400;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  margin-bottom: 1rem;
  margin-top: 1.25rem;
`;

export const ContainerTagsTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleHeaderTable = styled.strong`
  color: ${({ theme }) => theme.colorsDesignSystem.headerSubTitleTableText};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
