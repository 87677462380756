import * as S from './styles';
import StyledModalProps from './types';

export default function StyledModal({
  title,
  open,
  width,
  onClose,
  body,
  footer,
  centered,
  noMargin = false,
  noPadding = false,
}: StyledModalProps) {
  return (
    <S.ModalStyled
      title={title}
      onCancel={onClose}
      footer={
        footer ? (
          <S.ModalFooterContainer noMargin={noMargin} noPadding={noPadding} footer={footer}>
            {footer}
          </S.ModalFooterContainer>
        ) : null
      }
      centered={centered}
      open={open}
      width={width}
      noMargin={noMargin}
      noPadding={noPadding}
    >
      <S.ModalBodyContainer noMargin={noMargin} noPadding={noPadding} footer={footer}>
        {body}
      </S.ModalBodyContainer>
    </S.ModalStyled>
  );
}
