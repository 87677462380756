import { useEffect } from 'react';
import { Loading } from '../../../components/Loading';
import SimpleTable from '../../../components/Tables/SimpleTable';
import * as S from './styles';
import { ModalContentProps } from './types';
import { useContentModalShareProportion } from './useContentModalBidResponse';

export function ContentModalBidDetails({ title, id, specifications }: ModalContentProps) {
  const { itens, isLoading, columns, handleFetchBidItemDetails } = useContentModalShareProportion();

  useEffect(() => {
    handleFetchBidItemDetails(id, specifications);
  }, [id]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <S.Container>
          <div style={{ display: 'flex' }}>
            <S.TitleHeader>{title}</S.TitleHeader>
          </div>
          <SimpleTable
            striped
            bordered={false}
            pagination={false}
            dataSource={itens}
            rowKey="partNumber"
            columns={columns}
            isLoading={isLoading}
          />
        </S.Container>
      )}
    </div>
  );
}
