import { useTranslation } from 'react-i18next';
import * as S from './styles';

export const useChartPriceTableForMonth = () => {
  const { t } = useTranslation();

  const chartTheme = {
    before: '#6CC08B',
    current: '#105965',
    next: '#C2C8D4',
  };

  const handleRenderLegend = () => {
    return (
      <div>
        <S.Container>
          <S.BarChartItem colorBar={chartTheme.before} />
          <S.LabelsChart>
            {t('pages.procurement.dashboard.labelsGraphDemandPerMonth.before')}
          </S.LabelsChart>
          <S.BarChartItem colorBar={chartTheme.current} />
          <S.LabelsChart>
            {t('pages.procurement.dashboard.labelsGraphDemandPerMonth.current')}
          </S.LabelsChart>
          <S.BarChartItem colorBar={chartTheme.next} />
          <S.LabelsChart>
            {t('pages.procurement.dashboard.labelsGraphDemandPerMonth.after')}
          </S.LabelsChart>
        </S.Container>
      </div>
    );
  };

  const handleRenderColorByMonth = (month: number): string => {
    const currentMonth = new Date().getMonth() + 1;

    if (month < currentMonth) {
      return chartTheme.before;
    }

    if (month === currentMonth) {
      return chartTheme.current;
    }

    if (month > currentMonth) {
      return chartTheme.next;
    }

    return '';
  };

  return {
    handleRenderLegend,
    handleRenderColorByMonth,
  };
};
