import { Col, Row, Form } from 'antd';
import { BiPlus } from 'react-icons/bi';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUsers } from './useUsers';
import { EditableCell } from '../../components/EditableCell';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import * as SC from '../../styles/common';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { UserForm } from './Form';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import StyledModal from '../../components/Common/StyledModal';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledButton from '../../components/Common/StyledButton';

export function UsersPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.user.title'));

  const validateMessages = ValidateMessages();

  const {
    data,
    params,
    fetchUsers,
    handleChangeTable,
    isLoading,
    form,
    isNewItem,
    handleCloseFilteredTag,
    mergedColumns,
    visibleModalUser,
    openModalUser,
    closeModalUser,
    userEdit,
    setUserEdit,
  } = useUsers();

  const showTagsFilter = params.name || params.email;

  useEffect(() => {
    fetchUsers();
  }, [params]);

  return (
    <main>
      <Row justify="center">
        {visibleModalUser && (
          <StyledModal
            area="Users"
            width="26rem"
            title={userEdit ? t('pages.user.editUser') : t('pages.user.addUser')}
            open={visibleModalUser}
            onClose={() => {
              setUserEdit(null);
              closeModalUser();
            }}
            body={
              <UserForm
                onClose={() => {
                  setUserEdit(null);
                  closeModalUser();
                }}
                data={userEdit}
                fetchUsers={fetchUsers}
              />
            }
            footer={null}
          />
        )}

        <Col span={24} className="filter-page">
          <Row justify="center">
            <Col span={24}>
              <StyledHeader
                title={t('pages.user.title')}
                items={data.length}
                actions={
                  <StyledButton
                    key={1}
                    iconLeft={<BiPlus />}
                    disabled={isNewItem}
                    onClick={() => {
                      openModalUser();
                    }}
                    variant="primary"
                    text={`${t('pages.user.addUser')}`}
                  />
                }
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <SC.ContainerFilteredTags>
                {showTagsFilter && <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>}

                {params.name && (
                  <SC.TagFilter closable onClose={() => handleCloseFilteredTag('name')}>
                    <div className="tag-title">{t('pages.user.fields.name')}</div>
                    <div className="tag-value">{params.name}</div>
                  </SC.TagFilter>
                )}

                {params.email && (
                  <SC.TagFilter closable onClose={() => handleCloseFilteredTag('email')}>
                    <div className="tag-title">{t('pages.user.fields.email')}</div>
                    <div className="tag-value">{params.email}</div>
                  </SC.TagFilter>
                )}
              </SC.ContainerFilteredTags>
            </Col>
          </Row>

          <Row justify="center" style={{ marginBottom: 32 }}>
            <Col span={23}>
              <Form form={form} component={false} validateMessages={validateMessages}>
                <SimpleTable
                  rowKey="id"
                  data-cy="table"
                  striped
                  dataSource={data}
                  isLoading={isLoading}
                  columns={mergedColumns}
                  onChange={handleChangeTable}
                  scroll={{ x: 'max-content', y: 'calc(100vh - 20rem)' }}
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  pagination={false}
                  locale={{
                    emptyText: (
                      <ResultNoScenarios
                        title={t('common.results.noDataTitle')}
                        subTitle={t('common.results.noDataSubtitle')}
                      />
                    ),
                  }}
                />
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
}
