import styled from 'styled-components';
import { Row as AntdRow, Button as AntdButton } from 'antd';

export const Container = styled.div`
  height: 100vh;
`;

export const Main = styled(AntdRow)`
  height: calc(100vh - 50px);
`;

export const Button = styled(AntdButton)`
  background: ${({ theme }) => theme.colors.link};
  border: none;
  color: #fff;
  font-weight: 500;

  &:hover,
  &:focus {
    filter: brightness(0.9);
    background: ${({ theme }) => theme.colors.link};
  }
`;

export const ButtonSecondary = styled(Button)`
  color: ${({ theme }) => theme.colors.link};
  border-color: ${({ theme }) => theme.colors.link};
  background: #fff;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.link};
    color: ${({ theme }) => theme.colors.link};
    filter: brightness(0.9);
    background: #fff;
  }
`;
