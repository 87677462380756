import { CloseOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledFilterTag from '../../../components/Common/StyledFilterTag';
import StyledClearTag from '../../../components/Common/StyledFilterTag/StyledClearTag';
import { handleFormatValueFields } from '../../../helpers/nUtils';
import {
  formatDecimalPlaces,
  formatScoreData,
  getErrorMessage,
  getURI,
  oneAlert,
} from '../../../helpers/utils';
import { useToggle } from '../../../hooks/useToggle';
import { api } from '../../../services/api';
import theme from '../../../styles/theme';
import { FormattedData, TableDataType } from '../types';
import * as S from './styles';
import {
  Component,
  ComponentContent,
  ComponentResponse,
  PageData,
  Project,
  ProjectResponse,
  ShareProportionsResponse,
} from './types';

import { useCmsContext } from '../../../context/CmsContext';

const INITIAL_VALUES: PageData = {
  tableScore: [],
  shareProportionsGraphData: [],
  verticalBarChartData: [],
};

export function useDashboardSpecifications() {
  //const localTargetYear = localStorage.getItem('targetYear') || new Date().getFullYear().toString();
  const { t } = useTranslation();
  const { handleGetCms, handleSelectDefaultCm } = useCmsContext();
  const CMs = handleGetCms();
  const INITIAL_PARAMS = {
    product: 'Notebook',
    cm: handleSelectDefaultCm('Foxconn'),
    projectName: '',
    componentType: '',
    targetYear: dayjs(),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(INITIAL_VALUES);
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [isModalOpen, toggleModal] = useToggle(false);
  const [selectedProject, setSelectedProject] = useState<TableDataType>();
  const [projects, setProjects] = useState<Project[]>([]);
  const [components, setComponents] = useState<Component[]>([]);

  function generateLabel(quarter: string, total: number) {
    if (total > 0) {
      return `${quarter} ${total}`;
    }

    return quarter;
  }

  function formatShareProportionsData(graphData: ShareProportionsResponse[]) {
    const formatted = graphData.map((item) => {
      const { q1, q2, q3, q4 } = item;
      const splittedName = item.name.split('-');
      const name = splittedName.length > 2 ? splittedName[2] : splittedName[1];

      return {
        ...item,
        name,
        q1: {
          ...q1,
          label: 'Q1',
          fobPercentage: formatDecimalPlaces(q1.fobPercentage),
          brPercentage: formatDecimalPlaces(q1.brPercentage),
        },
        q2: {
          ...q2,
          label: 'Q2',
          fobPercentage: formatDecimalPlaces(q2.fobPercentage),
          brPercentage: formatDecimalPlaces(q2.brPercentage),
        },
        q3: {
          ...q3,
          label: 'Q3',
          fobPercentage: formatDecimalPlaces(q3.fobPercentage),
          brPercentage: formatDecimalPlaces(q3.brPercentage),
        },
        q4: {
          ...q4,
          label: 'Q4',
          fobPercentage: formatDecimalPlaces(q4.fobPercentage),
          brPercentage: formatDecimalPlaces(q4.brPercentage),
        },
      };
    });

    return formatted.splice(0, 5);
  }

  function formatFilters(filters: ProjectResponse[] | ComponentContent[]) {
    return filters.map((item, index) => {
      if ('project' in item) {
        return {
          key: `${item.project}-${index}`,
          value: item.project,
          label: item.project,
        };
      }

      return {
        value: item.id,
        label: item.name,
      };
    });
  }

  function handleSelectProject(project: any) {
    const projectData: TableDataType = formatScoreData(project);
    setSelectedProject(projectData);
    toggleModal();
  }

  async function fetchProjects() {
    try {
      const { data: projectData, status: projectStatus } = await api.get<ProjectResponse[]>(
        getURI('/dashboard/projectnames', { product: params.product, cm: params.cm })
      );
      if (projectStatus === 200) {
        const formattedProjects = formatFilters(projectData);
        setProjects([
          { value: '', label: `${t('common.all')} ${t('pages.dashboard.fields.projects')}` },
          ...formattedProjects,
        ]);
      }
      if (projectStatus === 204) {
        setProjects([]);
      }
    } catch (error) {
      oneAlert('error', t('toast.errorOnList'));
    }
  }

  async function fetchComponents() {
    try {
      const { data: componentsData, status: componentsStatus } = await api.get<ComponentResponse>(
        getURI('/componentType/ppb', {
          product: params.product,
          cm: params.cm,
          projectName: params.projectName,
        })
      );

      if (componentsStatus === 200) {
        const formattedComponents = formatFilters(componentsData.content);
        setComponents([
          { value: '', label: `${t('common.all')} ${t('pages.dashboard.fields.components')}` },
          ...formattedComponents,
        ]);
      }
      if (componentsStatus === 204) {
        setComponents([]);
      }
    } catch (error) {
      oneAlert('error', t('toast.errorOnList'));
    }
  }

  async function fetchData() {
    try {
      setIsLoading(true);

      const [verticalBarData, shareProportionData, tableScoreData] = await Promise.all([
        api.get(
          getURI('/dashboard/rankingComponentFobBr', {
            ...params,
            targetYear: dayjs(params.targetYear).format('YYYY'),
          })
        ),
        api.get(
          getURI('/dashboard/findDashboardShareProportionespec', {
            ...params,
            targetYear: dayjs(params.targetYear).format('YYYY'),
          })
        ),
        api.get(
          getURI('/dashboard/findDashboardForecastSku', {
            ...params,
            targetYear: dayjs(params.targetYear).format('YYYY'),
          })
        ),
      ]);

      const apiData = {
        verticalBarChartData: verticalBarData.data,
        shareProportionsGraphData: shareProportionData.data
          ? formatShareProportionsData(shareProportionData.data)
          : [],
        tableScore: tableScoreData.data || [],
      };

      setData(apiData);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnList'));
    }
  }

  function handleFilter(
    selectedFilter: any,
    type: 'projectName' | 'component' | 'targetYear' | 'product' | 'cm'
  ) {
    if (type === 'component') {
      const componentSelected = components.filter(
        (component) => component.value === selectedFilter
      );
      setParams((oldParams) => ({
        ...oldParams,
        componentType: componentSelected[0].value ? componentSelected[0].label : '',
      }));
    }
    setParams((oldParams) => ({ ...oldParams, [type]: selectedFilter }));
  }

  const handleRenderFiltersTags = () => {
    return (
      <>
        {params.projectName && (
          <StyledFilterTag
            label={t('pages.dashboard.labelProject')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.projectName}
            closeble
            onClose={() => {
              handleFilter('', 'projectName');
              handleFilter('', 'component');
            }}
          />
        )}
        {params.componentType && (
          <StyledFilterTag
            label={t('pages.dashboard.labelComponents')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.componentType}
            closeble
            onClose={() => {
              handleFilter('', 'component');
            }}
          />
        )}
      </>
    );
  };

  const handleRenderClearAllFilters = () => {
    if (params.projectName || params.componentType) {
      return <StyledClearTag onClick={() => setParams(INITIAL_PARAMS)} />;
    }
  };

  useEffect(() => {
    setParams((oldParams) => ({ ...oldParams, componentType: '' }));
    fetchComponents();
  }, [params.projectName]);

  useEffect(() => {
    fetchProjects();
    fetchComponents();
    fetchData();
  }, []);

  const columns: ColumnsType<FormattedData> = [
    {
      title: t('pages.dashboard.fields.projectName'),
      dataIndex: 'projectName',
      key: 'project name',
      render: (projectName, record) => {
        const isActive = !record.finished;

        return (
          <S.ProjectName
            $isActive={isActive}
            {...(isActive ? { onClick: () => handleSelectProject(record) } : {})}
          >
            {projectName}
          </S.ProjectName>
        );
      },
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: t('pages.dashboard.fields.modelName'),
      dataIndex: 'modelName',
      key: 'model name',
    },
    {
      title: t('pages.dashboard.fields.demands'),
      dataIndex: 'forecast',
      children: [
        {
          title: t('common.months.january'),
          dataIndex: ['january', 'skuForecast'],
          key: 'january.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return (
              <span>
                {handleFormatValueFields({
                  number: record.january.skuForecast,
                  minFractional: 0,
                  maxFactional: 2,
                })}
              </span>
            );
          },
        },
        {
          title: t('common.months.february'),
          dataIndex: ['february', 'skuForecast'],
          key: 'february.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return (
              <span>
                {handleFormatValueFields({
                  number: record.february.skuForecast,
                  minFractional: 0,
                  maxFactional: 2,
                })}
              </span>
            );
          },
        },
        {
          title: t('common.months.march'),
          dataIndex: ['march', 'skuForecast'],
          key: 'march.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return (
              <span>
                {handleFormatValueFields({
                  number: record.march.skuForecast,
                  minFractional: 0,
                  maxFactional: 2,
                })}
              </span>
            );
          },
        },
        {
          title: t('common.months.april'),
          dataIndex: ['april', 'skuForecast'],
          key: 'april.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return (
              <span>
                {handleFormatValueFields({
                  number: record.april.skuForecast,
                  minFractional: 0,
                  maxFactional: 2,
                })}
              </span>
            );
          },
        },
        {
          title: t('common.months.may'),
          dataIndex: ['may', 'skuForecast'],
          key: 'may.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return (
              <span>
                {handleFormatValueFields({
                  number: record.may.skuForecast,
                  minFractional: 0,
                  maxFactional: 2,
                })}
              </span>
            );
          },
        },
        {
          title: t('common.months.june'),
          dataIndex: ['june', 'skuForecast'],
          key: 'june.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return (
              <span>
                {handleFormatValueFields({
                  number: record.june.skuForecast,
                  minFractional: 0,
                  maxFactional: 2,
                })}
              </span>
            );
          },
        },
        {
          title: t('common.months.july'),
          dataIndex: ['july', 'skuForecast'],
          key: 'july.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return (
              <span>
                {handleFormatValueFields({
                  number: record.july.skuForecast,
                  minFractional: 0,
                  maxFactional: 2,
                })}
              </span>
            );
          },
        },
        {
          title: t('common.months.august'),
          dataIndex: ['august', 'skuForecast'],
          key: 'august.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return (
              <span>
                {handleFormatValueFields({
                  number: record.august.skuForecast,
                  minFractional: 0,
                  maxFactional: 2,
                })}
              </span>
            );
          },
        },
        {
          title: t('common.months.september'),
          dataIndex: ['september', 'skuForecast'],
          key: 'september.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return (
              <span>
                {handleFormatValueFields({
                  number: record.september.skuForecast,
                  minFractional: 0,
                  maxFactional: 2,
                })}
              </span>
            );
          },
        },
        {
          title: t('common.months.october'),
          dataIndex: ['october', 'skuForecast'],
          key: 'october.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return (
              <span>
                {handleFormatValueFields({
                  number: record.october.skuForecast,
                  minFractional: 0,
                  maxFactional: 2,
                })}
              </span>
            );
          },
        },
        {
          title: t('common.months.november'),
          dataIndex: ['november', 'skuForecast'],
          key: 'november.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return (
              <span>
                {handleFormatValueFields({
                  number: record.november.skuForecast,
                  minFractional: 0,
                  maxFactional: 2,
                })}
              </span>
            );
          },
        },
        {
          title: t('common.months.december'),
          dataIndex: ['december', 'skuForecast'],
          key: 'december.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return (
              <span>
                {handleFormatValueFields({
                  number: record.december.skuForecast,
                  minFractional: 0,
                  maxFactional: 2,
                })}
              </span>
            );
          },
        },
        {
          title: t('common.total'),
          dataIndex: ['total', 'skuForecast'],
          key: 'total.skuForecast',
          align: 'center',
          width: 75,
          render: (_, record) => {
            return (
              <span>
                {handleFormatValueFields({
                  number: record.total.skuForecast,
                  minFractional: 0,
                  maxFactional: 2,
                })}
              </span>
            );
          },
        },
      ],
    },
  ];

  return {
    isLoading,
    data,
    params,
    CMs,
    generateLabel,
    columns,
    isModalOpen,
    toggleModal,
    selectedProject,
    setSelectedProject,
    handleSelectProject,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    projects,
    components,
    handleFilter,
    fetchData,
  };
}
