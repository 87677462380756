import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import StyledTabs from '../../components/Common/StyledTabs';
import { StyledTab } from '../../components/Common/StyledTabs/types';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import * as S from './styles';
import { PricesPage } from './PricePage';
import { PackingPricePage } from './PackingPricePage';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';

export function ComponentsPricesPage() {
  const { t } = useTranslation();

  useDocumentTitle(t('pages.componentsPrices.Title'));

  const [tabs, setTabs] = useState<StyledTab[]>([
    { selected: true, title: t('pages.componentsPrices.tabTitles.prices') },
    { selected: false, title: t('pages.componentsPrices.tabTitles.packing') },
  ]);

  return (
    <S.Container>
      <StyledHeader title={t('pages.componentsPrices.Title')} />
      <StyledTabs tabs={tabs} setSelectedTab={setTabs} />
      {tabs[0].selected && <PricesPage />}
      {tabs[1].selected && <PackingPricePage />}
    </S.Container>
  );
}
