import { Card as AntCard, Row } from 'antd';
import styled, { css } from 'styled-components';

type ProjectNameProps = {
  $isActive: boolean;
};

type LegendContainerProps = {
  align?: React.CSSProperties['justifyContent'];
  padding: React.CSSProperties['padding'];
};

type LegendProps = {
  color?: string;
  type?: 'circle' | 'line' | 'square';
};

export const FilterContainer = styled(Row)`
  justify-content: flex-start;
  gap: 18px;
`;

export const FiltersTagContainer = styled(Row)`
  display: flex;
  align-items: center;
  margin-top: 0.938rem;
  gap: 0.375rem;
`;

export const GraphsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 46px;
`;

export const Card = styled(AntCard)`
  width: 100%;
  height: 100%;
  max-height: 392px;

  & .ant-card-body {
    width: 100%;
    height: 392px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ErrorCard = styled(AntCard)`
  width: 100%;
  height: 100%;
  max-height: 392px;

  & .ant-card-body {
    width: 100%;
    height: 392px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 32px;

  .ant-slider {
    width: 100%;
    flex: 1;
  }
`;

export const ProjectName = styled.span<ProjectNameProps>`
  font-weight: 500;
  color: ${({ $isActive }) => ($isActive ? '#5EA320' : 'rgba(102, 102, 102, 0.45)')};
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'default')};
`;

export const GraphInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    align-self: flex-end;
    color: #666666;
  }
`;

export const LegendContainer = styled.div<LegendContainerProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: ${({ align }) => align};
  padding: ${({ padding }) => padding};

  > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const Legend = styled.span<LegendProps>`
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${({ color }) => color || '#999'};

  ${({ type }) =>
    type === 'line' &&
    css`
      border-radius: 0;
      height: 1px;
      width: 38px;
    `}

  ${({ type }) =>
    type === 'square' &&
    css`
      border-radius: 0;
      height: 12px;
      width: 38px;
    `}
`;
