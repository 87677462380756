import { Col, ConfigProvider, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HeaderTitle } from '../../../components/PageHeader/HeaderTitle';
import { ResultNoScenarios } from '../../../components/ResultNoScenarios';
import { Loading } from '../../../components/Loading';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useSKUAlert } from './useSKUAlert';
import SimpleTable from '../../../components/Tables/SimpleTable';

export function SKUAlertPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.skuAlert.title'));
  const { id: alertId } = useParams<{ id: string }>();

  const { isLoading, columns, headerInfo, tableData, fetchAlert, handleChangeTable } =
    useSKUAlert();

  useEffect(() => {
    fetchAlert();
  }, [alertId]);

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <PageHeader
                  title={
                    <HeaderTitle
                      title={t('pages.skuAlert.title')}
                      subtitle={`${headerInfo?.qtFound} ${t('pages.skuAlert.fields.found')}`}
                    />
                  }
                  extra={[
                    <span key={1}>{headerInfo?.scenario}</span>,
                    <span key={2}>{headerInfo?.date}</span>,
                  ]}
                  className="pl-0 pr-0"
                />
              </Col>
            </Row>

            <Row justify="center">
              <Col span={23} style={{ paddingBottom: 24 }}>
                <ConfigProvider>
                  <SimpleTable
                    rowKey="id"
                    data-cy="table"
                    striped
                    onChange={handleChangeTable}
                    dataSource={tableData}
                    columns={columns}
                    pagination={false}
                    locale={{
                      emptyText: (
                        <ResultNoScenarios
                          title={t('common.results.noDataTitle')}
                          subTitle={t('common.results.noDataSubtitle')}
                          icon={null}
                        />
                      ),
                    }}
                  />
                </ConfigProvider>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
