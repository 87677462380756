import { Row } from 'antd';
import styled from 'styled-components';

export const Container = styled.main`
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.background};
  min-height: 95vh;
`;

export const ProjectName = styled.span`
  font-size: ${({ theme }) => theme.dimensions.font_title_large};
  color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
  margin-bottom: 65px;
`;

export const ContainerFilters = styled(Row)`
  gap: 1rem;
  align-items: flex-start;
  margin-bottom: 0.75rem;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  height: 3.125rem;
`;

export const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.938rem;
  background-color: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  padding: 1.125rem;
`;

export const FiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.938rem;
  gap: 0.375rem;
  margin-bottom: 0.938rem;
`;

export const HeaderExpandableItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.875rem;

  span {
    color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
    /* font-weight: bold; */
    font-weight: 600;
    font-size: 1rem;
    /* font-weight: 400; */
  }

  p {
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
    /* font-weight: bold; */

    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
`;

export const SpanHeaderTables = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
`;

export const DivErros = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  gap: 0.25rem;
`;

export const FieldError = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colorsDesignSystem.red};
`;
