import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { ILexicalToHTML } from './types';

export function LexicalToHTML({ onChange }: ILexicalToHTML) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (onChange) {
      return editor.registerUpdateListener(({ editorState }) => {
        const textAsLexicalString = JSON.stringify(editorState);

        editor.update(() => {
          const textAsHTML = $generateHtmlFromNodes(editor, null);
          onChange(editorState, textAsHTML, textAsLexicalString, editor);
        });
      });
    }
  }, [editor, onChange]);

  return null;
}
