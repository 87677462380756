import { Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../../components/Common/StyledButton';
import { IExitRFQModal } from './types';

export function ExitRFQModal({
  isOpen,
  onCloseModal,
  onDelete,
  onSaveDraft,
  loadingDelete,
  loadingDraft,
}: IExitRFQModal) {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('pages.newRfq.exitModalTitle')}
      open={isOpen}
      onCancel={onCloseModal}
      width="24rem"
      footer={
        <Row justify="space-between">
          <StyledButton variant="secondary" onClick={onDelete} loading={loadingDelete}>
            {t('pages.newRfq.deleteAll')}
          </StyledButton>
          <StyledButton variant="primary" onClick={onSaveDraft} loading={loadingDraft}>
            {t('pages.newRfq.saveDraft')}
          </StyledButton>
        </Row>
      }
    >
      {t('pages.newRfq.exitModalDescription')}
    </Modal>
  );
}
