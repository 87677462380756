import { Col, ConfigProvider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import SimpleTable from '../../../components/Tables/SimpleTable';
import { useAVLCSimulation } from './useAVLCSimulation';
import * as S from './styles';
import { Loading } from '../../../components/Loading';
import { AVLCPropTypes } from './types';

export function AVLCSimulation({ scenarioName, AVLCIds }: AVLCPropTypes) {
  const { t } = useTranslation();
  const { columns, AVLCdata, isLoading, handleFetchAvlcData } = useAVLCSimulation();
  useEffect(() => {
    handleFetchAvlcData(AVLCIds);
  }, []);

  return (
    <Col>
      <Row>
        <S.ContainerTitle>
          <S.TitleAVLC>AVLC</S.TitleAVLC>
          <S.TitleAVLC>|</S.TitleAVLC>
          <S.TitleScenarios>{scenarioName}</S.TitleScenarios>
        </S.ContainerTitle>
      </Row>
      <Row justify="center">
        <Col span={23}>
          <ConfigProvider>
            {isLoading ? (
              <Loading />
            ) : (
              <SimpleTable
                dataSource={AVLCdata}
                rowKey="id"
                columns={columns}
                pagination={false}
                striped
              />
            )}
          </ConfigProvider>
        </Col>
      </Row>
    </Col>
  );
}
