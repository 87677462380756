import styled from 'styled-components';
import { Row } from 'antd';
import { Label } from 'recharts';
import theme from '../../../styles/theme';
import StyledButton from '../../../components/Common/StyledButton';

export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  .icon {
    color: ${theme.colorsDesignSystem.primary};
    font-size: 0.875rem;
  }
`;

export const CellMoneySpan = styled.span`
  margin-right: 1rem;
`;
export const CellMoneyHeaderSpan = styled.span`
  margin-right: 0.3rem;
`;

export const FiltersContainer = styled(Row)`
  display: flex;
  align-items: end;
  gap: ${theme.dimensions.gap};
  /* margin-bottom: 1.875rem; */
  .ant-form-item {
    margin-bottom: 0;
  }
`;
export const FiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.938rem;
  gap: 0.375rem;
`;

export const SelectContainer = styled.div`
  width: 30%;
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const TotalPriceContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 78%;
`;

export const TotalPrice = styled(Label)`
  font-weight: 700;
  font-size: 2rem;
  color: ${theme.colorsDesignSystem.text};
`;

export const TotalPriceLabel = styled.p`
  width: fit-content;
  font-weight: 400;
  color: ${theme.colorsDesignSystem.text};
`;

export const BlueText = styled.p`
  color: #0c6dff;
`;

export const TableFiltersContainer = styled(Row)`
  gap: ${theme.dimensions.gap};
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ApplyButton = styled(StyledButton)`
  display: flex;
  align-items: end;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 45rem;
  width: 100%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-top: 1rem;

  .grafic {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .totalLabel {
      color: ${theme.colorsDesignSystem.text};
      font-weight: 300;
      font-size: 0.8rem;
    }
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  color: ${theme.colorsDesignSystem.text};
  size: bold;
  padding: 1rem;
  border-bottom: 1px solid ${theme.colorsDesignSystem.border};
  gap: 0.5rem;
`;

export const FilterAndTagContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableContainers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;
