import { Button, Row } from 'antd';
import styled from 'styled-components';

export const Container = styled.main``;

export const ProjectName = styled.span`
  font-size: ${({ theme }) => theme.dimensions.font_title_large};
  color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
  margin-bottom: 65px;
`;

export const ContainerFilters = styled(Row)`
  gap: 1rem;
  align-items: flex-end;
  margin-bottom: 0.75rem;
  margin-top: 2rem;
`;

export const FiltersTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.938rem;
  gap: 0.375rem;
`;

export const TablesContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 0.938rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.fontColorHeader};
  box-sizing: border-box;
  border: 0.063rem solid;
  border-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.borderColor};
  border-radius: ${({ isOpen }) =>
    isOpen ? '0.375rem 0.375rem 0 0' : '0.375rem 0.375rem 0.375rem 0.375rem'};
  margin-bottom: 1rem;
`;

export const TableContent = styled.div`
  padding: 0 1rem;
`;

export const HeaderExpandableItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.125rem;

  span {
    color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
    font-weight: 600;
    font-size: 1rem;
  }

  p {
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
`;

export const HeaderExpandableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div:nth-child(1) {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    p {
      font-size: 1rem;
      font-weight: 600;
      min-width: 1.125rem;
      text-align: right;
    }
  }
`;

export const ComponentsQntContainer = styled.span`
  min-width: 7.188rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const PartNumberHeader = styled.div`
  display: flex;
  font-weight: bold;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  justify-content: center;
  align-items: center;
  span {
    text-align: center;
    min-width: 7.25rem !important;
  }
`;

export const RowCell = styled.div`
  padding: 1rem;
  font-size: ${({ theme }) => theme.dimensions.font_small};
`;

export const LabelButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font: inherit;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  text-align: center;
  min-width: 4.375rem;
`;

export const EditButton = styled(Button)`
  :hover {
    color: ${({ theme }) => theme.colors.link} !important;
  }

  height: 2rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.grayBorder};
  font-weight: 400;
  font-size: 0.875rem;

  span {
    color: ${({ theme }) => theme.colors.prim};
  }
`;

export const HeaderCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const ContainerNoContent = styled.div`
  display: flex;
  min-width: 4.375rem;
  justify-content: center;
`;
