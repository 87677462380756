import styled from 'styled-components';
import { CountDownProps } from './types';

export const Container = styled.main<CountDownProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ showDate }) => (showDate ? 'space-between' : null)};
  align-items: center;
  gap: 2rem;
`;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  span {
    font-size: 0.625rem;
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  }

  b {
    font-weight: 600;
    font-size: 1rem;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }
`;

export const ContainerRight = styled.div<CountDownProps>`
  display: flex;
  flex-direction: column;

  span {
    font-size: 0.625rem;
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  }

  .destak {
    width: 100%;
    font-size: 1.5rem;
    color: ${({ theme, urgency }) =>
      urgency === 'HIGHT'
        ? theme.colorsDesignSystem.red
        : urgency === 'MEDIUM'
        ? theme.colorsDesignSystem.tagColorYellow
        : urgency === 'LOW'
        ? theme.colorsDesignSystem.primary
        : theme.colorsDesignSystem.timeStop};
    font-weight: 600;
  }
`;
