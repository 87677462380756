import { useTranslation } from 'react-i18next';
import * as S from './styles';

export function FilterViewPF({ options, value, setValue, groupBySpec, setGroupBySpec }) {
  const { t } = useTranslation();

  return (
    <S.ContainerSelect>
      <S.Label>{t('pages.factors.views.view')}:</S.Label>
      <S.SelectInput
        placeholder={t('pages.factors.views.view')}
        value={value}
        onChange={(e) => {
          setValue(e);
        }}
        options={options}
      />
    </S.ContainerSelect>
  );
}
