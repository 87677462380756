import { Button } from 'antd';
import styled from 'styled-components';

export const ActionButton = styled(Button)`
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.link};
  transition: filter 0.2s;
  justify-content: flex-start;
  padding: 0;

  &:hover,
  &:focus {
    background-color: ${({ disabled, theme }) =>
      disabled ? theme.colors.dark_row : 'transparent'} !important;
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.text_disabled : theme.colors.link} !important;
    filter: brightness(0.8);
  }

  svg {
    height: 1rem;
    width: 1rem;
  }

  span,
  a {
    bottom: 2px;
    position: relative;
  }

  &.btn-cancel {
    > span {
      color: ${({ theme }) => theme.colors.red};
      margin-left: 0;
      font-weight: 500;

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.text_disabled};
      }
    }

    &.green {
      > span {
        color: ${({ theme }) => theme.colors.link};

        &:hover,
        &:focus {
          color: ${({ theme }) => theme.colors.link};
        }
      }
    }
  }

  &.btn-modal {
    svg {
      fill: ${({ theme }) => theme.colors.link};
    }
    &::after {
      content: '';
      width: 100%;
      display: block;
      position: absolute;
      bottom: -14px;
      left: 0;
      height: 3px;
      background-color: ${({ theme }) => theme.colors.link};
    }
  }
`;
