import styled from 'styled-components';

export const Header = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.text};
  display: flex;
  flex-flow: row nowrap;
  height: 3.125rem;
  justify-content: space-between;
  padding: 0 1rem;
  border-bottom: 0.2rem solid ${(props) => props.theme.colorsDesignSystem.primary};
`;

export const LogoContainer = styled.div`
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  height: inherit;
`;

export const LogoAcerImg = styled.img`
  height: 1.75rem;
  padding: 0.25rem 0;
  cursor: pointer;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
  font-size: 1rem;
  font-weight: 700;
  height: 100%;
`;
