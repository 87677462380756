import { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Popconfirm, Space, Tooltip } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import theme from '../../styles/theme';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';
import { ForecastItem, ForecastParams } from './types';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import * as S from './styles';
import { api } from '../../services/api';
import { getURI, oneAlert } from '../../helpers/utils';
import { SkuDetails } from './ContentModalNewForecast/types';
import { useCmsContext } from '../../context/CmsContext';

export function useNewForecast() {
  const [isLoading, setIsLoading] = useState(false);
  const [projectsSelect, setProjectsSelect] = useState<Array<StyledSelectOptions>>([]);
  const [skus, setSkus] = useState<Array<StyledSelectOptions>>([]);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [forecastToEdit, setForecastToEdit] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [skuSelected, setSkuSelected] = useState<SkuDetails>();
  const [forecastMonth, setForecastMonth] = useState('');
  const { handleGetCms, handleSelectDefaultCm } = useCmsContext();
  const CMs = handleGetCms();

  const products = [
    // { value: '', label: 'Show All' },
    { value: 'Notebook', label: 'Notebook' },
    { value: 'Desktop', label: 'Desktop' },
  ];

  const initialParams = {
    product: 'Notebook',
    cm: handleSelectDefaultCm('Foxconn'),
    year: dayjs(),
    project: null,
    sku: null,
    page: 0,
    limit: 10,
    orderBy: 'ASC',
    withDemands: true,
  };

  const initialPagination = {
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  };
  const [pageControl, setPageControl] = useState(initialPagination);
  const [params, setParams] = useState<ForecastParams>(initialParams);
  const [forecasts, setForecasts] = useState<Array<ForecastItem>>([]);

  const prepareToEdit = (record: ForecastItem) => {
    form.setFieldsValue({ ...record });
    setForecastToEdit(record.sku);
  };

  const isEditing = (record: ForecastItem) => record.sku === forecastToEdit;

  const handleFetchTotalizers = async (dataForeCasts: Array<ForecastItem>) => {
    try {
      const { data } = await api.get(
        getURI('forecastGlobal/totalizer', { ...params, year: dayjs(params.year).format('YYYY') })
      );
      data &&
        dataForeCasts.unshift({
          modelName: '',
          january: data.januaryTotal,
          february: data.februaryTotal,
          march: data.marchTotal,
          april: data.aprilTotal,
          may: data.mayTotal,
          june: data.juneTotal,
          july: data.julyTotal,
          august: data.augustTotal,
          september: data.septemberTotal,
          october: data.octoberTotal,
          november: data.novemberTotal,
          december: data.decemberTotal,
          totalYear: data.total,
          sku: t('pages.newDemand.totalizer'),
          project: '',
          updateDateTime: '',
          year: '',
          isTotalizer: true,
        });
      setForecasts(dataForeCasts || []);
      setForecastMonth(data ? dataForeCasts[1].year : '');
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchForecast = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(
        getURI('forecastGlobal', { ...params, year: dayjs(params.year).format('YYYY') })
      );
      if (status === 200) {
        data && (await handleFetchTotalizers(data.content));
        data
          ? setPageControl({
              pageLabel: String(params.page + 1),
              totalElements: data.totalElements,
              totalPages: data.totalPages,
            })
          : setPageControl(initialPagination);
        return;
      }
      setForecasts([]);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const saveRecordAfterEditing = async (record: ForecastItem) => {
    try {
      setIsLoading(true);
      const valueForm = form.getFieldsValue();
      const { status } = await api.put('forecastGlobal', {
        april: Number(valueForm.april),
        august: Number(valueForm.august),
        december: Number(valueForm.december),
        february: Number(valueForm.february),
        january: Number(valueForm.january),
        july: Number(valueForm.july),
        june: Number(valueForm.june),
        march: Number(valueForm.march),
        may: Number(valueForm.may),
        modelName: record?.modelName,
        november: Number(valueForm.november),
        october: Number(valueForm.october),
        september: Number(valueForm.september),
        sku: record?.sku,
        year: record.year,
      });
      if (status === 200) {
        handleFetchForecast();
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnSave'));
    } finally {
      setForecastToEdit('');
    }
  };

  const handleFetchSkuDetails = async (sku: string) => {
    try {
      setIsLoading(true);
      const { data } = await api.get(getURI('forecastGlobal/skuDetails', { sku }));
      data && setSkuSelected(data);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchSkus = async () => {
    try {
      const year = params.year.format('YYYY');
      const { data, status } = await api.get(
        getURI('forecastGlobal/findSkus', {
          cm: params.cm,
          product: params.product,
          project: params.project,
          year,
          withDemands: params.withDemands,
        })
      );
      if (status === 200) {
        data &&
          setSkus(
            data.map((value: string) => {
              return {
                label: value,
                value,
              };
            })
          );
        return;
      }
      setSkus([]);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const renderNumberDivisor = (value: number) => {
    return value !== 0 ? value.toLocaleString(t('common.localeMoney')) : '-';
  };

  const columns = [
    {
      title: t('pages.newDemand.tableHeaders.sku'),
      align: 'left',
      dataIndex: 'sku',
      // sorter: true,
      key: 'sku',
      editable: false,
      width: '5rem',
      render: (value: string, record: ForecastItem) =>
        record.isTotalizer ? (
          <S.NormalField isTotalizer={record.isTotalizer}>{value}</S.NormalField>
        ) : (
          <Button
            type="link"
            onClick={() => {
              handleFetchSkuDetails(record.sku);
            }}
            style={{ color: theme.colors.dark_green, margin: 0 }}
          >
            {value}
          </Button>
        ),
    },
    {
      title: t('pages.newDemand.tableHeaders.project'),
      align: 'left',
      dataIndex: 'project',
      key: 'project',
      editable: false,
      width: '20rem',
      render: (value: number, record: ForecastItem) => {
        return (
          <S.Model>
            {record.project || record.sku === 'Total Month' || record.sku === 'Total do Mês'
              ? `${record.project}`
              : `${t('pages.newDemand.noInformation')}`}
          </S.Model>
        );
      },
    },

    {
      title: t('common.months.january'),
      key: 'january',
      dataIndex: 'january',
      required: true,
      align: 'right',
      editable: true,
      width: '4.5rem',
      render: (value: number, record: ForecastItem) => (
        <S.NormalField isTotalizer={record.isTotalizer}>{renderNumberDivisor(value)}</S.NormalField>
      ),
    },
    {
      title: t('common.months.february'),
      key: 'february',
      dataIndex: 'february',
      align: 'right',
      required: true,
      editable: true,
      width: '4.5rem',
      render: (value: number, record: ForecastItem) => (
        <S.NormalField isTotalizer={record.isTotalizer}>{renderNumberDivisor(value)}</S.NormalField>
      ),
    },
    {
      title: t('common.months.march'),
      key: 'march',
      dataIndex: 'march',
      align: 'right',
      required: true,
      editable: true,
      width: '4.5rem',
      render: (value: number, record: ForecastItem) => (
        <S.NormalField isTotalizer={record.isTotalizer}>{renderNumberDivisor(value)}</S.NormalField>
      ),
    },
    {
      title: t('common.months.april'),
      key: 'april',
      dataIndex: 'april',
      align: 'right',
      required: true,
      editable: true,
      width: '4.5rem',
      render: (value: number, record: ForecastItem) => (
        <S.NormalField isTotalizer={record.isTotalizer}>{renderNumberDivisor(value)}</S.NormalField>
      ),
    },
    {
      title: t('common.months.may'),
      key: 'may',
      dataIndex: 'may',
      required: true,
      align: 'right',
      editable: true,
      width: '4.5rem',
      render: (value: number, record: ForecastItem) => (
        <S.NormalField isTotalizer={record.isTotalizer}>{renderNumberDivisor(value)}</S.NormalField>
      ),
    },
    {
      title: t('common.months.june'),
      key: 'june',
      dataIndex: 'june',
      required: true,
      align: 'right',
      editable: true,
      width: '4.5rem',
      render: (value: number, record: ForecastItem) => (
        <S.NormalField isTotalizer={record.isTotalizer}>{renderNumberDivisor(value)}</S.NormalField>
      ),
    },

    {
      title: t('common.months.july'),
      key: 'july',
      dataIndex: 'july',
      align: 'right',
      required: true,
      editable: true,
      width: '4.5rem',
      render: (value: number, record: ForecastItem) => (
        <S.NormalField isTotalizer={record.isTotalizer}>{renderNumberDivisor(value)}</S.NormalField>
      ),
    },
    {
      title: t('common.months.august'),
      key: 'august',
      dataIndex: 'august',
      align: 'right',
      required: true,
      editable: true,
      width: '4.5rem',
      render: (value: number, record: ForecastItem) => (
        <S.NormalField isTotalizer={record.isTotalizer}>{renderNumberDivisor(value)}</S.NormalField>
      ),
    },
    {
      title: t('common.months.september'),
      key: 'september',
      dataIndex: 'september',
      align: 'right',
      required: true,
      editable: true,
      width: '4.5rem',
      render: (value: number, record: ForecastItem) => (
        <S.NormalField isTotalizer={record.isTotalizer}>{renderNumberDivisor(value)}</S.NormalField>
      ),
    },
    {
      title: t('common.months.october'),
      key: 'october',
      dataIndex: 'october',
      required: true,
      align: 'right',
      editable: true,
      width: '4.5rem',
      render: (value: number, record: ForecastItem) => (
        <S.NormalField isTotalizer={record.isTotalizer}>{renderNumberDivisor(value)}</S.NormalField>
      ),
    },
    {
      title: t('common.months.november'),
      key: 'november',
      dataIndex: 'november',
      required: true,
      align: 'right',
      editable: true,
      width: '4.5rem',
      render: (value: number, record: ForecastItem) => (
        <S.NormalField isTotalizer={record.isTotalizer}>{renderNumberDivisor(value)}</S.NormalField>
      ),
    },
    {
      title: t('common.months.december'),
      key: 'december',
      dataIndex: 'december',
      required: true,
      align: 'right',
      editable: true,
      width: '4.5rem',
      render: (value: number, record: ForecastItem) => (
        <S.NormalField isTotalizer={record.isTotalizer}>{renderNumberDivisor(value)}</S.NormalField>
      ),
    },
    {
      title: t('pages.newDemand.tableHeaders.total'),
      key: 'totalYear',
      dataIndex: 'totalYear',
      align: 'right',
      className: 'highlighted',
      width: '4.5rem',
      render: (value: number, record: ForecastItem) => (
        <S.NormalField isTotalizer={record.isTotalizer}>{renderNumberDivisor(value)}</S.NormalField>
      ),
    },
    {
      title: t('pages.pricesTaxes.fields.action'),
      key: 'action',
      align: 'center',
      width: 80,
      render: (_: any, record: ForecastItem) => {
        const editable = isEditing(record);

        const actions = [
          {
            key: '1',
            label: (
              <S.EditButton
                icon={<EditOutlined />}
                type="link"
                onClick={() => {
                  prepareToEdit(record);
                }}
                data-cy="edit"
              >
                {t('common.edit')}
              </S.EditButton>
            ),
          },
        ];

        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button
              type="primary"
              style={{ borderRadius: '8px' }}
              onClick={() => {
                saveRecordAfterEditing(record);
              }}
              data-cy="save"
            >
              {t('common.save')}
            </Button>
            <Popconfirm
              title={t('common.cancelMessage')}
              onConfirm={() => {
                setForecastToEdit('');
              }}
              onClick={() => {
                setForecastToEdit('');
              }}
            >
              <Button shape="circle" icon={<AiOutlineClose />} data-cy="cancel" />
            </Popconfirm>
          </Space>
        ) : //renderizando botão da actions apenas para os itens da listagem excluindo o totalizer
        record.isTotalizer ? null : (
          <TableAction dropdownItems={actions} placement="topright" />
        );
      },
    },
  ];

  const columnsWhitAdditionalProps = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'numberWithZero',
        required: col.required,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        maxWidth: '3.75rem',
        messageRequired: t('pages.newDemand.modalFields.messageRequired'),
        typeRule: 'numberWithZero',
      }),
    };
  });

  const handleUpdateFilters = (field: string, value: any) => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        [field]: value,
      };
    });
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {/* {params.cm && (
          <StyledFilterTag
            label={t('pages.newDemand.filters.cm')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.cm}
            closeble
            onClose={() => {
              handleUpdateFilters('cm', null);
              handleUpdateFilters('project', '');
              handleUpdateFilters('sku', '');
            }}
          />
        )} */}
        {/* {params.product && (
          <StyledFilterTag
            label={t('pages.newDemand.filters.product')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.product}
            closeble
            onClose={() => {
              handleUpdateFilters('product', '');
            }}
          />
        )} */}
        {params.project && (
          <StyledFilterTag
            label={t('pages.newDemand.filters.project')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.project}
            closeble
            onClose={() => {
              handleUpdateFilters('project', '');
              handleUpdateFilters('sku', '');
            }}
          />
        )}
        {params.sku && (
          <StyledFilterTag
            label={t('pages.newDemand.filters.sku')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.sku}
            closeble
            onClose={() => {
              handleUpdateFilters('sku', '');
            }}
          />
        )}
      </>
    );
  };

  const clearAllFilters = () => {
    setParams((prevState: ForecastParams) => {
      return {
        ...prevState,
        cm: 'Foxconn',
        year: initialParams.year,
        project: null,
        sku: null,
      };
    });
  };

  const handleRenderClearAllFilters = () => {
    if (params.project || params.sku) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  const handleFetchProjects = async () => {
    try {
      const year = params.year.format('YYYY');
      const { data, status } = await api.get(
        getURI('/forecastGlobal/projects', {
          cm: params.cm,
          product: params.product,
          withDemands: params.withDemands,
          year,
        })
      );
      if (status === 200 && data.length > 0) {
        const convertedProjects = data
          .filter((project: string) => project !== 'MC20')
          .map((project: string) => {
            return {
              label: project,
              value: project,
            };
          });
        convertedProjects.unshift({ label: t('common.showAll'), value: '' });
        setProjectsSelect(convertedProjects);
        return;
      }
      setProjectsSelect([]);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const changePage = (page: number, pageSize: number) => {
    setParams({ ...params, page: page - 1, limit: pageSize });
    setPageControl({ ...pageControl, pageLabel: String(page) });
  };

  const handleChangeTable = (pagination: any, filters: any, sorter: any) => {
    if (!forecasts.length) return;
    handleUpdateFilters('orderBy', sorter.order === 'ascend' ? 'ASC' : 'DESC');
  };

  return {
    isLoading,
    products,
    CMs,
    params,
    projectsSelect,
    columnsWhitAdditionalProps,
    form,
    forecasts,
    pageControl,
    showModal,
    skuSelected,
    skus,
    setSkus,
    setShowModal,
    handleUpdateFilters,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
    handleFetchForecast,
    handleFetchProjects,
    handleChangeTable,
    handleFetchSkus,
    forecastMonth,
    setProjectsSelect,
    setParams,
    changePage,
  };
}
