import styled from 'styled-components';
import { SelectInput as SInput } from '../LateralFilter/SelectInput';

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;
`;

export const Label = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.gray_light};
`;

export const SelectInput = styled(SInput)`
  width: 220px;
`;
