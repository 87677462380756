import styled from 'styled-components';

export const Pagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  margin: 16px 0 32px;

  .total {
  }

  .controls {
    display: flex;
    height: 24px;
    align-items: center;
    line-height: 1;
  }

  .input-wrapper {
  }

  .page-text {
    padding-top: 1px;
  }

  .page-of-text {
    padding-top: 1px;
  }

  .input-pagination {
    border: 1px solid ${(props) => props.theme.colors.prim};
    border-radius: 3px;
    color: ${(props) => props.theme.colors.prim};
    padding: 12px 0;
    margin: 1px 8px 0;
    width: 32px;
  }
`;

export const PageBtn = styled.button`
  background: transparent;
  border: 0;

  &.previous {
    color: ${({ page, theme }) => {
      if (page === 1) {
        return '#ccc';
      }

      return `${theme.colors.prim}`;
    }};
  }

  &.next {
    color: ${({ page, totalPages, theme }) => {
      if (page >= totalPages) {
        return '#ccc';
      }

      return `${theme.colors.prim}`;
    }};
  }
`;
