import { Button, Row } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 16px;
`;

export const ExportButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.link};
  box-shadow: none;
  color: ${({ theme }) => theme.colors.link};
  transition: filter 0.2s;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.link};
    color: ${({ theme }) => theme.colors.link};
    filter: brightness(0.8);
    text-decoration: underline;
  }
`;

export const FiltersContainer = styled(Row)`
  display: flex;
  gap: 1rem;
  align-items: flex-end;
`;

export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  .icon {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
    font-size: 0.875rem;
  }
`;

export const FilterTagsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0 1rem 0;
`;
