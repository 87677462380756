import styled from 'styled-components';

export const TooltipContainer = styled.div`
  padding: 1rem;
  background-color: #fff;
  opacity: 0.95;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
`;

export const TooltipText = styled.p`
  color: black;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 65%;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;
