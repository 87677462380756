import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5.2rem;
  padding: 3.13rem 3.13rem 0 3.13rem;
  width: 100%;
  .rowLeft {
    gap: 0.5rem;
    span {
      font-size: 1.5rem;
      color: ${({ theme }) => theme.colorsDesignSystem.text};
    }

    b {
      font-size: 1.5rem;
      color: ${({ theme }) => theme.colorsDesignSystem.blue};
    }
  }
  .rowRight {
    gap: 0.5rem;
    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colorsDesignSystem.text};
    }
  }
`;
