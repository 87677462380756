import { useTranslation } from 'react-i18next';

export function ValidateMessages() {
  const { t } = useTranslation();
  const validateMessages = {
    required: `\${label} ${t('common.validate.isRequired')}`,
    pattern: {
      mismatch: `${t('common.validate.invalid')}`,
    },
    types: {
      number: `\${label} ${t('common.validate.number')}`,
    },
    string: {
      max: `\${label} ${t('common.validate.max')} \${max} ${t('common.characters')}`,
      min: `\${label} ${t('common.validate.min')} \${min} ${t('common.characters')}`,
      range: `\${label} ${t('common.validate.range')} \${min} ${t('common.and')} \${max} ${t(
        'common.characters'
      )}`,
    },
    number: {
      range: `\${label} ${t('common.validate.range')} \${min} ${t('common.and')} \${max}`,
    },
  };

  return validateMessages;
}
