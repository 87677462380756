import * as S from './styles';
import StyledLabelProps from './types';

export default function StyledLabel({ title, styles }: StyledLabelProps) {
  return (
    <S.labels data-testid="styled-label" style={styles}>
      {title}
    </S.labels>
  );
}
