import { CBOMCardProd } from './types';

import ExpandableCard from '../../../../components/Common/ExpandableCard';
import SimpleTable from '../../../../components/Tables/SimpleTable';
import HeaderCard from '../HeaderCards';

export function CBOMCard({
  openCbomCard,
  setOpenCbomCard,
  cbomItens,
  columns,
  priceProps,
  isLoading,
}: CBOMCardProd) {
  return (
    <ExpandableCard
      isOpen={openCbomCard}
      headerCard={
        <HeaderCard
          title={`CBOM ${priceProps?.level}`}
          componentName={priceProps?.category || '-'}
        />
      }
      onExpandableContent={setOpenCbomCard}
    >
      <SimpleTable
        striped
        bordered={false}
        pagination={false}
        dataSource={cbomItens}
        rowKey="partNumber"
        columns={columns}
        headerNoPadding
        isLoading={isLoading}
      />
    </ExpandableCard>
  );
}
