import { useTranslation } from 'react-i18next';

import * as S from './styles';

import CheckedMail from '../../../../../assets/checked_maill.svg';

export function ConfirmationModal() {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.Image src={CheckedMail} />
      <S.FirstText>{t('pages.newRfq.steps.send.modal.congratulations')}</S.FirstText>
      <S.SecondText>{t('pages.newRfq.steps.send.modal.selectedItens')}</S.SecondText>
    </S.Container>
  );
}
