import { useState } from 'react';
import { FileProps } from './types';

export function useDragAndDropUploader() {
  const [files, setFiles] = useState<any>();

  const handleFileUpload = (file: any) => {
    setFiles(file);
  };

  return {
    files,
    handleFileUpload,
  };
}
