/* eslint-disable react/require-default-props */
import { Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../../components/Common/StyledButton';

interface IDeleteCategoryModal {
  isOpen: boolean;
  onDelete: () => void;
  onCloseModal: () => void;
  message?: string;
  loading?: boolean;
}

export function DeleteCategoryModal({
  isOpen,
  onCloseModal,
  onDelete,
  message,
  loading = false,
}: IDeleteCategoryModal) {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('pages.newRfq.deleteCategoryModalTitle')}
      open={isOpen}
      onCancel={onCloseModal}
      width="24rem"
      footer={
        <Row justify="space-between">
          <StyledButton variant="secondary" onClick={onCloseModal}>
            {t('common.cancel')}
          </StyledButton>
          <StyledButton variant="primary" onClick={onDelete} loading={loading}>
            {t('common.confirm')}
          </StyledButton>
        </Row>
      }
    >
      {message || t('pages.newRfq.deleteCategoryModalDescription')}
    </Modal>
  );
}
