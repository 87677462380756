import i18n from 'i18next';
import { StyledSelectOptions } from '../components/Common/StyledSelect/types';

export const formatDataToSelect = (data: string[], hasEmptyValue: boolean) => {
  if (data.length > 0) {
    const formattedData: StyledSelectOptions[] = data.map((item) => {
      return {
        label: item,
        value: item,
      };
    });

    if (hasEmptyValue) {
      formattedData.unshift({ label: i18n.t('common.showAll'), value: '' });
    }

    return formattedData;
  }
  return [];
};
