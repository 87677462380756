import styled from 'styled-components';

export const Graphic = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: nowrap;
  width: 25rem;
  height: 22.5rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.secondaryBorder};
`;

export const TooltipContainer = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.95;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
`;

export const TableHeader = styled.div`
  padding: 1.5rem;

  p {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 0;
  }

  span {
    font-size: 0.875rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.blue};
    line-height: 1rem;
  }
`;
