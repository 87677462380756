import { Empty } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ConfirmationModal } from './ConfirmationModal';
import * as S from './styles';
import { Step5Data, Step5Props } from './types';
import { useSend } from './useSend';

import StyledButton from '../../../../components/Common/StyledButton';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import StyledModal from '../../../../components/Common/StyledModal';

export function Send({ bidId, previousStep, onCancel }: Step5Props) {
  const { t } = useTranslation();
  const {
    bitSendModal,
    step5Data,
    loading,
    setBitSendModal,
    fetchStepSendData,
    renderContent,
    sendEmail,
  } = useSend(bidId);

  const history = useHistory();

  useEffect(() => {
    fetchStepSendData();
  }, []);

  return (
    <>
      <S.ContentForm>
        <StyledModal
          title={t('pages.newRfq.steps.send.modal.title')}
          open={bitSendModal}
          body={<ConfirmationModal />}
          width="45rem"
          footer={null}
          onClose={() => {
            setBitSendModal(false);
            history.push('/rfq');
          }}
        />

        {loading ? (
          <StyledLoading height={50} />
        ) : step5Data.length > 0 ? (
          renderContent(step5Data)
        ) : (
          <Empty />
        )}
      </S.ContentForm>
      <S.StepFooter>
        <StyledButton variant="slim" onClick={() => onCancel()}>
          {t('common.cancel')}
        </StyledButton>
        <S.StepButtonContainer>
          <StyledButton variant="secondary" onClick={() => previousStep()}>
            {t('common.previous')}
          </StyledButton>
          <StyledButton
            variant="primary"
            disabled={step5Data.some((item: Step5Data) => item.saved !== true) || loading}
            onClick={() => sendEmail()}
          >
            {t('common.send')}
          </StyledButton>
        </S.StepButtonContainer>
      </S.StepFooter>
    </>
  );
}
