import * as S from './styles';
import { HeaderCardProps } from './types';

export default function HeaderCard({
  title,
  componentName,
  forMonth,
  description,
}: HeaderCardProps) {
  return (
    <S.Container>
      <S.CbomLabel>{`${title}`}</S.CbomLabel>
      <S.Divider>|</S.Divider>
      {forMonth && (
        <>
          <S.ForMonth>{`${forMonth}`}</S.ForMonth>
          <S.Divider>|</S.Divider>
        </>
      )}
      <S.ComponentName>{componentName}</S.ComponentName>
      {description && (
        <>
          <S.Divider>|</S.Divider>
          <S.ForMonth>{`${description}`}</S.ForMonth>
        </>
      )}
    </S.Container>
  );
}
