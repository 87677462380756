import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledIndicationTag from '../../../components/IndicationTag';
import { handleFormatValueFields } from '../../../helpers/nUtils';
import { getURI, oneAlert } from '../../../helpers/utils';
import { api } from '../../../services/api';
import { services } from '../../../services/services';
import * as S from './styles';
import { ModalBidDetailsItens } from './types';

export function useContentModalShareProportion() {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [itens, setItems] = useState<Array<ModalBidDetailsItens>>([]);

  const columns: ColumnsType<ModalBidDetailsItens> = [
    {
      title: () => {
        return (
          <S.TitleHeaderTable>
            {t('pages.bidResponse.modalColumnLabels.project')}
          </S.TitleHeaderTable>
        );
      },
      dataIndex: 'project',
      key: 'project',
      align: 'left',
      render: (_, record) => {
        return <span>{record.project || '-'}</span>;
      },
    },
    {
      title: () => {
        return (
          <S.TitleHeaderTable>{t('pages.bidResponse.modalColumnLabels.cm')}</S.TitleHeaderTable>
        );
      },
      dataIndex: 'cm',
      key: 'cm',
      align: 'left',
    },
    {
      title: () => {
        return (
          <S.TitleHeaderTable>
            {t('pages.bidResponse.modalColumnLabels.specification')}
          </S.TitleHeaderTable>
        );
      },
      dataIndex: 'specification',
      key: 'specification',
      align: 'left',
    },
    {
      title: () => {
        return (
          <S.TitleHeaderTable>
            {t('pages.bidResponse.modalColumnLabels.qStatus')}
          </S.TitleHeaderTable>
        );
      },
      dataIndex: 'qstatus',
      key: 'qstatus',
      align: 'center',
      render: (_, record) => {
        return (
          <S.ContainerTagsTable>
            <StyledIndicationTag text={record.qstatus || ''} />
          </S.ContainerTagsTable>
        );
      },
    },
    {
      title: () => {
        return (
          <S.TitleHeaderTable>
            {t('pages.bidResponse.modalColumnLabels.partNumber')}
          </S.TitleHeaderTable>
        );
      },
      dataIndex: 'partNumber',
      key: 'partNumber',
      align: 'center',
    },
    {
      title: () => {
        return (
          <S.TitleHeaderTable>{t('pages.bidResponse.modalColumnLabels.demand')}</S.TitleHeaderTable>
        );
      },
      dataIndex: 'demand',
      key: 'demand',
      align: 'center',
      render: (_, record) => {
        return handleFormatValueFields({
          number: record.demand,
          minFractional: 0,
          maxFactional: 0,
        });
      },
    },
  ];

  const handleFetchBidItemDetails = async (id: string, specs: string[]) => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(
        getURI(`${services.rfq}/bid/supplier/detail/${id}`, {
          specifications: specs,
        })
      );
      status === 200 && setItems(data || []);
      status === 204 && setItems([]);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    columns,
    itens,
    handleFetchBidItemDetails,
  };
}
