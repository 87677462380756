import styled from 'styled-components';

export const NotificationsFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

type WrapperProps = {
  seen: boolean;
};
export const NotificationWrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: start;
  margin-bottom: 24px;
  .description {
    font-weight: ${({ seen }) => (seen ? 400 : 600)};
    color: ${({ theme }) => theme.colors.title};
    .data {
      color: ${({ theme }) => theme.colors.link};
    }
  }
`;

export const NotificationDate = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  span {
    color: rgba(0, 0, 0, 0.45);
  }
`;
