import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { handleFormatValueFields } from '../../../../helpers/nUtils';
import * as S from './styles';
import { ChartTableForMonthProps, CustomTooltipProps } from './types';
import { useChartPriceTableForMonth } from './useChartPriceTableForMonth';

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <S.TooltipContainer>
        <p>{`${label}`}</p>
        {payload.map((entry) => {
          return (
            <p key={entry.payload.name} style={{ color: entry.color }}>
              {handleFormatValueFields({ number: entry.value, minFractional: 0, maxFactional: 0 })}
            </p>
          );
        })}
      </S.TooltipContainer>
    );
  }
  return null;
};

export default function PriceTableChart({ data }: ChartTableForMonthProps) {
  const { handleRenderColorByMonth, handleRenderLegend } = useChartPriceTableForMonth();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 25,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={(value: number) =>
            handleFormatValueFields({ number: value, minFractional: 0, maxFactional: 0 })
          }
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend align="right" content={handleRenderLegend} />
        <Bar
          barSize={40}
          dataKey="value"
          label={{
            position: 'top',
            formatter: (value: number) =>
              handleFormatValueFields({ number: value, minFractional: 0, maxFactional: 0 }),
          }}
        >
          {data.map((entry, _) => (
            <Cell key={entry.month} fill={handleRenderColorByMonth(entry.month)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
