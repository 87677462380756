import { Row } from 'antd';
import dayjs from 'dayjs';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined,
  CodepenOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { HeadersData, InOutData, InOutDataItems, Params } from './types';
import { api } from '../../../services/api';
import { getURI, oneAlert } from '../../../helpers/utils';
import * as S from './styles';
import ExpandableTable from '../../ComponentsPrices/ExpandableTable';
import StyledIndicationTag from '../../../components/IndicationTag';
import SimpleTable from '../../../components/Tables/SimpleTable';
import { StyledSelectOptions } from '../../../components/Common/StyledSelect/types';
import StyledFilterTag from '../../../components/Common/StyledFilterTag';
import theme from '../../../styles/theme';
import { formatDataToSelect } from '../../../helpers/formatDataToSelect';
import StyledClearTag from '../../../components/Common/StyledFilterTag/StyledClearTag';
import { useModal } from '../../../components/Modal/useModal';
import { InOutDashboardProps } from './Dashboard/types';
import { useCmsContext } from '../../../context/CmsContext';

export function useInOut() {
  const { t } = useTranslation();
  const { handleGetCms, handleSelectDefaultCm } = useCmsContext();
  const CMs = handleGetCms();
  const initialParams: Params = {
    cm: handleSelectDefaultCm('Foxconn'),
    category: '',
    spec: '',
    partNumber: '',
    source: '',
    date: dayjs(),
  };

  const sources: Array<StyledSelectOptions> = [
    { value: '', label: t('common.showAll') },
    { value: 'FOB', label: 'FOB' },
    { value: 'BR', label: 'BR' },
  ];

  const ExpandCategories: any = {};
  const [expandableViewStatus, setExpandableViewStatus] = useState(ExpandCategories);
  const [inOutComponents, setInOutComponents] = useState<Array<InOutData>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<Array<StyledSelectOptions>>([]);
  const [specifications, setSpecifications] = useState<Array<StyledSelectOptions>>([]);
  const [partNumbers, setPartNumbers] = useState<Array<StyledSelectOptions>>([]);
  const [params, setParams] = useState<Params>(initialParams);
  const [visibleDashbord, openDashbord, closeDashard] = useModal();
  const [modalData, setModalData] = useState<InOutDashboardProps>({
    partNumber: '',
    category: '',
    cm: '',
    source: '',
    specification: '',
    description: '',
  });

  const handleUpdateExpandableViews = (value: string) => {
    setExpandableViewStatus((oldParams: any) => {
      return {
        ...oldParams,
        [value]: !expandableViewStatus[value],
      };
    });
  };

  const handleFormatValueFields = (number: string | number): string => {
    try {
      return Number(number).toLocaleString(t('common.localeMoney'), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } catch (error) {
      console.log(error);
      return '-';
    }
  };

  const handleFormatValueQtyFields = (number: string | number): string => {
    try {
      return Number(number).toLocaleString(t('common.localeMoney'), {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } catch (error) {
      console.log(error);
      return '-';
    }
  };

  const updateParams = (field: string, value: string) => {
    setParams((prevState) => {
      switch (field) {
        case 'cm':
          return {
            ...prevState,
            [field]: value,
            category: '',
            spec: '',
            source: '',
            partNumber: '',
          };
        case 'category':
          return {
            ...prevState,
            [field]: value,
            spec: '',
            source: '',
            partNumber: '',
          };
        case 'spec':
          return {
            ...prevState,
            [field]: value,
            source: '',
            partNumber: '',
          };
        case 'source':
          return {
            ...prevState,
            [field]: value,
            partNumber: '',
          };
        case 'partNumber':
          return {
            ...prevState,
            [field]: value,
          };

        default:
          return {
            ...prevState,
            [field]: value,
          };
      }
    });
  };

  const hasParams = () => {
    return params.category || params.spec || params.partNumber || params.source;
  };

  const handleFetchComponents = async () => {
    try {
      setIsLoading(true);
      const { data, status }: { data: Array<InOutData>; status: number } = await api.get(
        getURI('/inout', {
          ...params,
          date: params.date?.format('MMMM/YYYY').toLocaleUpperCase(),
        })
      );
      if (status === 200) {
        setInOutComponents(data);
        if (hasParams()) {
          setExpandableViewStatus({ [data[0].category]: true });
        } else setExpandableViewStatus({});
      }
      status === 204 && setInOutComponents([]);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
      setInOutComponents([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchHeaders = async () => {
    try {
      const { data, status }: { data: HeadersData; status: number } = await api.get(
        getURI('/inout/header', {
          ...params,
          cm: params.cm,
          date: params.date?.format('MMMM/YYYY').toLocaleUpperCase(),
        })
      );
      if (status === 200) {
        setCategories(formatDataToSelect(data.categoryList, true));
        setSpecifications(formatDataToSelect(data.specList, true));
        setPartNumbers(formatDataToSelect(data.partNumberList, true));
      }
      if (status === 204) {
        setCategories([]);
        setSpecifications([]);
        setPartNumbers([]);
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const columns: ColumnsType<InOutDataItems> = [
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.partNumber')}</S.ColumnHeader>;
      },
      dataIndex: 'partNumber',
      align: 'left',
      width: '9.625rem',
      render: (value, record) => {
        return record.isTotal ? (
          <S.NormalField isTotal={record.isTotal}>{value || '-'}</S.NormalField>
        ) : (
          <S.PartNumberData
            onClick={() => {
              setModalData({
                partNumber: record.partNumber,
                category: record.category,
                cm: record.cm,
                source: record.origin,
                specification: record.type,
                description: record.model,
              });
              openDashbord();
            }}
          >
            {value}
          </S.PartNumberData>
        );
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.specification')}</S.ColumnHeader>;
      },
      dataIndex: 'type',
      align: 'left',
      width: '10.5rem',
      render: (value: string, record) => {
        if (record.isTotal) {
          return '';
        }
        return <S.TableData>{value || '-'}</S.TableData>;
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.source')}</S.ColumnHeader>;
      },
      dataIndex: 'source',
      align: 'center',
      width: '6.625rem',
      render: (_, record) => {
        if (record.isTotal) {
          return null;
        }
        return <StyledIndicationTag text={record.origin} />;
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.cmPn')}</S.ColumnHeader>;
      },
      dataIndex: 'cmPartNumber',
      align: 'left',
      width: '10.375rem',
      render: (value: string, record) => {
        if (record.isTotal) {
          return '';
        }
        return <S.TableData>{value || '-'}</S.TableData>;
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.mba')}</S.ColumnHeader>;
      },
      dataIndex: 'mba',
      align: 'right',
      width: '6.5rem',
      render: (value, record) => {
        return (
          <S.NormalField isTotal={record.isTotal}>
            {handleFormatValueQtyFields(value) || '-'}
          </S.NormalField>
        );
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.receive')}</S.ColumnHeader>;
      },
      dataIndex: 'received',
      align: 'right',
      width: '6.5rem',
      render: (value, record) => {
        if (record.isTotal) {
          console.log('record', value);
        }
        return (
          <S.NormalField isTotal={record.isTotal}>
            {value ? handleFormatValueQtyFields(value) : '-'}
          </S.NormalField>
        );
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.return')}</S.ColumnHeader>;
      },
      dataIndex: 'returned',
      align: 'right',
      width: '6.5rem',
      render: (value, record) => {
        return (
          <S.NormalField isTotal={record.isTotal}>
            {value ? handleFormatValueQtyFields(value) : '-'}
          </S.NormalField>
        );
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.consumed')}</S.ColumnHeader>;
      },
      dataIndex: 'consumed',
      align: 'right',
      width: '6.5rem',
      render: (value, record) => {
        return (
          <S.NormalField isTotal={record.isTotal}>
            {value ? handleFormatValueQtyFields(value) : '-'}
          </S.NormalField>
        );
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.scrap')}</S.ColumnHeader>;
      },
      dataIndex: 'scrap',
      align: 'right',
      width: '6.5rem',
      render: (value, record) => {
        return (
          <S.NormalField isTotal={record.isTotal}>
            {value ? handleFormatValueQtyFields(value) : '-'}
          </S.NormalField>
        );
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.endMonth')}</S.ColumnHeader>;
      },
      dataIndex: 'end',
      align: 'right',
      width: '7.25rem',
      render: (value, record) => {
        return (
          <S.NormalField isTotal={record.isTotal}>
            {value ? handleFormatValueQtyFields(value) : '-'}
          </S.NormalField>
        );
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.currentMonth')}</S.ColumnHeader>;
      },
      dataIndex: 'currentMonth',
      align: 'right',
      width: '7.25rem',
      render: (value, record) => {
        return (
          <S.NormalField isTotal={record.isTotal}>
            {value ? `$${handleFormatValueFields(value)}` : '-'}
          </S.NormalField>
        );
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.nextMonth')}</S.ColumnHeader>;
      },
      dataIndex: 'nextMonth',
      align: 'right',
      width: '7.25rem',
      render: (value, record) => {
        return (
          <S.NormalField isTotal={record.isTotal}>
            {value ? `$${handleFormatValueFields(value)}` : '-'}
          </S.NormalField>
        );
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.variance')}</S.ColumnHeader>;
      },
      dataIndex: 'variance',
      align: 'right',
      width: '6.5rem',
      render: (value, record) => {
        return (
          <S.NormalField isTotal={record.isTotal}>
            {value ? (
              record.varianceStatus ? (
                <S.VarianceColor status={record.varianceStatus}>
                  <ArrowUpOutlined /> ${handleFormatValueFields(value)}
                </S.VarianceColor>
              ) : (
                <S.VarianceColor status={record.varianceStatus}>
                  <ArrowDownOutlined /> ${handleFormatValueFields(value)}
                </S.VarianceColor>
              )
            ) : (
              '-'
            )}
          </S.NormalField>
        );
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.creditDebit')}</S.ColumnHeader>;
      },
      dataIndex: 'credit/debit',
      align: 'right',
      width: '8.5rem',
      render: (value, record) => {
        return (
          <S.NormalField isTotal={record.isTotal}>
            {value ? `$${handleFormatValueFields(value)}` : '-'}
          </S.NormalField>
        );
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.tax')}</S.ColumnHeader>;
      },
      dataIndex: 'tax',
      align: 'right',
      width: '8.5rem',
      render: (value, record) => {
        return (
          <S.NormalField isTotal={record.isTotal}>
            {value ? `$${handleFormatValueFields(value)}` : '-'}
          </S.NormalField>
        );
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.total')}</S.ColumnHeader>;
      },
      dataIndex: 'total',
      align: 'right',
      width: '8.5rem',
      render: (value, record) => {
        return (
          <S.NormalField isTotal={record.isTotal}>
            {value ? `$${handleFormatValueFields(value)}` : '-'}
          </S.NormalField>
        );
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.inOut.tablesHeaders.totalQuarter')}</S.ColumnHeader>;
      },
      dataIndex: 'totalQuarter',
      align: 'right',
      width: '8.5rem',
      render: (value, record) => {
        return (
          <S.NormalField isTotal={record.isTotal}>
            {value ? `$${handleFormatValueFields(value)}` : '-'}
          </S.NormalField>
        );
      },
    },
  ];

  const handleRenderSourceTag = (items: Array<InOutDataItems>, type: 'FOB' | 'BR') => {
    if (items.some((item) => item.origin === type)) {
      return <StyledIndicationTag text={type} />;
    }
  };

  const handleRenderTablesComponents = () => {
    return inOutComponents.map((item: InOutData) => {
      const totalRow = {
        partNumber: t('pages.inOut.tablesHeaders.total'),
        specification: '',
        origin: '',
        cmPartnumber: '',
        mba: item.totalMba,
        received: item.totalReceived,
        returned: item.totalReturn,
        consumed: item.totalConsumed,
        scrap: item.totalScrap,
        end: item.totalEnd,
        currentMonth: item.totalCurrentMonth,
        nextMonth: item.totalNextMonth,
        isTotal: true,
      };
      return (
        <S.TablesContainer key={item.category} isOpen={expandableViewStatus[item.category]}>
          <S.HeaderExpandableItem>
            <ExpandableTable
              isOpen={expandableViewStatus[item.category]}
              onClick={() => {
                handleUpdateExpandableViews(item.category);
              }}
            />
            <S.HeaderExpandableContainer>
              <div>
                <span>{item.category}</span>
                <Row>
                  {handleRenderSourceTag(item.items, 'FOB')}
                  {handleRenderSourceTag(item.items, 'BR')}
                </Row>
              </div>
              <div>
                <S.ComponentsQntContainer>
                  <p>
                    <CodepenOutlined style={{ fontSize: 28 }} />
                  </p>
                  <p>{item.items.length}</p>
                  <p className="partNumbers">{t('pages.inOut.partNumbers')}</p>
                </S.ComponentsQntContainer>
              </div>
            </S.HeaderExpandableContainer>
          </S.HeaderExpandableItem>
          {expandableViewStatus[item.category] && (
            <S.TableContent>
              <SimpleTable
                striped
                bordered={false}
                pagination={false}
                dataSource={[...item.items, totalRow]}
                rowKey="partNumber"
                columns={columns}
                noBorder
                scroll={{ x: 'max-content', y: 'auto' }}
                scrollable
              />
            </S.TableContent>
          )}
        </S.TablesContainer>
      );
    });
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {params.category && (
          <StyledFilterTag
            label={t('pages.inOut.labels.category')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.category}
            closeble
            onClose={() => {
              updateParams('category', '');
            }}
          />
        )}
        {params.spec && (
          <StyledFilterTag
            label={t('pages.inOut.labels.specification')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.spec}
            closeble
            onClose={() => {
              updateParams('specification', '');
            }}
          />
        )}
        {params.source && (
          <StyledFilterTag
            label={t('pages.inOut.labels.source')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.source}
            closeble
            onClose={() => {
              updateParams('source', '');
            }}
          />
        )}
        {params.partNumber && (
          <StyledFilterTag
            label={t('pages.inOut.labels.partNumber')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.partNumber}
            closeble
            onClose={() => {
              updateParams('partNumber', '');
            }}
          />
        )}
      </>
    );
  };

  const clearAllFilters = () => {
    setParams(initialParams);
  };

  const handleRenderClearAllFilters = () => {
    if (params.category || params.spec || params.partNumber || params.source) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  return {
    inOutComponents,
    isLoading,
    CMs,
    sources,
    params,
    categories,
    specifications,
    partNumbers,
    visibleDashbord,
    modalData,
    closeDashard,
    handleFetchComponents,
    handleFetchHeaders,
    handleRenderTablesComponents,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    updateParams,
  };
}
