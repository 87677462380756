import { useEffect, useRef } from 'react';
import { Col, ConfigProvider, Popconfirm, Row, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import { EditButton } from './styles';
import * as S from './styles';
import * as SC from '../../styles/common';
import { usePBB } from './usePPB';
import { CreateProduct } from './Form/createProduct';
import { renderDate } from '../../helpers/utils';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { Loading } from '../../components/Loading';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { Button } from '../../components/Button';
import SimpleTable from '../../components/Tables/SimpleTable';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledButton from '../../components/Common/StyledButton';
import StyledModal from '../../components/Common/StyledModal';

export function PPBPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.ppbProducts.title'));

  const {
    data,
    params,
    fetchPPBProducts,
    isLoading,
    form,
    save,
    isEditing,
    deleteItem,
    onCloseModal,
    visible,
    openModal,
    openModalEdit,
    goToRulesPPB,
    isDropdownVisible,
    setIsDropdownVisible,
  } = usePBB();

  const modalRef = useRef(null);
  const modalTitle = `${isEditing ? t('common.edit') : t('common.add')} ${t(
    'pages.ppbProducts.fields.product'
  )}`;

  const columns = [
    {
      title: t('pages.ppbProducts.fields.productName'),
      dataIndex: 'productName',
      key: 'productName',
      width: '8.75rem',
      render: (value) => {
        return <Typography.Link>{value}</Typography.Link>;
      },
    },
    {
      title: t('pages.ppbProducts.fields.targetScore'),
      dataIndex: 'targetScore',
      key: 'targetScore',
      align: 'center',
      width: '5rem',
      render: (value) => {
        return <SC.SpanBlue>{value}</SC.SpanBlue>;
      },
    },
    {
      title: t('pages.ppbProducts.fields.description'),
      dataIndex: 'description',
      key: 'description',
      width: '26rem',
      render: (value, row) => {
        return !row.description ? '-' : value;
      },
    },
    {
      title: t('pages.ppbProducts.fields.inForceDate'),
      dataIndex: 'inforceDate',
      key: 'inforceDate',
      align: 'center',
      width: '8.5rem',
      render: (value) => {
        return value && renderDate(value, '2-digit');
      },
    },
    {
      title: t('pages.ppbProducts.fields.publishedDate'),
      dataIndex: 'publishedDate',
      key: 'publishedDate',
      align: 'center',
      width: '8.5rem',
      render: (value) => {
        return value && renderDate(value, '2-digit');
      },
    },
    {
      title: t('pages.ppbProducts.fields.modified'),
      dataIndex: 'modified',
      key: 'modified',
      align: 'center',
      width: '8.5rem',
      render: (value) => {
        return value && renderDate(value, '2-digit');
      },
    },
    {
      title: t('common.action'),
      key: 'action',
      align: 'center',
      width: 160,
      render: (_, record) => {
        const actions = [
          {
            key: '1',
            label: (
              <EditButton
                type="link"
                onClick={(e) => {
                  setIsDropdownVisible(false);
                  openModalEdit(e, record);
                }}
                data-cy="edit"
              >
                {t('common.edit')}
              </EditButton>
            ),
          },
          {
            key: '2',
            label: (
              <Popconfirm
                title={t('common.deleteMessage')}
                onCancel={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                onConfirm={async (e) => {
                  e.stopPropagation();
                  await deleteItem(record.id);
                }}
              >
                <Button type="link" danger data-cy="delete">
                  {t('common.delete')}
                </Button>
              </Popconfirm>
            ),
          },
        ];

        return <TableAction dropdownItems={actions} />;
      },
    },
  ];

  useEffect(() => {
    fetchPPBProducts();
  }, [params]);

  return (
    <S.Container>
      {isLoading ? (
        <Row>
          <Loading />
        </Row>
      ) : (
        <Col span={24} className="filter-page">
          <Row justify="center">
            <Col span={24}>
              <StyledHeader
                title={t('pages.ppbProducts.title')}
                actions={
                  <StyledButton
                    variant="primary"
                    iconLeft={<BiPlus />}
                    onClick={openModal}
                    text={`${t('common.add')} ${t('pages.ppbProducts.fields.product')}`}
                  />
                }
              />
            </Col>
          </Row>

          <Row justify="center">
            <Col span={24}>
              {isLoading ? (
                <Loading />
              ) : (
                <ConfigProvider>
                  <SimpleTable
                    rowKey="id"
                    data-cy="table"
                    striped
                    dataSource={data}
                    columns={columns}
                    pagination={{
                      current: params.page + 1,
                      pageSize: params.limit,
                      hideOnSinglePage: true,
                    }}
                    onRow={(record) => ({
                      onClick: () => goToRulesPPB(record),
                    })}
                    locale={{
                      emptyText: (
                        <ResultNoScenarios
                          title={t('common.results.noDataTitle')}
                          subTitle={t('common.results.noDataSubtitle')}
                        />
                      ),
                    }}
                  />
                </ConfigProvider>
              )}
            </Col>
          </Row>
        </Col>
      )}

      {visible && (
        <StyledModal
          title={modalTitle}
          ref={modalRef}
          open={visible}
          onClose={onCloseModal}
          key="modal-pbb"
          width="46rem"
          body={
            <CreateProduct onClose={onCloseModal} form={form} save={save} loading={isLoading} />
          }
          footer={null}
        />
      )}
    </S.Container>
  );
}
