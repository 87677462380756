import * as S from './styles';
import StyledCheckboxProps from './types';

export default function StyledCheckbox({
  value,
  options,
  defaultValue,
  onChange,
}: StyledCheckboxProps) {
  return (
    <S.checkbox value={value} options={options} defaultValue={defaultValue} onChange={onChange} />
  );
}
