import styled from 'styled-components';
import { Menu, Switch as AntSwitch } from 'antd';
import { BellOutlined } from '@ant-design/icons';

const { Item, SubMenu: AntdSubMenu } = Menu;

export const Switch = styled(AntSwitch)`
  && {
    background-color: ${(props) => props.theme.colors.link};
  }
  :hover {
    background-color: ${(props) => props.theme.colors.link} !important;
  }
`;

export const MenuButtonContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  .menu-btn {
    color: ${(props) => props.theme.colors.text_light};
    font-size: 14px;
    padding-right: 8px;
    text-transform: uppercase;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  height: 100%;

  .ant-menu-light {
    padding: 0.25rem;
  }

  .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: ${({ theme }) => theme.colorsDesignSystem.white};
  }

  .ant-menu-submenu-selected {
    background: ${({ theme }) => theme.colorsDesignSystem.primary};
  }
`;

export const LogoutContainer = styled.div`
  cursor: pointer;
  margin-top: auto;
  text-align: right;
`;

export const LogoutLink = styled.span`
  color: ${(props) => props.theme.colors.link};
`;

export const Label = styled.span`
  color: ${(props) => props.theme.colors.title};
  padding-left: 1rem;
  padding-right: 0.5rem;
`;

export const BellIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colorsDesignSystem.primary};
`;

export const BellOutlinedIcon = styled(BellOutlined)`
  color: ${(props) => props.theme.colors.white};
  font-size: 1.25rem;
`;
