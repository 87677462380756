import { useEffect } from 'react';

export function SingleLogin() {
  const URL = '';

  useEffect(() => {
    window.location.href = 'https://www.hml.acer-loginunico.com/';
  }, []);
  return null;
}
