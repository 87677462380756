import styled from 'styled-components';
import { Row } from 'antd';
import SimpleTable from '../../../../../components/Tables/SimpleTable';
import StyledButton from '../../../../../components/Common/StyledButton';

interface ColoredHiligthProps {
  color: string;
}

interface LegendPriceProps {
  type: 'LOWER' | 'HIGHER' | 'NOCHANGE';
}

interface TitleTableContainerProps {
  type: 'CENTER' | 'LEFT' | 'RIGTH';
}

export const SubCardHeader = styled(Row)`
  border-bottom: solid 1px ${({ theme }) => theme.colorsDesignSystem.grayDivider};
  justify-content: space-between;
`;

export const SubCardHeaderText = styled.div`
  color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
  font-weight: bold;
  font-size: 0.875rem;
  padding: 0.8rem 1.4rem;
`;

export const SkuComponentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BodyContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 17rem;
  justify-content: center;
  width: 99%;
  .ant-table-tbody {
  }
  overflow-y: scroll;
`;

export const TableContainer = styled.div`
  width: 33%;
  height: fit-content;
  align-items: center;
  justify-content: center;
  border: solid 1px ${({ theme }) => theme.colorsDesignSystem.grayBorder};
  &:first-child {
    border-radius: 0.25rem 0 0 0;
  }
  &:last-child {
    border-radius: 0 0.25rem 0 0;
  }
`;

export const SkuComponentsTable = styled(SimpleTable)`
  .ant-table-content table thead > tr:nth-child(-n + 1) > th {
    border: none !important;
    border-radius: 0 !important;
  }
  .ant-table-content table thead > tr:nth-child(-n + 1) > th:first-child {
    border: none !important;
    border-radius: 0 !important;
  }
  .ant-table-content table tbody > tr > td {
    border: none !important;
  }
  .ant-table-content table tbody > tr > td:last-child {
    border: none !important;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.header};
  height: fit-content;
  text-align: center;
  padding: 0.75rem 0;
  border-radius: 0.25rem 0.25rem 0 0;
`;

export const ComponentText = styled.div`
  cursor: pointer;
  padding: 0rem 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  font-weight: bold;
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
  justify-content: end;
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
`;

export const HighlightDivSkuColor = styled.div<ColoredHiligthProps>`
  height: 0.2rem;
  background-color: ${({ color }) => color};
`;

export const LegendPrice = styled.div<LegendPriceProps>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  background-color: ${({ type, theme }) =>
    type === 'HIGHER'
      ? theme.colorsDesignSystem.red
      : type === 'NOCHANGE'
      ? 'transparent'
      : theme.colorsDesignSystem.blue};
`;

export const TitleTableContainer = styled.div<TitleTableContainerProps>`
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.externalTitle};
  display: flex;
  padding: 0rem 1rem;
  justify-content: ${({ type }) =>
    type === 'CENTER' ? 'center' : type === 'LEFT' ? 'flex-start' : 'flex-end'};
`;

export const StyledIndicationTagContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const StyledCmQuotesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 3.5rem;
`;

export const FixedHeader = styled.div`
  top: 0;
  position: sticky;
  z-index: 100;
`;

export const SkuHeaderText = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
`;

export const CmQuotesAlligner = styled.div`
  display: flex;
  justify-content: end;
  padding: 0rem 1rem;
`;

export const SeeQuotesButton = styled(StyledButton)`
  max-width: 6.688rem;
  align-self: self-end;
  margin-right: 2rem;
`;
