import { useEffect, useRef } from 'react';
import { Breadcrumb, Col, Descriptions, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { StyledPageHeader } from '../../../components/Common/StyledPageHeader/styles';
import { ActionButton } from '../../../components/ActionButton';
import { HeaderTitle } from '../../../components/PageHeader/HeaderTitle';
import { useRulesPPB } from './useRulesPPB';
import { CreateRulesPPB } from './Form/createRule';
import { renderDate } from '../../../helpers/utils';
import { ResultNoScenarios } from '../../../components/ResultNoScenarios';
import { Loading } from '../../../components/Loading';
import * as S from '../styles';
import * as SC from '../../../styles/common';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import SimpleTable from '../../../components/Tables/SimpleTable';
import { RoutesPath } from '../../../routes/routesPath';
import StyledModal from '../../../components/Common/StyledModal';
import { UpdateDate } from '../../../components/Common/UpdateDate';

export function RulesPPBPage({ match }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.ppbProducts.title'));

  const {
    data,
    productInfo,
    fetchProductInfo,
    fetchRulesPPB,
    loadingTitles,
    isLoading,
    form,
    save,
    isEditing,
    visible,
    openModal,
    onCloseModal,
    columns,
    params,
    handleCloseFilteredTag,
    openModalCreate,
  } = useRulesPPB();

  const modalRef = useRef(null);
  const modalTitle = `${isEditing ? t('common.edit') : t('common.add')} ${t('common.rule')}`;
  const showTagsFilter =
    params.header_description ||
    params.header_partTypes ||
    params.header_calcBase ||
    params.header_bonusStage;

  useEffect(() => {
    fetchProductInfo();
  }, []);

  useEffect(() => {
    fetchRulesPPB();
  }, [params]);

  return (
    <S.Container>
      {isLoading ? (
        <Row>
          <Loading />
        </Row>
      ) : (
        <Col span={24} className="filter-page">
          <Row justify="center">
            <Col span={24}>
              <StyledPageHeader
                title={
                  <HeaderTitle
                    breadcrumb={
                      <Breadcrumb separator="|" className="with-breacrumb">
                        <Breadcrumb.Item>
                          <Link to={RoutesPath.ppb}>
                            <AiOutlineLeft /> {t('pages.ppbProducts.title')}
                          </Link>
                        </Breadcrumb.Item>
                        {!loadingTitles && (
                          <Breadcrumb.Item>
                            {productInfo?.productName} -{' '}
                            <SC.SpanBlueBig>{productInfo?.targetScore}</SC.SpanBlueBig>
                          </Breadcrumb.Item>
                        )}
                      </Breadcrumb>
                    }
                  />
                }
                className="pl-0 pr-0"
                extra={[
                  <ActionButton
                    key={1}
                    icon={<BiPlus />}
                    onClick={openModalCreate}
                    buttonText={`${t('common.add')} ${t('common.rule')}`}
                  />,
                ]}
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <S.ContainerInfo>
                <Descriptions size="small" layout="vertical">
                  <Descriptions.Item label={t('pages.ppbProducts.fields.description')}>
                    {productInfo?.description}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('pages.ppbProducts.fields.inForceDate')}>
                    {productInfo?.inforceDate && renderDate(productInfo.inforceDate, '2-digit')}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('pages.ppbProducts.fields.publishedDate')}>
                    {productInfo?.publishedDate && renderDate(productInfo.publishedDate, '2-digit')}
                  </Descriptions.Item>
                </Descriptions>
              </S.ContainerInfo>
            </Col>
          </Row>

          <Row justify="center">
            <Col span={24}>
              <SC.ContainerFilteredTags>
                {showTagsFilter && <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>}
                {params.header_description && (
                  <SC.TagFilter
                    closable
                    onClose={() => handleCloseFilteredTag('header_description')}
                  >
                    <div className="tag-title">{t('pages.ppb.fields.description')}</div>
                    <div className="tag-value">{params.header_description}</div>
                  </SC.TagFilter>
                )}
                {params.header_calcBase && (
                  <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_calcBase')}>
                    <div className="tag-title">{t('pages.ppb.fields.calcBase')}</div>
                    <div className="tag-value">{params.header_calcBase}</div>
                  </SC.TagFilter>
                )}
                {params.header_partTypes && (
                  <SC.TagFilter closable onClose={() => handleCloseFilteredTag('header_partTypes')}>
                    <div className="tag-title">{t('pages.ppb.fields.partTypes')}</div>
                    <div className="tag-value">{params.header_partTypes}</div>
                  </SC.TagFilter>
                )}
                {params.header_bonusStage && (
                  <SC.TagFilter
                    closable
                    onClose={() => handleCloseFilteredTag('header_bonusStage')}
                  >
                    <div className="tag-title">{t('pages.ppb.fields.bonusStage')}</div>
                    <div className="tag-value">{params.header_bonusStage}</div>
                  </SC.TagFilter>
                )}
              </SC.ContainerFilteredTags>
            </Col>
          </Row>

          <Row justify="center">
            <Col span={24}>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <UpdateDate date={productInfo?.modified} />
                  <Form form={form} component={false}>
                    <SimpleTable
                      rowKey="id"
                      data-cy="table"
                      striped
                      dataSource={data}
                      columns={columns}
                      pagination={{
                        current: 1,
                        pageSize: 25,
                        hideOnSinglePage: true,
                      }}
                      scroll={{ x: 'max-content', y: 'calc(100vh - 24rem)' }}
                      locale={{
                        emptyText: (
                          <ResultNoScenarios
                            title={t('common.results.noDataTitle')}
                            subTitle={t('common.results.noDataSubtitle')}
                          />
                        ),
                      }}
                    />
                  </Form>
                </>
              )}
            </Col>
          </Row>
        </Col>
      )}

      {visible && (
        <StyledModal
          title={modalTitle}
          onClose={onCloseModal}
          open
          body={
            <CreateRulesPPB onClose={onCloseModal} form={form} save={save} loading={isLoading} />
          }
          footer={null}
        />
      )}
    </S.Container>
  );
}
