import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartData, Data, InOutCardProps } from './types';
import { api } from '../../../../../services/api';
import { getURI, oneAlert } from '../../../../../helpers/utils';
import InOutChart from './chart';

export function useDashboardInOut(props: InOutCardProps) {
  const { t } = useTranslation();
  const [expandableViewStatus, setExpandableViewStatus] = useState(true);
  const [dataChart, setDataChart] = useState<Array<ChartData>>();
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateExpandableViews = () => {
    setExpandableViewStatus(!expandableViewStatus);
  };
  const handleFetchData = async () => {
    try {
      setIsLoading(true);
      const { data, status }: Data = await api.get(
        getURI('/inout/dashboard', {
          partNumber: props.partNumber,
          quarter: props.quarter,
        })
      );
      if (status === 200) {
        setDataChart(data);
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChart = () => {
    return dataChart?.map((item) => {
      return (
        <InOutChart
          key={item.date}
          date={item.date}
          mba={item.mba}
          received={item.received}
          returned={item.returned}
          consumed={item.consumed}
          scrap={item.scrap}
          end={item.end}
          hasData={item.hasData}
        />
      );
    });
  };
  return {
    expandableViewStatus,
    isLoading,
    handleUpdateExpandableViews,
    handleFetchData,
    handleChart,
  };
}
