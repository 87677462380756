import ExpandableItem from '../ExpandableItem';
import * as S from './styles';
import { ExpandableCardProps } from './types';

export default function ExpandableCard({
  headerCard,
  children,
  onExpandableContent,
  isOpen,
}: ExpandableCardProps) {
  return (
    <S.Container>
      <S.Header noBorder={isOpen} onClick={onExpandableContent}>
        <ExpandableItem isOpen={isOpen} onClick={onExpandableContent} />
        {headerCard}
      </S.Header>
      {isOpen && <S.Content>{children}</S.Content>}
    </S.Container>
  );
}
