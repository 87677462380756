import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectInput } from '../../LateralFilter/SelectInput';
import { api } from '../../../services/api';
import { getURI } from '../../../helpers/utils';
import StyledSelect from '../../Common/StyledSelect';

export function ComponentsTypeSelect({ ...props }) {
  const { t } = useTranslation();
  const labelNone =
    props?.id === 'partTypeSimulation'
      ? `${t('common.showAll')} - ${t('filter.partType')}`
      : t('common.none');
  const [options, setOptions] = useState([]);

  const getComponents = async () => {
    const urlComponentType = props.scenario
      ? `componentType/${props.scenario}`
      : props?.ppb
      ? `componentType/ppb`
      : `componentType`;

    const {
      data: { content: componentType },
    } = await api.get(getURI(urlComponentType));

    if (componentType?.length) {
      const formatOptions = componentType
        .filter((opt) => opt.name?.length)
        .map((opt) => ({ label: opt.name, value: opt.name }));
      setOptions(formatOptions);
    }
  };

  useEffect(() => {
    (async () => {
      await getComponents();
    })();
  }, []);

  return (
    <StyledSelect
      showSearch
      placeholder={t('pages.simulation.placeholder.category')}
      name="SelectPartType"
      id="SelectPartType"
      allowClear
      options={[{ label: labelNone, value: '' }, ...options]}
      {...props}
    />
  );
}
